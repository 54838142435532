import React, { useState } from 'react';
import classNames from 'classnames';
import { FieldTextButton } from '../buttons/FieldTextButton';
import { SpriteIcon, SpriteIconProps } from '../icons/SpriteIcon';

export type Props = {
  name: string;
  label?: string;
  placeholder: string;
  type: React.HTMLInputTypeAttribute;
  isDisabled?: boolean;
  autoComplete?: string;
  required?: boolean;
  value: string;
  updateValue: (value: string) => void;
  error?: string | null;
  setError?: (error: string | null) => void;
  fixedHeight?: boolean;
  withoutPadding?: boolean;
  isModalInput?: boolean;
  iconName?: SpriteIconProps;
  bgColor?: string;
  styles?: string;
};

export const SimpleInput: React.FC<Props> = ({
  label,
  placeholder,
  type,
  isDisabled,
  autoComplete,
  name,
  required,
  value,
  updateValue: setValue,
  error,
  setError,
  fixedHeight = true,
  withoutPadding = false,
  isModalInput = false,
  bgColor,
  iconName,
  styles,
}) => {
  const [isPasswordDisplayed, setIsPasswordDisplayed] = useState(false);

  const isPasswordField = type === 'password';

  const toggleHiddenPassword = () =>
    setIsPasswordDisplayed(!isPasswordDisplayed);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
    if (!setError) return;
    if (required && !e.currentTarget.value) {
      setError('This field is required');
    } else {
      setError(null);
    }
  };

  return (
    <div className={`relative ${fixedHeight ? 'h-[104px]' : ''} w-full`}>
      {label ? (
        <label className="text-p-small text-gray p-1">{label}</label>
      ) : null}
      <div
        className={classNames(
          'flex items-center w-full',
          {
            'rounded-xl': !styles?.includes('rounded'),
            'border-red border-[1px]': !!error,
            'w-full text-black rounded-48': isModalInput,
            'bg-white py-0': isModalInput,
            'px-3 py-4': !withoutPadding,
            'p-0': withoutPadding,
            'bg-gray-light': !bgColor,
            [`bg-${bgColor}`]: !!bgColor,
          },
          styles,
        )}
      >
        {iconName && (
          <SpriteIcon iconName={iconName} className="w-5 h-5 fill-black mr-2" />
        )}
        <input
          name={name}
          value={value}
          className={classNames(
            'w-full bg-clip-content text-black text-[16px] font-medium placeholder:text-gray',
            'outline-none caret-purple disabled:text-gray peer',
            {
              'bg-gray-light': !bgColor,
              [`bg-${bgColor}`]: !!bgColor,
              'text-black placeholder:text-gray bg-white': isModalInput,
            },
          )}
          disabled={isDisabled}
          type={isPasswordDisplayed ? 'text' : type}
          required={required}
          placeholder={placeholder}
          onChange={handleChange}
          autoComplete={autoComplete}
        />
        {isPasswordField && (
          <FieldTextButton onClick={toggleHiddenPassword}>
            {isPasswordDisplayed ? 'Hide' : 'Show'}
          </FieldTextButton>
        )}
      </div>
      {error && <p className="text-p-small text-red p-1">{error}</p>}
    </div>
  );
};
