import mapImg from '@assets/images/landingPage/mob-img-1.webp';
import shareTripImg from '@assets/images/landingPage/mob-img-2.webp';
import hiddenGemsImg from '@assets/images/landingPage/mob-img-3.webp';
import interestsImg from '@assets/images/landingPage/mob-img-4.webp';
import catalogueCardsImg from '@assets/images/landingPage/mob-img-5.webp';
import experienceCardsImg from '@assets/images/landingPage/mob-img-6.webp';

import shareTripLarge from '@assets/images/landingPage/desk-img-2.webp';
import interestsImgLarge from '@assets/images/landingPage/desk-img-4.webp';
import experienceCardsImgLarge from '@assets/images/landingPage/desk-img-6.webp';
import experienceCardsBgImgLarge from '@assets/images/landingPage/desk-bg-img-6.svg';

import { ArticleCardTypes } from '@/types/ArticleCardTypes';
import { InterestLandingTypes } from '@/types/InterestLandingTypes';

export const featureList: ArticleCardTypes[] = [
  {
    reverse: true,
    image: mapImg,
    imageRounded: true,
    title: 'Get started in a snap',
    text: 'Just tell us your destination, dates, and number of travel companions. Our AI will craft a detailed itinerary in minutes, including daily plans, a real-time map, and top-rated recommendations in the area.',
  },
  {
    reverse: true,
    image: shareTripImg,
    isLargeVariantExists: true,
    imageRounded: true,
    textColor: 'text-white',
    backgroundColor: 'bg-purple',
    largeImage: shareTripLarge,
    imageFit: 'cover',
    title: 'Next, get your crew involved',
    text: 'Round up your people for the adventure. Once everyone’s vibes are locked in with a vote, our AI will map out the ultimate route based on what you and your travel pals all want.',
  },
  {
    reverse: true,
    image: hiddenGemsImg,
    imageRounded: true,
    textColor: 'text-white',
    backgroundColor: 'bg-purple',
    imagePosition: 'top',
    imageFit: 'cover',
    title: 'Find those hidden local gems',
    text: 'Keep your IG stories drool-worthy. Picks and suggestions are selected using review aggregation from across the web. No duds or weird back alley dead ends -- just a bunch of cool backdrops, yummy food, and memorable experiences.',
  },
  {
    reverse: true,
    image: interestsImg,
    imageRounded: true,
    isLargeVariantExists: true,
    largeImage: interestsImgLarge,
    title: 'Design your “best ever” trip',
    text: 'Customize your plan around accessible hotel location, dietary needs, group sizes, ages, and more. You can start off on the right foot at the very beginning by setting preferred activities, style, and budget.',
  },
  {
    reverse: true,
    imageRounded: true,
    image: catalogueCardsImg,
    title: 'Feeling adventurous 🤠?',
    text: 'Give your itinerary a playful shake – use our shuffle button to mix up your schedule, add new activities, and keep the ones you love, all with the help of our AI.',
  },
  {
    reverse: true,
    imageRounded: true,
    image: experienceCardsImg,
    isLargeVariantExists: true,
    textColor: 'text-white',
    backgroundColor: 'bg-purple',
    backgroundImage: experienceCardsBgImgLarge,
    largeImage: experienceCardsImgLarge,
    title: 'Travel without a hitch',
    text: 'Essentials covered. Now booking and managing your trips is at your fingertips. No delays, just instant access to plan, book, and manage your journey—all within the app.',
  },
];

export const interestSectionData: InterestLandingTypes[] = [
  {
    content: '🖼️ Cultural Escapes',
    link: '',
  },
  {
    content: '🌲 Nature Retreats',
    link: '',
  },
  {
    content: '🍔 Foodie Adventures',
    link: '',
  },
  {
    content: '🔥 Perfect for groups',
    link: '',
  },
  {
    content: '🏙️ Urban Explorations',
    link: '',
  },
  {
    content: '🌴 Beach Getaways',
    link: '',
  },
  {
    content: '⛪ Historical Journeys',
    link: '',
  },
  {
    content: '🧼 Wellness Retreats',
    link: '',
  },
  {
    content: '👣 Adventure Quests',
    link: '',
  },
  {
    content: '🏔️ Mountain Expeditions',
    link: '',
  },
  {
    content: '💎 Hidden Gems',
    link: '',
  },
  {
    content: '👪 Family Trip',
    link: '',
  },
  {
    content: '👑 Luxury Getaways',
    link: '',
  },
  {
    content: '🙋🏻‍♂️ Solo Adventure',
    link: '',
  },
];
