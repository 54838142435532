import { useApi } from '@api/serviceProvider.ts';
import { useMutation } from '@tanstack/react-query';

export const useGetTravelers = () => {
  const { api } = useApi();

  const {
    mutate,
    data,
    isPending: isItineraryLoading,
  } = useMutation({
    mutationFn: async (itineraryId: number | undefined) => {
      if (!itineraryId) {
        return [];
      }
      return await api.apiV1ItinerariesListTravelers(itineraryId);
    },
  });
  return {
    getTravelers: mutate,
    travelers: data || [],
    isLoadingList: isItineraryLoading,
  };
};
