import classNames from 'classnames';
import Sheet from 'react-modal-sheet';

export type BottomSheetBaseProps = {
  isOpen: boolean;
  snapPoints?: number[];
  isPadded?: boolean;
  onClose: () => void;
  overrideZIndex?: boolean;
};

type Props = BottomSheetBaseProps & {
  children: React.ReactNode;
  isContentDragDisabled?: boolean;
  bgWhite?: boolean;
};

const BORDER_TOP_RADIUS = '24px';

export const BottomSheet = ({
  snapPoints,
  isOpen,
  isPadded = true,
  isContentDragDisabled,
  onClose,
  children,
  overrideZIndex,
  bgWhite = true,
}: Props) => {
  const bg = bgWhite
    ? { backgroundColor: '#ffffff' }
    : { backgroundColor: '#F4F4F4' };
  return (
    <Sheet
      snapPoints={snapPoints}
      isOpen={isOpen}
      onClose={onClose}
      disableScrollLocking
      className={classNames('lg:hidden', {
        'z-[99999999!important]': overrideZIndex,
      })}
    >
      <Sheet.Container
        style={{
          borderTopLeftRadius: BORDER_TOP_RADIUS,
          borderTopRightRadius: BORDER_TOP_RADIUS,
          maxWidth: '768px',
          left: '50%',
          translateX: '-50%',
          ...bg,
        }}
      >
        <Sheet.Header />
        <Sheet.Content
          disableDrag={isContentDragDisabled}
          className={classNames({
            'px-4': isPadded,
          })}
        >
          {children}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={onClose} className="backdrop-blur-[6px]">
        <div className="bg-overlay w-full h-full" />
      </Sheet.Backdrop>
    </Sheet>
  );
};
