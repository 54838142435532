import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

export const useShuffleExperiences = (itineraryId: number | undefined) => {
  const [error, setError] = useState<string | null>(null);
  const apiFactory = useApi();
  const queryClient = useQueryClient();

  const { mutateAsync: shuffleExperiences, isPending } = useMutation({
    mutationFn: async () => {
      if (itineraryId) {
        return apiFactory.api.apiV1ItinerariesShuffle(itineraryId);
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.itinerary],
      });
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.commutes],
      });
    },
    onError: (error) => {
      if (error instanceof Error) {
        return setError(error.message);
      }
      setError('An unexpected error occurred');
    },
  });

  return {
    error,
    isPending,
    shuffleExperiences,
  };
};
