import { Button } from '@components/buttons/Button';
import { SpriteIcon } from '@components/icons/SpriteIcon';
import { Feedback } from '@sentry/react';
import { forwardRef } from 'react';

type Props = {
  title: string;
  text: string;
  buttonText: string;
  onClick: () => void;
};

export const BannerTailor = forwardRef<HTMLDivElement, Props>(
  ({ title, text, buttonText, onClick }, ref) => {
    const handleBugReportClick = () => {
      const feedback = new Feedback({
        autoInject: false,
        colorScheme: 'light',
      });

      feedback.openDialog();
    };
    return (
      <div
        className="flex flex-col gap-1 bg-purple rounded-3xl pt-3 pb-5 w-full px-2 md:max-w-[466px] md:mx-auto"
        ref={ref}
      >
        <div className="flex items-start justify-start gap-2">
          <SpriteIcon iconName="stars" className="w-6 h-6 fill-white" />
          <div className="text-white text-p-medium whitespace-nowrap">
            {title}
          </div>
        </div>
        <div className="space-y-4 ml-8">
          <div className="text-white text-h6">{text}</div>
          <div className="flex gap-5">
            <Button variant="primary" size="small" onClick={onClick}>
              {buttonText}
            </Button>
            <Button
              variant="secondary"
              size="small"
              onClick={handleBugReportClick}
            >
              Report a bug
            </Button>
          </div>
        </div>
      </div>
    );
  },
);
