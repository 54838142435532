import React from 'react';

import { OptionModalBase } from '@components/modals/OptionModalBase';
import { MenuItemProps } from '@components/modalsSections/modalOption/ModalOption';
import { useDeleteExperienceFromItinerary } from '@hooks/useDeleteExperienceFromItinerary';
import { useSuggestDifferentExperience } from '@hooks/useSuggestDifferentExperience';

import { Experience } from '@/generated';
import { useWindowSize } from '@/hooks/useWindowSize';
import { MenuItem } from '../menu/MenuItem';
import { PopUpMenu } from '../menu/PopUpMenu';
import { useGetElementPosition } from '@/hooks/useGetElementPosition';
import { useGoogleAnalytics } from '@/hooks/useGoogleAnalytics';

type Props = {
  experience: Experience;
  onChangeDateTimeClick: () => void;
  onOverlayClick: () => void;
  isOpen?: boolean;
  onClose?: () => void;
  menuCaller?: HTMLButtonElement | null;
};

export const ChangeExperienceOptionsModal = ({
  experience,
  onChangeDateTimeClick,
  onOverlayClick,
  isOpen = true,
  onClose,
  menuCaller,
}: Props) => {
  const [isMenu, setIsMenu] = React.useState(true);
  const { pos } = useGetElementPosition(menuCaller ?? null);
  const { handleDeleteExperience, isDeleteExperienceLoading } =
    useDeleteExperienceFromItinerary(experience.id, onOverlayClick);
  const { sendGAEvent, EVENT_ACTIONS, EVENT_CATEGORIES } = useGoogleAnalytics();
  const { windowType } = useWindowSize();

  const {
    handleSuggestDifferentExperience,
    isLoading: isSuggestDifferentExperienceLoading,
  } = useSuggestDifferentExperience(experience.id);

  const isLoading =
    isDeleteExperienceLoading || isSuggestDifferentExperienceLoading;

  const handleGetTicketsClick = () => {
    if (!experience.details?.website) {
      return alert('Experience website not available');
    }

    if (experience.viator_product_code) {
      /* How many users click on a Viator link in their itinerary */
      sendGAEvent(EVENT_CATEGORIES.itinerary, EVENT_ACTIONS.navigateToViator);
    }

    window.open(experience.details?.website, '_blank');
    onClose ? onClose() : onOverlayClick();
  };

  const handleDeleteExperienceWrapper = () => {
    handleDeleteExperience();
    onClose ? onClose() : onOverlayClick();
  };

  const items: MenuItemProps[] = [
    {
      label: 'Get tickets',
      icon: 'shareBox',
      onClick: handleGetTicketsClick,
    },
    {
      label: 'Change date and time',
      icon: 'calendar',
      onClick: onChangeDateTimeClick,
    },
    {
      label: 'Suggest different',
      icon: 'stars',
      onClick: handleSuggestDifferentExperience,
    },
    {
      label: 'Remove experience',
      icon: 'basket',
      isHighlighted: true,
      onClick: handleDeleteExperienceWrapper,
    },
  ];

  const handleOnClose = () => {
    setIsMenu(true);
    onClose ? onClose() : null;
  };

  const handleOnChoose = (funcToExecute: () => void) => {
    funcToExecute();
    setIsMenu(true);
    onClose ? onClose() : null;
  };

  return (
    <>
      {windowType === 'lg' && isMenu ? (
        <PopUpMenu
          isOpen={isOpen}
          onClose={handleOnClose}
          styles={{
            top: pos.top + pos.height + 10,
            left: pos.left - pos.width * 2 - 20,
          }}
        >
          {items.map((item) => (
            <React.Fragment key={item.label}>
              <MenuItem
                iconName={item.icon}
                text={item.label}
                isDisabled={isLoading}
                onClick={() => handleOnChoose(item.onClick)}
                isRounded
                iconColor={item.isHighlighted ? 'red' : undefined}
                textColor={item.isHighlighted ? 'red' : undefined}
              />
              <div className="border-b border-gray-light" />
            </React.Fragment>
          ))}
        </PopUpMenu>
      ) : (
        isOpen && (
          <div className="w-svw h-svh absolute lg:hidden">
            <OptionModalBase
              items={items}
              onOverlayClick={onOverlayClick}
              isLoading={isLoading}
            />
          </div>
        )
      )}
    </>
  );
};
