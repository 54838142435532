import { TabLayout } from '@components/layouts/TabLayout';
import { AddExperienceSearchPage } from '@pages/AddExperienceSearchPage';
import { AuthPage } from '@pages/AuthPage';
import { MaintainPage } from '@pages/MaintainPage.tsx';
import { ProfilePage } from '@pages/ProfilePage';
import { ResetPasswordPage } from '@pages/ResetPasswordPage';
import { ResetPasswordRetryPage } from '@pages/ResetPasswordRetryPage';
import { SignUpSuccessPage } from '@pages/SignUpSuccessPage';
import { routes } from '@routes/routes';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AddExperiencePage } from '@/pages/AddExperiencePage';
import { ConfirmPasswordPage } from '@/pages/ConfirmPasswordPage';
import { DiscoverPage } from '@/pages/DiscoverPage';
import { LoginPage } from '@/pages/LoginPage';
import { MyTripsPage } from '@/pages/MyTripsPage';
import { PrivacyPage } from '@/pages/PrivacyPage';
import { SignUpEmailVerificationPage } from '@/pages/SignUpEmailVerificationPage';
import { StartPage } from '@/pages/StartPage';
import { TermsAndConditionsPage } from '@/pages/TermsAndConditionsPage';

import { ProtectedRoute } from './ProtectedRoute';
import { ItineraryRedirectPage } from '@/pages/ItineraryRedirectPage';
import { AuthRedirect } from './AuthRedirect';

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          index
          path={routes.home}
          element={
            import.meta.env.VITE_MAINTAIN === 'true' ? (
              <MaintainPage />
            ) : (
              <StartPage />
            )
          }
        />
        <Route index path={routes.hiddenHome} element={<StartPage />} />
        <Route element={<ProtectedRoute />}>
          <Route path={routes.home} element={<TabLayout />}>
            <Route path={routes.discovery.index} element={<DiscoverPage />} />
            <Route path={routes.profile} element={<ProfilePage />} />
            <Route path={routes.myTrips} element={<MyTripsPage />} />
          </Route>
        </Route>

        <Route
          path={routes.auth.registerSuccess}
          element={<SignUpSuccessPage />}
        />

        <Route element={<AuthRedirect />}>
          <Route path={routes.auth.index} element={<AuthPage />} />
          <Route path={routes.auth.login} element={<LoginPage />} />
          <Route
            path={routes.auth.resetPassword}
            element={<ResetPasswordPage />}
          />
          <Route
            path={routes.auth.resetPasswordRetry}
            element={<ResetPasswordRetryPage />}
          />
          <Route
            path={routes.auth.resetPasswordConfirmPassword}
            element={<ConfirmPasswordPage />}
          />
        </Route>
        <Route
          path={routes.auth.registerVerificationSuccess}
          element={<SignUpEmailVerificationPage />}
        />

        <Route
          path={routes.itinerary.index}
          element={<ItineraryRedirectPage />}
        />

        <Route
          path={routes.itinerary.addExperience}
          element={<AddExperiencePage />}
        />
        <Route
          path={routes.itinerary.search}
          element={<AddExperienceSearchPage />}
        />
        <Route
          path={routes.footer.terms}
          element={<TermsAndConditionsPage />}
        />
        <Route path={routes.footer.privacy} element={<PrivacyPage />} />
      </Routes>
    </BrowserRouter>
  );
};
