import { IconButton } from '@components/buttons/IconButton';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { routes } from '@/routes/routes';

type Props = {
  hasBackButton?: boolean;
  handleCloseButtonClick?: () => void;
};

export const AuthHeader = ({
  hasBackButton,
  handleCloseButtonClick,
}: Props) => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => navigate(routes.back);

  return (
    <div
      className={classNames(
        'w-full flex items-center h-[48px]',
        'md:col-span-6 md:col-span-4 ',
        'md:absolute md:top-0 md:left-0 md:z-10 md:px-4 md:py-4',
        {
          'justify-between': hasBackButton,
          'justify-end': !hasBackButton,
        },
      )}
    >
      {hasBackButton && (
        <IconButton
          onClick={handleBackButtonClick}
          iconName="arrowLeft"
          size="large"
          className="fill-black"
        />
      )}
      {handleCloseButtonClick && (
        <IconButton
          onClick={handleCloseButtonClick}
          iconName="cross"
          size="large"
          className=" fill-black"
        />
      )}
    </div>
  );
};
