import { create } from 'zustand';

import { StartPageModalTypes } from '@/types/AppTypes';

type State = {
  openedModal: StartPageModalTypes | null;
};

type Actions = {
  setOpenedModal: (modalName: StartPageModalTypes | null) => void;
};

const initialState = {
  openedModal: null,
};

export const useStartPageModalStore = create<State & Actions>((set) => ({
  ...initialState,
  setOpenedModal: (modalName: StartPageModalTypes | null) =>
    set({ openedModal: modalName }),
}));
