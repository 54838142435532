import { SpriteIcon } from '@components/icons/SpriteIcon';
import classNames from 'classnames';

type Props = {
  undo?: boolean;
  fillColor?: 'white' | 'black';
  onClick?: () => void;
};

export const ActionButton = ({ undo, fillColor, onClick }: Props) => {
  return (
    <button onClick={onClick}>
      {undo ? (
        <p
          className={classNames('text-p', {
            'text-white': fillColor === 'white',
          })}
        >
          Undo
        </p>
      ) : (
        <SpriteIcon iconName="cross" className={`w-6 h-6 fill-${fillColor}`} />
      )}
    </button>
  );
};
