/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommuteMode } from './CommuteMode';
export type Commute = {
    readonly id?: number;
    origin_id: number;
    origin_type: Commute.origin_type;
    destination_id: number;
    destination_type: Commute.destination_type;
    itinerary: number;
    travel_mode?: Commute.travel_mode;
    distance: string;
    duration: string;
    polyline?: any;
    all_modes: Array<CommuteMode>;
};
export namespace Commute {
    export enum origin_type {
        EXPERIENCE = 'experience',
        ACCOMMODATION = 'accommodation',
    }
    export enum destination_type {
        EXPERIENCE = 'experience',
        ACCOMMODATION = 'accommodation',
    }
    export enum travel_mode {
        CAR = 'car',
        TRUCK = 'truck',
        TAXI = 'taxi',
        BUS = 'bus',
        VAN = 'van',
        MOTORCYCLE = 'motorcycle',
        BICYCLE = 'bicycle',
        PEDESTRIAN = 'pedestrian',
    }
}

