import { getFormattedDateTime } from '@utils/formatDateTime.ts';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useExperienceDateTimeForm } from '@/forms/useExperienceDateTimeForm';
import { FreeSlot } from '@/generated/models/FreeSlot';
import { useGetItinerariesFreeSlots } from '@/hooks/useGetItinerariesFreeSlots';
import { useRedirectOnInvalidItineraryId } from '@/hooks/useRedirectOnInvalidItineraryId';
import { FromToTimeRange, Meridian } from '@/types/AppTypes';
import { TIME_HOUR_MINUTE_SECONDS_FORMAT } from '@/utils/constants';
import { getFreeEndTimeslots } from '@/utils/getFreeEndTimeslots';

import { EndTimeSlots } from './timeSlotBottomSheet/EndTimeSlots';
import { StartTimeSlots } from './timeSlotBottomSheet/StartTimeSlots';
import { PopUpContainer } from '../containers/PopUpContainer';

type TimeSlotsView = 'startSlots' | 'endSlots';

export type SaveClickHandlerProp = {
  selectedDay: string;
  timeRangeToUpdate: FromToTimeRange | null;
  from: string;
  to: string;
  meridianFrom: Meridian;
  meridianTo: Meridian;
};

type Props = {
  isOpen: boolean;
  timeRangeToUpdate: FromToTimeRange | null;
  onClose: () => void;
  onSaveClick: (props: SaveClickHandlerProp) => void;
};

export const TailoringTimeSlotBottomSheet = ({
  onSaveClick,
  timeRangeToUpdate,
  ...rest
}: Props) => {
  const [slotsView, setSlotsView] = useState<TimeSlotsView>('startSlots');
  const [endTimes, setEndTimes] = useState<string[]>([]);
  const [selectDay, setSelectDay] = useState<string>('');
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<FreeSlot>(
    {} as FreeSlot,
  );

  const { id } = useParams();
  useRedirectOnInvalidItineraryId(id);
  const itineraryId = id ? Number(id) : undefined;

  const { timeSlots, mutation, isLoading } = useGetItinerariesFreeSlots(
    itineraryId,
    undefined,
  );

  const handleOnClose = () => {
    rest.onClose();
    setSlotsView('startSlots');
  };

  useEffect(() => {
    if (itineraryId) {
      mutation();
    }
  }, [itineraryId]);

  useEffect(() => {
    const firstDay = _.first(timeSlots);
    if (firstDay) {
      setSelectDay(firstDay.date);
    }
  }, [timeSlots]);

  const timeslotsForSelectedDay = timeSlots?.find((e) => e.date === selectDay);

  const handleDisplayEndSlots = () => {
    if (selectedTimeSlot) {
      const freeEndTimeSlots = getFreeEndTimeslots(selectedTimeSlot);
      setEndTimes(freeEndTimeSlots);
      setSlotsView('endSlots');
    }
  };

  const handleBackClick = () => setSlotsView('startSlots');

  const currentTime = getFormattedDateTime({
    format: TIME_HOUR_MINUTE_SECONDS_FORMAT,
  });

  const { isSubmitDisabled } = useExperienceDateTimeForm({
    defaultExperienceStartTime: timeRangeToUpdate?.from ?? currentTime,
    defaultExperienceEndTime: timeRangeToUpdate?.to ?? currentTime,
  });

  const handleOnSaveClick = () => {
    let [hour] = selectedTimeSlot.start_time.split(':').map(Number);
    const meridian_start = hour >= 12 ? 'PM' : 'AM';
    [hour] = selectedTimeSlot.end_times[0].split(':').map(Number);
    const meridian_end = hour >= 12 ? 'PM' : 'AM';

    onSaveClick({
      selectedDay: selectDay,
      timeRangeToUpdate,
      from: selectedTimeSlot.start_time,
      to: selectedTimeSlot.end_times[0],
      meridianFrom: meridian_start,
      meridianTo: meridian_end,
    });
    setSlotsView('startSlots');
    handleOnClose();
  };

  const isDisabled =
    isLoading || !selectedTimeSlot || !selectDay || !isSubmitDisabled;

  return (
    <PopUpContainer
      snapPoints={[0.8]}
      isOpen={rest.isOpen}
      onClose={handleOnClose}
    >
      {slotsView === 'startSlots' && (
        <StartTimeSlots
          timeSlots={timeSlots}
          isLoading={isLoading}
          selectDay={selectDay}
          selectedTimeSlot={selectedTimeSlot}
          isDisabled={isDisabled}
          timeslotsForSelectedDay={timeslotsForSelectedDay}
          onButtonClick={handleDisplayEndSlots}
          setSelectDay={setSelectDay}
          setSelectedTimeSlot={setSelectedTimeSlot}
        />
      )}
      {slotsView === 'endSlots' && (
        <EndTimeSlots
          timeSlots={endTimes}
          selectedTimeSlot={selectedTimeSlot}
          isDisabled={isDisabled}
          onButtonClick={handleOnSaveClick}
          setSelectedTimeSlot={setSelectedTimeSlot}
          handleBackClick={handleBackClick}
        />
      )}
    </PopUpContainer>
  );
};
