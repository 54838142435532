import { IconButton } from '@components/buttons/IconButton';
import { SpriteIcon } from '@components/icons/SpriteIcon';
import { formatCommuteDuration } from '@utils/formatCommuteDuration';
import { mapTravelModeToIcon } from '@utils/mapTravelModeToIcon';

import { Commute } from '@/generated';

type Props = {
  onEditClick: () => void;
  commute: Commute;
  timeRange?: string;
};

export const CommutingCard = ({ commute, onEditClick, timeRange }: Props) => {
  return (
    <div className="flex items-center justify-between w-full max-w-[359px] pr-2">
      <div className="flex gap-4 justify-between">
        <div className="bg-white rounded-2xl p-3 w-fit shadow-general">
          <SpriteIcon
            iconName={mapTravelModeToIcon(commute.travel_mode)}
            className="w-8 h-8"
          />
        </div>
        <div className="flex flex-col my-auto">
          <p className="text-p-bold text-black whitespace-nowrap">Travel</p>
          <div
            className={`flex ${
              timeRange ? 'justify-center' : 'justify-start'
            } items-center self-stretch gap-2`}
          >
            {timeRange && (
              <>
                <p className="text-p-medium text-black">{timeRange}</p>
                <div>•</div>
              </>
            )}
            <p className="text-p-medium text-black whitespace-nowrap">
              {formatCommuteDuration(Number(commute.duration))}
            </p>
          </div>
        </div>
      </div>
      <IconButton onClick={onEditClick} iconName="edit" />
    </div>
  );
};
