import { DeleteItineraryBottomSheet } from '@/components/bottomSheets/DeleteItineraryBottomSheet';
import { EditInvitedUserBottomSheet } from '@/components/bottomSheets/EditInvitedUserBottomSheet';
import { ExperienceMapBottomSheet } from '@/components/bottomSheets/ExperienceMapBottomSheet';
import { InviteUserBottomSheet } from '@/components/bottomSheets/InviteUserBottomSheet';
import { RemoveInvitedUserBottomSheet } from '@/components/bottomSheets/RemoveInvitedUserBottomSheet';
import { Button } from '@/components/buttons/Button';
import { TextButton } from '@/components/buttons/TextButton';
import { Container } from '@/components/containers/Container';
import { UpperPart } from '@/components/headers/UpperPart';
import { InvitedTravelersList } from '@/components/lists/InvitedTravelersList';
import { EditTripWindow } from '@/components/modals/EditTripWindow';
import { TailoringModal } from '@/components/modals/TailoringModal';
import { Itinerary } from '@/generated';
import { useGetMyItineraryDetails } from '@/hooks/useGetMyItineraryDetails';
import { useGetTravelers } from '@/hooks/useGetTravelers';
import { useSendInvites } from '@/hooks/useSendInvites';
import { useUpdateItinerary } from '@/hooks/useUpdateItinerary';
import { routes } from '@/routes/routes';
import { useInitialSearchStore } from '@/stores/initialSearchStore';
import { useNextStepStore } from '@/stores/nextStepStore';
import { ActivityTag } from '@/types/AppTypes';
import { parseDate } from '@/utils/formatDateTime';
import { getTotalPeopleCount } from '@/utils/getTotalPeopleCount';
import imagePlaceholder from '@assets/images/landingPage/general-background.webp';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Params = {
  itineraryId?: number;
  token?: string;
  isAdmin: boolean;
  invitedUserId?: number;
};

export const ItineraryPendingPage = ({
  itineraryId,
  token,
  isAdmin,
  invitedUserId,
}: Params) => {
  // States
  const [isEditTripSheetOpen, setIsEditTripSheetOpen] = useState(false);
  const [chosenInvitedUser, setChosenInvitedUser] = useState<any | undefined>(
    undefined,
  );
  const [isExperienceMapSheetOpen, setIsExperienceMapSheetOpen] =
    useState(false);
  const [
    isDeleteItineraryBottomSheetOpen,
    setIsDeleteItineraryBottomSheetOpen,
  ] = useState(false);
  const [isInviteUserBottomSheetOpen, setIsInviteUserBottomSheetOpen] =
    useState(false);
  const [isTailoringModalOpen, setIsTailoringModalOpen] = useState(false);
  const { updateItinerary, isLoading: isUpdating } = useUpdateItinerary();
  const setNextStep = useNextStepStore((state) => state.setNextStep);

  const editTravelerCallerRef = useRef<{
    [key: string]: HTMLButtonElement | null;
  }>({});

  const getTravelerRef = useCallback(
    (traveler: any) => {
      if (!traveler) return null;
      const key =
        typeof traveler === 'string'
          ? traveler
          : traveler.username || traveler.email;
      return editTravelerCallerRef.current[key] || null;
    },
    [chosenInvitedUser],
  );

  // Hooks
  const { itinerary, isItineraryLoading } = useGetMyItineraryDetails(
    itineraryId,
    token,
  );
  const { getTravelers, travelers, isLoadingList } = useGetTravelers();
  const [travelersLocal, setTravelersLocal] = useState(travelers);
  const { sendInvites, isLoading: isSendingInvite } =
    useSendInvites(itineraryId);
  const setInitialSearch = useInitialSearchStore(
    (state) => state.setInitialSearch,
  );
  const navigate = useNavigate();

  // Loading states
  const isLoading = [
    isItineraryLoading,
    isLoadingList,
    isUpdating,
    isSendingInvite,
  ].some((loading) => loading);

  // Bottom sheets
  const [
    isEditInvitedUserBottomSheetOpen,
    setIsEditInvitedUserBottomSheetOpen,
  ] = useState(false);
  const [
    isRemoveInvitedUserBottomSheetOpen,
    setIsRemoveInvitedUserBottomSheetOpen,
  ] = useState(false);

  // handlers
  const onEditInvitedUser = (traveler: any) => {
    setChosenInvitedUser(traveler);
    setIsEditInvitedUserBottomSheetOpen(!isEditInvitedUserBottomSheetOpen);
  };
  const onDeleteTripHandler = () => {
    setIsDeleteItineraryBottomSheetOpen(true);
  };
  const onEditSaveClick = () => {
    updateItinerary(itineraryId);
    handleToggleEditTripSheetOpen();
  };

  const handleToggleTailoringModalOpen = () =>
    setIsTailoringModalOpen(!isTailoringModalOpen);
  const handleToggleEditTripSheetOpen = () =>
    setIsEditTripSheetOpen(!isEditTripSheetOpen);

  const handleToggleExperienceMapSheetOpen = () =>
    setIsExperienceMapSheetOpen(!isExperienceMapSheetOpen);

  const onInviteUsersHandler = () => {
    setIsInviteUserBottomSheetOpen(!isInviteUserBottomSheetOpen);
  };
  const onGenerateItineraryHandler = () => {
    setInitialSearch({
      itineraryId: null,
      invitedPeople: null,
      isWaitingForPeople: false,
    });
    handleToggleTailoringModalOpen();
  };

  // Edit invited user bottom sheet handlers
  const onResendInvitationHandler = () => {
    if (chosenInvitedUser && chosenInvitedUser.email) {
      sendInvites({ invitedUsers: [chosenInvitedUser.email] });
    }
  };
  const onEditInvitedUserDeleteHandler = () => {
    setIsEditInvitedUserBottomSheetOpen(false);
    setIsRemoveInvitedUserBottomSheetOpen(true);
  };
  const onEditInvitedUserCloseHandler = () => {
    setIsEditInvitedUserBottomSheetOpen(false);
  };

  const onSaveItineraryClick = () => {
    if (token && itineraryId) {
      setNextStep({
        saveTrip: {
          itineraryId,
          token,
          invitedUserId,
        },
      });
      navigate(routes.auth.index);
    }
  };

  const filterTravelers = (travelers: any[]) => {
    const invitedUsers = travelersLocal.map((traveler) =>
      typeof traveler !== 'string' ? traveler.email : traveler,
    );

    return travelers.filter((traveler) => {
      return !traveler.email || !invitedUsers.includes(traveler.email);
    });
  };

  // Effects
  useEffect(() => {
    if (itineraryId) {
      getTravelers(itineraryId);
    }
  }, [
    itineraryId,
    isInviteUserBottomSheetOpen,
    isRemoveInvitedUserBottomSheetOpen,
  ]);

  useEffect(() => {
    if (travelers && travelers.length != travelersLocal.length) {
      setTravelersLocal(filterTravelers(travelers));
    }
  }, [travelers]);

  return (
    <main className="w-full h-svh mx-auto relative bg-white flex flex-col items-center justify-center">
      {itinerary?.id && (
        <div className="w-full h-full relative flex flex-col bg-gray-light">
          <div className="w-full h-fit flex flex-col items-center sticky top-0 gap-y-4 bg-white shadow-header pb-4 z-10 pt-2">
            <UpperPart
              location={itinerary.city ?? 'Unknown location'}
              dateRangeText={
                parseDate({
                  startDate: itinerary.start_date,
                  endDate: itinerary.end_date,
                }) ?? 'Invalid date range'
              }
              horizontalSpacing={2}
              people={getTotalPeopleCount(itinerary.persons) ?? 0}
              isLoadingData={isLoading}
              onEditClick={handleToggleEditTripSheetOpen}
              onMapClick={handleToggleExperienceMapSheetOpen}
              is_admin={isAdmin}
              onSaveClick={onSaveItineraryClick}
            />
          </div>
          <Container fullHeight>
            <div className="flex flex-col items-center p-2 w-full h-full gap-y-6 overflow-x-hidden bg-gray-light">
              <img
                loading="lazy"
                src={
                  itinerary.image?.photo
                    ? `${itinerary.image?.photo}`
                    : imagePlaceholder
                }
                className="w-full rounded-24 h-1/2 w-1/2"
                alt="Destination image"
              />
              <div className="flex flex-col h-full w-full">
                <div className="flex mb-3 justify-between">
                  <p className="text-p-medium text-center text-gray-dark">
                    Waiting for responses
                  </p>
                  {isAdmin && (
                    <TextButton onClick={onInviteUsersHandler}>
                      Invite people
                    </TextButton>
                  )}
                </div>
                {isAdmin && (
                  <div className="mb-2">
                    <Button
                      size="large"
                      full={true}
                      onClick={onGenerateItineraryHandler}
                      iconName="stars"
                    >
                      Generate itinerary
                    </Button>
                  </div>
                )}
                <InvitedTravelersList
                  isAdmin={isAdmin}
                  travelers={travelersLocal}
                  isLoadingList={isLoadingList}
                  onEditInvitedUserHandler={onEditInvitedUser}
                  expectedNumberOfTravelers={itinerary.persons?.adults ?? 0}
                  menuCallerRef={editTravelerCallerRef}
                />
              </div>
            </div>
          </Container>
        </div>
      )}
      {/** Bottom sheets */}
      <EditTripWindow
        isOpen={isEditTripSheetOpen}
        onClose={handleToggleEditTripSheetOpen}
        onSaveClick={onEditSaveClick}
        onDeleteTripClick={onDeleteTripHandler}
        itineraryData={itinerary as Itinerary}
        withMenu={false}
        isAdmin={isAdmin}
      />
      <RemoveInvitedUserBottomSheet
        itineraryId={itinerary.id}
        isOpen={isRemoveInvitedUserBottomSheetOpen}
        onClose={() => {
          setIsRemoveInvitedUserBottomSheetOpen(
            !isRemoveInvitedUserBottomSheetOpen,
          );
        }}
        travelerToRemove={chosenInvitedUser}
      />
      <ExperienceMapBottomSheet
        dateExperiences={[]}
        commutes={[]}
        isOpen={isExperienceMapSheetOpen}
        onClose={handleToggleExperienceMapSheetOpen}
        selectedDate={undefined}
      />
      {isAdmin && (
        <EditInvitedUserBottomSheet
          isLoading={false}
          isAdmin={isAdmin}
          onResendInvitation={onResendInvitationHandler}
          onDelete={onEditInvitedUserDeleteHandler}
          isOpen={isEditInvitedUserBottomSheetOpen}
          onClose={onEditInvitedUserCloseHandler}
          menuCaller={getTravelerRef(chosenInvitedUser)}
        />
      )}
      {isDeleteItineraryBottomSheetOpen && isAdmin && (
        <DeleteItineraryBottomSheet
          itineraryId={itineraryId}
          isOpen={isDeleteItineraryBottomSheetOpen}
          onClose={() => setIsDeleteItineraryBottomSheetOpen(false)}
        />
      )}
      {isInviteUserBottomSheetOpen && isAdmin && (
        <InviteUserBottomSheet
          itineraryId={itineraryId}
          travelers={travelersLocal}
          updateTravelers={setTravelersLocal}
          isOpen={isInviteUserBottomSheetOpen}
          onClose={() => setIsInviteUserBottomSheetOpen(false)}
        />
      )}
      {isTailoringModalOpen && itinerary?.id && (
        <TailoringModal
          activity_tags={itinerary.destination?.activity_tags as ActivityTag[]}
          itineraryId={itinerary.id}
          onClose={handleToggleTailoringModalOpen}
          toastMessage="Itinerary generated"
        />
      )}
    </main>
  );
};
