import { create, StateCreator } from 'zustand';
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';

export type State = {
  itineraryDestination: string;
  itineraryDaysRange: string[];
};

export type Actions = {
  setItinerarySearch: (value: Partial<State>) => void;
  clearStore: () => void;
};

const initialState: State = {
  itineraryDestination: '',
  itineraryDaysRange: [],
};

type ItineraryStoreType = State & Actions;

type ItineraryPersistStoreType = (
  config: StateCreator<ItineraryStoreType>,
  options: PersistOptions<ItineraryStoreType>,
) => StateCreator<ItineraryStoreType>;

export const useItineraryStore = create<ItineraryStoreType>(
  (persist as ItineraryPersistStoreType)(
    (set) => ({
      ...initialState,
      setItinerarySearch: (value) =>
        set((state) => ({
          ...state,
          ...value,
        })),
      clearStore: () => set(initialState),
      setItineraryDestination: (itineraryDestination: string) =>
        set({ itineraryDestination }),
    }),
    {
      name: 'itinerary-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
