import { Button } from '@components/buttons/Button';
import { useDeleteAccount } from '@hooks/useDeleteAccount';

import { useUserStore } from '@/stores/userStore';
import { PopUpContainer } from '../containers/PopUpContainer';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const DeleteAccountBottomSheet = ({ onClose, isOpen }: Props) => {
  const user = useUserStore((state) => state.user);

  const { isDeleteAccountLoading, handleDeleteAccount } =
    useDeleteAccount(onClose);

  const handleDeleteAccountClick = () => {
    if (user?.user.pk) {
      handleDeleteAccount(user.user.pk);
    }
  };

  return (
    <PopUpContainer snapPoints={[0.4]} onClose={onClose} isOpen={isOpen}>
      <div className="flex flex-col h-full pb-4 lg:pb-0">
        <h4 className="text-h4 mb-1">Are you sure?</h4>
        <p className="text-p text-gray pb-2">
          By deleting your account you would permanently lose your saved trips
          and drafts.
        </p>
        <div className="flex flex-col gap-2 py-4 lg:flex-row lg:pb-0">
          <Button
            size="large"
            full
            onClick={handleDeleteAccountClick}
            disabled={isDeleteAccountLoading}
          >
            Delete account
          </Button>
          <Button
            size="large"
            full
            variant="secondary"
            onClick={onClose}
            disabled={isDeleteAccountLoading}
          >
            Cancel
          </Button>
        </div>
      </div>
    </PopUpContainer>
  );
};
