import '@/index.css';
import 'react-toastify/dist/ReactToastify.css';

import { CloseToastButton } from '@components/toasts/CloseToastButton';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';

import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GTM,
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
  attachStacktrace: true,
  debug: false,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  enabled: import.meta.env.VITE_ENABLE_SENTRY === 'enabled',
  integrations: [
    new Sentry.BrowserTracing(),
    ...(import.meta.env.VITE_ENABLE_REPLAYS === 'enabled'
      ? [
          new Sentry.Replay({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
            networkCaptureBodies: false,
            networkResponseHeaders: ['X-Request-Id'],
            useCompression: false,
          }),
        ]
      : []),
  ],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,
});

root.render(
  <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
    <React.StrictMode>
      <App />
      <ToastContainer
        limit={3}
        position="top-center"
        style={{
          zIndex: 999999999,
          gap: 5,
          display: 'flex',
          flexDirection: 'column',
        }}
        autoClose={5000}
        hideProgressBar
        closeButton={<CloseToastButton fillColor="black" />}
        closeOnClick={false}
        className="py-2 px-4 mb-2"
        toastClassName="px-2 py-5 rounded-2xl text-p text-black"
      />
    </React.StrictMode>
  </GoogleOAuthProvider>,
);
