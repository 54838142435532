import { useUserStore } from '@stores/userStore';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { useApi } from '@/api/serviceProvider';
import { routes } from '@/routes/routes';
import { removeAuthCookie } from '@/utils/removeAuthCookie';
import { showToast } from '@/utils/showToast';
import { useInitialSearchStore } from '@/stores/initialSearchStore';
import { googleLogout } from '@react-oauth/google';

export const useLogout = () => {
  const navigate = useNavigate();
  const clearUser = useUserStore((state) => state.clearUser);
  const setInitialSearch = useInitialSearchStore((state) => state.setInitialSearch);
  const apiFactory = useApi();

  const { mutate, isPending } = useMutation({
    mutationKey: ['login'],
    mutationFn: async () => {
      removeAuthCookie();
      return await apiFactory.auth.authLogoutCreate();
    },
    onSuccess: () => {
      clearUser();
      googleLogout();
      setInitialSearch({ invitedPeople: null, isWaitingForPeople: false, itineraryId: null });
      navigate(routes.home);
    },
    onError: () => showToast('Failed to logout'),
  });

  return {
    handleLogout: mutate,
    isLoading: isPending,
  };
};
