import { useState, useEffect } from 'react';
import { TABLET_BREAKPOINT, DESKTOP_BREAKPOINT } from '@/utils/constants';
import { debounce } from 'lodash';

export type windowTypes = 'sm' | 'md' | 'lg';
export type WindowSize = {
    windowSize: number | undefined;
    windowType: windowTypes
};

export const useWindowSize = (): WindowSize => {
    const [windowSize, setWindowSize] = useState<number | undefined>(undefined);
    const [windowType, setWindowType] = useState<WindowSize['windowType']>('sm');

    const getWindowType = (width: number): WindowSize['windowType'] => {
        if (width < TABLET_BREAKPOINT) {
            return 'sm';
        } else if (width < DESKTOP_BREAKPOINT) {
            return 'md';
        } else {
            return 'lg';
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setWindowSize(width);
            setWindowType(getWindowType(width));
        };

        const debounceResize = debounce(handleResize, 100);

        window.addEventListener('resize', debounceResize);
        handleResize();

        return () => window.removeEventListener('resize', debounceResize);
    }, []);

    return {
        windowSize,
        windowType,
    };
};