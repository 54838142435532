import { SpriteIcon } from '@components/icons/SpriteIcon';
import { TruncText } from '@components/texts/TruncText';
import classNames from 'classnames';
import { useGoogleLogin } from '@react-oauth/google';
import { useGoogleSignIn } from '@/hooks/useGoogleSignIn';

export const GoogleButton = ({
  isSubmitDisabled,
  isLoading,
}: {
  isSubmitDisabled?: boolean;
  isLoading?: boolean;
}) => {
  const { googleSignIn } = useGoogleSignIn();

  const login = useGoogleLogin({
    onSuccess: (res) => googleSignIn({ code: res.code }),
    flow: 'auth-code',
    redirect_uri: `${import.meta.env.VITE_API_URL}/auth/google/login/`,
  });

  const handleOnGoogleClick = (e: any) => {
    e.preventDefault();
    login();
  };

  return (
    <button
      type="button"
      disabled={isSubmitDisabled || isLoading}
      onClick={handleOnGoogleClick}
      className={classNames(
        'flex flex-col justify-center px-6 py-3',
        'w-full rounded-[40px] h-[52px]',
        'bg-gray-light hover:bg-gray-hover w-full',
      )}
    >
      <div className="flex items-center gap-1 w-full justify-center items-center">
        <SpriteIcon iconName="google" className="w-9 h-9" />
        <TruncText className="text-start max-w-none" isFull={false}>
          Continue with Google
        </TruncText>
      </div>
    </button>
  );
};
