import 'react-day-picker/dist/style.css';

import { Button } from '@components/buttons/Button';
import { DatePicker } from '@components/datePicker/DatePicker';
import { Header } from '@components/headers/Header';
import { useClearDateRange } from '@hooks/useClearDateRange.ts';
import { useInitialSearchStore } from '@stores/initialSearchStore';
import { useStartPageModalStore } from '@stores/startPageModalStore';

import { SelectRangeEventHandlerCustom } from '@/types/DatePickerTypes';
import classNames from 'classnames';
import { useWindowSize } from '@/hooks/useWindowSize';
import { useGetElementPosition } from '@/hooks/useGetElementPosition';
import { BottomSheet } from '../bottomSheets/BottomSheet';
import { SpriteIcon } from '../icons/SpriteIcon';
import { parseDate } from '@/utils/formatDateTime';

const pastMonth = new Date();

type Props = {
  isOpen: boolean;
  onClose: () => void;
  menuCaller?: HTMLDivElement | null;
};

export const DatesModal = ({ isOpen, onClose, menuCaller }: Props) => {
  const initialSearchDateRange = useInitialSearchStore(
    (state) => state.dateRange,
  );

  const setInitialSearch = useInitialSearchStore(
    (state) => state.setInitialSearch,
  );

  const { setOpenedModal } = useStartPageModalStore();
  const { handleClearDate } = useClearDateRange();

  const handleCloseModal = () => setOpenedModal(null);

  const handleDateSelect: SelectRangeEventHandlerCustom = (newRange) => {
    setInitialSearch({
      dateRange: {
        from: newRange?.from,
        to: newRange?.to,
      },
    });
  };

  const { windowType } = useWindowSize();
  const { pos } = useGetElementPosition(menuCaller ?? null);
  const dateRange = useInitialSearchStore((state) => state.dateRange);

  if (windowType !== 'lg') {
    return (
      <BottomSheet onClose={onClose} isOpen={isOpen} bgWhite={false}>
        <Header
          actionButtonText="Clear"
          onActionButtonClick={handleClearDate}
          onBackButtonClick={handleCloseModal}
        />
        <div className="flex flex-col gap-5 w-full py-5">
          <h4 className="text-h4 flex self-start">When is your trip?</h4>
          <DatePicker
            defaultMonth={
              initialSearchDateRange?.from
                ? initialSearchDateRange.from
                : pastMonth
            }
            selected={
              initialSearchDateRange ? initialSearchDateRange : undefined
            }
            onSelect={handleDateSelect}
          />
        </div>
        <div className="w-full p-4 mb-4">
          <Button
            size="large"
            disabled={!initialSearchDateRange}
            onClick={handleCloseModal}
            full
          >
            Select dates
          </Button>
        </div>
      </BottomSheet>
    );
  } else {
    return (
      <>
        <div
          style={{
            margin: 0,
            width: pos.width ? `${pos.width}px` : undefined,
            height: pos.height ? `${pos.height}px` : undefined,
            top: pos.top ? `${pos.top}px` : undefined,
            left: pos.left ? `${pos.left}px` : undefined,
          }}
          className={classNames('fixed m-0 z-10', '', {
            flex: isOpen,
            hidden: !isOpen,
          })}
        >
          <div
            className={classNames(
              'rounded-48 ml-2 my-2 bg-white flex items-center',
              'px-4 py-2',
            )}
            style={{
              width: pos.width ? `${pos.width - 16}px` : undefined,
              height: pos.height ? `${pos.height - 16}px` : undefined,
            }}
          >
            <div className="flex gap-x-1">
              <SpriteIcon iconName="calendar" className="w-5 h-5" />
              <p className="text-p">
                {parseDate({
                  startDate: dateRange?.from,
                  endDate: dateRange?.to,
                }) ?? 'When?'}
              </p>
            </div>
            <div
              className={classNames(
                'absolute bg-white rounded-48 left-2 z-10 p-5 shadow-general',
              )}
              style={{
                top: pos.height ? `${pos.height}px` : undefined,
              }}
            >
              <DatePicker
                defaultMonth={
                  initialSearchDateRange?.from
                    ? initialSearchDateRange.from
                    : pastMonth
                }
                selected={
                  initialSearchDateRange ? initialSearchDateRange : undefined
                }
                onSelect={handleDateSelect}
                withDateRange={false}
                removeMargin={true}
              />
            </div>
          </div>
        </div>
        <div
          className={classNames('fixed top-0 left-0 w-full h-svh z-9 !m-0', {
            hidden: !isOpen,
            block: isOpen,
          })}
          onClick={onClose}
        />
      </>
    );
  }
};
