import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiError, Experience } from '@/generated';
import { showToast } from '@/utils/showToast';

export const useUpdateExperience = (
  experienceId: number | undefined,
  closeModal: () => void,
) => {
  const queryClient = useQueryClient();
  const apiFactory = useApi();

  const {
    mutateAsync: handleUpdateExperience,
    isPending: isUpdateExperienceLoading,
  } = useMutation({
    mutationKey: ['updateExperience'],
    mutationFn: async (updatedExperience: Partial<Experience>) => {
      if (!experienceId) {
        return;
      }

      return apiFactory.api.apiV1ExperiencesPartialUpdate(
        experienceId,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updatedExperience,
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.itinerary],
      });
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.commutes],
      });
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.commuteList],
      });

      closeModal();

      showToast('Experience updated successfully');
    },
    onError: (error: ApiError) => {
      const errorMessage =
        error.status === 400
          ? 'This time slot already contains another experience'
          : 'Failed to update experience';

      closeModal();
      showToast(errorMessage);
    },
  });
  return {
    handleUpdateExperience,
    isUpdateExperienceLoading,
  };
};
