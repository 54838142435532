import { SpriteIcon, SpriteIconProps } from '@components/icons/SpriteIcon';
import classNames from 'classnames';
import { forwardRef } from 'react';

type Props = {
  iconName: SpriteIconProps;
  className?: string;
  onClick: () => void;
  background?: string;
  disabled?: boolean;
  size?: 'large' | 'medium' | 'small';
};

export const IconButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      iconName,
      onClick,
      className,
      background = 'white',
      disabled,
      size = 'medium',
    },
    ref,
  ) => {
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        className={classNames(
          'w-fit h-fit rounded-full disabled:bg-gray-disabled lg:hover:bg-gray-hover active:bg-gray',
          {
            'p-1.5': size === 'small',
            'p-2': size === 'medium',
          },
          { [`bg-${background}`]: background },
        )}
        ref={ref}
      >
        <SpriteIcon
          iconName={iconName}
          className={classNames(
            {
              'fill-gray': disabled,
              'h-5 w-5': size === 'small',
              'h-6 w-6': size === 'medium',
              'h-8 w-8': size === 'large',
            },
            className,
          )}
        />
      </button>
    );
  },
);
