import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useAuthEmailStore } from '@/stores/authEmailStore';
import { emailValidation, passwordValidation } from '@/utils/yupValidations';

const SignUpSchema = yup.object().shape({
  firstName: yup.string().required('This field is required'),
  lastName: yup.string().required('This field is required'),
  email: emailValidation,
  password: passwordValidation,
});

export type SignUpSchemaType = yup.InferType<typeof SignUpSchema>;

export const useSignUpForm = () => {
  const unlockExperienceEmail = useAuthEmailStore((state) => state.email);

  const { control, handleSubmit, formState } = useForm<SignUpSchemaType>({
    mode: 'all',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      firstName: '',
      lastName: '',
      email: unlockExperienceEmail,
      password: '',
    },
    resolver: yupResolver(SignUpSchema),
  });

  const isSubmitDisabled = !formState.isValid || formState.isSubmitting;

  return {
    control,
    handleSubmitRegisterAccountForm: handleSubmit,
    isSubmitDisabled,
  };
};
