import { useEffect, useState } from 'react';
import { useSendInvites } from '@/hooks/useSendInvites';
import { useInvitationLink } from '@/hooks/useInvitationLink';

import { useInitialSearchStore } from '@/stores/initialSearchStore';

import { SpriteIcon } from '@components/icons/SpriteIcon';
import { Button } from '@components/buttons/Button';
import { TailoringButton } from '@components/buttons/TailoringButton';
import { DisabledInput } from '@components/inputs/DisabledInput';
import { SimpleInput } from '@components/inputs/SimpleInput';
import { CheckBox } from '@components/inputs/CheckBox';
import { PopUpContainer } from '../containers/PopUpContainer';
import { useGoogleAnalytics } from '@/hooks/useGoogleAnalytics';

type Props = {
  itineraryId?: number;
  travelers: any[];
  isOpen: boolean;
  onClose: () => void;
  updateTravelers?: (travelers: any[]) => void;
  storeInvitations?: boolean;
  isWithCheckBox?: boolean;
};

export const InviteUserBottomSheet = ({
  itineraryId,
  travelers,
  isOpen,
  onClose,
  updateTravelers,
  storeInvitations = true,
  isWithCheckBox = false,
}: Props) => {
  // search store
  const setInitialSearchStore = useInitialSearchStore(
    (state) => state.setInitialSearch,
  );
  const isWaitingForPeopleSearchStore = useInitialSearchStore(
    (state) => state.isWaitingForPeople,
  );

  // states
  const [token, setToken] = useState<string | null>(null);
  const [value, setValue] = useState<string>('');
  const [invitedUsers, setInvitedUsers] = useState<string[] | any[]>(travelers);
  const [isWaitingForUsers, setIsWaitingForUsers] = useState<boolean>(
    isWaitingForPeopleSearchStore ?? false,
  );
  const [isCopyButtonClicked, setIsCopyButtonClicked] =
    useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // hooks
  const { invitationLink, copyToClipboard } = useInvitationLink(
    itineraryId,
    token,
  );
  const { sendInvites, data, isLoading } = useSendInvites(itineraryId);
  const { sendGAEvent, EVENT_ACTIONS, EVENT_CATEGORIES } = useGoogleAnalytics();

  const onAddUser = () => {
    if (value) {
      let email = value.toLowerCase();
      if (!email.includes('@')) {
        setError('Invalid email provided!');
        return;
      }

      if (
        invitedUsers.includes(email) ||
        travelers.find((traveler) => traveler.email === email)
      ) {
        setError('Email was already added!');
        return;
      }

      setInvitedUsers([...invitedUsers, email]);
      setValue('');
    }
  };

  const onRemoveUser = (email: string) => {
    const travelerToRemove = travelers.find(
      (traveler) => traveler.email === email || traveler === email,
    );
    if (travelerToRemove) {
      updateTravelers &&
        updateTravelers(
          travelers.filter((traveler) => traveler !== travelerToRemove),
        );
      setInvitedUsers(invitedUsers.filter((user) => user !== travelerToRemove));
      setInitialSearchStore({
        invitedPeople: invitedUsers.filter((user) => user !== travelerToRemove),
      });
      return;
    }
  };

  const onCopyButtonClick = () => {
    setIsCopyButtonClicked(true);
    copyToClipboard(invitationLink);
    sendGAEvent(
      EVENT_CATEGORIES.groupPlanning,
      EVENT_ACTIONS.copyInvitationLink,
    );
  };

  const onInviteButtonClick = () => {
    if (invitedUsers.length != 0) {
      sendGAEvent(
        EVENT_CATEGORIES.groupPlanning,
        EVENT_ACTIONS.sendInvitationEmails,
      );
    }

    if (updateTravelers) {
      updateTravelers(invitedUsers);
    }
    if (storeInvitations) {
      sendInvites({ invitedUsers });
    } else {
      setInitialSearchStore({ invitedPeople: invitedUsers });
    }

    setValue('');
    setError(null);
    onClose();
  };

  const onToggleWaitingForUsers = () => {
    setInitialSearchStore({ isWaitingForPeople: !isWaitingForUsers });
    setIsWaitingForUsers(!isWaitingForUsers);
  };

  useEffect(() => {
    if (itineraryId) {
      sendInvites({ invitedUsers: [] });
      setInitialSearchStore({ itineraryId: itineraryId });
    }
  }, [itineraryId]);

  useEffect(() => {
    if (data) {
      setToken(data.token);
    }
  }, [data]);

  useEffect(() => {
    if (
      ((invitedUsers.length > 0 || isCopyButtonClicked) &&
        !isWaitingForUsers) ||
      ((invitedUsers.length == 0 || !isCopyButtonClicked) && isWaitingForUsers)
    ) {
      onToggleWaitingForUsers();
    }
  }, [invitedUsers, isCopyButtonClicked]);

  useEffect(
    () =>
      sendGAEvent(
        EVENT_CATEGORIES.groupPlanning,
        EVENT_ACTIONS.openGroupPlanning,
      ),
    [],
  );

  return (
    <PopUpContainer isOpen={isOpen} onClose={onClose} overrideZIndex={true}>
      <div className="flex flex-col pb-4 h-full w-full justify-between overflow-auto">
        <div className="">
          <h5 className="text-h4 pb-2">Invite people</h5>
          <p className="text-p-medium text-black">
            Enter people email you want to invite to the trip
          </p>
          <div className="flex items-top w-full gap-2 mt-2">
            <SimpleInput
              name="email"
              type="email"
              value={value}
              updateValue={setValue}
              error={error}
              setError={setError}
              placeholder="Enter email"
            />
            <span className="w-[52px]">
              <Button
                size="large"
                onClick={onAddUser}
                iconName="checkmark"
                disabled={false}
                roundnessVariant="xxxl"
                type="submit"
                styles={{
                  width: '52px',
                }}
              />
            </span>
          </div>
          <div className="">
            <p className="text-p-medium">Or invite them via link</p>
            <div className="flex items-center w-full gap-2">
              <DisabledInput
                value={invitationLink || ''}
                placeholder="Your link will appear here..."
                label=""
              />
              <span className="w-[52px]">
                <Button
                  size="large"
                  onClick={onCopyButtonClick}
                  iconName="copy"
                  disabled={isLoading || !invitationLink}
                  roundnessVariant="xxxl"
                  type="submit"
                  styles={{
                    width: '52px',
                  }}
                />
              </span>
            </div>
            {isWithCheckBox && (
              <CheckBox
                label="Wait for everyone's input"
                labelClassNames=""
                variant="default"
                state={isWaitingForUsers}
                setState={onToggleWaitingForUsers}
              />
            )}
          </div>
          <div className="flex gap-2 mt-6">
            <div className="flex flex-wrap overflow-y-auto max-h-[50svh] justify-center items-center gap-3">
              {invitedUsers.map((user, index) => (
                <TailoringButton key={index} onClick={() => {}} multipleChoice>
                  <span className="flex items-center">
                    {typeof user === 'string'
                      ? user
                      : user.email ?? user.username}
                    <span onClick={() => onRemoveUser(user)}>
                      <SpriteIcon iconName="cross" className="w-4 h-4 ml-2" />
                    </span>
                  </span>
                </TailoringButton>
              ))}
            </div>
          </div>
        </div>
        <div className="h-[52px] mt-4">
          <Button
            size="large"
            full={true}
            onClick={onInviteButtonClick}
            disabled={
              isLoading || (invitedUsers.length == 0 && !isCopyButtonClicked)
            }
          >
            Invite ({invitedUsers.length})
          </Button>
        </div>
      </div>
    </PopUpContainer>
  );
};
