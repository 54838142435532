import { DATE_WEEK_DAY_SHORT_MONTH_DAY_FORMAT } from '@/utils/constants';
import { getFormattedDate } from '@/utils/formatDateTime';

type Props = {
  date?: Date;
  placeholder: string;
};

export const CalendarTextField = ({ date, placeholder }: Props) => {
  return (
    <div
      className={`bg-white p-3 rounded-xl w-full ${
        date ? 'text-black' : 'text-gray'
      }`}
    >
      <div>
        {date
          ? getFormattedDate({
              date: date,
              format: DATE_WEEK_DAY_SHORT_MONTH_DAY_FORMAT,
            })
          : placeholder}
      </div>
    </div>
  );
};
