import { PopUpContainer } from '../containers/PopUpContainer';
import { useInitialSearchStore } from '@/stores/initialSearchStore';
import { SpriteIcon } from '../icons/SpriteIcon';
import { SearchInput } from '../inputs/SearchInput';
import { Loader } from '../loaders/Loader';
import { SearchResult } from '../modalsSections/searchResult/SearchResult';
import { useGooglePlaces } from '@/hooks/useGooglePlaces';
import { useStartPageModalStore } from '@/stores/startPageModalStore';
import { Suggestion } from 'use-places-autocomplete';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const SetDestinationWindow = ({ isOpen, onClose }: Props) => {
  const searchStore = useInitialSearchStore((state) => state.setInitialSearch);
  const currentDest = useInitialSearchStore((state) => state.location?.name);
  const { setOpenedModal } = useStartPageModalStore();
  const { searchValue, setSearchValue, suggestions, handleSelectPrediction } =
    useGooglePlaces(searchStore, setOpenedModal);

  const handleSelectPredictionWrapper = (
    locationName: string,
    placeId: string,
  ) => {
    handleSelectPrediction(locationName, placeId);
    setSearchValue(locationName);
    onClose();
  };

  return (
    <PopUpContainer isOpen={isOpen} onClose={onClose} isWhite={false}>
      <div className="flex w-full gap-x-3">
        <SpriteIcon
          iconName="arrowLeft"
          className="w-8 h-8 fill-black"
          onClick={onClose}
        />
        <p className="text-h4">Location</p>
      </div>
      <div className="w-full py-5">
        <SearchInput
          onSearch={setSearchValue}
          bgColor={'white'}
          value={searchValue}
        />
        <div className="flex w-full justify-center py-4">
          {suggestions.loading && <Loader />}
          {!suggestions.loading && (
            <div className="flex flex-col justify-start gap-2 w-full">
              {suggestions.data && suggestions.data.length > 0 ? (
                <>
                  <h5 className="text-h5 flex">Search results</h5>
                  {suggestions.data.map((suggestion: Suggestion) => (
                    <SearchResult
                      key={suggestion.place_id}
                      result={suggestion.description}
                      onClick={() =>
                        handleSelectPredictionWrapper(
                          suggestion.description,
                          suggestion.place_id,
                        )
                      }
                      iconName="map"
                    />
                  ))}
                </>
              ) : (
                searchValue &&
                searchValue != currentDest && (
                  <div className="text-black">
                    <h5 className="text-h5">No results found</h5>
                    <p className="text-p">
                      We went all out but we couldn't find
                      <br />
                      such a place on the map.
                    </p>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </PopUpContainer>
  );
};
