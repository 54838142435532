import classNames from 'classnames';

import { ToastMessageVariant } from '@/types/AppTypes';

type Props = {
  icon?: React.ReactNode;
  message: string;
  variant?: ToastMessageVariant;
};

export const ToastMessage = ({ icon, message, variant = 'light' }: Props) => {
  return (
    <div className="flex items-center gap-1.5">
      {icon}
      <span
        className={classNames('font-sans text-p', {
          'text-white': variant === 'dark',
          'text-black': variant === 'light',
        })}
      >
        {message}
      </span>
    </div>
  );
};
