import { useState, useCallback, useEffect } from 'react';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
    LatLng,
} from 'use-places-autocomplete';
import { DEBOUNCE_TIME_MS } from '@utils/constants';

interface UseGooglePlacesResult {
    coordinates: LatLng | null;
    currentLocation: string | undefined;
    isCurrentLocationDisabled: boolean;
    searchValue: string;
    suggestions: ReturnType<typeof usePlacesAutocomplete>['suggestions'];
    ready: boolean;
    isLoading: boolean;
    handleGetCurrentLocation: () => void;
    setSearchValue: (value: string) => void;
    handleSelectCurrentLocationPrediction: (
        locationName: string,
        coords: LatLng | null
    ) => void;
    handleSelectPrediction: (locationName: string, placeId: string) => void;
    setIsCurrentLocationDisabled: (disabled: boolean) => void;
}

export const useGooglePlaces = (
    setInitialSearch: (data: any) => void,
    setOpenedModal: (value: any) => void
): UseGooglePlacesResult => {
    const [isCurrentLocationDisabled, setIsCurrentLocationDisabled] = useState(false);
    const [coordinates, setCoordinates] = useState<LatLng | null>(null);
    const [currentLocation, setCurrentLocation] = useState<string | undefined>();

    const {
        ready,
        value: searchValue,
        suggestions,
        setValue: setSearchValue,
    } = usePlacesAutocomplete({
        callbackName: 'initSearch',
        requestOptions: {
            language: 'en',
            types: ['(cities)'],
        },
        debounce: DEBOUNCE_TIME_MS,
    });

    const handleGetCurrentLocation = useCallback(() => {
        const success = async ({ coords }: GeolocationPosition) => {
            setCoordinates({
                lat: coords.latitude,
                lng: coords.longitude,
            });
            const results = await getGeocode({
                location: { lat: coords.latitude, lng: coords.longitude },
            });
            setCurrentLocation(results[0].formatted_address);
        };

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success);
            setIsCurrentLocationDisabled(true);
        }
    }, []);

    const handleSelectCurrentLocationPrediction = useCallback(
        (locationName: string, coords: LatLng | null) => {
            setInitialSearch({
                location: {
                    name: locationName,
                    coords: coords,
                },
            });
            setOpenedModal(null);
        },
        [setInitialSearch, setOpenedModal],
    );

    const handleSelectPrediction = async (
        locationName: string,
        placeId: string
    ) => {
        const results = await getGeocode({
            placeId: placeId,
        });
        const { lat, lng } = await getLatLng(results[0]);
        setInitialSearch({
            location: {
                name: locationName,
                coords: { lat, lng },
            },
        });
        setOpenedModal(null);
    };

    useEffect(() => {
        if (
            currentLocation &&
            (searchValue.length < 1 || suggestions.data.length < 1)
        ) {
            setIsCurrentLocationDisabled(true);
        }
    }, [currentLocation, suggestions.data, searchValue]);

    return {
        coordinates,
        currentLocation,
        isCurrentLocationDisabled,
        searchValue,
        suggestions,
        ready,
        isLoading: suggestions.loading,
        handleGetCurrentLocation,
        setSearchValue,
        handleSelectCurrentLocationPrediction,
        handleSelectPrediction,
        setIsCurrentLocationDisabled,
    };
};
