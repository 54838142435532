import { SpriteIcon, SpriteIconProps } from '../icons/SpriteIcon';

type Params = {
  header: string;
  description: string;
  iconName: SpriteIconProps;
  onClick?: () => void;
};

export const Option = ({ header, description, iconName, onClick }: Params) => {
  return (
    <div
      className="w-full max-w-[343px] grid gap-y-4 items-center rounded-36 bg-gray-light p-4 shadow-general cursor-pointer"
      onClick={onClick}
    >
      <SpriteIcon
        iconName={iconName}
        className="w-10 h-10 fill-white rounded-40 bg-purple p-2"
      />
      <p className="text-h4 text-black">{header}</p>
      <p className="text-p text-gray">{description}</p>
    </div>
  );
};
