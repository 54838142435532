import { PopUpContainer } from '../containers/PopUpContainer';
import { SetDestinationWindow } from '../modals/SetDestinationWindow';
import { SetDatesWindow } from '../modals/SetDatesWindow';
import { SetPeopleWindow } from '../modals/SetPeopleWindow';
import { useState } from 'react';
import { StartPageModalTypes } from '@/types/AppTypes';
import { useInitialSearchStore } from '@/stores/initialSearchStore';
import { LargeIconButton } from '../buttons/LargeIconButton';
import { parseDate } from '@/utils/formatDateTime';
import { getTotalPeopleCount } from '@/utils/getTotalPeopleCount';
import { Button } from '../buttons/Button';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  generateItinerary: () => void;
  isPending?: boolean;
};

export const AddNewTripBottomSheet = ({
  isOpen,
  isPending,
  onClose,
  generateItinerary,
}: Props) => {
  const initialSearch = useInitialSearchStore((state) => state);
  const { dateRange, location, people } = initialSearch;

  const [isPersonsModalOpen, setIsPersonsModalOpen] = useState(false);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isDatesModalOpen, setIsDatesModalOpen] = useState(false);

  const handleModalToggle = (type: StartPageModalTypes) => {
    if (type === 'PEOPLE') {
      setIsPersonsModalOpen(!isPersonsModalOpen);
    } else if (type === 'LOCATION') {
      setIsLocationModalOpen(!isLocationModalOpen);
    } else if (type === 'DATES') {
      setIsDatesModalOpen(!isDatesModalOpen);
    } else {
      setIsPersonsModalOpen(false);
      setIsLocationModalOpen(false);
      setIsDatesModalOpen(false);
    }
  };

  return (
    <>
      {!isLocationModalOpen && !isDatesModalOpen && !isPersonsModalOpen && (
        <PopUpContainer onClose={onClose} isOpen={isOpen} isWhite={false}>
          <div className="flex flex-col px-4 justify-center gap-4 w-full h-full">
            <h4 className="text-h4">Edit trip</h4>
            <div className="flex flex-col gap-3 items-center h-full">
              <LargeIconButton
                onClick={() => handleModalToggle('LOCATION')}
                leftIcon="map"
                rightIcon="pen"
                styles="bg-white"
                filled
              >
                {location?.name ?? 'Where are you going?'}
              </LargeIconButton>
              <LargeIconButton
                onClick={() => handleModalToggle('DATES')}
                leftIcon="calendar"
                rightIcon="pen"
                styles="bg-white"
                filled
              >
                {parseDate({
                  startDate: dateRange?.from,
                  endDate: dateRange?.to,
                }) ?? 'When?'}
              </LargeIconButton>
              <LargeIconButton
                onClick={() => handleModalToggle('PEOPLE')}
                leftIcon="user"
                rightIcon="pen"
                styles="bg-white"
                filled
              >
                {getTotalPeopleCount(people)
                  ? getTotalPeopleCount(people) == 1
                    ? '1 traveler'
                    : `${getTotalPeopleCount(people)} travelers`
                  : 'With whom?'}
              </LargeIconButton>
            </div>
            <div className="w-full p-4 mt-auto bottom-0 left-0">
              <Button
                size="large"
                onClick={generateItinerary}
                full
                disabled={isPending}
              >
                Save changes
              </Button>
            </div>
          </div>
        </PopUpContainer>
      )}
      <SetDestinationWindow
        isOpen={isLocationModalOpen}
        onClose={() => handleModalToggle('LOCATION')}
      />
      <SetDatesWindow
        isOpen={isDatesModalOpen}
        onClose={() => handleModalToggle('DATES')}
        initialSearchDateRange={initialSearch.dateRange}
        setInitialSearch={initialSearch.setInitialSearch}
      />
      <SetPeopleWindow
        isOpen={isPersonsModalOpen}
        onClose={() => handleModalToggle('PEOPLE')}
        persons={people}
        itineraryData={undefined}
        setData={(newData) => {
          initialSearch.setInitialSearch({ people: newData });
        }}
        clearData={() => {
          initialSearch.setInitialSearch({
            location: null,
            dateRange: null,
            people: null,
          });
        }}
      />
    </>
  );
};
