import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { routes } from '@/routes/routes';
import { showToast } from '@/utils/showToast';

export const useRedirectOnInvalidItineraryId = (id: string | undefined) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      navigate(routes.home);
      showToast('Invalid itinerary');
    }
  }, [id, navigate]);
};
