import classNames from 'classnames';
import { ReactNode } from 'react';

type Props = {
  onClick: () => void;
  children: ReactNode;
  padding?: string;
  color?: string;
  textVariant?: string;
  disabled?: boolean;
};

export const FieldTextButton = ({
  onClick,
  children,
  padding,
  color = 'purple',
  textVariant = 'medium',
  disabled = false,
}: Props) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        'hover:text-purple-dark text-gray peer-focus:text-purple',
        { [`text-p-${textVariant}`]: textVariant },
        { [`px-${padding}`]: padding },
        { [`text-${color}`]: color },
      )}
    >
      {children}
    </button>
  );
};
