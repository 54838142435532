/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptInvitation } from '../models/AcceptInvitation';
import type { AssignExperience } from '../models/AssignExperience';
import type { AssignTraveler } from '../models/AssignTraveler';
import type { Commute } from '../models/Commute';
import type { CommuteCompute } from '../models/CommuteCompute';
import type { CurrenciesResponse } from '../models/CurrenciesResponse';
import type { DietaryRestriction } from '../models/DietaryRestriction';
import type { Experience } from '../models/Experience';
import type { ExperienceCategory } from '../models/ExperienceCategory';
import type { ExperienceDetails } from '../models/ExperienceDetails';
import type { ExperienceGatherDetails } from '../models/ExperienceGatherDetails';
import type { FreeSlotsByDate } from '../models/FreeSlotsByDate';
import type { GenerateItinerary } from '../models/GenerateItinerary';
import type { Interest } from '../models/Interest';
import type { InviteUser } from '../models/InviteUser';
import type { Itinerary } from '../models/Itinerary';
import type { OverlapCheck } from '../models/OverlapCheck';
import type { RemoveInviteUser } from '../models/RemoveInviteUser';
import type { ResponseCode200 } from '../models/ResponseCode200';
import type { ScrapDestination } from '../models/ScrapDestination';
import type { Tailoring } from '../models/Tailoring';
import type { User } from '../models/User';
import type { UserProfile } from '../models/UserProfile';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ApiService {
    constructor(public readonly httpRequest: BaseHttpRequest) { }
    /**
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns any
     * @throws ApiError
     */
    public apiV1CommutesList(
        page?: number,
        pageSize?: number,
    ): CancelablePromise<{
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<Commute>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/commutes/',
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @param data
     * @returns Commute
     * @throws ApiError
     */
    public apiV1CommutesCreate(
        data: Commute,
    ): CancelablePromise<Commute> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/commutes/',
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @param data
     * @returns Commute OK
     * @throws ApiError
     */
    public apiV1CommutesCompute(
        data: CommuteCompute,
    ): CancelablePromise<Commute> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/commutes/compute/',
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                424: `Failed Dependency`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this commute.
     * @returns Commute
     * @throws ApiError
     */
    public apiV1CommutesRead(
        id: number,
    ): CancelablePromise<Commute> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/commutes/{id}/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this commute.
     * @param data
     * @returns Commute
     * @throws ApiError
     */
    public apiV1CommutesUpdate(
        id: number,
        data: Commute,
    ): CancelablePromise<Commute> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/commutes/{id}/',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this commute.
     * @param data
     * @returns Commute
     * @throws ApiError
     */
    public apiV1CommutesPartialUpdate(
        id: number,
        data: Commute,
    ): CancelablePromise<Commute> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/commutes/{id}/',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this commute.
     * @returns void
     * @throws ApiError
     */
    public apiV1CommutesDelete(
        id: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/commutes/{id}/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * ViewSet for handling Destination-related requests.
     * @returns ScrapDestination
     * @throws ApiError
     */
    public apiV1DestinationsList(): CancelablePromise<Array<ScrapDestination>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/destinations/',
        });
    }
    /**
     * Scrapes destination data from the Viator API.
     * :param request: rest_framework.request.Request
     * :return: rest_framework.response.Response
     * @param data
     * @returns ScrapDestination
     * @throws ApiError
     */
    public apiV1DestinationsScrap(
        data: ScrapDestination,
    ): CancelablePromise<ScrapDestination> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/destinations/scrap/',
            body: data,
        });
    }
    /**
     * ViewSet for handling Destination-related requests.
     * @param id A unique integer value identifying this destination.
     * @returns ScrapDestination
     * @throws ApiError
     */
    public apiV1DestinationsRead(
        id: number,
    ): CancelablePromise<ScrapDestination> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/destinations/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns DietaryRestriction
     * @throws ApiError
     */
    public apiV1DietaryRestrictionsList(): CancelablePromise<Array<DietaryRestriction>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/dietary-restrictions/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @param data
     * @returns DietaryRestriction
     * @throws ApiError
     */
    public apiV1DietaryRestrictionsCreate(
        data: DietaryRestriction,
    ): CancelablePromise<DietaryRestriction> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/dietary-restrictions/',
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this dietary restriction.
     * @returns DietaryRestriction
     * @throws ApiError
     */
    public apiV1DietaryRestrictionsRead(
        id: number,
    ): CancelablePromise<DietaryRestriction> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/dietary-restrictions/{id}/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this dietary restriction.
     * @returns void
     * @throws ApiError
     */
    public apiV1DietaryRestrictionsDelete(
        id: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/dietary-restrictions/{id}/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @returns ExperienceCategory
     * @throws ApiError
     */
    public apiV1ExperienceCategoriesList(): CancelablePromise<Array<ExperienceCategory>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/experience-categories/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @returns ExperienceDetails
     * @throws ApiError
     */
    public apiV1ExperienceDetailsList(): CancelablePromise<Array<ExperienceDetails>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/experience-details/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @param data
     * @returns ExperienceDetails
     * @throws ApiError
     */
    public apiV1ExperienceDetailsCreate(
        data: ExperienceDetails,
    ): CancelablePromise<ExperienceDetails> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/experience-details/',
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @param experience A unique value identifying this experience details.
     * @returns ExperienceDetails
     * @throws ApiError
     */
    public apiV1ExperienceDetailsRead(
        experience: string,
    ): CancelablePromise<ExperienceDetails> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/experience-details/{experience}/',
            path: {
                'experience': experience,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param experience A unique value identifying this experience details.
     * @param data
     * @returns ExperienceDetails
     * @throws ApiError
     */
    public apiV1ExperienceDetailsUpdate(
        experience: string,
        data: ExperienceDetails,
    ): CancelablePromise<ExperienceDetails> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/experience-details/{experience}/',
            path: {
                'experience': experience,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param experience A unique value identifying this experience details.
     * @param data
     * @returns ExperienceDetails
     * @throws ApiError
     */
    public apiV1ExperienceDetailsPartialUpdate(
        experience: string,
        data: ExperienceDetails,
    ): CancelablePromise<ExperienceDetails> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/experience-details/{experience}/',
            path: {
                'experience': experience,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param experience A unique value identifying this experience details.
     * @returns void
     * @throws ApiError
     */
    public apiV1ExperienceDetailsDelete(
        experience: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/experience-details/{experience}/',
            path: {
                'experience': experience,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param minPrice min_price
     * @param maxPrice max_price
     * @param category category
     * @param type type
     * @param city city
     * @param ordering Which field to use when ordering the results.
     * @param search A search term.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns any
     * @throws ApiError
     */
    public apiV1ExperiencesList(
        minPrice?: string,
        maxPrice?: string,
        category?: string,
        type?: 'non-dining' | 'dining',
        city?: string,
        ordering?: string,
        search?: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<{
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<Experience>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/experiences/',
            query: {
                'min_price': minPrice,
                'max_price': maxPrice,
                'category': category,
                'type': type,
                'city': city,
                'ordering': ordering,
                'search': search,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @param data
     * @returns Experience
     * @throws ApiError
     */
    public apiV1ExperiencesCreate(
        data: Experience,
    ): CancelablePromise<Experience> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/experiences/',
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @param minPrice min_price
     * @param maxPrice max_price
     * @param category category
     * @param type type
     * @param city city
     * @param ordering Which field to use when ordering the results.
     * @param search A search term.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns any
     * @throws ApiError
     */
    public apiV1ExperiencesFeaturedExperiences(
        minPrice?: string,
        maxPrice?: string,
        category?: string,
        type?: 'non-dining' | 'dining',
        city?: string,
        ordering?: string,
        search?: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<{
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<Experience>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/experiences/featured-experiences/',
            query: {
                'min_price': minPrice,
                'max_price': maxPrice,
                'category': category,
                'type': type,
                'city': city,
                'ordering': ordering,
                'search': search,
                'page': page,
                'page_size': pageSize,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this experience.
     * @returns Experience
     * @throws ApiError
     */
    public apiV1ExperiencesRead(
        id: number,
    ): CancelablePromise<Experience> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/experiences/{id}/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this experience.
     * @param data
     * @returns Experience
     * @throws ApiError
     */
    public apiV1ExperiencesUpdate(
        id: number,
        data: Experience,
    ): CancelablePromise<Experience> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/experiences/{id}/',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this experience.
     * @param data
     * @returns Experience
     * @throws ApiError
     */
    public apiV1ExperiencesPartialUpdate(
        id: number,
        data: Experience,
    ): CancelablePromise<Experience> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/experiences/{id}/',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this experience.
     * @returns void
     * @throws ApiError
     */
    public apiV1ExperiencesDelete(
        id: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/experiences/{id}/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this experience.
     * @param data
     * @returns Experience OK
     * @throws ApiError
     */
    public apiV1ExperiencesAssign(
        id: number,
        data: AssignExperience,
    ): CancelablePromise<Experience> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/experiences/{id}/assign/',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
                412: `Precondition Failed`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this experience.
     * @param data
     * @returns Experience OK
     * @throws ApiError
     */
    public apiV1ExperiencesGatherDetails(
        id: number,
        data: ExperienceGatherDetails,
    ): CancelablePromise<Experience> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/experiences/{id}/gather-details/',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this experience.
     * @returns binary OK
     * @throws ApiError
     */
    public apiV1ExperiencesImage(
        id: number,
    ): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/experiences/{id}/image/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this experience.
     * @returns any OK
     * @throws ApiError
     */
    public apiV1ExperiencesPin(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/experiences/{id}/pin/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this experience.
     * @returns any OK
     * @throws ApiError
     */
    public apiV1ExperiencesPurge(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/experiences/{id}/purge/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this experience.
     * @returns Experience OK
     * @throws ApiError
     */
    public apiV1ExperiencesSuggestDifferent(
        id: number,
    ): CancelablePromise<Experience> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/experiences/{id}/suggest-different/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this experience.
     * @returns any OK
     * @throws ApiError
     */
    public apiV1ExperiencesUndo(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/experiences/{id}/undo/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this experience.
     * @returns any OK
     * @throws ApiError
     */
    public apiV1ExperiencesUnpin(
        id: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/experiences/{id}/unpin/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param text Filter by text
     * @param code Filter by code
     * @returns any
     * @throws ApiError
     */
    public apiV1FoodPreferencesList(
        text?: string,
        code?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/food-preferences/',
            query: {
                'text': text,
                'code': code,
            },
        });
    }
    /**
     * @param search A search term.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns any
     * @throws ApiError
     */
    public apiV1InterestsList(
        search?: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<{
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<Interest>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/interests/',
            query: {
                'search': search,
                'page': page,
                'page_size': pageSize,
            },
        });
    }
    /**
     * @param data
     * @returns Interest
     * @throws ApiError
     */
    public apiV1InterestsCreate(
        data: Interest,
    ): CancelablePromise<Interest> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/interests/',
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @param data
     * @returns Interest
     * @throws ApiError
     */
    public apiV1InterestsScrap(
        data: Interest,
    ): CancelablePromise<Interest> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/interests/scrap/',
            body: data,
        });
    }
    /**
     * @param id A unique integer value identifying this interest.
     * @returns Interest
     * @throws ApiError
     */
    public apiV1InterestsRead(
        id: number,
    ): CancelablePromise<Interest> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/interests/{id}/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this interest.
     * @returns void
     * @throws ApiError
     */
    public apiV1InterestsDelete(
        id: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/interests/{id}/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param ordering Which field to use when ordering the results.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns any
     * @throws ApiError
     */
    public apiV1ItinerariesList(
        ordering?: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<{
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<Itinerary>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/itineraries/',
            query: {
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @param data
     * @returns Itinerary
     * @throws ApiError
     */
    public apiV1ItinerariesCreate(
        data: Itinerary,
    ): CancelablePromise<Itinerary> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/itineraries/',
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * Accept an invitation to an itinerary
     * @param data
     * @returns any OK
     * @throws ApiError
     */
    public apiV1ItinerariesAcceptInvitation(
        data: AcceptInvitation,
    ): CancelablePromise<{
        /**
         * Message of the response
         */
        message?: string;
        /**
         * Data of the response
         */
        data?: {
            /**
             * Email of the user
             */
            email?: string;
            /**
             * Phone number of the user
             */
            phone_number?: string;
            /**
             * Username of the user
             */
            username?: string;
            /**
             * ID of the itinerary
             */
            itinerary_id?: number;
            /**
             * Type of the token
             */
            type?: string;
            /**
             * City of the itinerary
             */
            city?: string;
            /**
             * Dates of the itinerary
             */
            dates?: string;
            /**
             * Number of persons
             */
            persons?: number;
            /**
             * Is the invitation for a group
             */
            is_group?: boolean;
            /**
             * Personal token of the user
             */
            personal_token?: string;
            /**
             * ID of the invited user
             */
            invited_user_id?: number;
            /**
             * Is the invitation accepted
             */
            is_accepted?: boolean;
        };
        /**
         * Status code of the response
         */
        status?: number;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/itineraries/accept-invitation/',
            body: data,
            errors: {
                400: `Itinerary not found exception`,
            },
        });
    }
    /**
     * @param data
     * @returns AssignTraveler
     * @throws ApiError
     */
    public apiV1ItinerariesAssignTravelerToItinerary(
        data: AssignTraveler,
    ): CancelablePromise<AssignTraveler> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/itineraries/assign-traveler/',
            body: data,
        });
    }
    /**
     * @param ordering Which field to use when ordering the results.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @returns CurrenciesResponse OK
     * @throws ApiError
     */
    public apiV1ItinerariesCurrencies(
        ordering?: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<CurrenciesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/itineraries/currencies/',
            query: {
                'ordering': ordering,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @param data
     * @returns Itinerary Created
     * @throws ApiError
     */
    public apiV1ItinerariesGenerate(
        data: GenerateItinerary,
    ): CancelablePromise<Itinerary> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/itineraries/generate/',
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this itinerary.
     * @param token Token to get itinerary
     * @returns Itinerary
     * @throws ApiError
     */
    public apiV1ItinerariesRead(
        id: number,
        token?: string,
    ): CancelablePromise<Itinerary> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/itineraries/{id}/',
            path: {
                'id': id,
            },
            query: {
                'token': token,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this itinerary.
     * @param data
     * @returns Itinerary
     * @throws ApiError
     */
    public apiV1ItinerariesUpdate(
        id: number,
        data: Itinerary,
    ): CancelablePromise<Itinerary> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/itineraries/{id}/',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this itinerary.
     * @param data
     * @returns Itinerary
     * @throws ApiError
     */
    public apiV1ItinerariesPartialUpdate(
        id: number,
        data: Itinerary,
    ): CancelablePromise<Itinerary> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/itineraries/{id}/',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * Add some custom logic here if needed
     * @param id A unique integer value identifying this itinerary.
     * @returns void
     * @throws ApiError
     */
    public apiV1ItinerariesDelete(
        id: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/itineraries/{id}/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this itinerary.
     * @param date Itinerary date to get commutes for
     * @returns Commute OK
     * @throws ApiError
     */
    public apiV1ItinerariesCommutes(
        id: number,
        date: string,
    ): CancelablePromise<Array<Commute>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/itineraries/{id}/commutes/',
            path: {
                'id': id,
            },
            query: {
                'date': date,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                412: `Precondition Failed`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this itinerary.
     * @param experienceId Experience ID for which to get free timeslots
     * @returns FreeSlotsByDate OK
     * @throws ApiError
     */
    public apiV1ItinerariesFreeSlots(
        id: number,
        experienceId?: string,
    ): CancelablePromise<Array<FreeSlotsByDate>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/itineraries/{id}/free-timeslots/',
            path: {
                'id': id,
            },
            query: {
                'experience_id': experienceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this itinerary.
     * @returns binary OK
     * @throws ApiError
     */
    public apiV1ItinerariesImage(
        id: number,
    ): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/itineraries/{id}/image/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this itinerary.
     * @returns Itinerary
     * @throws ApiError
     */
    public apiV1ItinerariesCreateInviteLink(
        id: number,
    ): CancelablePromise<Itinerary> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/itineraries/{id}/invite-link/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this itinerary.
     * @param data
     * @returns ResponseCode200 OK
     * @throws ApiError
     */
    public apiV1ItinerariesInvite(
        id: number,
        data: InviteUser,
    ): any {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/itineraries/{id}/invite/',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this itinerary.
     * @param data
     * @returns Experience OK
     * @throws ApiError
     */
    public apiV1ItinerariesCheckForOverlaps(
        id: number,
        data: OverlapCheck,
    ): CancelablePromise<Array<Experience>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/itineraries/{id}/overlaps/',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * Verify token, invitation and return payload
     * @param id A unique integer value identifying this itinerary.
     * @param token Token to parse
     * @returns any OK
     * @throws ApiError
     */
    public apiV1ItinerariesParseInvitationToken(
        id: number,
        token: string,
    ): CancelablePromise<{
        /**
         * Message of the response
         */
        message?: string;
        /**
         * Data of the response
         */
        data?: {
            /**
             * Email of the user
             */
            email?: string;
            /**
             * Phone number of the user
             */
            phone_number?: string;
            /**
             * Username of the user
             */
            username?: string;
            /**
             * ID of the itinerary
             */
            itinerary_id?: number;
            /**
             * Type of the token
             */
            type?: string;
            /**
             * City of the itinerary
             */
            city?: string;
            /**
             * Dates of the itinerary
             */
            dates?: string;
            /**
             * Number of persons
             */
            persons?: number;
            /**
             * Is the invitation for a group
             */
            is_group?: boolean;
            /**
             * Personal token of the user
             */
            personal_token?: string;
            /**
             * ID of the invited user
             */
            invited_user_id?: number;
            /**
             * Is the invitation accepted
             */
            is_accepted?: boolean;
        };
        /**
         * Status code of the response
         */
        status?: number;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/itineraries/{id}/parse-invitation-token/',
            path: {
                'id': id,
            },
            query: {
                'token': token,
            },
            errors: {
                302: `Found`,
                400: `Bad Request`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this itinerary.
     * @param data
     * @returns ResponseCode200 OK
     * @throws ApiError
     */
    public apiV1ItinerariesRemove(
        id: number,
        data: RemoveInviteUser,
    ): CancelablePromise<ResponseCode200> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/itineraries/{id}/remove-invite/',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this itinerary.
     * @returns Itinerary
     * @throws ApiError
     */
    public apiV1ItinerariesSave(
        id: number,
    ): CancelablePromise<Itinerary> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/itineraries/{id}/save/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this itinerary.
     * @returns Itinerary OK
     * @throws ApiError
     */
    public apiV1ItinerariesShuffle(
        id: number,
    ): CancelablePromise<Itinerary> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/itineraries/{id}/shuffle/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this itinerary.
     * @param data
     * @returns Itinerary Created
     * @throws ApiError
     */
    public apiV1ItinerariesTailorItinerary(
        id: number,
        data: Tailoring,
    ): CancelablePromise<Itinerary> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/itineraries/{id}/tailor/',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this itinerary.
     * @returns any OK
     * @throws ApiError
     */
    public apiV1ItinerariesListTravelers(
        id: number,
    ): CancelablePromise<Array<{
        id?: number;
        email?: string;
        phone_number?: string;
        username?: string;
        user?: any;
        itinerary?: number;
        is_admin?: boolean;
        is_accepted?: boolean;
        interests?: Array<string>;
        food_preferences?: Array<string>;
        min_budget?: number;
        max_budget?: number;
        has_filled_preferences?: boolean;
    }>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/itineraries/{id}/travelers/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @returns User
     * @throws ApiError
     */
    public apiV1UsersList(): CancelablePromise<Array<User>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/users/',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @param status Filter by status (all, pending, ongoing, finished)
     * @returns Itinerary OK
     * @throws ApiError
     */
    public apiV1UsersMyTrips(
        status?: string,
    ): CancelablePromise<Array<Itinerary>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/users/my-trips/',
            query: {
                'status': status,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this user.
     * @returns User
     * @throws ApiError
     */
    public apiV1UsersRead(
        id: number,
    ): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/users/{id}/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this user.
     * @param data
     * @returns User
     * @throws ApiError
     */
    public apiV1UsersUpdate(
        id: number,
        data: User,
    ): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/users/{id}/',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this user.
     * @param data
     * @returns User
     * @throws ApiError
     */
    public apiV1UsersPartialUpdate(
        id: number,
        data: User,
    ): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/v1/users/{id}/',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this user.
     * @returns void
     * @throws ApiError
     */
    public apiV1UsersDelete(
        id: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/users/{id}/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found or Non Existing`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this user.
     * @returns UserProfile OK
     * @throws ApiError
     */
    public apiV1UsersProfileRead(
        id: number,
    ): CancelablePromise<UserProfile> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/users/{id}/profile/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this user.
     * @param data
     * @returns UserProfile OK
     * @throws ApiError
     */
    public apiV1UsersProfileCreate(
        id: number,
        data: UserProfile,
    ): CancelablePromise<UserProfile> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/users/{id}/profile/',
            path: {
                'id': id,
            },
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this user.
     * @returns binary OK
     * @throws ApiError
     */
    public apiV1UsersProfileProfileImage(
        id: number,
    ): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/users/{id}/profile/image/',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }
}
