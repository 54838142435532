import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider';
import { useQuery } from '@tanstack/react-query';

import { useUserStore } from '@/stores/userStore';

export const useGetProfile = () => {
  const user: any = useUserStore((state) => state.user?.user);
  const apiFactory = useApi();

  const { data, isLoading } = useQuery({
    queryKey: [queryKeyTypes.profile],
    queryFn: () => {
      const userId = user?.pk ?? user?.id;
      if (!userId) {
        return;
      }
      return apiFactory.api.apiV1UsersRead(userId);
    },
  });

  return {
    profile: data,
    isProfileLoading: isLoading,
  };
};
