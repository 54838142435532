import classNames from 'classnames';
import { IconButton } from '../buttons/IconButton';

type Params = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  white?: boolean;
};

export const ModalWindow = ({
  children,
  isOpen,
  onClose,
  white = true,
}: Params) => {
  return (
    <div
      className={classNames(
        'flex-col fixed w-screen h-screen z-[9999999] bg-overlay backdrop-blur-[6px] top-0 left-0',
        {
          hidden: !isOpen,
          flex: isOpen,
        },
      )}
    >
      <div className="flex flex-col justify-center items-center h-full w-full">
        <div className="flex justify-end w-[466px] mb-2">
          <IconButton
            iconName="cross"
            size="medium"
            background="gray-light"
            onClick={onClose}
          />
        </div>
        <div
          className={classNames(
            'flex flex-col justify-center items-center rounded-24 p-8 w-[466px]',
            {
              'bg-white': white,
              'bg-gray-light': !white,
            },
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
