import { useInfiniteQuery } from '@tanstack/react-query';
import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider.ts';
import { useRemoveDuplicatesFromExperienceArray } from '@hooks/useRemoveDuplicatesFromExperienceArray';
import { useAddExperienceStore } from '@stores/addExperienceStore';
import { useInitialSearchStore } from '@stores/initialSearchStore';
import { FIRST_PAGE_NUMBER, PAGE_SIZE } from '@utils/constants';
import { useEffect } from 'react';

import { Experience } from '@/generated';
import { useItineraryStore } from '@/stores/itineraryStore';

interface ApiResponse {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Experience[];
}

export const useGetFeaturedExperiences = (searchValue: string | undefined) => {
  const initialSearch = useInitialSearchStore((state) => state);
  const itineraryDestination = useItineraryStore((state) => state.itineraryDestination);
  const chosenSorting = useAddExperienceStore((state) => state.chosenSorting);
  const chosenFilters = useAddExperienceStore((state) => state.chosenFilters);
  const chosenExperienceCategory = useAddExperienceStore(
    (state) => state.chosenExperienceCategory,
  );
  const apiFactory = useApi();
  const experienceType =
    chosenExperienceCategory === 'activities' ? 'non-dining' : 'dining';

  const parseCity = (city: string) => city.split(',')[0];
  let city = parseCity(itineraryDestination) || parseCity(initialSearch.location?.name || '').split(',')[0];
  if (city.includes('Miami')) {
    city = 'Miami';
  }

  const fetchExperienceList = async ({
    pageParam = FIRST_PAGE_NUMBER,
  }): Promise<ApiResponse> => {
    return apiFactory.api.apiV1ExperiencesFeaturedExperiences(
      undefined,
      undefined,
      // TODO: update BE types
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      chosenFilters.categories,
      experienceType,
      // TODO: we should send all location data
      city,
      chosenSorting,
      searchValue || undefined,
      pageParam,
      PAGE_SIZE,
    );
  };

  const {
    data,
    error,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryFn: fetchExperienceList,
    initialPageParam: FIRST_PAGE_NUMBER,
    queryKey: [
      queryKeyTypes.experiencesList,
      chosenSorting,
      chosenFilters,
      experienceType,
      searchValue,
    ],
    getNextPageParam: (lastPage, allPages) =>
      lastPage.results.length === PAGE_SIZE ? allPages.length + 1 : undefined,
  });

  useEffect(() => {
    // Refetch data whenever the search value changes
    refetch();
  }, [searchValue, refetch]);

  const featuredExperiences = data?.pages.map(({ results }) => results).flat(1);

  const featuredExperiencesWithoutDuplicates =
    useRemoveDuplicatesFromExperienceArray(featuredExperiences);

  const filteredExperiences = featuredExperiencesWithoutDuplicates?.filter(
    (experience) => experience.name?.toLowerCase() !== 'unknown',
  );

  return {
    data: filteredExperiences,
    error,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  };
};
