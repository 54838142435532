import { TripCard } from '@components/cards/TripCard';
import { Loader } from '@components/loaders/Loader';
import { EmptyListMessage } from '@components/texts/EmptyListMessage';

import { Itinerary } from '@/generated';

type Props = {
  itineraries: Itinerary[] | undefined;
  isLoading?: boolean;
  onCardClick: (itineraryId: number) => void;
};

export const ItineraryList = ({
  itineraries,
  isLoading,
  onCardClick,
}: Props) => {
  return (
    <div className="flex flex-col gap-4 pb-[85px]">
      {isLoading ? (
        <div className="flex flex-col mt-8 w-full h-full items-center">
          <Loader />
        </div>
      ) : (
        <>
          {itineraries?.length === 0 && (
            <EmptyListMessage message="No Itinerary available" />
          )}
          {itineraries?.map((itinerary) => (
            <TripCard
              key={itinerary.id}
              title={itinerary.city}
              dateRange={
                itinerary.start_date && itinerary.end_date
                  ? {
                      from: new Date(itinerary.start_date),
                      to: new Date(itinerary.end_date),
                    }
                  : null
              }
              people={
                (itinerary?.persons?.adults ?? 0) +
                (itinerary?.persons?.children ?? 0) +
                (itinerary?.persons?.infants ?? 0)
              }
              experiences={itinerary?.experiences?.length}
              onClick={() => itinerary.id && onCardClick(itinerary.id)}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              image={itinerary?.image?.photo}
            />
          ))}
        </>
      )}
    </div>
  );
};
