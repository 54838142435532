import {
  BottomSheet,
  BottomSheetBaseProps,
} from '@components/bottomSheets/BottomSheet';
import { Button } from '@components/buttons/Button';
import { FieldTextButton } from '@components/buttons/FieldTextButton';
import { FiltersSection } from '@components/sections/FiltersSection';
import { useGetCategories } from '@hooks/useGetCategories';

import { Experience } from '@/generated';
import { useWindowSize } from '@/hooks/useWindowSize';
import { IconButton } from '../buttons/IconButton';

type Props = {
  experiences: Experience[] | undefined;
  chosenFilters: string[];
  onFilterClick: (value: string) => void;
  onClearFiltersClick: () => void;
  handleToggleFilterSheet?: () => void;
} & BottomSheetBaseProps;

export const ExperienceFilterBottomSheet = ({
  experiences,
  chosenFilters,
  onFilterClick,
  onClearFiltersClick,
  ...rest
}: Props) => {
  const { categories, isCategoriesLoading } = useGetCategories(experiences);
  const { windowType } = useWindowSize();

  const children = (
    <div className="px-4 overflow-auto h-full">
      <h4 className="text-h4 pb-4 sticky top-0 bg-white">Filters</h4>
      <div className="flex flex-col gap-7 ">
        <FiltersSection
          title="Highlights"
          filters={categories}
          chosenFilters={chosenFilters}
          onFiltersClick={onFilterClick}
        />
      </div>
      <div className="sticky bottom-0 flex justify-between p-4 bg-white">
        <FieldTextButton
          disabled={isCategoriesLoading}
          onClick={onClearFiltersClick}
          textVariant="bold"
        >
          Clear all
        </FieldTextButton>
        <Button
          size="large"
          onClick={rest.onClose}
          isLoading={isCategoriesLoading}
        >
          Show results
        </Button>
      </div>
    </div>
  );

  if (windowType === 'lg') {
    return (
      <>
        <div className="w-full px-5 py-[14px]">
          <IconButton
            iconName="arrowLeft"
            size="large"
            onClick={rest.onClose}
          />
        </div>
        {children}
      </>
    );
  } else {
    <BottomSheet {...rest} isPadded={false}>
      {children}
    </BottomSheet>;
  }
};
