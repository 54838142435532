import { SpriteIcon } from '@components/icons/SpriteIcon';
import classNames from 'classnames';
import { useState } from 'react';

type VariantType = 'default' | 'small';

type Props = {
  label: string;
  labelClassNames: string;
  variant?: VariantType;
  state?: boolean;
  setState?: (value: boolean) => void;
};

export const CheckBox = ({
  label,
  labelClassNames,
  variant = 'default',
  state = false,
  setState,
}: Props) => {
  const [checked, setChecked] = useState(state);

  const isSmallVariant = variant === 'small';

  const toggleState = () => {
    if (setState) {
      setState(!checked);
    }
    setChecked(!checked);
  };
  return (
    <div className="flex items-center mb-4 gap-x-2">
      <div
        className={classNames('relative w-5 h-5', {
          'w-5 h-5': !isSmallVariant,
          'w-4 h-4': isSmallVariant,
        })}
      >
        <input
          id="default-checkbox"
          type="checkbox"
          checked={state ?? checked}
          onChange={() => toggleState()}
          className={classNames(
            'cursor-pointer rounded appearance-none focus:ring-0 checked:bg-purple checked:border-none border-black border-[1px] bg-white',
            {
              'w-5 h-5': !isSmallVariant,
              'w-4 h-4': isSmallVariant,
            },
          )}
        />
        {(state ?? checked) && (
          <SpriteIcon
            className={classNames(
              'absolute -translate-x-1/2 -translate-y-1/2 pointer-events-none fill-none left-1/2 stroke-white',
              {
                'w-4 h-4 stroke-[2.13px] top-1/2': !isSmallVariant,
                'w-3 h-3 stroke-[2px] top-[60%]': isSmallVariant,
              },
            )}
            iconName="check"
          />
        )}
      </div>
      <label htmlFor="default-checkbox" className={labelClassNames}>
        {label}
      </label>
    </div>
  );
};
