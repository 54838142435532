import { Button } from '@components/buttons/Button';
import { IconButton } from '@components/buttons/IconButton';

type Props = {
  title: string;
  text: string;
  isOpen: boolean;
  onClick: () => void;
  onClose: () => void;
  buttonLabel: string;
};

export const Banner = ({
  title,
  text,
  buttonLabel,
  isOpen,
  onClick,
  onClose,
}: Props) => {
  return (
    <div
      className={`shadow-general bg-white w-full flex flex-col px-5 py-5 rounded-3xl ${
        isOpen ? 'visible' : 'invisible'
      }`}
    >
      <div className="flex w-full flex-col self-start">
        <div className="justify-between items-center flex w-full mb-1 self-start">
          <p className="text-h5">{title}</p>
          <IconButton
            iconName="cross"
            size="small"
            background="gray-light"
            onClick={onClose}
          />
        </div>
        <p className="text-gray text-p max-w-[271px] md:max-w-none mb-6 self-start">
          {text}
        </p>
      </div>
      <Button size="medium" onClick={onClick}>
        {buttonLabel}
      </Button>
    </div>
  );
};
