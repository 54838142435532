/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CommuteMode = {
    travel_mode: CommuteMode.travel_mode;
    distance: string;
    duration: string;
    polyline?: any;
};
export namespace CommuteMode {
    export enum travel_mode {
        CAR = 'car',
        TRUCK = 'truck',
        TAXI = 'taxi',
        BUS = 'bus',
        VAN = 'van',
        MOTORCYCLE = 'motorcycle',
        BICYCLE = 'bicycle',
        PEDESTRIAN = 'pedestrian',
    }
}

