import { SpriteIcon, SpriteIconProps } from '@components/icons/SpriteIcon';
import classNames from 'classnames';

type Props = {
  iconName: SpriteIconProps;
  isActive: boolean;
  sequenceNumber: number;
};

export const MapItineraryIcon = ({
  iconName,
  isActive,
  sequenceNumber,
}: Props) => {
  return (
    <div
      className={classNames(
        'flex justify-center items-center shadow-map gap-2 pl-1 pr-3 h-10 w-[72px] rounded-40',
        {
          'bg-black': isActive,
          'bg-gray-light': !isActive,
        },
      )}
    >
      <div
        className={classNames('rounded-full p-1.5', {
          'bg-white': isActive,
          'bg-black': !isActive,
        })}
      >
        <SpriteIcon
          iconName={iconName}
          className={classNames('w-5 h-5', {
            'fill-black': isActive,
            'fill-green': !isActive,
          })}
        />
      </div>
      <p
        className={classNames('text-p self-center whitespace-nowrap my-auto', {
          'text-white': isActive,
          'text-black': !isActive,
        })}
      >
        {sequenceNumber}
      </p>
    </div>
  );
};
