import { SpriteIcon, SpriteIconProps } from '@components/icons/SpriteIcon';
import { TruncText } from '@components/texts/TruncText';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { MenuItemType } from '@/types/MenuTypes';
import { forwardRef } from 'react';

type Props = {
  iconName: SpriteIconProps;
  onClick?: () => void;
  text: string;
  textColor?: string;
  iconColor?: string;
  withoutData?: boolean;
  paddingVertical?: number;
  link?: string;
  isDisabled?: boolean;
  isRounded?: boolean;
  isEditMode?: boolean;
  styles?: string;
  variant?: MenuItemType;
};

export const MenuItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      iconName,
      onClick,
      text,
      textColor = 'black',
      variant = 'light',
      link,
      iconColor = 'black',
      isDisabled,
      isRounded,
      isEditMode,
      styles,
      paddingVertical = 3,
    },
    ref,
  ) => {
    return (
      <div
        className={classNames(
          `flex px-4 cursor-pointer  py-${paddingVertical} ${styles}`,
          {
            'text-black bg-white': variant === 'light',
            'text-white bg-overlay-soft backdrop-blur-[6px]':
              variant === 'dark',
            'text-black bg-gray-light': variant === 'gray',
            'rounded-xl': isRounded,
          },
        )}
        ref={ref}
        onClick={!isDisabled ? onClick : () => {}}
      >
        <div className="flex items-center gap-1 w-full lg:justify-start">
          <SpriteIcon
            className={classNames(
              'w-5 h-5 fill-black',
              textColor && `fill-${textColor}`,
              iconColor && `fill-${iconColor}`,
              {
                'fill-black': variant === 'light' || variant === 'gray',
                'fill-white': variant === 'dark',
              },
            )}
            iconName={iconName}
          />
          {link ? (
            <Link to={link}>Get tickets</Link>
          ) : (
            <TruncText
              className={classNames(textColor && `text-${textColor}`, {
                'text-black': variant === 'light' || variant === 'gray',
                'text-white': variant === 'dark',
              })}
            >
              {text}
            </TruncText>
          )}
        </div>
        {isEditMode && (
          <SpriteIcon
            className={classNames(
              'w-6 h-6 fill-black',
              textColor && `fill-${textColor}`,
              iconColor && `fill-${iconColor}`,
              {
                'fill-black': variant === 'light' || variant === 'gray',
                'fill-white': variant === 'dark',
              },
            )}
            iconName="edit"
          />
        )}
      </div>
    );
  },
);
