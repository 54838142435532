import { Button } from '@components/buttons/Button';

type Params = {
  onSignIn: (value: 'signIn' | 'signUp' | 'index') => void;
};

export const SignInButtons = ({ onSignIn }: Params) => {
  return (
    <>
      <div
        className="flex mdm:hidden md:mr-[30px]"
        onClick={() => {
          onSignIn('index');
        }}
      >
        <p className="text-p-bold">Log in or Sign up</p>
      </div>
      <div className="flex gap-x-3 justify-end hidden mdm:flex lg:hidden">
        <Button
          size="medium"
          variant="quaternary"
          onClick={() => {
            onSignIn('signIn');
          }}
        >
          Log in
        </Button>
        <Button
          size="medium"
          variant="quaternary"
          roundnessVariant="xxl"
          onClick={() => {
            onSignIn('signUp');
          }}
          styles={{ border: 'solid black 1px' }}
        >
          Sign up
        </Button>
      </div>
    </>
  );
};
