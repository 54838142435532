import { SpriteIcon } from '@components/icons/SpriteIcon';
import { ChangeEvent, FormEvent } from 'react';

type Props = {
  value?: string | null;
  onSearch: (value: string) => void;
  bgColor?: string;
  placeholder?: string;
  disabled?: boolean;
};

export const SearchInput = ({
  value,
  onSearch,
  bgColor = 'white',
  placeholder = 'Search',
  disabled,
}: Props) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onSearch(value);
  };

  const handleClearLocationValue = () => {
    onSearch('');
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearch(value ?? '');
  };

  return (
    <form
      className={`shadow-general w-full rounded-[40px] bg-${bgColor}`}
      onSubmit={handleSubmit}
    >
      <div className="flex items-center gap-2 px-5 h-[60px]">
        <button type="submit" aria-label="Submit">
          <SpriteIcon
            iconName="search"
            className="stroke-gray fill-none stroke-[3px] h-5 w-5"
          />
        </button>
        <div className="relative w-full flex items-center overflow-hidden">
          <input
            type="text"
            aria-label="Search"
            placeholder={placeholder}
            className={`outline-none w-full caret-purple bg-${bgColor}`}
            onChange={handleChange}
            value={value ?? ''}
            disabled={disabled}
          />
        </div>
        {value && (
          <div onClick={handleClearLocationValue} className="cursor-pointer">
            <SpriteIcon iconName="cross" className="w-5 h-5 fill-gray" />
          </div>
        )}
      </div>
    </form>
  );
};
