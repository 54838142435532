import { BudgetStepOptionType } from '@/types/BudgetStepOptionTypes';

export const budgetStepOptions: BudgetStepOptionType[] = [
  {
    min_price: 1,
    max_price: 2,
    text: '$-$$',
  },
  {
    min_price: 2,
    max_price: 3,
    text: '$$-$$$',
  },
  {
    min_price: 3,
    max_price: 4,
    text: '$$$-$$$$',
  },
  {
    min_price: 0,
    max_price: 4,
    text: "Doesn't matter",
  },
];
