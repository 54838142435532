import { SortExperienceItem } from '@components/sortExperienceItem/SortExperienceInputItem';
import { useAddExperienceStore } from '@stores/addExperienceStore';
import { BottomSheetBaseProps } from './BottomSheet';
import { PopUpContainer } from '../containers/PopUpContainer';

//TODO: fix
import {
  ChosenSorting,
  ChosenSortingValue,
} from '@/types/stores/AddExperienceStoreTypes';

const sortCategories: ChosenSorting[] = [
  { label: 'Highest rank first', value: '-rating' },
  { label: 'Lowest price first', value: 'price' },
  { label: 'A-Z', value: 'abc' },
];

type Props = BottomSheetBaseProps & {
  menuCaller?: HTMLButtonElement | null;
};

export const ExperienceSortByBottomSheet = (props: Props) => {
  const chosenSorting = useAddExperienceStore((state) => state.chosenSorting);

  const handleSetChosenSorting = useAddExperienceStore(
    (state) => state.setChosenSorting,
  );

  const handleSortItemClick = (value: ChosenSortingValue) => {
    handleSetChosenSorting(value);
    props.onClose();
  };

  const content = (
    <>
      {sortCategories.map(({ label, value }, index) => (
        <SortExperienceItem
          key={label}
          label={label}
          isSelected={chosenSorting === value}
          onClick={() => handleSortItemClick(value)}
          isRoundedTop={index === 0}
          isRoundedBottom={index === sortCategories.length - 1}
        />
      ))}
    </>
  );
  return (
    <PopUpContainer
      snapPoints={[0.5]}
      {...props}
      isMenu={true}
      menuCaller={props.menuCaller}
      menuChildren={content}
    >
      <div className="flex flex-col">
        <h4 className="mb-4 pl-3 text-black text-h4">Sort by</h4>
        <div className="flex flex-col gap-1">{content}</div>
      </div>
    </PopUpContainer>
  );
};
