import { useApi } from '@api/serviceProvider';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { routes } from '@/routes/routes';
import { removeAuthCookie } from '@/utils/removeAuthCookie';
import { showToast } from '@/utils/showToast';

export const useDeleteAccount = (onCompleted?: () => void) => {
  const navigate = useNavigate();

  const { api } = useApi();

  const { mutate: handleDeleteAccount, isPending: isDeleteAccountLoading } =
    useMutation({
      mutationFn: async (accountId: number) => {
        if (!accountId) {
          return;
        }

        return await api.apiV1UsersDelete(accountId);
      },
      onSuccess: () => {
        showToast('Account has been removed successfully');
        removeAuthCookie();
        navigate(routes.home);
      },
      onError: () => showToast('Failed to remove account'),
      onSettled: () => {
        if (onCompleted) {
          onCompleted();
        }
      },
    });

  return {
    handleDeleteAccount,
    isDeleteAccountLoading,
  };
};
