import { Banner } from '@components/banners/Banner';
import { SearchHeader } from '@components/headers/SearchHeader';
import { Loader } from '@components/loaders/Loader';
import { SearchResult } from '@components/modalsSections/searchResult/SearchResult';
import { useInitialSearchStore } from '@stores/initialSearchStore';
import { useStartPageModalStore } from '@stores/startPageModalStore';
import { useGooglePlaces } from '@hooks/useGooglePlaces';
import { useWindowSize } from '@/hooks/useWindowSize';
import { BottomSheet } from '../bottomSheets/BottomSheet';
import { useGetElementPosition } from '@/hooks/useGetElementPosition';
import classNames from 'classnames';
import { SimpleInput } from '../inputs/SimpleInput';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  menuCaller?: HTMLDivElement | null;
};

export const LocationSearchModal = ({ isOpen, onClose, menuCaller }: Props) => {
  const setInitialSearch = useInitialSearchStore(
    (state) => state.setInitialSearch,
  );
  const { setOpenedModal } = useStartPageModalStore();

  const {
    coordinates,
    currentLocation,
    isCurrentLocationDisabled,
    searchValue,
    suggestions,
    ready,
    isLoading,
    handleGetCurrentLocation,
    setSearchValue,
    handleSelectCurrentLocationPrediction,
    handleSelectPrediction,
    setIsCurrentLocationDisabled,
  } = useGooglePlaces(setInitialSearch, setOpenedModal);

  const isSearchValueEmpty = searchValue.length === 0;

  const headerText =
    currentLocation && isSearchValueEmpty
      ? 'Current location'
      : 'Search results';

  const isBannerVisible =
    !isCurrentLocationDisabled && isSearchValueEmpty && !currentLocation;

  const isPlacePredictionsVisible = !isSearchValueEmpty && !suggestions.loading;

  const isCurrentLocationVisible =
    !isLoading && currentLocation && isSearchValueEmpty;

  const isErrorMessageVisible =
    !isLoading &&
    !isCurrentLocationVisible &&
    !isPlacePredictionsVisible &&
    !isSearchValueEmpty;

  const { windowType } = useWindowSize();
  const { pos } = useGetElementPosition(menuCaller ?? null);

  if (windowType !== 'lg') {
    return (
      <BottomSheet isOpen={isOpen} onClose={onClose} bgWhite={false}>
        <SearchHeader
          onSearch={setSearchValue}
          onClick={() => setOpenedModal(null)}
          value={currentLocation || searchValue}
          disabled={!ready}
        />
        <div className="flex w-full justify-center py-4">
          {isBannerVisible && (
            <Banner
              title="Current location"
              text="Allow us to use your location if you want to explore your home town."
              onClick={handleGetCurrentLocation}
              onClose={() => setIsCurrentLocationDisabled(true)}
              isOpen={isBannerVisible}
              buttonLabel="Allow location"
            />
          )}
          {!isBannerVisible && (
            <div className="flex flex-col w-full justify-start gap-2">
              {!isErrorMessageVisible && (
                <h5 className="text-h5 flex">{headerText}</h5>
              )}
              {isLoading && (
                <div className="flex w-full justify-center mt-8">
                  <Loader />
                </div>
              )}
              {isCurrentLocationVisible && (
                <SearchResult
                  onClick={() =>
                    handleSelectCurrentLocationPrediction(
                      currentLocation,
                      coordinates,
                    )
                  }
                  result={currentLocation}
                  iconName="location"
                />
              )}
              {isPlacePredictionsVisible &&
                suggestions.data
                  .filter(
                    ({ types }) =>
                      types.includes('locality') ||
                      types.includes('administrative_level_3'),
                  )
                  .map(({ place_id, description }) => (
                    <SearchResult
                      key={place_id}
                      result={description}
                      onClick={() =>
                        handleSelectPrediction(description, place_id)
                      }
                      iconName="map"
                    />
                  ))}
              {isErrorMessageVisible && (
                <div className="text-black">
                  <h5 className="text-h5">No results found</h5>
                  <p className="text-p">
                    We went all out but we couldn't find
                    <br />
                    such a place on the map.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </BottomSheet>
    );
  } else {
    return (
      <>
        <div
          style={{
            margin: 0,
            width: pos.width ? `${pos.width}px` : undefined,
            height: pos.height ? `${pos.height}px` : undefined,
            top: pos.top ? `${pos.top}px` : undefined,
            left: pos.left ? `${pos.left}px` : undefined,
          }}
          className={classNames('fixed m-0 z-10', '', {
            flex: isOpen,
            hidden: !isOpen,
          })}
        >
          <div
            className={classNames(
              'rounded-48 ml-2 my-2 bg-white flex items-center',
              'px-4 py-2',
            )}
            style={{
              width: pos.width ? `${pos.width - 16}px` : undefined,
              height: pos.height ? `${pos.height - 16}px` : undefined,
            }}
          >
            <SimpleInput
              name="destination"
              placeholder={''}
              type={'text'}
              value={searchValue}
              updateValue={setSearchValue}
              fixedHeight={false}
              withoutPadding={true}
              bgColor="white"
              iconName="map"
              styles="rounded-0"
            />
            <div
              className={classNames(
                'absolute bg-white rounded-48 left-2 z-10 shadow-general',
                {
                  hidden:
                    !isBannerVisible &&
                    !isPlacePredictionsVisible &&
                    !isCurrentLocationVisible &&
                    !isErrorMessageVisible,
                  flex:
                    isBannerVisible ||
                    isPlacePredictionsVisible ||
                    isCurrentLocationVisible ||
                    isErrorMessageVisible,
                  'p-5': !isBannerVisible,
                  'p-0': isBannerVisible,
                },
              )}
              style={{
                top: pos.height ? `${pos.height}px` : undefined,
                width: pos.width ? `${pos.width - 16}px` : undefined,
              }}
            >
              {isBannerVisible && (
                <Banner
                  title="Current location"
                  text="Allow us to use your location if you want to explore your home town."
                  onClick={handleGetCurrentLocation}
                  onClose={() => setIsCurrentLocationDisabled(true)}
                  isOpen={isBannerVisible}
                  buttonLabel="Allow location"
                />
              )}
              {!isBannerVisible && (
                <div className="flex flex-col w-full justify-start gap-2">
                  {isCurrentLocationVisible && (
                    <SearchResult
                      onClick={() =>
                        handleSelectCurrentLocationPrediction(
                          currentLocation,
                          coordinates,
                        )
                      }
                      result={currentLocation}
                      iconName="location"
                    />
                  )}
                  {isPlacePredictionsVisible &&
                    suggestions.data
                      .filter(
                        ({ types }) =>
                          types.includes('locality') ||
                          types.includes('administrative_level_3'),
                      )
                      .map(({ place_id, description }) => (
                        <SearchResult
                          key={place_id}
                          result={description}
                          onClick={() =>
                            handleSelectPrediction(description, place_id)
                          }
                          iconName="map"
                        />
                      ))}
                  {isErrorMessageVisible && (
                    <div className="text-black">
                      <h5 className="text-h5">No results found</h5>
                      <p className="text-p">
                        We went all out but we couldn't find
                        <br />
                        such a place on the map.
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={classNames('fixed top-0 left-0 w-full h-svh z-9 !m-0', {
            hidden: !isOpen,
            block: isOpen,
          })}
          onClick={onClose}
        />
      </>
    );
  }
};
