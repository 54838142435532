import { InterestLandingTypes } from '@/types/InterestLandingTypes';

type Params = {
  content: InterestLandingTypes;
};

export const InterestItem = ({ content }: Params) => {
  return (
    <div className="flex py-2 px-4 rounded-36 bg-gray-light cursor-pointer flex-none">
      <p className="text-p-web">{content.content}</p>
    </div>
  );
};
