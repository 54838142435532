import React from 'react';
import { Link } from 'react-router-dom';

import { MailToButton } from '@/components/buttons/MailToButton';
import { Footer } from '@/components/footer/Footer';
import { TitleHeaderWithLogo } from '@/components/headers/TitleHeaderWithLogo';
import { Section, SectionsTypes } from '@/components/legal/Section';
import { routes } from '@/routes/routes';

export const PrivacyPage: React.FC = () => {
  return (
    <>
      <TitleHeaderWithLogo title={'Privacy Policy'} />
      <div className={'flex flex-col bg-gray-light brd-radius-4 p-4 pt-24'}>
        <Section type={SectionsTypes.SUBTITLE}>
          Our Privacy Policy was last updated on 09.05.2024.
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You. We use Your Personal data to provide and improve the
          Service. By using the Service, You agree to the collection and use of
          information in accordance with this Privacy Policy. This Privacy
          Policy was generated by TermsFeed SaaS Privacy Policy Generator.
        </Section>

        <Section type={SectionsTypes.TITLE}>
          Interpretation and Definitions
        </Section>
        <Section type={SectionsTypes.SUBTITLE}>Interpretation</Section>
        <Section type={SectionsTypes.CONTENT}>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </Section>

        <Section type={SectionsTypes.SUBTITLE}>Definitions</Section>
        <Section type={SectionsTypes.CONTENT}>
          {[
            {
              name: '"Account"',
              data: 'means a unique account created for You to access our Service or parts of our Service.',
            },
            {
              name: '"Company"',
              data: '(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to "TravelPal"',
            },
            {
              name: '"Country"',
              data: 'refers to the United States of America.',
            },
            {
              name: '"Cookies"',
              data: 'are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.',
            },
            {
              name: '"Device"',
              data: 'means any device that can access the Service such as a computer, a cell phone or a digital tablet.',
            },
            {
              name: '"Personal',
              data: 'Data" is any information that relates to an identified or identifiable individual.',
            },
            { name: '"Service"', data: 'refers to the Website' },
            {
              name: '"Service Provider"',
              data: 'means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.',
            },
            {
              name: '"Usage Data"',
              data: 'refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).',
            },
            {
              name: '"Website"',
              data: 'refers to "travelpal.ai", accessible from "https://www.travelpal.ai/"',
            },
            {
              name: '"You"',
              data: 'means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.',
            },
          ].map((value, index) => (
            <Section key={index} type={SectionsTypes.POINT}>
              <b>{value.name}</b> {value.data}
            </Section>
          ))}
        </Section>

        <Section type={SectionsTypes.TITLE}>
          Collecting and Using Your Personal Data
        </Section>
        <Section type={SectionsTypes.SUBTITLE}>Types of Data Collected</Section>
        <Section type={SectionsTypes.SUB_SUBTITLE}>Personal Data</Section>
        <Section type={SectionsTypes.CONTENT}>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </Section>
        {[
          { name: 'Email address', data: '' },
          { name: 'First name and last name', data: '' },
          { name: 'Phone number', data: '' },
          { name: 'Address, State, Province, ZIP/Postal code, City', data: '' },
          { name: 'Usage Data', data: '' },
        ].map((value, index) => (
          <Section key={index} type={SectionsTypes.POINT}>
            <b>{value.name}</b>
          </Section>
        ))}

        <Section type={SectionsTypes.SUB_SUBTITLE}>Usage Data</Section>
        <Section type={SectionsTypes.CONTENT}>
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </Section>

        <Section type={SectionsTypes.SUB_SUBTITLE}>
          Tracking Technologies and Cookies
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service. The technologies We use may
          include:
        </Section>
        {[
          {
            name: 'Cookies or Browser Cookies.',
            data: 'A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies',
          },
          {
            name: 'Web Beacons.',
            data: 'Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).',
          },
        ].map((value, index) => (
          <Section key={index} type={SectionsTypes.POINT}>
            <b>{value.name}</b> {value.data}
          </Section>
        ))}
        <Section type={SectionsTypes.CONTENT}>
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
          remain on Your personal computer or mobile device when You go offline,
          while Session Cookies are deleted as soon as You close Your web
          browser.
        </Section>

        {[
          {
            name: 'Necessary / Essential Cookies',
            data: 'Type: Session Cookies\nAdministered by: Us\nPurpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.',
          },
          {
            name: 'Cookies Policy / Notice Acceptance Cookies',
            data: 'Type: Persistent Cookies\nAdministered by: Us\nPurpose: These Cookies identify if users have accepted the use of cookies on the Website.',
          },
          {
            name: 'Functionality Cookies',
            data: 'Type: Persistent Cookies\nAdministered by: Us\nPurpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.',
          },
          {
            name: 'Tracking and Performance Cookies',
            data: 'Type: Persistent Cookies\nAdministered by: Third-Parties\nPurpose: These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these Cookies to test new pages, features or new functionality of the Website to see how our users react to them.',
          },
        ].map((value, index) => (
          <Section key={index} type={SectionsTypes.POINT}>
            <b>{value.name}</b>
            <div className="flex flex-col pt-3">
              {' '}
              {value.data.split('\n').map((item, index) => (
                <Section key={index} type={SectionsTypes.CONTENT}>
                  {item}
                </Section>
              ))}
            </div>
          </Section>
        ))}
        <Section type={SectionsTypes.CONTENT}>
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy or the Cookies
          section of our Privacy Policy.
        </Section>

        <Section type={SectionsTypes.TITLE}>Use of Your Personal Data</Section>
        <Section type={SectionsTypes.CONTENT}>
          The Company may use Personal Data for the following purposes:
        </Section>
        {[
          {
            name: 'To provide and maintain our Service,',
            data: 'including to monitor the usage of our Service',
          },
          {
            name: 'To manage Your Account:',
            data: 'to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user',
          },
          {
            name: 'For the performance of a contract:',
            data: 'the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service',
          },
          {
            name: 'To contact You:',
            data: "To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
          },
          {
            name: 'To provide You with news',
            data: 'special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.',
          },
          {
            name: 'To manage Your requests:',
            data: 'To attend and manage Your requests to Us.',
          },
          {
            name: 'For business transfers:',
            data: 'We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.',
          },
          {
            name: 'For other purposes:',
            data: 'We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.',
          },
        ].map((value, index) => (
          <Section key={index} type={SectionsTypes.POINT}>
            <b>{value.name}</b> {value.data}
          </Section>
        ))}
        <Section type={SectionsTypes.CONTENT}>
          We may share Your personal information in the following situations:
        </Section>
        {[
          {
            name: 'With Service Providers',
            data: 'We may share Your personal information with Service Providers to monitor and analyze the use of our Service, for payment processing, to contact You.',
          },
          {
            name: 'For business transfers',
            data: 'We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.',
          },
          {
            name: 'With Affiliates',
            data: 'We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.',
          },
          {
            name: 'With business partners:',
            data: 'We may share Your information with Our business partners to offer You certain products, services or promotions.',
          },
          {
            name: 'With other users:',
            data: 'when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.',
          },
          {
            name: 'With Your consent:',
            data: 'We may disclose Your personal information for any other purpose with Your consent.',
          },
        ].map((value, index) => (
          <Section key={index} type={SectionsTypes.POINT}>
            <b>{value.name}</b> {value.data}
          </Section>
        ))}

        <Section type={SectionsTypes.TITLE}>
          Retention of Your Personal Data
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </Section>

        <Section type={SectionsTypes.TITLE}>
          Transfer of Your Personal Data
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </Section>

        <Section type={SectionsTypes.TITLE}>
          Disclosure of Your Personal Data
        </Section>

        <Section type={SectionsTypes.SUB_SUBTITLE}>
          Business Transactions
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </Section>

        <Section type={SectionsTypes.SUB_SUBTITLE}>Law enforcement</Section>
        <Section type={SectionsTypes.CONTENT}>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </Section>

        <Section type={SectionsTypes.SUB_SUBTITLE}>
          Other legal requirements
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </Section>
        {[
          { name: 'Comply with a legal obligation', data: '' },
          {
            name: 'Protect and defend the rights or property of the Company',
            data: '',
          },
          {
            name: 'Prevent or investigate possible wrongdoing in connection with the Service',
            data: '',
          },
          {
            name: 'Protect the personal safety of Users of the Service or the public',
            data: '',
          },
          { name: 'Protect against legal liability', data: '' },
        ].map((value, index) => (
          <Section key={index} type={SectionsTypes.POINT}>
            {value.name}
          </Section>
        ))}

        <Section type={SectionsTypes.TITLE}>
          Security of Your Personal Data
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </Section>

        <Section type={SectionsTypes.TITLE}>
          Detailed Information on the Processing of Your Personal Data
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          The Service Providers We use may have access to Your Personal Data.
          These third-party vendors collect, store, use, process and transfer
          information about Your activity on Our Service in accordance with
          their Privacy Policies.
        </Section>

        <Section type={SectionsTypes.SUBTITLE}>Analytics</Section>
        <Section type={SectionsTypes.CONTENT}>
          We may use third-party Service providers to monitor and analyze the
          use of our Service.
        </Section>
        {
          // TODO: ADD LIST OF ANALYTICS
        }

        <Section type={SectionsTypes.TITLE}>Children's Privacy</Section>
        <Section type={SectionsTypes.CONTENT}>
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent's consent before We collect and use that
          information.
        </Section>

        <Section type={SectionsTypes.TITLE}>Links to Other Websites</Section>
        <Section type={SectionsTypes.CONTENT}>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </Section>

        <Section type={SectionsTypes.TITLE}>
          Changes to this Privacy Policy
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </Section>

        <Section type={SectionsTypes.TITLE}>Contact Us</Section>
        <Section type={SectionsTypes.CONTENT}>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </Section>

        <Section type={SectionsTypes.POINT}>
          {'By sending us an email: '}
          <MailToButton
            label={'"contact@travelpal.ai"'}
            mailto={'mailto:contact@travelpal.ai'}
          />
        </Section>
        <Section type={SectionsTypes.POINT}>
          {'By visiting this page on our website: '}
          <Link
            to={routes.footer.privacy}
          >{`"travelpal.ai${routes.footer.privacy}"`}</Link>
        </Section>
        <Footer />
      </div>
    </>
  );
};
