import { Button } from '@components/buttons/Button';
import { Input } from '@components/inputs/Input';
import { useResetPassword } from '@hooks/useResetPassword';
import { routes } from '@routes/routes';
import { useAuthEmailStore } from '@stores/authEmailStore';
import { useNavigate } from 'react-router-dom';

import { AuthLayout } from '@/components/layouts/AuthLayout';
import {
  ResetPasswordSchemaType,
  useResetPasswordForm,
} from '@/forms/useResetPasswordForm';

export const ResetPasswordPage = () => {
  const navigate = useNavigate();

  const setEmailValue = useAuthEmailStore((state) => state.setEmail);

  const handleNavigateToResetPasswordRetryScreen = () =>
    navigate(routes.auth.resetPasswordRetry);

  const { handleResetPassword, isResetPasswordLoading } = useResetPassword(
    handleNavigateToResetPasswordRetryScreen,
  );

  const { control, handleSubmit, isSubmitDisabled } = useResetPasswordForm();

  const onSubmit = ({ email }: ResetPasswordSchemaType) => {
    setEmailValue(email);
    handleResetPassword(email);
  };

  return (
    <AuthLayout hasBackButton>
      <form className="flex flex-col mt-2">
        <Input
          label="Email"
          placeholder="Enter your email"
          type="email"
          name="email"
          autoComplete="email"
          control={control}
        />
        <Button
          size="large"
          full
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitDisabled}
          isLoading={isResetPasswordLoading}
        >
          Reset password
        </Button>
      </form>
    </AuthLayout>
  );
};
