type Props = {
  active: number;
  steps: number;
};

export const ProgressBar = ({ active, steps }: Props) => {
  return (
    <div
      className="w-full grid gap-1"
      style={{ gridTemplateColumns: `repeat(${steps}, 1fr)` }}
    >
      {Array(steps)
        .fill(null)
        .map((_, index) => (
          <div
            key={index}
            className={` ${
              index < active ? 'bg-purple' : 'bg-purple-light'
            } rounded-sm w-full h-[3px]`}
          />
        ))}
    </div>
  );
};
