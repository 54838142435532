import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider.ts';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { Experience } from '@/generated';

export const useGetMyItineraryDetails = (
  itineraryId: number | undefined,
  token: string | undefined = undefined,
) => {
  const apiFactory = useApi();

  const {
    data,
    isLoading: isItineraryLoading,
    error,
  } = useQuery({
    queryKey: [queryKeyTypes.itinerary, itineraryId],
    queryFn: () => {
      if (!itineraryId) {
        return;
      }
      return apiFactory.api.apiV1ItinerariesRead(Number(itineraryId), token);
    },
    retry: 1,
    enabled: !!itineraryId,
    refetchOnWindowFocus: false,
  });

  const filteredExperiences = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data?.experiences?.filter(
        (experience: Experience) => !experience.deleted_at,
      ) ?? [],
    [data?.experiences],
  );

  const itinerary = { ...data, experiences: filteredExperiences };

  return {
    itinerary,
    isItineraryLoading,
    itineraryError: error,
    isItineraryEmpty: () => !itinerary || (itinerary.experiences.length === 0 && itinerary.status !== 'pending') || !itinerary.id,
  };
};
