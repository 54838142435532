import { ReactNode } from 'react';
import classNames from 'classnames';

type Props = {
  heading: string;
  subheading?: string;
  children: ReactNode;
};

export const Step = ({ heading, subheading, children }: Props) => {
  return (
    <div className="w-full h-full md:grid md:grid-cols-12">
      <div
        className={classNames('hidden md:flex md:col-span-3 lg:col-span-4')}
      ></div>
      <div
        className={classNames(
          'flex flex-col w-full h-full gap-y-6 py-8 items-center',
          'md:col-span-6 md:pt-[64px]',
          'lg:col-span-4',
        )}
      >
        <div
          className={classNames(
            'w-full flex flex-col items-center gap-1',
            'lg:col-span-4',
          )}
        >
          <h4 className="text-h4 text-black text-center">{heading}</h4>
          {subheading && (
            <p className="text-p text-gray text-center max-w-[260px]">
              {subheading}
            </p>
          )}
        </div>
        <div className="w-full flex flex-col items-center gap-y-3">
          {children}
        </div>
      </div>
      <div
        className={classNames('hidden md:flex md:col-span-3 lg:col-span-4')}
      ></div>
    </div>
  );
};
