import React from 'react';

import { OptionModalBase } from '@components/modals/OptionModalBase';
import { MenuItemProps } from '../modalsSections/modalOption/ModalOption';
import { PopUpMenu } from '../menu/PopUpMenu';
import { MenuItem } from '../menu/MenuItem';
import { useGetElementPosition } from '@/hooks/useGetElementPosition';
import { useWindowSize } from '@/hooks/useWindowSize';

type Props = {
  isLoading: boolean;
  isAdmin: boolean;
  onClose: () => void;
  onResendInvitation: () => void;
  onDelete: () => void;
  isOpen?: boolean;
  menuCaller?: HTMLButtonElement | null;
};

export const EditInvitedUserBottomSheet = ({
  isLoading,
  onClose,
  isAdmin,
  onResendInvitation,
  onDelete,
  isOpen = true,
  menuCaller,
}: Props) => {
  const { windowType } = useWindowSize();
  const { pos } = useGetElementPosition(menuCaller ?? null);

  const adminInviteComponent = isAdmin
    ? [
        {
          label: 'Resend invitation',
          icon: 'shareBox',
          onClick: onResendInvitation,
        },
      ]
    : [];

  const items: MenuItemProps[] = [
    ...(adminInviteComponent as MenuItemProps[]),
    {
      label: 'Remove user',
      icon: 'basket',
      isHighlighted: true,
      onClick: onDelete,
    },
  ];

  const handleOnChoose = (funcToExecute: () => void) => {
    funcToExecute();
    onClose();
  };

  const handleOnClose = () => {
    onClose();
  };

  return (
    <>
      {windowType === 'lg' ? (
        <PopUpMenu
          isOpen={isOpen}
          onClose={handleOnClose}
          styles={{
            top: pos.top && pos.height ? pos.top + pos.height + 10 : 0,
            left: pos.left && pos.width ? pos.left - pos.width * 2 - 20 : 0,
          }}
        >
          {items.map((item) => (
            <React.Fragment key={item.label}>
              <MenuItem
                iconName={item.icon}
                text={item.label}
                isDisabled={isLoading}
                onClick={() => handleOnChoose(item.onClick)}
                isRounded
                iconColor={item.isHighlighted ? 'red' : undefined}
                textColor={item.isHighlighted ? 'red' : undefined}
              />
              <div className="border-b border-gray-light" />
            </React.Fragment>
          ))}
        </PopUpMenu>
      ) : (
        isOpen && (
          <div className="w-svw h-svh absolute lg:hidden">
            <OptionModalBase
              items={items}
              onOverlayClick={onClose}
              isLoading={isLoading}
            />
          </div>
        )
      )}
    </>
  );
};
