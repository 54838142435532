/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Accommodation } from './Accommodation';
import type { Destination } from './Destination';
import type { ItineraryImage } from './ItineraryImage';
import type { Persons } from './Persons';
export type Itinerary = {
    readonly id?: number;
    name?: string | null;
    readonly user?: number | null;
    destination?: Destination;
    city: string;
    city_longitude?: number | null;
    city_latitude?: number | null;
    start_date: string;
    end_date: string;
    currency?: Itinerary.currency | null;
    persons: Persons;
    min_price?: number | null;
    max_price?: number | null;
    food_preferences?: Array<string | null> | null;
    dietary_restrictions?: Array<string | null> | null;
    readonly experiences?: string;
    accommodations?: Array<Accommodation>;
    image?: ItineraryImage;
    status?: string;
};
export namespace Itinerary {
    export enum currency {
        USD = 'USD',
        EUR = 'EUR',
        JPY = 'JPY',
        GBP = 'GBP',
        AUD = 'AUD',
        CAD = 'CAD',
        CHF = 'CHF',
        CNY = 'CNY',
        SEK = 'SEK',
        NZD = 'NZD',
        MXN = 'MXN',
        SGD = 'SGD',
        HKD = 'HKD',
        NOK = 'NOK',
        KRW = 'KRW',
        TRY = 'TRY',
        RUB = 'RUB',
        INR = 'INR',
        BRL = 'BRL',
        ZAR = 'ZAR',
        SAR = 'SAR',
        AED = 'AED',
        PLN = 'PLN',
        TWD = 'TWD',
        THB = 'THB',
        IDR = 'IDR',
        HUF = 'HUF',
        CZK = 'CZK',
        ILS = 'ILS',
        CLP = 'CLP',
        PHP = 'PHP',
        PKR = 'PKR',
        MYR = 'MYR',
        RON = 'RON',
        COP = 'COP',
        ARS = 'ARS',
        DKK = 'DKK',
        IQD = 'IQD',
    }
}

