import { Button } from '@components/buttons/Button';
import { HTMLInputTypeAttribute, useState } from 'react';
import { Control, Path } from 'react-hook-form';

import { TextButton } from '../buttons/TextButton';
import { Input } from '../inputs/Input';

export type ProfileInputProps<T extends Record<string, string>> = {
  name: Path<T>;
  placeholder: string;
  label: string;
  type: HTMLInputTypeAttribute;
  autoComplete?: string;
};

type Props<T extends Record<string, string>> = {
  control: Control<T> | undefined;
  title: string;
  value: string | undefined;
  isLoading: boolean;
  inputs: ProfileInputProps<T>[];
  isSubmitDisabled: boolean;
  onSavePress: () => void;
};

export const ProfileDetailSection = <T extends Record<string, string>>({
  control,
  inputs,
  title,
  isLoading,
  isSubmitDisabled,
  value,
  onSavePress,
}: Props<T>) => {
  const [isEditActive, setIsEditActive] = useState(false);

  const textButtonText = isEditActive ? 'Cancel' : 'Change';

  const handleToggleIsActive = () => setIsEditActive(!isEditActive);

  return (
    <section className="px-2 py-4 flex flex-col justify-between border-b-[1px] border-gray-light w-full">
      <div className="flex justify-between mb-2">
        <h6 className="text-h6">{title}</h6>
        <TextButton onClick={handleToggleIsActive}>{textButtonText}</TextButton>
      </div>
      {isEditActive ? (
        <form className="flex flex-col w-full">
          {inputs &&
            inputs.map((input) => (
              <Input
                key={input.name}
                control={control}
                isDisabled={isLoading}
                {...input}
              />
            ))}

          <div className="mt-2">
            <Button
              disabled={isSubmitDisabled}
              isLoading={isLoading}
              variant="primary"
              size="medium"
              onClick={() => {
                onSavePress();
                handleToggleIsActive();
              }}
            >
              Save
            </Button>
          </div>
        </form>
      ) : (
        <p className="text-p text-gray">{value || '-'}</p>
      )}
    </section>
  );
};
