import { Card } from '@components/cards/Card';
import { Loader } from '@components/loaders/Loader';
import { EmptyListMessage } from '@components/texts/EmptyListMessage';
import { forwardRef, Fragment, useState } from 'react';

import { Commute, Experience } from '@/generated';
import { getCommuteTimeRange } from '@/utils/getCommuteTimeRange';
import { getUTCDateTime } from '@/utils/formatDateTime';
import { DATE_DAY_EXTENDED_MONTH_DAY_FORMAT } from '@/utils/constants';

import { CommutingCard } from '@components/cards/CommutingCard';
import { CommutingCardError } from '@components/cards/CommutingCardError';
import { CommutingCardSkeleton } from '@components/cards/CommutingCardSkeleton';
import { DropdownList } from './DropdownList';
import { useSplitExperiences } from '@/hooks/useSplitExperiences';
import { DayCommutes } from '@/hooks/useGetItineraryCommutes';

type Props = {
  experiences: Experience[];
  commutes: Commute[] | DayCommutes[];
  fetchCommutesForDate: (date: string) => void;
  isLoadingList: boolean;
  isExperienceDetailsLoading: boolean;
  isLoadingCommutes: boolean;
  onCardEditClick: (experience: Experience) => void;
  onCommuteEditClick: (commute: Commute) => void;
  isAdmin?: boolean;
  isDaySelected?: boolean;
  menuCallerRef?: React.MutableRefObject<{
    [key: string]: HTMLButtonElement | null;
  }>;
  firstExperienceTimeSlotRef?: React.MutableRefObject<HTMLDivElement | null>;
};

export const ItineraryExperienceList = forwardRef<HTMLDivElement, Props>(
  (
    {
      isLoadingList,
      isExperienceDetailsLoading,
      isLoadingCommutes,
      experiences,
      commutes,
      onCardEditClick,
      onCommuteEditClick,
      isAdmin = true,
      isDaySelected = false,
      menuCallerRef,
      firstExperienceTimeSlotRef,
      fetchCommutesForDate,
    },
    ref,
  ) => {
    const splitExperiences = useSplitExperiences(experiences);
    const [openedIndex, setOpenedIndex] = useState<React.Key | null>(0);
    const onToggle = (index: React.Key, date: string) => {
      if (isDaySelected) return;
      if (openedIndex === index) {
        setOpenedIndex(null);
      } else {
        if (parseCommutes(commutes, date).length === 0) {
          fetchCommutesForDate(date);
        }
        setOpenedIndex(index);
      }
    };

    const parseCommutes = (
      commutes: Commute[] | DayCommutes[],
      date: string,
    ): Commute[] => {
      if (isDaySelected) {
        return commutes as Commute[];
      }

      const dayCommutes = commutes as DayCommutes[];
      const founded = dayCommutes.find((record) => record[date]);
      return founded ? founded[date] : [];
    };

    return (
      <div
        className="relative w-full flex flex-col gap-y-6 items-center pb-[90px] md:max-w-[466px] md:mx-auto"
        ref={ref}
      >
        {isLoadingList ? (
          <Loader />
        ) : (
          <>
            {splitExperiences.map((day, index) => {
              // Each 'day' is an object like { "Aug 01 2023": Experiences[] }
              const date = Object.keys(day)[0];
              const dateObj = day[date][0].date;
              const experiencesForDate = day[date];

              return (
                <DropdownList
                  title={getUTCDateTime({ dateTime: date }).format(
                    DATE_DAY_EXTENDED_MONTH_DAY_FORMAT,
                  )}
                  isOpened={openedIndex === index}
                  onToggle={() => onToggle(index, dateObj ?? '')}
                  isDaySelected={isDaySelected}
                  key={index}
                >
                  {experiencesForDate.length === 0 && (
                    <EmptyListMessage message="No experiences available" />
                  )}
                  {experiencesForDate.map((experience, index) => {
                    if (!experience.details) return;

                    const hasCommuteForExperience = parseCommutes(
                      commutes,
                      dateObj ?? '',
                    ).some((commute) => commute.origin_id === experience.id);

                    return (
                      <Fragment key={experience.id}>
                        <Card
                          experience={experience}
                          isLoadingDetail={isExperienceDetailsLoading}
                          onEditClick={() => onCardEditClick(experience)}
                          isAdmin={isAdmin}
                          ref={(el) => {
                            if (el && menuCallerRef?.current && experience.id) {
                              menuCallerRef.current[experience.id] = el;
                            }
                          }}
                          timeSlotRef={
                            index === 0 &&
                            firstExperienceTimeSlotRef !== undefined
                              ? firstExperienceTimeSlotRef
                              : undefined
                          }
                        />
                        {hasCommuteForExperience && commutes ? (
                          parseCommutes(commutes, dateObj ?? '').map(
                            (commute) => {
                              if (commute.origin_id === experience.id) {
                                return (
                                  <CommutingCard
                                    key={commute.id}
                                    timeRange={getCommuteTimeRange(
                                      experience.date,
                                      experience.end_time,
                                      commute.duration,
                                    )}
                                    commute={commute}
                                    onEditClick={() =>
                                      onCommuteEditClick(commute)
                                    }
                                  />
                                );
                              }
                            },
                          )
                        ) : !hasCommuteForExperience &&
                          !isExperienceDetailsLoading &&
                          !isLoadingCommutes &&
                          index + 1 !== experiencesForDate.length ? (
                          <CommutingCardError />
                        ) : (
                          index + 1 !== experiencesForDate.length && (
                            <CommutingCardSkeleton />
                          )
                        )}
                      </Fragment>
                    );
                  })}
                </DropdownList>
              );
            })}
          </>
        )}
      </div>
    );
  },
);
