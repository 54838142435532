import { CommuteRouteItem } from '@components/modalsSections/commuteRoute/CommuteRouteItem';

type Props = {
  origin: string;
  destination: string;
};

export const CommuteRoute = ({ origin, destination }: Props) => {
  return (
    <div className="relative flex flex-col gap-y-3">
      <div className="border-l-2 border-dashed h-4 border-gray absolute top-1/2 -translate-y-1/2 left-[11px]" />
      <CommuteRouteItem locationText={origin} iconName="location" />
      <CommuteRouteItem locationText={destination} iconName="map" />
    </div>
  );
};
