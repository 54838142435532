import { routes } from '@/routes/routes';
import { TextButton } from '../buttons/TextButton';

export const SignUpLegalText = () => {
  const handleLegalLinkClick = (route: string) => window.open(route, '_blank');

  return (
    <div className="flex gap-x-1 gap-y-0 flex-wrap justify-center">
      <p className="text-p-small text-gray">
        By creating an account, I agree to TravelPal's
      </p>
      <TextButton
        smallText
        onClick={() => {
          handleLegalLinkClick(routes.footer.terms);
        }}
      >
        Terms of Service
      </TextButton>
      <p className="text-p-small text-gray">and acknowledge</p>
      <TextButton
        smallText
        onClick={() => {
          handleLegalLinkClick(routes.footer.privacy);
        }}
      >
        Privacy Policy.
      </TextButton>
    </div>
  );
};
