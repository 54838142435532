import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { useApi } from '@/api/serviceProvider';
import { SignUpSchemaType } from '@/forms/useSignUpForm';
import { ApiError } from '@/generated';
import { routes } from '@/routes/routes';
import { removeAuthCookie } from '@/utils/removeAuthCookie';
import { showToast } from '@/utils/showToast';
import { useGoogleAnalytics } from './useGoogleAnalytics';

export const useSignUp = () => {
  const apiFactory = useApi();
  const navigate = useNavigate();
  const { sendGAEvent, EVENT_ACTIONS, EVENT_CATEGORIES } = useGoogleAnalytics();

  const { mutate, isPending } = useMutation({
    mutationKey: ['signUp'],
    mutationFn: async ({
      email,
      password,
      firstName,
      lastName,
    }: SignUpSchemaType) => {
      removeAuthCookie();

      if (!email || !password) {
        return;
      }

      return await apiFactory.auth.authRegistrationCreate({
        first_name: firstName,
        last_name: lastName,
        email,
        password1: password,
        password2: password,
      });
    },
    onSuccess: () => {
      sendGAEvent(EVENT_CATEGORIES.userAuth, EVENT_ACTIONS.completeRegistration);
      navigate(routes.auth.registerSuccess);
    },
    onError: (error: ApiError) => {
      const errorMessage = error.body.email[0];

      showToast(
        errorMessage ?? 'Failed to create an account. Please try again later',
      );
    },
  });

  return {
    handleSignUp: mutate,
    isLoading: isPending,
  };
};
