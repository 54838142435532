import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { passwordValidation } from '@/utils/yupValidations';

const ConfirmPasswordSchema = yup.object().shape({
  password: passwordValidation,
  confirmPassword: passwordValidation.oneOf(
    [yup.ref('password'), ''],
    'Passwords must match',
  ),
});

export type ConfirmPasswordSchemaType = yup.InferType<
  typeof ConfirmPasswordSchema
>;

export const useConfirmPasswordForm = () => {
  const { control, handleSubmit, formState } =
    useForm<ConfirmPasswordSchemaType>({
      mode: 'all',
      reValidateMode: 'onBlur',
      shouldFocusError: true,
      defaultValues: {
        password: '',
        confirmPassword: '',
      },
      resolver: yupResolver(ConfirmPasswordSchema),
    });

  const isSubmitDisabled = !formState.isValid || formState.isSubmitting;

  return {
    control,
    handleSubmit,
    isSubmitting: formState.isSubmitting,
    isSubmitDisabled,
  };
};
