import { TruncText } from '@components/texts/TruncText';
import classNames from 'classnames';
import { ReactNode } from 'react';

type Props = {
  onClick: () => void;
  children: ReactNode;
  selected?: boolean;
  multipleChoice?: boolean;
  // The type "any" for the reference is used on purpose - we pass "(card: Element) => void" type to this prop in ExperienceList
  // eslint-disable-next-line @/typescript-eslint/no-explicit-any
  reference?: any;
};

export const TailoringButton = ({
  onClick,
  children,
  selected,
  multipleChoice,
  reference,
}: Props) => {
  return (
    <button
      className={classNames('flex items-center justify-center', {
        'w-auto px-4 py-[10px] rounded-40': multipleChoice,
        'w-full p-4 rounded-xl': !multipleChoice,
        'bg-black': selected,
        'bg-gray-light': !selected,
      })}
      onClick={onClick}
      ref={reference}
    >
      <TruncText
        className={classNames({
          'text-white': selected,
          'text-black': !selected,
        })}
      >
        {children}
      </TruncText>
    </button>
  );
};
