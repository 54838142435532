import logo from '@assets/animations/travelpal_logo.json';
import {
  itineraryLoaderMiamiTexts,
  itineraryLoaderTexts,
} from '@data/appData/loaderData';
import Lottie from 'lottie-react';
import { useState } from 'react';
import { DateRange } from 'react-day-picker';

import { useLoaderTexts } from '@/hooks/useLoaderTexts';
import { useInitialSearchStore } from '@/stores/initialSearchStore';
import { PROGRESS_PART } from '@/utils/constants';

type Props = {
  dateRange: DateRange | null;
};

export const ItineraryLoader = ({ dateRange }: Props) => {
  const [progress, setProgress] = useState(0);
  const location = useInitialSearchStore((state) => state.location);
  const isMiami =
    typeof location?.name == 'string'
      ? location.name.toLowerCase().includes('miami')
      : false;
  const loaderTexts = isMiami
    ? itineraryLoaderMiamiTexts
    : itineraryLoaderTexts;

  const updateProgress = () => {
    if (progress < 75) {
      setProgress(progress + PROGRESS_PART);
    } else {
      setProgress(99);
    }
  };

  const { currentCharIndex, fade } = useLoaderTexts(
    loaderTexts,
    isMiami,
    progress,
    updateProgress,
  );

  return (
    <div className="flex flex-col w-full h-svh bg-white z-30 absolute top-0 left-0 justify-center items-center px-4 xl:max-w-screen-md xl:left-1/2 xl:-translate-x-1/2">
      <div className="flex flex-col items-center">
        <div className="w-[210px]">
          <Lottie
            animationData={logo}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
        <h5
          className={`text-h5 text-center w-72 h-[90px] ${
            fade ? 'animate-fadeOutFast' : 'animate-fadeInFast'
          }`}
        >
          {loaderTexts[currentCharIndex]}
        </h5>
      </div>
      {dateRange !== null && (
        <div className="flex flex-col absolute bottom-10 w-full items-center px-8 gap-1">
          <p className="mb-3 text-p-medium text-center w-72">
            This usually takes up to 1 minute...
          </p>
          <div className="w-full h-1 bg-purple-light rounded-lg">
            <div
              className="h-full bg-purple rounded-lg transition-width duration-[1000ms] ease-in-out"
              style={{ width: `${progress}%` }}
            />
          </div>
          <p className="mt-2 text-p-medium">{progress}%</p>
        </div>
      )}
    </div>
  );
};
