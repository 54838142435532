import { Button } from '@components/buttons/Button';
import { SpriteIcon } from '@components/icons/SpriteIcon';
import { ItineraryLoader } from '@components/loaders/ItineraryLoader';
import { useAddItineraryTyMyTrips } from '@hooks/useAddItineraryToMyTrips';
import { useShuffleExperiences } from '@hooks/useShuffleExperiences';
import { routes } from '@routes/routes';
import { useInitialSearchStore } from '@stores/initialSearchStore';
import { useNavigate } from 'react-router-dom';

import { useItineraryToSaveStore } from '@/stores/itineraryToSaveStore';
import { useUserStore } from '@/stores/userStore';
import { useNextStepStore } from '@/stores/nextStepStore';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  useGoogleAnalytics,
} from '@/hooks/useGoogleAnalytics';

type Props = {
  itineraryId: number | undefined;
};

export const BottomBar = ({ itineraryId }: Props) => {
  const initialSearchDateRange = useInitialSearchStore(
    (state) => state.dateRange,
  );
  const setNextStep = useNextStepStore((state) => state.setNextStep);

  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);

  const { shuffleExperiences, isPending } = useShuffleExperiences(itineraryId);
  const { sendGAEvent } = useGoogleAnalytics();

  const isLoggedIn = !!user?.access;

  const handleSetItineraryIdToSaveId = useItineraryToSaveStore(
    (state) => state.setItineraryToSaveId,
  );

  const { handleAddItinerary, isAddItineraryPending } =
    useAddItineraryTyMyTrips();

  const handleReplanClick = () => {
    /* How many users click on “Replan” after generating an itinerary */
    sendGAEvent(EVENT_CATEGORIES.itinerary, EVENT_ACTIONS.replanItinerary);
    shuffleExperiences();
  };
  const handleSaveTripClick = () => {
    /* How many users click on “Save trip” after generating an itinerary */
    sendGAEvent(EVENT_CATEGORIES.itinerary, EVENT_ACTIONS.saveTrip);
    if (!isLoggedIn) {
      if (itineraryId) {
        handleSetItineraryIdToSaveId(itineraryId);
        setNextStep({
          saveTripAdmin: {
            itineraryId: itineraryId,
            peopleToInvite: [],
          },
        });
      }

      return navigate(routes.auth.index);
    }

    handleAddItinerary(itineraryId);
  };
  const handlePlusClick = () =>
    navigate(`/itinerary/${itineraryId}/add-experience`);

  if (isPending) {
    return <ItineraryLoader dateRange={initialSearchDateRange} />;
  }

  return (
    <div className="w-full flex justify-center">
      <div className="flex gap-2 p-2 rounded-36 bg-white shadow-bottom-bar fixed bottom-4 self-center w-full max-w-max z-[5]">
        <Button
          size="large"
          iconName="stars"
          onClick={handleReplanClick}
          variant="secondary"
          disabled={isAddItineraryPending}
        >
          Replan
        </Button>
        <button
          onClick={handlePlusClick}
          disabled={isAddItineraryPending}
          className="bg-green hover:bg-green-hover disabled:bg-green-disabled h-fit false flex justify-center items-center py-3.5 gap-2 rounded-[40px] w-[150px]"
        >
          <SpriteIcon iconName="plus" className="w-6 h-6" />
        </button>
        <Button
          size="large"
          iconName="hearth"
          onClick={handleSaveTripClick}
          disabled={isAddItineraryPending}
        >
          Save trip
        </Button>
      </div>
    </div>
  );
};
