import placeholderImg from '@assets/images/landingPage/experience-placeholder.webp';
import { CatalogueCard } from '@components/cards/CatalogueCard';
import { Loader } from '@components/loaders/Loader';
import { EmptyListMessage } from '@components/texts/EmptyListMessage';
import { useInfiniteScrollRef } from '@hooks/useInfiniteScrollRef';

import { Experience } from '@/generated';
import { NextPageParamType } from '@/types/QueryTypes';
import { forwardRef } from 'react';

type Props = {
  experiences: Experience[] | undefined;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  onCardClick: (experience: Experience) => void;
  fetchNextPage: NextPageParamType<Experience>;
};

export const ExperienceList = forwardRef<
  {
    [key: string]: HTMLButtonElement | null;
  },
  Props
>(
  (
    {
      experiences = [],
      hasNextPage,
      isFetchingNextPage,
      onCardClick,
      fetchNextPage,
    },
    ref,
  ) => {
    const lastCardRef = useInfiniteScrollRef({
      hasNextPage,
      isFetchingNextPage,
      fetchNextPage,
    });

    const typedRef = ref as React.MutableRefObject<{
      [key: string]: HTMLButtonElement | null;
    } | null>;

    return (
      <>
        {Array.isArray(experiences) && experiences.length === 0 && (
          <EmptyListMessage message="No experiences available" />
        )}
        {experiences.map((experience, index) => (
          <CatalogueCard
            reference={
              experiences.length === index + 1 ? lastCardRef : undefined
            }
            key={experience.id}
            title={experience?.details?.place_name}
            rating={experience.details?.rating}
            reviews={experience.details?.user_ratings_total}
            image={experience.details?.place_photo ?? placeholderImg}
            priceLevel={experience.details?.price_level ?? 0}
            onAddClick={() => onCardClick(experience)}
            ref={(el) => {
              if (el && typedRef?.current && experience.id) {
                typedRef.current[experience.id] = el;
              }
            }}
          />
        ))}
        {isFetchingNextPage && (
          <div className="h-[120px]">
            <Loader />
          </div>
        )}
      </>
    );
  },
);
