import { useInitialSearchStore } from '@/stores/initialSearchStore';
import { useTailorSearchStore } from '@/stores/tailorSearchStore';
import { SearchHeader } from '@components/headers/SearchHeader';
import { Loader } from '@components/loaders/Loader';
import { SearchResult } from '@components/modalsSections/searchResult/SearchResult';
import { useStartPageModalStore } from '@stores/startPageModalStore';
import { Suggestion, Suggestions } from 'use-places-autocomplete';

interface SearchHeaderSectionProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
  suggestions: Suggestions;
  handleSelectClick: (value: google.maps.places.AutocompletePrediction) => void;
  isWithBackButton?: boolean;
}

export const SearchHeaderSection = ({
  searchValue,
  setSearchValue,
  suggestions,
  handleSelectClick,
  isWithBackButton,
}: SearchHeaderSectionProps) => {
  const { setOpenedModal } = useStartPageModalStore();
  const currentDest = useInitialSearchStore((state) => state.location?.name);
  const currentHotelDest = useTailorSearchStore(
    (state) => state.tailoredSearch.accommodation?.name,
  );

  return (
    <>
      <SearchHeader
        value={searchValue}
        onSearch={setSearchValue}
        onClick={() => setOpenedModal(null)}
        bgColor="gray-light"
        disabled={false}
        isWithBackButton={isWithBackButton}
      />
      <div className="flex w-full py-4">
        {suggestions.loading && <Loader />}
        {!suggestions.loading && (
          <div className="flex flex-col justify-start gap-2">
            {suggestions.data && suggestions.data.length > 0 ? (
              <>
                <h5 className="text-h5 flex">Search results</h5>
                {suggestions.data.map((suggestion: Suggestion) => (
                  <SearchResult
                    key={suggestion.place_id}
                    result={suggestion.description}
                    onClick={() => handleSelectClick(suggestion)}
                    iconName="map"
                  />
                ))}
              </>
            ) : (
              searchValue &&
              searchValue.split(',')[0] !== currentDest &&
              searchValue.split(',')[0] !== currentHotelDest && (
                <div className="text-black">
                  <h5 className="text-h5">No results found</h5>
                  <p className="text-p">
                    We went all out but we couldn't find
                    <br />
                    such a place on the map.
                  </p>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </>
  );
};
