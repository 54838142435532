import { SpriteIconProps } from '@components/icons/SpriteIcon';

const categoryToIconMapping: Record<string, SpriteIconProps> = {
  sightseeing: 'sightseeing',
  restaurant: 'restaurant',
  dining: 'restaurant',
  leisure: 'beach',
  'historical site': 'history',
  museum: 'history',
  landmark: 'sightseeing',
  viewpoint: 'sightseeing',
  culture: 'culture',
  shopping: 'indoor',
  entertainment: 'culture',
  recreation: 'park',
  'beach & relaxation': 'beach',
  'shopping & dining': 'restaurant',
  'park & recreation': 'park',
  'wildlife & adventure': 'park',
  'education & science': 'culture',
  'family & education': 'family',
  'culture & history': 'culture',
  'nature & wildlife': 'park',
  'beach & nature': 'beach',
  'education & sightseeing': 'sightseeing',
  'culture & education': 'culture',
  culinary: 'restaurant',
  historical: 'history',
  walking: 'pedestrian',
  pedestrian: 'pedestrian',
  food: 'food',
  relax: 'beach',
  sports: 'pedestrian',
  music: 'culture',
  sunbathing: 'beach',
  indoor: 'indoor',
};

export const mapCategoryToIcon = (
  category?: { name: string }[],
): SpriteIconProps => {
  if (!category || !Array.isArray(category) || category.length === 0) {
    return 'park';
  }
  for (const cat of category) {
    const icon = categoryToIconMapping[cat.name.toLowerCase()];
    if (icon) {
      return icon;
    }
  }

  return 'sightseeing';
};
