import { TailoringButton } from '@components/buttons/TailoringButton';
import { Loader } from '@components/loaders/Loader';
import { EmptyListMessage } from '@components/texts/EmptyListMessage';

import { useInfiniteScrollRef } from '@/hooks/useInfiniteScrollRef';
import { ActivityTag } from '@/types/AppTypes';

type Props = {
  interests: Array<ActivityTag>;
  selectedInterests: Array<ActivityTag> | undefined;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  onInterestClick: (interest: ActivityTag) => void;
  // Type of next line of code: NextPageParamType<Interest>
  // eslint-disable-next-line @/typescript-eslint/no-explicit-any
  fetchNextPage: any;
};

export const InterestsList = ({
  interests = [],
  selectedInterests = [],
  hasNextPage,
  isFetchingNextPage,
  onInterestClick,
  fetchNextPage,
}: Props) => {
  const lastCardRef = useInfiniteScrollRef({
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  });

  return (
    <>
      {Array.isArray(interests) && interests.length === 0 && (
        <EmptyListMessage message="No interests available" />
      )}
      <div className="flex flex-wrap justify-center gap-3">
        <div className="flex flex-wrap overflow-y-auto max-h-[50svh] justify-center gap-3">
          {interests.map((interest, index) => (
            <TailoringButton
              reference={
                interests.length === index + 1 ? lastCardRef : undefined
              }
              key={index}
              onClick={() => onInterestClick(interest)}
              selected={selectedInterests?.some(
                (interestName) => interestName.tag === interest.tag,
              )}
              multipleChoice
            >
              {interest.name}
            </TailoringButton>
          ))}
        </div>
        {isFetchingNextPage && (
          <div className="h-[120px]">
            <Loader />
          </div>
        )}
      </div>
    </>
  );
};
