import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeyTypes } from '@/api/queryKeyTypes';
import { useApi } from '@/api/serviceProvider';
import { showToast } from '@/utils/showToast';

export const useAddItineraryTyMyTrips = () => {
  const apiFactory = useApi();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: async (itineraryId: number | undefined) => {
      if (!itineraryId) {
        return;
      }

      return await apiFactory.api.apiV1ItinerariesSave(itineraryId);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.usersTrips],
      });

      showToast('Itinerary added successfully');
    },
    onError: () => showToast('Failed to add itinerary'),
  });

  return {
    handleAddItinerary: mutate,
    isAddItineraryPending: isPending,
  };
};
