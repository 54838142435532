import { useNavigate } from 'react-router-dom';

import { FieldTextButton } from '@/components/buttons/FieldTextButton';
import { AuthLayout } from '@/components/layouts/AuthLayout';
import { useResetPassword } from '@/hooks/useResetPassword';
import { routes } from '@/routes/routes';
import { useAuthEmailStore } from '@/stores/authEmailStore';

export const ResetPasswordRetryPage = () => {
  const navigate = useNavigate();

  const handleNavigateToCheckYourEmailScreen = () =>
    navigate({
      pathname: routes.auth.registerSuccess,
      search: '?enabledResend=false',
    });

  const { handleResetPassword, isResetPasswordLoading } = useResetPassword(
    handleNavigateToCheckYourEmailScreen,
  );

  const email = useAuthEmailStore((state) => state.email);
  const clearEmailValue = useAuthEmailStore((state) => state.clearEmail);

  const handleCancelButtonClick = () => {
    clearEmailValue();
    navigate(routes.home);
  };

  const handleResendEmailClick = () => handleResetPassword(email);

  return (
    <AuthLayout handleCloseButtonClick={handleCancelButtonClick}>
      <h3 className="text-h3 pb-2">Reset password</h3>
      <span className="text-p text-gray pb-6">
        {`An email with instructions has been sent to ${email}. Check your email
        for a verification link and click on it to confirm.`}
      </span>
      <span className="text-p text-gray pb-10">
        The message might end up in your spam folder.
      </span>
      <span className="text-p text-gray">
        Didn’t get an email?{' '}
        <FieldTextButton
          disabled={isResetPasswordLoading}
          onClick={handleResendEmailClick}
          textVariant="bold"
        >
          Resend
        </FieldTextButton>
      </span>
    </AuthLayout>
  );
};
