import dayjs from 'dayjs';

import { TIME_HOUR_MINUTE_WITHOUT_SECONDS_FORMAT } from './constants.ts';
import { formatTimeRange } from './formatDateTime.ts';

export const getCommuteTimeRange = (
  date: string | null | undefined,
  experienceEndTime: string | null | undefined,
  commuteDuration: string | null | undefined,
): string => {
  if (!date || experienceEndTime == null || commuteDuration == null) {
    return '';
  }

  const [hours, minutes] = experienceEndTime.split(':').map(Number);

  const commuteMinutes = Math.ceil(Number(commuteDuration) / 60);
  const totalMinutes = minutes + commuteMinutes;

  const newTime = dayjs()
    .set('hour', hours)
    .set('minute', totalMinutes)
    .format(TIME_HOUR_MINUTE_WITHOUT_SECONDS_FORMAT);

  return formatTimeRange(date, experienceEndTime, newTime);
};
