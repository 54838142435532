import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { showToast } from '@/utils/showToast';

export const useExperiencesUndoDeletion = (
  experienceId: number | undefined,
) => {
  const queryClient = useQueryClient();
  const apiFactory = useApi();

  const {
    mutateAsync: handleUndoExperienceDeletion,
    isPending: isUndoExperienceDeletionLoading,
  } = useMutation({
    mutationFn: async () => {
      if (!experienceId) {
        return;
      }

      return apiFactory.api.apiV1ExperiencesUndo(experienceId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.itinerary],
      });
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.commutes],
      });

      showToast('Experience deletion canceled');
    },
    onError: () => showToast('Failed to cancel experience deletion'),
  });

  return {
    handleUndoExperienceDeletion,
    isUndoExperienceDeletionLoading,
  };
};
