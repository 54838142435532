import { SpriteIcon } from '@components/icons/SpriteIcon';

type Props = {
  result: string;
  iconName?: 'location' | 'map';
  subResult?: string;
  onClick: () => void;
};

export const SearchResult = ({
  result,
  subResult,
  onClick,
  iconName = 'location',
}: Props) => {
  return (
    <div onClick={onClick} className="flex items-center gap-x-2 max-w-[342px]">
      <div className="flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-general flex-shrink-0">
        <SpriteIcon iconName={iconName} className="w-6 h-6 fill-black" />
      </div>
      <div>
        <p className="text-p text-black">{result}</p>
        {subResult && <p className="text-p-small text-gray">{subResult}</p>}
      </div>
    </div>
  );
};
