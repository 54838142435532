import { TabButton } from '../buttons/TabButton';
import { TitleHeader } from './TitleHeader';

export type ItineraryStatusTab = 'all' | 'pending' | 'ongoing' | 'finished';

type Params = {
  selectedTab: ItineraryStatusTab;
  onTabChange: (tab: ItineraryStatusTab) => void;
};

export const MyTripsHeader = ({ selectedTab, onTabChange }: Params) => {
  return (
    <TitleHeader title="My Trips">
      <div className="w-full overflow-x-auto flex gap-x-2 scrollbar-hide mx-auto mt-4">
        <TabButton
          status="all"
          title="All"
          onClick={() => onTabChange('all')}
          selected={selectedTab}
        />
        <TabButton
          status="pending"
          title="Pending"
          onClick={() => onTabChange('pending')}
          selected={selectedTab}
        />
        <TabButton
          status="ongoing"
          title="Ongoing"
          onClick={() => onTabChange('ongoing')}
          selected={selectedTab}
        />
        <TabButton
          status="finished"
          title="Finished"
          onClick={() => onTabChange('finished')}
          selected={selectedTab}
        />
      </div>
    </TitleHeader>
  );
};
