import { Button } from '@components/buttons/Button';
import { Header } from '@components/headers/Header';
import { PeopleAmount } from '@components/modalsSections/peopleAmount/PeopleAmount';
import { PopUpContainer } from '../containers/PopUpContainer';
import { getTotalPeopleCount } from '@/utils/getTotalPeopleCount';
import { Itinerary, Persons } from '@/generated';
import { usePeopleAmount } from '@/hooks/usePeopleAmount';

type Params = {
  isOpen: boolean;
  onClose: () => void;
  persons: Persons | null | undefined;
  itineraryData: Itinerary | undefined;
  setData: (newData: any) => void;
  clearData?: () => void;
};

export const SetPeopleWindow = ({
  isOpen,
  onClose,
  persons,
  clearData,
}: Params) => {
  const peopleAmount = usePeopleAmount();
  return (
    <PopUpContainer isOpen={isOpen} onClose={onClose} isWhite={false}>
      <Header
        actionButtonText={clearData ? 'Clear' : undefined}
        onActionButtonClick={clearData ? () => clearData() : undefined}
      />
      <div className="flex flex-col gap-5 w-full py-5">
        <h4 className="text-h4 flex self-start">Who's coming?</h4>
        <PeopleAmount
          people={peopleAmount.people}
          invitedPeople={peopleAmount.invitedPeople}
          itineraryData={peopleAmount.data}
          generatedItineraryId={peopleAmount.generatedItineraryId}
          isInviteBottomSheet={peopleAmount.isInviteBottomSheet}
          handleAmountChange={peopleAmount.handleAmountChange}
          onInviteHandler={peopleAmount.onInviteHandler}
          setIsInviteBottomSheet={peopleAmount.setIsInviteBottomSheet}
        />
      </div>
      <div className="w-full p-4">
        <Button
          size="large"
          disabled={!getTotalPeopleCount(persons)}
          onClick={onClose}
          full
        >
          Apply
        </Button>
      </div>
    </PopUpContainer>
  );
};
