import { useAddExperienceToItinerary } from '@hooks/useAddExperienceToItinerary';
import { useGetItinerariesFreeSlots } from '@hooks/useGetItinerariesFreeSlots';
import { getFreeEndTimeslots } from '@utils/getFreeEndTimeslots';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { useCheckForOverlaps } from '@/hooks/useCheckForOverlaps';
import { FreeSlot } from '@/generated';

import { ExperienceDateTimeSchemaType } from '../ExperienceChangeDateTimeBottomSheet';
import { EndTimeSlots } from './EndTimeSlots';
import { OverlappingExperiences } from './OverlappingExperiences';
import { StartTimeSlots } from './StartTimeSlots';
import { PopUpContainer } from '@/components/containers/PopUpContainer';
import { useAddExperienceStore } from '@/stores/addExperienceStore';

type TimeSlotsView = 'startSlots' | 'endSlots' | 'overlappingSlots';

type Props = {
  itineraryId: number | undefined;
  isOpen: boolean;
  onClose: () => void;
  isUpdate: boolean;
  handleUpdateExperience?: ({}: ExperienceDateTimeSchemaType) => void;
};

export const TimeSlotBottomSheet = ({
  itineraryId,
  isUpdate = false,
  handleUpdateExperience,
  ...rest
}: Props) => {
  const [slotsView, setSlotsView] = useState<TimeSlotsView>('startSlots');
  const [endTimes, setEndTimes] = useState<string[]>([]);
  const [overlappingExperiencesIds, setOverlappingExperiencesIds] = useState<
    number[]
  >([]);

  const { chosenExperience } = useAddExperienceStore();
  const {
    handleAddExperience,
    isPending,
    setSelectDay,
    setSelectedTimeSlot,
    selectDay,
    selectedTimeSlot,
  } = useAddExperienceToItinerary({
    itineraryId: itineraryId,
    overlappingExperiences: overlappingExperiencesIds,
    onTimeSlotSheetClose: rest.onClose,
  });

  const { checkForOverlapping, overlappingExperiences, isChecking } =
    useCheckForOverlaps({
      itineraryId: itineraryId,
      timeSlot: selectedTimeSlot,
      day: selectDay,
    });

  useEffect(() => {
    if (
      chosenExperience &&
      selectDay &&
      selectedTimeSlot.start_time &&
      selectedTimeSlot.end_times[0]
    ) {
      checkForOverlapping();
    }
  }, [chosenExperience, selectDay, selectedTimeSlot]);

  const overriddenHandleAddExperience = useCallback(() => {
    // if there are no overlapping experiences, updates or adds experience to itinerary
    // if overlapping experiences exists, shows overlapping experiences view
    if (overlappingExperiences.length === 0) {
      if (isUpdate && handleUpdateExperience) {
        handleUpdateExperience({
          date: selectDay,
          fromTime: selectedTimeSlot?.start_time,
          toTime: selectedTimeSlot?.end_times[0],
        });
      } else {
        handleAddExperience();
      }
    } else {
      const overlappingIds = overlappingExperiences
        .filter((exp) => exp && exp.id !== undefined)
        .map((exp) => exp.id as number);

      setOverlappingExperiencesIds(overlappingIds);

      if (
        overlappingExperiences.length === 1 &&
        chosenExperience?.id &&
        overlappingExperiences[0].id &&
        chosenExperience.id === overlappingExperiences[0].id
      ) {
        if (isUpdate && handleUpdateExperience) {
          handleUpdateExperience({
            date: selectDay,
            fromTime: selectedTimeSlot?.start_time,
            toTime: selectedTimeSlot?.end_times[0],
          });
        } else {
          handleAddExperience();
        }
      } else {
        setSlotsView('overlappingSlots');
      }
    }
  }, [
    overlappingExperiences,
    selectDay,
    selectedTimeSlot,
    chosenExperience,
    checkForOverlapping,
    handleUpdateExperience,
    isUpdate,
    handleAddExperience,
  ]);

  const { timeSlots, mutation, isLoading } = useGetItinerariesFreeSlots(
    itineraryId,
    chosenExperience?.id,
  );

  useEffect(() => {
    if (chosenExperience) {
      setSlotsView('startSlots');
      mutation();

      if (isUpdate) {
        setSelectedTimeSlot({
          start_time: chosenExperience.start_time,
          end_times: [chosenExperience.end_time],
        } as FreeSlot);
        setSelectDay(chosenExperience.date || '');
      }
    }
  }, [chosenExperience]);

  const timeslotsForSelectedDay = timeSlots?.find((e) => e.date === selectDay);

  const isDisabled = isPending || isChecking || !selectedTimeSlot || !selectDay;

  useEffect(() => {
    const firstDay = _.first(timeSlots);
    if (firstDay) {
      setSelectDay(firstDay.date);
    }
  }, [setSelectDay]);

  useEffect(() => {
    handleDisplayEndSlots();
  }, [setSelectedTimeSlot]);

  const handleBackClick = () => setSlotsView('startSlots');

  const handleDisplayEndSlots = () => {
    if (selectedTimeSlot) {
      const freeEndTimeSlots = getFreeEndTimeslots(selectedTimeSlot);
      setEndTimes(freeEndTimeSlots);
      setSlotsView('endSlots');
    }
  };

  return (
    <PopUpContainer {...rest} isPadded={false}>
      {slotsView === 'startSlots' && (
        <StartTimeSlots
          timeSlots={timeSlots}
          isLoading={isLoading}
          selectDay={selectDay}
          selectedTimeSlot={selectedTimeSlot}
          isDisabled={isDisabled}
          timeslotsForSelectedDay={timeslotsForSelectedDay}
          onButtonClick={handleDisplayEndSlots}
          setSelectDay={setSelectDay}
          setSelectedTimeSlot={setSelectedTimeSlot}
        />
      )}
      {slotsView === 'endSlots' && (
        <EndTimeSlots
          timeSlots={endTimes}
          selectedTimeSlot={selectedTimeSlot}
          isDisabled={isDisabled}
          onButtonClick={overriddenHandleAddExperience}
          setSelectedTimeSlot={setSelectedTimeSlot}
          handleBackClick={handleBackClick}
        />
      )}
      {slotsView === 'overlappingSlots' && (
        <OverlappingExperiences
          newExperience={chosenExperience}
          newExperienceStartTime={selectedTimeSlot?.start_time}
          newExperienceEndTime={selectedTimeSlot?.end_times[0]}
          overlappingExperiences={overlappingExperiences.filter((exp) => {
            return exp.id !== chosenExperience?.id;
          })}
          onConfirm={handleAddExperience}
          onCancel={handleBackClick}
        />
      )}
    </PopUpContainer>
  );
};
