import { SpriteIcon, SpriteIconProps } from '@components/icons/SpriteIcon';
import classNames from 'classnames';

export type MenuItemProps = {
  label: string;
  icon: SpriteIconProps;
  isHighlighted?: boolean;
  onClick: () => void;
};

type Props = MenuItemProps & {
  hasBorder?: boolean;
};

export const ModalOption = ({
  label,
  isHighlighted,
  icon,
  onClick,
  hasBorder,
}: Props) => {
  return (
    <div
      className={classNames('flex px-4 h-14 items-center', {
        'border-b border-b-gray-light': hasBorder,
      })}
      onClick={onClick}
    >
      <SpriteIcon
        className={classNames('w-4 h-4', {
          'fill-red': isHighlighted,
        })}
        iconName={icon}
      />
      <p
        className={classNames('text-p ml-2', {
          'text-red': isHighlighted,
        })}
      >
        {label}
      </p>
    </div>
  );
};
