import axios from 'axios';
import Cookies from 'js-cookie';

import {
  ApiService,
  AuthService,
  MetricsService,
  TravelPal,
} from '@/generated';
import { showToast } from '@/utils/showToast';
import { useNavigate } from 'react-router-dom';
import { routes } from '@/routes/routes';

let service: { api: ApiService; auth: AuthService; metrics: MetricsService };

export const useApi = () => {
  const nav = useNavigate();
  const token = Cookies.get('tp_access_token');

  service = new TravelPal({ TOKEN: token });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        Cookies.remove('tp_access_token');
        showToast('Session expired, please login again');
        nav(routes.auth.login);
      }
      return Promise.reject(error);
    },
  );

  return {
    api: service?.api,
    auth: service?.auth,
    metrics: service?.metrics,
  };
};
