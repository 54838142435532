import { useEffect, useState } from 'react';

import { TWO_AND_HALF_SECONDS, TWO_SECONDS } from '@/utils/constants';

export const useLoaderTexts = (
  loaderTexts: string[],
  isMiami: boolean | undefined,
  progress: number,
  updateProgress: () => void,
) => {
  const [currentCharIndex, setCurrentCharIndex] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      updateProgress();
    }, TWO_SECONDS);
    return () => clearInterval(interval);
  }, [progress]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentCharIndex < loaderTexts.length - 1) {
        setFade(true);
        setTimeout(() => {
          setCurrentCharIndex(currentCharIndex + 1);
          setFade(false);
        }, TWO_AND_HALF_SECONDS);
      }
    }, TWO_AND_HALF_SECONDS);
    return () => clearInterval(interval);
  }, [currentCharIndex, loaderTexts, isMiami]);

  return { currentCharIndex, fade };
};
