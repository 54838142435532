import { Link } from 'react-router-dom';

type Props = {
  label: string;
  mailto: string;
};

export const MailToButton: React.FC<Props> = ({ label, mailto }) => {
  // mailto must be started with 'mailto:' to open the default mail client
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    try {
      window.location.href = mailto;
    } catch (error) {
      console.error('Error occurred while setting window location:', error);
    }
  };

  return (
    <Link to="#" onClick={handleClick}>
      {label}
    </Link>
  );
};
