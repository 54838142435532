import { useState } from 'react';
import { useGenerateItinerary } from '@/hooks/useGenerateItinerary';
import { InitialSearchStoreType, useInitialSearchStore } from '@stores/initialSearchStore';

export const usePeopleAmount = () => {
    const { data, generateItinerary, isPending } = useGenerateItinerary({ isPendingItineraryNeeded: true, isGAEventNeeded: false });
    const location = useInitialSearchStore((state) => state.location);
    const dateRange = useInitialSearchStore((state) => state.dateRange);
    const people = useInitialSearchStore((state) => state.people);
    const invitedPeople = useInitialSearchStore((state) => state.invitedPeople);
    const generatedItineraryId = useInitialSearchStore((state) => state.itineraryId);

    const [isInviteBottomSheet, setIsInviteBottomSheet] = useState(false);
    const setInitialSearch = useInitialSearchStore((state) => state.setInitialSearch);

    const handleAmountChange = (
        updatedValue: Partial<InitialSearchStoreType['people']>,
    ) => {
        setInitialSearch({
            people: {
                ...people,
                ...updatedValue,
            },
        });
    };

    const onInviteHandler = () => {
        if (!location) {
            setInitialSearch({
                location: {
                    name: 'Miami, FL, USA',
                    coords: { lat: 25.7616798, lng: -80.1917902 },
                },
            });
        }
        if (!dateRange) {
            setInitialSearch({ dateRange: { from: new Date(), to: new Date() } });
        }
        if (!people) {
            setInitialSearch({ people: { adults: 1, children: 0, infants: 0 } });
        }

        if (!isPending && !data?.id && !generatedItineraryId) {
            generateItinerary();
        }
        setIsInviteBottomSheet(true);
    };

    return {
        people,
        invitedPeople,
        data,
        generatedItineraryId,
        isPending,
        isInviteBottomSheet,
        handleAmountChange,
        onInviteHandler,
        setIsInviteBottomSheet,
    };
};