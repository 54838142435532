import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  TIME_HOUR_MINUTE_SECONDS_FORMAT,
  TIME_MERIDIAN_FORMAT,
  TWELVE_HOURS_TIME_HOUR_MINUTE_WITHOUT_SECONDS_FORMAT,
} from '@/utils/constants';
import { getFormattedDateTime } from '@/utils/formatDateTime';
import { fromTimeValidation, toTimeValidation } from '@/utils/yupValidations';

const ExperienceDateTimeSchema = yup.object().shape({
  toTime: toTimeValidation,
  fromTime: fromTimeValidation,
  toMeridian: yup.string(),
  fromMeridian: yup.string(),
});

export type ExperienceDateTimeSchemaType = yup.InferType<
  typeof ExperienceDateTimeSchema
>;

type Props = {
  defaultExperienceStartTime: string;
  defaultExperienceEndTime: string;
};

export const useExperienceDateTimeForm = ({
  defaultExperienceStartTime,
  defaultExperienceEndTime,
}: Props) => {
  const { control, handleSubmit, formState, trigger } =
    useForm<ExperienceDateTimeSchemaType>({
      mode: 'all',
      reValidateMode: 'onBlur',
      shouldFocusError: true,
      defaultValues: {
        toTime: getFormattedDateTime({
          dateTime: defaultExperienceEndTime,
          inputFormat: TIME_HOUR_MINUTE_SECONDS_FORMAT,
          format: TWELVE_HOURS_TIME_HOUR_MINUTE_WITHOUT_SECONDS_FORMAT,
        }),
        fromTime: getFormattedDateTime({
          dateTime: defaultExperienceStartTime,
          inputFormat: TIME_HOUR_MINUTE_SECONDS_FORMAT,
          format: TWELVE_HOURS_TIME_HOUR_MINUTE_WITHOUT_SECONDS_FORMAT,
        }),
        toMeridian: getFormattedDateTime({
          dateTime: defaultExperienceEndTime,
          inputFormat: TIME_HOUR_MINUTE_SECONDS_FORMAT,
          format: TIME_MERIDIAN_FORMAT,
        }),
        fromMeridian: getFormattedDateTime({
          dateTime: defaultExperienceStartTime,
          inputFormat: TIME_HOUR_MINUTE_SECONDS_FORMAT,
          format: TIME_MERIDIAN_FORMAT,
        }),
      },
      resolver: yupResolver(ExperienceDateTimeSchema),
    });

  const isSubmitDisabled = !formState.isValid || formState.isSubmitting;

  return {
    control,
    handleSubmitForm: handleSubmit,
    isSubmitDisabled,
    triggerValidation: trigger,
  };
};
