import { SpriteIcon, SpriteIconProps } from '@components/icons/SpriteIcon';
import { ReactNode } from 'react';

import { TruncText } from '../texts/TruncText';

type Props = {
  leftIcon?: SpriteIconProps;
  rightIcon?: SpriteIconProps;
  filled?: boolean;
  styles?: string;
  children: ReactNode;
  onClick: () => void;
};

export const LargeIconButton = ({
  leftIcon,
  rightIcon,
  styles,
  children,
  filled,
  onClick,
}: Props) => {
  return (
    <button
      onClick={onClick}
      className={`bg-gray-light w-full max-w-[343px] h-14 flex gap-2 px-4 py-3 rounded-xl justify-between ${styles}`}
    >
      <div
        className={`${
          filled ? 'text-black' : 'text-gray'
        } flex items-center gap-2 my-auto w-full`}
      >
        {leftIcon && (
          <SpriteIcon
            iconName={leftIcon}
            className={`${filled ? 'fill-black' : 'fill-gray'} w-6 h-6`}
          />
        )}
        <TruncText maxWidth={220}>{children}</TruncText>
      </div>
      {rightIcon && <SpriteIcon iconName={rightIcon} className="w-7 h-7" />}
    </button>
  );
};
