import { create } from 'zustand';

import { Experience } from '@/generated';
import {
  ChosenExperienceCategory,
  ChosenSortingValue,
  Filters,
} from '@/types/stores/AddExperienceStoreTypes';

type State = {
  chosenExperienceCategory: ChosenExperienceCategory;
  chosenExperience: Experience | null;
  chosenFilters: Filters;
  chosenSorting: ChosenSortingValue;
};

type Actions = {
  setChosenExperienceCategory: (experience: ChosenExperienceCategory) => void;
  setChosenFilters: (filter: string) => void;
  clearFilters: () => void;
  setChosenSorting: (sortingValue: ChosenSortingValue) => void;
  setChosenExperience: (experience: Experience) => void;
  clearChosenExperience: () => void;
  clearExperienceStore: () => void;
};

const initialState: State = {
  chosenExperience: null,
  chosenExperienceCategory: 'activities',
  chosenFilters: { categories: [] },
  chosenSorting: '-rating',
};

export const useAddExperienceStore = create<State & Actions>()((set) => ({
  ...initialState,
  setChosenExperienceCategory: (chosenExperienceCategory) =>
    set({
      chosenExperienceCategory,
      chosenFilters: initialState.chosenFilters,
    }),
  // TODO: split setChosenFilters into 2 actions (add and remove)
  setChosenFilters: (chosenFilter) =>
    set((state) => {
      if (state.chosenFilters.categories.includes(chosenFilter)) {
        const filteredFilters = state.chosenFilters.categories.filter(
          (filter) => filter !== chosenFilter,
        );

        return { chosenFilters: { categories: filteredFilters } };
      }
      return {
        chosenFilters: {
          categories: [...state.chosenFilters.categories, chosenFilter],
        },
      };
    }),
  setChosenExperience: (chosenExperience: Experience) =>
    set({ chosenExperience }),
  clearChosenExperience: () => set({ chosenExperience: null }),
  clearFilters: () => set({ chosenFilters: initialState.chosenFilters }),
  setChosenSorting: (chosenSorting) => set({ chosenSorting }),
  clearExperienceStore: () => set(initialState),
}));
