import { useGetElementPosition } from '@/hooks/useGetElementPosition';
import { SpriteIcon } from '@components/icons/SpriteIcon';
import { TimeSlot } from '@components/timeSlots/TimeSlot';
import { Tooltip } from '@components/tooltips/Tooltip';
import { formatDateTime } from '@utils/formatDateTime.ts';
import { useState } from 'react';

type Props = {
  startTime?: string | null;
  endTime?: string | null;
  date?: string | null;
  onClose: () => void;
  timeSlotElement?: HTMLDivElement | null;
};

export const ItineraryTooltips = ({
  startTime,
  endTime,
  onClose,
  date,
  timeSlotElement,
}: Props) => {
  const [showFirstTooltip, setShowFirstTooltip] = useState(true);
  const [showSecondTooltip, setShowSecondTooltip] = useState(false);

  const closeFirstBanner = () => {
    setShowFirstTooltip(false);
    setShowSecondTooltip(true);
  };

  const { pos } = useGetElementPosition(timeSlotElement ?? null);

  return (
    <div className="h-svh w-full fixed flex flex-col items-center inset-0 z-10 bg-black bg-opacity-50 backdrop-blur">
      {showFirstTooltip && (
        <div
          className="absolute flex flex-col gap-4"
          style={{
            top: pos.top ? `${pos.top}px` : undefined,
            left: pos.left ? `${pos.left}px` : undefined,
            width: pos.width ? `${pos.width}px` : undefined,
            height: pos.height ? `${pos.height}px` : undefined,
          }}
        >
          <div className="relative left-3">
            <TimeSlot
              time={`${formatDateTime(date, startTime)} - ${formatDateTime(
                date,
                endTime,
              )}`}
              hasIcon
            />
          </div>
          <Tooltip
            title="Pin experience"
            text="If you like a particular experience and don't want to lose it when shuffling, just tap the “pin” icon. Tap again to unpin it."
            buttonLabel="Next tip"
            position="TOP-LEFT"
            onClick={closeFirstBanner}
          />
        </div>
      )}
      {showSecondTooltip && (
        <div className="absolute flex flex-col bottom-6 gap-4 lg:left-[60px]">
          <Tooltip
            title="Replan experiences"
            text="Replan for a new order of your experiences and new suggestions. Make sure to pin your favorites before replanning to keep them."
            buttonLabel="Got it"
            position="BOTTOM-LEFT"
            onClick={onClose}
          />
          <div className="bg-gray-light flex gap-2 justify-center items-center py-3.5 px-[30px] rounded-40 w-fit">
            <SpriteIcon iconName="stars" className="w-6 h-6" />
            <p className="text-p-bold">Replan</p>
          </div>
        </div>
      )}
    </div>
  );
};
