/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Destination = {
    name: string;
    type: Destination.type;
    url_name: string;
    default_currency_code?: string;
    timezone?: string;
    viator_id: number;
    latitude: string;
    longitude: string;
    selectable?: boolean;
    parent_id?: number | null;
    activity_tags?: any;
};
export namespace Destination {
    export enum type {
        COUNTRY = 'COUNTRY',
        REGION = 'REGION',
        CITY = 'CITY',
    }
}

