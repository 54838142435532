import { TruncText } from '@components/texts/TruncText';
import classNames from 'classnames';

import { DATE_DAY_EXTENDED_MONTH_DAY_FORMAT } from '@/utils/constants';
import { getUTCDateTime } from '@/utils/formatDateTime';

type Props = {
  date: string;
  selected?: boolean;
  onClick: () => void;
};

export const DayButton = ({ date, onClick, selected }: Props) => {
  return (
    <button
      onClick={onClick}
      className={classNames('w-fit py-2.5 px-4 rounded-40', {
        'bg-black': selected,
        'bg-gray-light': !selected,
      })}
    >
      <TruncText
        maxWidth={100}
        className={classNames({
          'text-white': selected,
          'text-black': !selected,
        })}
      >
        {getUTCDateTime({ dateTime: date }).format(
          DATE_DAY_EXTENDED_MONTH_DAY_FORMAT,
        )}
      </TruncText>
    </button>
  );
};
