import { Button } from '@components/buttons/Button';
import { IconButton } from '@components/buttons/IconButton';
import { OverlappingCard } from '@components/cards/OverlappingCard';
import { Key } from 'react';

import { Experience } from '@/generated';

type Props = {
  newExperience?: Experience | null;
  newExperienceStartTime?: string;
  newExperienceEndTime?: string;
  overlappingExperiences: Array<Experience>;
  onConfirm: () => void;
  onCancel: () => void;
};

export const OverlappingExperiences = ({
  newExperience,
  newExperienceStartTime,
  newExperienceEndTime,
  overlappingExperiences,
  onConfirm,
  onCancel,
}: Props) => {
  if (!newExperience) {
    newExperience = undefined;
  }
  return (
    <div className="flex flex-col h-full">
      <div className="px-4">
        <h4 className="text-h4 text-black mb-1">Experience overlapping</h4>
        <p className="text-p text-gray mb-6">
          If you continue, this experiences will be deleted. Are you sure you
          want to precede?
        </p>
        <IconButton iconName="arrowLeft" size="large" onClick={onCancel} />
      </div>
      <div className="flex flex-col gap-3 overflow-auto max-h-[400px] px-4">
        <OverlappingCard
          experience={newExperience}
          isNew={true}
          startTime={
            newExperienceStartTime || newExperience?.start_time || undefined
          }
          endTime={newExperienceEndTime || newExperience?.end_time || undefined}
        />
        {overlappingExperiences?.map((experience, index: Key) => (
          <OverlappingCard experience={experience} key={index} />
        ))}
      </div>
      <div className="flex mt-auto items-center justify-center p-4 shadow-general">
        <Button size="large" full onClick={onConfirm}>
          Add to my trip
        </Button>
      </div>
    </div>
  );
};
