import { TextButton } from '@components/buttons/TextButton';
import { ExperienceList } from '@components/lists/ExperienceList';
import { Loader } from '@components/loaders/Loader';

import { Experience } from '@/generated';
import { NextPageParamType } from '@/types/QueryTypes';
import { forwardRef } from 'react';

type Props = {
  experiences: Experience[] | undefined;
  isLoading: boolean;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  onExperienceCardPlusClick: (experience: Experience) => void;
  onSortByClick: () => void;
  fetchNextPage: NextPageParamType<Experience>;
  sortMenuCallerRef?: React.MutableRefObject<HTMLButtonElement | null>;
};

export const AddExperienceSection = forwardRef<
  {
    [key: string]: HTMLButtonElement | null;
  },
  Props
>(
  (
    {
      experiences,
      isLoading,
      hasNextPage,
      isFetchingNextPage,
      onExperienceCardPlusClick,
      onSortByClick,
      fetchNextPage,
      sortMenuCallerRef,
    },
    ref,
  ) => {
    const experienceCount = experiences?.length ?? 0;

    return (
      <section className="px-2 lg:px-5 bg-gray-light flex-grow overflow-y-auto relative">
        <div className="flex py-5 justify-between items-center sticky top-0 z-10 bg-gray-light">
          <p className="text-gray text-p-bold">{experienceCount} experiences</p>
          <TextButton
            icon="sort"
            onClick={onSortByClick}
            ref={sortMenuCallerRef}
          >
            Sort by
          </TextButton>
        </div>
        <div className="flex gap-2 justify-center w-full flex-wrap">
          {isLoading ? (
            <Loader />
          ) : (
            <ExperienceList
              experiences={experiences}
              onCardClick={onExperienceCardPlusClick}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              ref={ref}
            />
          )}
        </div>
      </section>
    );
  },
);
