import { useState } from 'react';
import { Option } from '@components/sections/Option';
import { PersonalLinkBottomSheet } from '@components/bottomSheets/PersonalLinkBottomSheet';
import { Header } from '@components/headers/Header';
import { useNextStepStore } from '@/stores/nextStepStore';
import { useInvitationStore } from '@/stores/invitationStore';
import { useNavigate } from 'react-router-dom';
import { routes } from '@/routes/routes';

type Params = {
  token?: string;
  itineraryId?: number;
  usernameNeeded: boolean;
  onHeaderBackHandler: () => void;
  onHeaderActionHandler: () => void;
};
export const RegisterStep = ({
  token,
  itineraryId,
  usernameNeeded,
  onHeaderBackHandler,
  onHeaderActionHandler,
}: Params) => {
  const navigate = useNavigate();
  const [isPersonalLinkPageOpen, setIsPersonalPageOpen] =
    useState<boolean>(false);
  const { interests, foodPreferences, dailyBudget } = useInvitationStore(
    (state) => state.invitationSearch,
  );
  const onContinueAsGuestHandler = () => {
    setIsPersonalPageOpen(true);
  };

  // const = useItineraryF
  const setNextStep = useNextStepStore((state) => state.setNextStep);

  const onRegisterHandler = () => {
    if (!token || !itineraryId) {
      return;
    }

    setNextStep({
      saveTrip: {
        token,
        itineraryId,
        interests: interests?.map((interest) => interest.name),
        foodPreferences: foodPreferences?.map(
          (foodPreference) => foodPreference.code,
        ),
        min_price_level: dailyBudget?.min_price ?? 1,
        max_price_level: dailyBudget?.max_price ?? 4,
      },
    });
    navigate(routes.auth.index);
  };

  return (
    <div className="pl-4 pr-4 min-h-screen overflow-hidden flex flex-col">
      <Header
        actionButtonText="Return home"
        onBackButtonClick={onHeaderBackHandler}
        onActionButtonClick={onHeaderActionHandler}
      />
      <div className="flex flex-col gap-y-5 items-center justify-center flex-grow">
        <Option
          header="Continue as a guest"
          description="You can only view a trip you were invited to. 
                    However, you will be able to create an account whenever you want."
          iconName="user"
          onClick={onContinueAsGuestHandler}
        />
        <Option
          header="Create account"
          description="Create an account to save your trip and build multiple ones, share them with your friends and keep track of your bookings."
          iconName="star"
          onClick={onRegisterHandler}
        />
        <PersonalLinkBottomSheet
          token={token}
          isOpen={isPersonalLinkPageOpen}
          onClose={() => {
            setIsPersonalPageOpen(!isPersonalLinkPageOpen);
          }}
          usernameNeeded={usernameNeeded}
        />
      </div>
    </div>
  );
};
