import type { LayersList } from '@deck.gl/core/typed';
import { GoogleMapsOverlay } from '@deck.gl/google-maps/typed';
import { useMap } from '@vis.gl/react-google-maps';
import { useEffect, useMemo } from 'react';

export type DeckglOverlayProps = { layers?: LayersList };

export const DeckGlOverlay = ({ layers }: DeckglOverlayProps) => {
  const deck = useMemo(() => new GoogleMapsOverlay({ interleaved: true }), []);

  const map = useMap();
  useEffect(() => {
    deck.setMap(map);
  }, [map]);

  useEffect(() => {
    deck.setProps({ layers });
  }, [layers]);

  return null;
};
