import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { SpriteIcon } from '@/components/icons/SpriteIcon';
import { CloseToastButton } from '@/components/toasts/CloseToastButton';
import { ToastMessage } from '@/components/toasts/ToastMessage';
import { Experience } from '@/generated';

export const usePinExperience = (experience?: Experience) => {
  const queryClient = useQueryClient();
  const pinActionText = experience?.pinned ? 'unpinned' : 'pinned';
  const apiFactory = useApi();

  const { mutate: pinUnpinExperience, isPending } = useMutation({
    mutationFn: async () => {
      if (experience) {
        if (!experience.pinned) {
          return await apiFactory.api.apiV1ExperiencesPin(experience?.id ?? 0);
        } else {
          return await apiFactory.api.apiV1ExperiencesUnpin(
            experience?.id ?? 0,
          );
        }
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.itinerary],
      });
      toast(
        <ToastMessage
          message={`Experience ${pinActionText}`}
          icon={
            <SpriteIcon
              iconName="pin"
              className="w-5 h-5 fill-white stroke-[2px]"
            />
          }
          variant="dark"
        />,
        {
          theme: 'dark',
          className: 'rounded-lg',
          closeButton: <CloseToastButton fillColor="white" />,
        },
      );
    },
    onError: () =>
      toast(
        <ToastMessage
          message={`Experience couldn't be ${pinActionText}, please try again later`}
          icon={
            <SpriteIcon
              iconName="pin"
              className="w-5 h-5 fill-white stroke-[2px]"
            />
          }
          variant="dark"
        />,
        {
          theme: 'dark',
          className: 'rounded-lg',
          closeButton: <CloseToastButton fillColor="white" />,
        },
      ),
  });

  return {
    isPending,
    pinUnpinExperience,
  };
};
