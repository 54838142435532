import { SpriteIcon } from '@components/icons/SpriteIcon';
import { SearchInput } from '@components/inputs/SearchInput';
import { routes } from '@routes/routes';
import { useNavigate } from 'react-router-dom';

type Props = {
  value?: string | null;
  onSearch: (value: string) => void;
  onClick?: () => void;
  bgColor?: string;
  disabled?: boolean;
  isWithBackButton?: boolean;
};

export const SearchHeader = ({
  onSearch,
  onClick,
  bgColor,
  value,
  disabled,
  isWithBackButton = true,
}: Props) => {
  const navigate = useNavigate();

  const handleGoBack = () => navigate(routes.back);

  return (
    <div className="flex w-full gap-2 justify-center">
      {isWithBackButton && (
        <button onClick={onClick ?? handleGoBack}>
          <SpriteIcon iconName="arrowLeft" className="w-8 h-8 fill-black" />
        </button>
      )}
      <SearchInput
        onSearch={onSearch}
        bgColor={bgColor}
        value={value}
        disabled={disabled}
      />
    </div>
  );
};
