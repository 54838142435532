import { useMemo } from 'react';

import { Experience } from '@/generated';

export const useRemoveDuplicatesFromExperienceArray = (
  experiences?: Experience[],
) => {
  const filteredExperiences = useMemo(
    () =>
      experiences?.filter((experience, index) => {
        return (
          index ===
          experiences?.findIndex(
            (exp) => experience.id === exp.id || experience.name === exp.name,
          )
        );
      }),
    [experiences],
  );

  return filteredExperiences;
};
