import { useMutation } from '@tanstack/react-query';

import { useApi } from '@/api/serviceProvider';
import { FreeSlot } from '@/generated';

type Props = {
  itineraryId?: number | undefined;
  timeSlot: FreeSlot;
  day: string;
};

export const useCheckForOverlaps = ({ itineraryId, timeSlot, day }: Props) => {
  const apiFactory = useApi();

  const { mutate, data, isPending } = useMutation({
    mutationFn: async () => {
      if (!itineraryId) {
        return;
      }

      const data = {
        start_time: timeSlot.start_time,
        end_time: timeSlot.end_times[0],
        date: day,
      };

      const res = await apiFactory.api.apiV1ItinerariesCheckForOverlaps(
        itineraryId,
        data,
      );
      return res;
    },
  });

  return {
    checkForOverlapping: mutate,
    overlappingExperiences: data || [],
    isChecking: isPending,
  };
};
