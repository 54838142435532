import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider.ts';
import { useQueries, useQueryClient } from '@tanstack/react-query';

import { Experience } from '@/generated';
import { areAllExperiencesWithDetails } from '@/utils/experiencesHelperFunctions';

export const useGatherExperiencesDetails = (experiences: Experience[]) => {
  const apiFactory = useApi();
  const queryClient = useQueryClient();

  const experiencesToFetch =
    experiences?.filter((experience) => !experience.details) ?? [];

  const { data, pending } = useQueries({
    queries:
      experiencesToFetch.map(({ id, details }) => {
        return {
          queryKey: [queryKeyTypes.experienceDetail, id],
          queryFn: async () => {
            const response = await apiFactory.api.apiV1ExperiencesGatherDetails(
              id ?? 0,
              {},
            );
            return response || null;
          },
          enabled: details === null,
          refetchOnWindowFocus: false,
          retry: 1,
        };
      }) ?? [],
    combine: (results) => {
      if (areAllExperiencesWithDetails(experiences)) {
        return {
          data: experiences,
          pending: false,
        };
      }

      const allResultsDefined = results.every((res) => !!res.data);

      if (!allResultsDefined && !results.some((res) => res.isPending)) {
        queryClient.invalidateQueries({
          queryKey: [queryKeyTypes.itinerary],
        });
      }

      return {
        data: results
          .map((res) => res.data)
          .filter((item): item is Experience => !!item),
        pending:
          results.some((result) => result.isPending || !result.data) &&
          experiences.some((exp) => !exp.details),
      };
    },
  });

  return {
    experienceDetails: data,
    isExperienceDetailsLoading: pending,
  };
};
