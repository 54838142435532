type Props = {
  children: React.ReactNode;
  onOverlayClick?: () => void;
};

export const OverlayBase = ({ children, onOverlayClick }: Props) => {
  return (
    <div
      className="flex flex-col fixed w-full h-svh bg-overlay backdrop-blur-[6px] z-20 xl:max-w-screen-md xl:left-1/2 xl:-translate-x-1/2"
      onClick={onOverlayClick}
    >
      {children}
    </div>
  );
};
