import { ItineraryStatusTab } from '@components/headers/MyTripsHeader';
import classNames from 'classnames';
import { TruncText } from '@components/texts/TruncText';

type Props = {
  title: string;
  status: ItineraryStatusTab;
  selected: ItineraryStatusTab;
  onClick: (tab: ItineraryStatusTab) => void;
};

export const TabButton = ({ status, title, selected, onClick }: Props) => {
  const isSelected = selected === status;
  return (
    <button
      onClick={() => {
        onClick(status);
      }}
      className={classNames('w-fit py-2.5 px-4 rounded-40', {
        'bg-black': isSelected,
        'bg-gray-light': !isSelected,
      })}
    >
      <TruncText
        maxWidth={100}
        className={classNames({
          'text-white': isSelected,
          'text-black': !isSelected,
        })}
      >
        {title}
      </TruncText>
    </button>
  );
};
