import { Experience } from '@/generated';

import { getUTCDateTime } from './formatDateTime';

export const isValidCommuteMode = (
  duration: number,
  originExperience?: Experience,
  destinationExperience?: Experience,
): boolean => {
  if (!originExperience?.date || !destinationExperience?.start_time) {
    return true;
  }

  const commuteEndTime = getUTCDateTime({
    dateTime: `${originExperience.date} ${originExperience.end_time}`,
  }).add(duration, 'seconds');

  const destinationStartTime = getUTCDateTime({
    dateTime: `${destinationExperience.date} ${destinationExperience.start_time}`,
  });

  return commuteEndTime.isBefore(destinationStartTime);
};
