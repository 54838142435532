import { useGoogleLocation } from '@hooks/useTailorLocation';
import { SearchHeaderSection } from '../sections/SearchHeaderSection';
import { Step } from './Step';
import { useTailorSearchStore } from '@/stores/tailorSearchStore';

export const PlaceOfStay = ({}) => {
  const searchStore = useTailorSearchStore((state) => state.setTailoredSearch);
  const { searchValue, setSearchValue, suggestions, handleSelectClick } =
    useGoogleLocation({ setTailorValue: searchStore });

  return (
    <Step
      heading="Where are you staying?"
      subheading="We'll find the nearest experiences and optimize your overall itinerary."
    >
      <SearchHeaderSection
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        suggestions={suggestions}
        handleSelectClick={handleSelectClick}
        isWithBackButton={false}
      />
    </Step>
  );
};
