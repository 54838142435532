import { DateRange } from 'react-day-picker';
import { PopUpContainer } from '../containers/PopUpContainer';
import { DatePicker } from '../datePicker/DatePicker';
import { Header } from '../headers/Header';
import { State } from '@/stores/initialSearchStore';
import { SelectRangeEventHandlerCustom } from '@/types/DatePickerTypes';
import { Button } from '../buttons/Button';

type Params = {
  isOpen: boolean;
  onClose: () => void;
  clearData?: () => void;
  initialSearchDateRange: DateRange | null;
  setInitialSearch: (value: Partial<State>) => void;
};

const pastMonth = new Date();

export const SetDatesWindow = ({
  isOpen,
  onClose,
  clearData,
  initialSearchDateRange,
  setInitialSearch,
}: Params) => {
  const handleDateSelect: SelectRangeEventHandlerCustom = (newRange) => {
    setInitialSearch({
      dateRange: {
        from: newRange?.from,
        to: newRange?.to,
      },
    });
  };

  return (
    <PopUpContainer isOpen={isOpen} onClose={onClose} isWhite={false}>
      <Header
        actionButtonText="Clear"
        onActionButtonClick={clearData}
        onBackButtonClick={onClose}
      />
      <div className="flex flex-col gap-5 w-full py-5">
        <h4 className="text-h4 flex self-start">When is your trip?</h4>
        <DatePicker
          defaultMonth={
            initialSearchDateRange?.from
              ? initialSearchDateRange.from
              : pastMonth
          }
          selected={initialSearchDateRange ? initialSearchDateRange : undefined}
          onSelect={handleDateSelect}
        />
      </div>
      <div className="w-full p-4 mb-4">
        <Button
          size="large"
          disabled={!initialSearchDateRange}
          onClick={onClose}
          full
        >
          Select dates
        </Button>
      </div>
    </PopUpContainer>
  );
};
