import { ReactElement, useState } from 'react';

export const useMultistepForm = (steps: ReactElement[]) => {
  const [currStepIndex, setCurrStepIndex] = useState(0);

  const next = () => {
    setCurrStepIndex((i) => {
      if (i > steps.length - 1) return i;
      return i + 1;
    });
  };

  const back = () => {
    setCurrStepIndex((i) => {
      if (i <= 0) return i;
      return i - 1;
    });
  };

  return {
    currStepIndex,
    step: steps[currStepIndex],
    steps,
    next,
    back,
    isFirstStep: currStepIndex === 0,
    isLastStep: currStepIndex === steps.length - 1,
  };
};
