import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider.ts';
import { useIsFetching, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { Commute, Experience } from '@/generated';

export type DayCommutes = {
  [key: string]: Commute[];
};

export const useGetItineraryCommutes = (
  itineraryId: number | undefined,
  experiences: Experience[] | undefined,
  isExperienceDetailsLoading: boolean,
  currentDate: string | null | undefined,
) => {
  const apiFactory = useApi();
  const [dateToFetch, setDateToFetch] = useState<string | undefined>(undefined);
  const [allCommutes, setAllCommutes] = useState<DayCommutes[]>();
  const isFetchingItinerary = useIsFetching({
    queryKey: [queryKeyTypes.itinerary],
  });
  const isFetchingDetails = useIsFetching({
    queryKey: [queryKeyTypes.experienceDetail],
  });

  const areAllExperiencesWithDetails =
    experiences &&
    experiences.length > 0 &&
    experiences.every(
      ({ details }) => details && Object.keys(details).length > 0,
    );

  const refetchForDate = (date: string) => {
    setDateToFetch(date);
    refetch();
  }

  const { data, isLoading, refetch, error } = useQuery({
    queryFn: async () => {
      if (!itineraryId) {
        return [];
      }
      if (!currentDate && !dateToFetch) {
        return [];
      }
      try {
        const date = currentDate ?? dateToFetch ?? '';
        const res = await apiFactory.api.apiV1ItinerariesCommutes(itineraryId, date);

        setAllCommutes((prevCommutes) => [
          ...prevCommutes ?? [],
          { [date]: res },
        ]);

        setDateToFetch(undefined);
        return res;
      } catch (err) {
        throw err;
      }
    },
    queryKey: [queryKeyTypes.commutes, itineraryId, currentDate ?? dateToFetch],
    enabled:
      !isExperienceDetailsLoading &&
      !isFetchingItinerary &&
      !isFetchingDetails &&
      areAllExperiencesWithDetails &&
      !!itineraryId &&
      (!!currentDate ||
        !!dateToFetch),
    refetchOnWindowFocus: false,
    retry: 1,
    staleTime: 1000 * 60 * 10, // cache response for 10 minutes 
  });

  useEffect(() => {
    if (!isExperienceDetailsLoading) {
      refetch();
    }
  }, [isExperienceDetailsLoading, refetch]);

  useEffect(() => {
    if (dateToFetch) {
      refetch();
    }
  }, [dateToFetch])

  return {
    commutes: data ?? [],
    isCommutesLoading: isLoading,
    allCommutes: allCommutes ?? [],
    refetchForDate,
    error,
  };
};
