import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/buttons/Button';
import { TextButton } from '@/components/buttons/TextButton';
import { Input } from '@/components/inputs/Input';
import { AuthLayout } from '@/components/layouts/AuthLayout';
import { routes } from '@/routes/routes';
import { useAuthEmailStore } from '@/stores/authEmailStore';
import { useSignUpForm } from '@/forms/useSignUpForm';
import { useSignUp } from '@/hooks/useSignUp';
import { SignUpLegalText } from '@/components/texts/SignUpLegalText';
import { SpriteIcon } from '@/components/icons/SpriteIcon';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { useGoogleSignIn } from '@/hooks/useGoogleSignIn';
import { GoogleButton } from '@/components/buttons/GoogleButton';

export const AuthPage = () => {
  const navigate = useNavigate();

  const handleCrossTap = () => navigate(routes.back);
  const handleLogInTap = () => navigate(routes.auth.login, { replace: true });

  const setAuthEmail = useAuthEmailStore((state) => state.setEmail);

  const { control, isSubmitDisabled, handleSubmitRegisterAccountForm } =
    useSignUpForm();
  const { handleSignUp, isLoading: isEntering } = useSignUp();

  const handleOnRegisterClick = handleSubmitRegisterAccountForm((data) => {
    setAuthEmail(data.email.toLowerCase().trim());
    handleSignUp(data);
  });

  const { googleSignIn, isLoading: isEnteringUsingGoogle } = useGoogleSignIn();

  useGoogleOneTapLogin({
    onSuccess: async (response) => googleSignIn({ token: response.credential }),
    use_fedcm_for_prompt: true,
  });

  const isLoading = isEnteringUsingGoogle || isEntering;

  return (
    <AuthLayout handleCloseButtonClick={handleCrossTap}>
      <div className="hidden md:flex mb-[32px] mt-[64px]">
        <SpriteIcon iconName="logoMobile" className="w-[114px] h-4" />
      </div>
      <h3 className="text-h3 mb-2">Unlock the full experience</h3>
      <p className="text-p text-gray mb-2 md:md-8">
        Create an account to save your trip and build multiple ones, share them
        with your friends and keep track of your bookings.
      </p>
      <form className="flex flex-col mt-4 mb-auto">
        <Input
          control={control}
          isDisabled={isLoading}
          name="firstName"
          label="First name"
          placeholder="Enter your first name"
          type="text"
        />
        <Input
          control={control}
          isDisabled={isLoading}
          name="lastName"
          label="Last name"
          placeholder="Enter your last name"
          type="text"
        />
        <Input
          control={control}
          isDisabled={isLoading}
          name="email"
          label="Email"
          placeholder="Enter your email"
          type="email"
          autoComplete="email"
        />
        <Input
          control={control}
          isDisabled={isLoading}
          name="password"
          label="Password"
          placeholder="Create a password"
          type="password"
        />
        <section className="flex gap-2 text-gray self-center mb-6">
          <p className="text-p">Already have an account?</p>
          <TextButton onClick={handleLogInTap}>Log in</TextButton>
        </section>
      </form>
      <section className="flex flex-col mt-auto mb-4 gap-5">
        <SignUpLegalText />
        <Button
          disabled={isSubmitDisabled}
          size="large"
          isLoading={isLoading}
          full
          onClick={handleOnRegisterClick}
        >
          Create an account
        </Button>
        <p className="p-text w-full text-center">or</p>
        <GoogleButton isLoading={isLoading} />
      </section>
    </AuthLayout>
  );
};
