import { SpriteIcon, SpriteIconProps } from '@/components/icons/SpriteIcon';

type Props = {
  iconName: SpriteIconProps;
  text: string;
};

const TripDetailItem = ({ iconName, text }: Props) => (
  <div className="flex items-center">
    <SpriteIcon
      iconName={iconName}
      className="stroke-white fill-white h-5 w-5 mr-1"
    />
    <p className="text-p-medium">{text}</p>
  </div>
);

export default TripDetailItem;
