import classNames from 'classnames';

import { ArticleCardTypes } from '@/types/ArticleCardTypes';
import { useWindowSize } from '@/hooks/useWindowSize';

export const ArticleCard = ({
  image,
  title,
  text,
  textColor,
  backgroundColor,
  backgroundImage,
  isLargeVariantExists = false,
  largeImage,
  imagePosition,
  imageFit,
  imageRounded,
  reverse,
  parentClassNames,
}: ArticleCardTypes) => {
  const { windowType } = useWindowSize();
  const backgroundImageStyle: React.CSSProperties = {
    background: `url(${backgroundImage}) no-repeat center center/cover`,
    backgroundColor: '#4C0DD2',
  };
  return (
    <div
      className={classNames(
        `flex ${backgroundColor ? backgroundColor : 'bg-gray-light'}`,
        'justify-between mx-auto rounded-3xl',
        'max-w-[360px] h-[470px]',
        'lg:max-w-none',
        'lg:h-[500px]',
        'relative  gap-y-4',
        {
          'flex-col-reverse': reverse,
          'flex-col': !reverse,
          'lg:col-span-2': isLargeVariantExists,
        },
        parentClassNames,
      )}
      style={backgroundImage && windowType === 'lg' ? backgroundImageStyle : {}}
    >
      <div
        className={classNames('relative flex w-full h-full', {
          'items-end': reverse,
        })}
      >
        <img
          src={image}
          alt={title}
          className={classNames('absolute inset-0 w-full h-full', {
            'rounded-3xl': imageRounded,
            [`object-${imagePosition}`]: !!imagePosition,
            [`object-${imageFit}`]: !!imageFit,
            'object-fit': imageFit != undefined,
            'lg:hidden': isLargeVariantExists,
          })}
          style={{
            objectPosition: imagePosition,
            objectFit: imageFit,
          }}
        />
        {isLargeVariantExists && largeImage && (
          <img
            src={largeImage}
            alt={title}
            className={classNames(
              'hidden lg:flex absolute bottom-[-1px] w-full lg:h-full lgx:h-auto lg:object-cover lgx:object-fit',
              { 'rounded-3xl lg:rounded-none lgx:rounded-3xl': imageRounded },
            )}
          />
        )}
      </div>
      <div className="flex flex-col gap-2 p-6 pb-0">
        <h4
          className={classNames('text-h4 lg:text-[32px] text-black', textColor)}
        >
          {title}
        </h4>
        <p
          className={classNames('text-p-web', textColor, {
            'lg:w-1/2': isLargeVariantExists,
          })}
        >
          {text}
        </p>
      </div>
    </div>
  );
};
