import { Loader } from '@components/loaders/Loader';
import { Traveler } from '@components/cards/Traveler';

type Props = {
  isAdmin: boolean;
  travelers: any[];
  updateTravelers?: (travelers: any[]) => void;
  isLoadingList: boolean;
  onEditInvitedUserHandler: (traveler: any) => void;
  expectedNumberOfTravelers?: number;
  menuCallerRef?: React.MutableRefObject<{
    [key: string]: HTMLButtonElement | null;
  }>;
};

export const InvitedTravelersList = ({
  isAdmin,
  travelers,
  isLoadingList,
  onEditInvitedUserHandler,
  expectedNumberOfTravelers,
  menuCallerRef,
}: Props) => {
  return (
    <div className="w-full h-full flex flex-col items-center bg-white rounded-t-[12px] shadow-general">
      {isLoadingList ? (
        <Loader size="large" />
      ) : (
        <>
          {travelers && travelers.length > 0
            ? travelers.map((traveler, index) => (
                <Traveler
                  key={index}
                  travelerKey={index}
                  traveler={traveler}
                  isAllowEdit={isAdmin}
                  onEditTraveler={onEditInvitedUserHandler}
                  ref={(el) => {
                    if (el && menuCallerRef?.current && traveler.id) {
                      const key =
                        typeof traveler === 'string'
                          ? traveler
                          : traveler.username || traveler.email;
                      menuCallerRef.current[key] = el;
                    }
                  }}
                />
              ))
            : null}
          {expectedNumberOfTravelers !== undefined &&
            [
              ...Array(
                Math.max(expectedNumberOfTravelers - travelers.length, 0),
              ),
            ].map((_, index) => (
              <Traveler
                key={travelers.length + index}
                travelerKey={travelers.length + index}
                traveler={{ username: 'Unknown user' }}
                isAllowEdit={false}
                onEditTraveler={onEditInvitedUserHandler}
              />
            ))}
        </>
      )}
    </div>
  );
};
