import { Tabbar } from '@components/bottomBars/Tabbar';
import { Outlet } from 'react-router';
import Cookies from 'js-cookie';

export const TabLayout = () => {
  const isLoggedIn = Cookies.get('tp_access_token') ? true : false;

  return (
    <main>
      <Outlet />
      {isLoggedIn && <Tabbar />}
    </main>
  );
};
