import { useApi } from '@/api/serviceProvider';
import { RecordCreateRequest } from '@/generated';
import { useMutation } from '@tanstack/react-query';


export const useSendEvent = () => {
    const { metrics } = useApi();

    const { mutate, isPending } = useMutation({
        mutationFn: async (event: RecordCreateRequest) => {
            return await metrics.metricsCreate(event);
        },
    });

    return {
        sendEvent: mutate,
        isLoading: isPending,
    };
};
