import { SpriteIcon } from '@components/icons/SpriteIcon';
import classNames from 'classnames';
import { forwardRef } from 'react';

type Props = {
  time: string;
  pinned?: boolean;
  hasIcon?: boolean;
  pinClick?: () => void;
};

export const TimeSlot = forwardRef<HTMLDivElement, Props>(
  ({ time, pinned, hasIcon, pinClick }, ref) => {
    return (
      <div
        className={classNames(
          'flex justify-center w-fit items-center bg-white gap-1 pr-3 rounded-3xl py-1',
          {
            'pl-1': hasIcon,
            'pl-3': !hasIcon,
          },
        )}
        ref={ref}
      >
        {hasIcon && (
          <div
            onClick={pinClick}
            className={classNames('rounded-full p-1 cursor-pointer', {
              'bg-purple': pinned,
              'bg-gray-light': !pinned,
            })}
          >
            <SpriteIcon
              iconName="pin"
              className={classNames('w-4 h-4', {
                'fill-white': pinned,
                'fill-gray': !pinned,
              })}
            />
          </div>
        )}
        <div className="text-black text-p-medium whitespace-nowrap self-center">
          {time}
        </div>
      </div>
    );
  },
);
