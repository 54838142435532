import imagePlaceholder from '@assets/images/landingPage/experience-placeholder.webp';
import { DateRange } from 'react-day-picker';

import { getFormattedDate } from '@/utils/formatDateTime';
import classNames from 'classnames';

export type TripCardProps = {
  // The type "any" for the reference is used on purpose - we pass "(card: Element) => void" type to this prop in ItineraryList
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reference?: any;
  title: string | undefined | null;
  dateRange: DateRange | null;
  people: number | undefined;
  experiences: number | undefined;
  image: string | undefined | null;
  onClick?: () => void;
  fullWidth?: boolean;
};

export const TripCard = ({
  reference,
  title,
  dateRange,
  people,
  experiences,
  image,
  onClick,
  fullWidth = false,
}: TripCardProps) => {
  const formattedStartDateMonth = getFormattedDate({
    date: dateRange?.from,
    format: 'MMM',
  });

  const formattedStartDateDay = getFormattedDate({
    date: dateRange?.from,
    format: 'DD',
  });

  const formattedEndDateMonth = getFormattedDate({
    date: dateRange?.to,
    format: 'MMM',
  });

  const formattedEndDateDay = getFormattedDate({
    date: dateRange?.to,
    format: 'DD',
  });

  const endDate =
    formattedStartDateMonth !== formattedEndDateMonth
      ? `${formattedEndDateMonth} ${formattedEndDateDay}`
      : formattedEndDateDay;

  const dateRangeString = `${formattedStartDateMonth} ${formattedStartDateDay}-${endDate}`;
  return (
    <div
      className={classNames(
        'items-start flex gap-0 shadow-general max-h-[124px]',
        {
          'w-full': fullWidth,
        },
      )}
      onClick={onClick}
      ref={reference}
    >
      <img
        loading="lazy"
        src={image ?? imagePlaceholder}
        className="object-cover object-center w-[111px] self-stretch rounded-l-[20px]"
        alt="Image description"
      />
      <div className="w-full self-stretch flex flex-col p-5 rounded-r-[20px] gap-5 bg-white">
        <div>
          <p className="text-p-bold text-black whitespace-nowrap w-4/5 overflow-hidden text-ellipsis">
            {title ?? ''}
          </p>
          <div className="items-start flex flex-col">
            <div className="items-center flex w-full justify-start gap-2">
              <div className="text-p-medium text-black">{dateRangeString}</div>
              <div>•</div>
              <div className="text-p-medium text-black whitespace-nowrap">
                {people ?? 0} people
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="text-p-medium text-gray">
            {experiences ?? 0} experiences
          </p>
        </div>
      </div>
    </div>
  );
};
