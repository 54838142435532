import { DateRange } from 'react-day-picker';
import { create, StateCreator } from 'zustand';
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';

import { Persons } from '@/generated';
import { NamedCoords } from '@/types/AppTypes';

export type State = {
  location: NamedCoords | null;
  dateRange: DateRange | null;
  people: Persons | null;
  invitedPeople: string[] | null;
  itineraryId: number | null;
  isWaitingForPeople: boolean;
};

export type Action = {
  setInitialSearch: (value: Partial<State>) => void;
};

const initialState: State = {
  location: null,
  dateRange: null,
  people: null,
  invitedPeople: null,
  itineraryId: null,
  isWaitingForPeople: false,
};

export type InitialSearchStoreType = State & Action;

type InitialSearchPersistStoreType = (
  config: StateCreator<InitialSearchStoreType>,
  options: PersistOptions<InitialSearchStoreType>,
) => StateCreator<InitialSearchStoreType>;

export const useInitialSearchStore = create<InitialSearchStoreType>(
  (persist as InitialSearchPersistStoreType)(
    (set) => ({
      ...initialState,
      setInitialSearch: (value) =>
        set((state) => ({
          ...state,
          ...value,
        })),
    }),
    {
      name: 'initial-search-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
