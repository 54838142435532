import { Button } from '@components/buttons/Button';
import { useLogout } from '@hooks/useLogout';
import { PopUpContainer } from '../containers/PopUpContainer';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const LogoutBottomSheet = ({ onClose, isOpen }: Props) => {
  const { handleLogout, isLoading } = useLogout();

  return (
    <PopUpContainer snapPoints={[0.35]} onClose={onClose} isOpen={isOpen}>
      <div className="flex flex-col h-full pb-4 lg:w-full lg:pb-0">
        <h4 className="text-h4 mb-5">Are you sure?</h4>
        <div className="flex flex-col gap-2 lg:flex-row">
          <Button
            size="large"
            full
            onClick={handleLogout}
            isLoading={isLoading}
          >
            Log out
          </Button>
          <Button
            size="large"
            full
            variant="secondary"
            onClick={onClose}
            isLoading={isLoading}
          >
            Cancel
          </Button>
        </div>
      </div>
    </PopUpContainer>
  );
};
