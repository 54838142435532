import { BottomSheet } from '@components/bottomSheets/BottomSheet';
import {
  ExperienceMap,
  MapFiltersType,
} from '@components/modals/ExperienceMap';

import { Commute, Experience } from '@/generated';

type Props = {
  isOpen: boolean;
  dateExperiences: Experience[] | undefined;
  commutes: Commute[] | undefined;
  chosenFilters?: string[];
  onFilterClick?: (value: string) => void;
  mapFiltersType?: MapFiltersType;
  onClose: () => void;
  handleToggleFilterSheet?: () => void;
  selectedDate: string | null | undefined;
  setSelectedDate?: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >;
};

export const ExperienceMapBottomSheet = ({
  isOpen,
  dateExperiences = [],
  commutes = [],
  mapFiltersType,
  chosenFilters,
  handleToggleFilterSheet,
  onFilterClick,
  onClose,
  selectedDate,
  setSelectedDate,
}: Props) => {
  return (
    <BottomSheet
      isOpen={isOpen}
      isContentDragDisabled
      isPadded={false}
      onClose={onClose}
    >
      <div className="w-full h-full">
        <ExperienceMap
          experiences={dateExperiences}
          commutes={commutes}
          filtersType={mapFiltersType}
          chosenFilters={chosenFilters}
          onFilterClick={onFilterClick}
          handleToggleFilterSheet={handleToggleFilterSheet}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </div>
    </BottomSheet>
  );
};
