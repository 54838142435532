import { routes } from '@routes/routes';
import { Link } from 'react-router-dom';

type Props = {
  errorMessage: any;
};

export const ErrorSection = ({ errorMessage }: Props) => {
  return (
    <section className="flex flex-col w-full h-svh z-10 absolute top-0 left-0 justify-center items-center p-4 bg-white">
      <p className="text-p">{errorMessage.status}</p>
      <Link to={routes.home} className="text-p-bold text-purple">
        Return to home page and try again
      </Link>
    </section>
  );
};
