import { useApi } from "@/api/serviceProvider";
import { useMutation } from "@tanstack/react-query";
import { useParseNextStep } from "./useParseNextStep";
import { useUserStore } from "@/stores/userStore";
import Cookies from "js-cookie";
import { useAuthEmailStore } from "@/stores/authEmailStore";
import { useItineraryToSaveStore } from "@/stores/itineraryToSaveStore";
import { useAddItineraryTyMyTrips } from "./useAddItineraryToMyTrips";
import { useGoogleAnalytics } from "./useGoogleAnalytics";
import { showToast } from "@/utils/showToast";

export const useGoogleSignIn = () => {
    const { auth } = useApi();
    const { parseNextStep } = useParseNextStep();
    const setUser = useUserStore((state) => state.setUser);
    const clearInitialEmail = useAuthEmailStore((state) => state.clearEmail);
    const itineraryToSaveId = useItineraryToSaveStore(
        (state) => state.itineraryToSaveId,
    );
    const { handleAddItinerary } = useAddItineraryTyMyTrips();
    const handleClearItineraryToSaveId = useItineraryToSaveStore(
        (state) => state.clearStore,
    );
    const { sendGAEvent, EVENT_ACTIONS, EVENT_CATEGORIES } = useGoogleAnalytics();

    const { mutate, isPending } = useMutation({
        onMutate: async ({ token, code }: {
            token?: string | undefined;
            code?: string | undefined;
        }) => {
            if (!token && !code) {
                return;
            }
            let response: any;

            if (code) {
                response = await auth.authGoogleLoginCreate({
                    code: code
                });
            } else {
                response = await auth.authGoogleLoginCreate({
                    access_token: token,
                });
            }

            if (response) {
                setUser({ ...response, pk: response.id || response.pk });
                Cookies.set('tp_access_token', response?.access);
                clearInitialEmail();
                sendGAEvent(EVENT_CATEGORIES.userAuth, EVENT_ACTIONS.loginViaGoogle);

                if (itineraryToSaveId) {
                    handleAddItinerary(itineraryToSaveId);
                    handleClearItineraryToSaveId();
                }
                parseNextStep(response.email);
            } else {
                showToast('Smth went wrong, please try again later', 'dark');
            }
        }
    });

    return {
        googleSignIn: mutate,
        isLoading: isPending
    }
};