import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Commute } from '@/generated';
import { showToast } from '@/utils/showToast';

export const useUpdateCommute = (
  commuteId: number | undefined,
  updatedCommute: Partial<Commute>,
  onCompleted: () => void,
) => {
  const queryClient = useQueryClient();
  const apiFactory = useApi();
  const {
    mutateAsync: handleUpdateCommute,
    isPending: isUpdateCommuteLoading,
  } = useMutation({
    mutationKey: ['updateExperience'],
    mutationFn: async () => {
      if (!commuteId) {
        return;
      }

      return apiFactory.api.apiV1CommutesPartialUpdate(
        commuteId,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updatedCommute,
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.commutes],
      });
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.commuteList],
      });
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.itinerary],
      });

      onCompleted();

      showToast('Commute updated successfully');
    },
    onError: () => {
      onCompleted();
      showToast('Failed to update commute');
    },
  });
  return {
    handleUpdateCommute,
    isUpdateCommuteLoading,
  };
};
