import { Tooltip } from '@components/tooltips/Tooltip';

import { TripInfo } from '../headers/TripInfo';
import { useGetElementPosition } from '@/hooks/useGetElementPosition';

type Props = {
  location: string;
  dateRangeText: string;
  people: number;
  onClick: () => void;
  editItineraryDivRef?: HTMLDivElement | null;
};

export const EditPreferencesTooltip = ({
  location,
  dateRangeText,
  people,
  onClick,
  editItineraryDivRef,
}: Props) => {
  const { pos } = useGetElementPosition(editItineraryDivRef ?? null);
  return (
    <div className="h-svh w-full fixed flex flex-col items-center inset-0 z-10 bg-black bg-opacity-50 backdrop-blur">
      <div
        className="absolute flex flex-col top-2 left-[45px] md:top-[85px] md:left-[45px] lg:top-[70px] lg:left-[45px] gap-4"
        style={{
          top: pos.top ? `${pos.top}px` : undefined,
          left: pos.left ? `${pos.left}px` : undefined,
          width: pos.width ? `${pos.width}px` : undefined,
          height: pos.height ? `${pos.height}px` : undefined,
        }}
      >
        <div className="relative">
          <TripInfo
            location={location}
            dateRangeText={dateRangeText}
            people={people}
          />
        </div>
        <Tooltip
          title="Edit preferences"
          text="If you want to change any of your preferences, just tap on edit next to your trip information."
          buttonLabel="Got it"
          position="TOP-RIGHT"
          onClick={onClick}
        />
      </div>
    </div>
  );
};
