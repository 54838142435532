import { useTooltipsStore } from '@/stores/itineraryTooltipsStore';

export const useShowItineraryTooltips = () => {
  const didShowItineraryTooltips = useTooltipsStore(
    (state) => state.didShowItineraryTooltips,
  );

  const handleSetShowItineraryTooltipsTooltips = useTooltipsStore(
    (state) => state.setDidShowItineraryTooltips,
  );

  const handleCloseItineraryTooltip = () =>
    handleSetShowItineraryTooltipsTooltips(true);

  return {
    didShowItineraryTooltips,
    handleCloseItineraryTooltip,
  };
};
