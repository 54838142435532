import { getUniqueDatesFromExperiences } from '@utils/getUniqueArray';
import { useEffect } from 'react';

import { Experience } from '@/generated';

export const useGetUniqueDateRangeFromExperiences = (
  experiences: Experience[] | undefined,
  setItineraryDaysRange: (dateRange: string[]) => void,
) => {
  useEffect(() => {
    if (Array.isArray(experiences) && experiences.length > 0) {
      const dateRange = getUniqueDatesFromExperiences(experiences) as string[];
      setItineraryDaysRange(dateRange);
    }
  }, [experiences, setItineraryDaysRange]);
};
