import { useApi } from '@/api/serviceProvider';
import { useMutation } from '@tanstack/react-query';

type AssignTripParams = {
  email?: string;
  itineraryId?: number;
  token?: string;
  invitedUserId?: number;
  interests?: string[];
  foodPreferences?: string[];
  min_price_level?: number;
  max_price_level?: number;
};

export const useAssignTripToInvitedUser = () => {
  const { api } = useApi();
  const { mutate, data, isPending, error } = useMutation({
    mutationFn: async (data: AssignTripParams) => {
      if (!data.itineraryId || !data.token || !data.email) {
        return;
      }

      return await api.apiV1ItinerariesAssignTravelerToItinerary({
        email: data.email,
        itinerary_id: data.itineraryId,
        token: data.token,
        itinerary_traveler_pk: data.invitedUserId,
        interests: data.interests,
        food_preferences: data.foodPreferences,
        min_price_level: data.min_price_level,
        max_price_level: data.max_price_level,
      });
    },
  });

  return {
    assignTrip: mutate,
    data: data,
    isLoading: isPending,
    error: error,
  };
};
