type Polyline = {
  latitude: number;
  longitude: number;
};

export const convertToGeoPath = (polyline: Polyline[]) => {
  const geoPath = polyline.map((polyline) => [
    polyline.longitude,
    polyline.latitude,
  ]);
  return geoPath;
};
