import { InviteUserBottomSheet } from '@/components/bottomSheets/InviteUserBottomSheet';
import { Itinerary, Persons } from '@/generated';
import { PeopleAmountCategory } from '@components/modalsSections/peopleAmount/PeopleAmountCategory';

type Props = {
  people: Persons | null | undefined;
  itineraryData: Itinerary | undefined;
  generatedItineraryId?: number | null;
  handleAmountChange: (peopleUpdate: Persons | null) => void;
  invitedPeople?: string[] | null;
  onInviteHandler?: () => void;
  isInviteBottomSheet?: boolean;
  setIsInviteBottomSheet?: (value: boolean) => void;
  withoutShadow?: boolean;
};

export const PeopleAmount = ({
  people,
  invitedPeople,
  itineraryData,
  generatedItineraryId,
  isInviteBottomSheet,
  handleAmountChange,
  onInviteHandler,
  setIsInviteBottomSheet,
  withoutShadow = false,
}: Props) => {
  return (
    <div className="flex flex-col w-full gap-3">
      <PeopleAmountCategory
        iconName="adult"
        label="Adult"
        ageRange="(Age 13-99)"
        category="adults"
        onChange={handleAmountChange}
        value={people?.adults}
        displayInviteBtn={people?.adults ? people.adults > 0 : false}
        onInviteClick={onInviteHandler}
        withoutShadow={withoutShadow}
      />
      <PeopleAmountCategory
        iconName="youth"
        label="Children"
        ageRange="(Age 3-12)"
        category="children"
        onChange={handleAmountChange}
        value={people?.children}
        withoutShadow={withoutShadow}
      />
      <PeopleAmountCategory
        iconName="infant"
        label="Infant"
        ageRange="(Age 2 and younger)"
        category="infants"
        onChange={handleAmountChange}
        value={people?.infants}
        withoutShadow={withoutShadow}
      />
      {isInviteBottomSheet && setIsInviteBottomSheet && (
        <InviteUserBottomSheet
          itineraryId={itineraryData?.id ?? generatedItineraryId ?? undefined}
          travelers={invitedPeople ?? []}
          isOpen={isInviteBottomSheet}
          onClose={() => setIsInviteBottomSheet(false)}
          storeInvitations={false}
          isWithCheckBox
        />
      )}
    </div>
  );
};
