import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  styles?: React.CSSProperties;
};

export const PopUpMenu = ({ children, ...props }: Props) => {
  return (
    <>
      <div
        className={classNames(
          'absolute bg-white text-black flex-col w-[244px] rounded-[12px] shadow-general z-20',
          {
            hidden: !props.isOpen,
            flex: props.isOpen,
          },
        )}
        style={props.styles}
      >
        {children}
      </div>
      <div
        id="banner"
        className={classNames('absolute z-[19] w-full h-full top-0 left-0', {
          flex: props.isOpen,
          hidden: !props.isOpen,
        })}
        onClick={props.onClose}
      />
    </>
  );
};
