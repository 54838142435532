/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExperienceCategory } from './ExperienceCategory';
import type { ExperienceDetails } from './ExperienceDetails';
export type Experience = {
    readonly id?: number;
    itinerary?: number | null;
    details?: ExperienceDetails;
    name?: string | null;
    date?: string | null;
    start_time?: string | null;
    end_time?: string | null;
    readonly category?: Array<ExperienceCategory>;
    deleted_at?: string | null;
    type: Experience.type;
    pinned?: boolean;
    paid?: boolean | null;
    featured?: boolean;
    city: string;
    viator_product_code?: string | null;
    place_id?: string | null;
    price?: number;
};
export namespace Experience {
    export enum type {
        NON_DINING = 'non-dining',
        DINING = 'dining',
    }
}

