import { routes } from '@routes/routes';

import { TabbarOptionTypes } from '@/types/TabbarOptionTypes';

export const tabbarButtons: TabbarOptionTypes[] = [
  {
    icon: 'stars',
    value: 'Discover',
    routeName: routes.discovery.index,
  },
  {
    icon: 'location',
    value: 'My Trips',
    routeName: routes.myTrips,
  },
  {
    icon: 'user',
    value: 'Profile',
    routeName: routes.profile,
  },
];
