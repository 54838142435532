import { tabbarButtons } from '@/data/appData/tabbarData';
import { useUserShortName } from '@/hooks/useUserShortName';
import { routes } from '@/routes/routes';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SpriteIcon } from '../icons/SpriteIcon';
import { DesktopTabBarButton } from '../buttons/DeskTabBarButton';
import classNames from 'classnames';

type TitleHeaderVariants = 'standard' | 'discovery';

type Props = {
  title?: string;
  children?: React.ReactNode;
  variant?: TitleHeaderVariants;
};

export const TitleHeader = ({
  title,
  variant = 'standard',
  children,
}: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const shortUserName = useUserShortName();

  useEffect(() => {
    if (!shortUserName) {
      navigate(routes.home);
    }
  }, [shortUserName, navigate]);

  const currentTab = tabbarButtons.find((tab) => tab.routeName === pathname);

  return (
    <header
      className={classNames(
        'grid mdx:grid-cols-2 md:grid-rows-2 px-4 md:px-8 pb-4 pt-4  relative',
        {
          'bg-white shadow-header md:pt-0': variant === 'standard',
          'bg-transparent p-4': variant === 'discovery',
        },
      )}
    >
      <div
        className={classNames('hidden md:flex justify-between', {
          'row-span-2 items-center': variant === 'discovery',
        })}
      >
        <SpriteIcon
          iconName={variant === 'standard' ? 'logoMobile' : 'logoWhite'}
          className={classNames('hidden mdx:block mx-8 ', {
            'transform -translate-x-[42%] max-h-4 my-5': variant === 'standard',
            'w-[120px] h-[42px]': variant === 'discovery',
          })}
        />
      </div>
      <div className="hidden mdx:flex gap-x-7 row-span-3 items-center justify-end">
        {tabbarButtons.map(({ icon, value, routeName }) => (
          <DesktopTabBarButton
            key={value}
            icon={icon}
            value={value}
            routeName={routeName}
            currentTab={currentTab}
            shortUserName={shortUserName}
            navigate={navigate}
            variant={variant}
          />
        ))}
      </div>
      {title && (
        <div className="flex justify-between items-center">
          <h3 className="text-h3">{title}</h3>
        </div>
      )}
      {children}
    </header>
  );
};
