import { useParams } from 'react-router-dom';
import { ItineraryPendingPage } from './ItineraryPendingPage';
import { useRedirectOnInvalidItineraryId } from '@/hooks/useRedirectOnInvalidItineraryId';
import { useGetMyItineraryDetails } from '@/hooks/useGetMyItineraryDetails';
import { Loader } from '@/components/loaders/Loader';
import { ItineraryPage } from './ItineraryPage';
import { useUserStore } from '@/stores/userStore';
import { useDecodeInvitationToken } from '@/hooks/useDecodeInvitationToken';
import { useEffect } from 'react';
import { ItineraryInviteOverviewPage } from './ItineraryInviteOverviewPage';
import { ItineraryWithExperiences } from '@/types/AppTypes';

export const ItineraryRedirectPage = () => {
  const { id, token } = useParams();
  const userId = useUserStore((state) => state.user?.user.pk);

  useRedirectOnInvalidItineraryId(id);

  const itineraryId = id ? Number(id) : undefined;

  const { itinerary, isItineraryLoading, isItineraryEmpty } =
    useGetMyItineraryDetails(itineraryId, token);

  const { invitation, decodeInvitationToken, isLoading, error } =
    useDecodeInvitationToken();

  useEffect(() => {
    if (token && itineraryId && !isItineraryEmpty()) {
      decodeInvitationToken({ itineraryId, token });
    }
  }, [token, isItineraryLoading]);

  if (isItineraryLoading || isLoading) {
    return (
      <div className="w-full h-screen flex justify-center items-center overflow-hidden">
        <Loader size="large" />
      </div>
    );
  }

  if (!isItineraryEmpty()) {
    if (
      token &&
      invitation &&
      !error &&
      (invitation.is_group || !invitation.is_accepted)
    ) {
      return (
        <ItineraryInviteOverviewPage
          token={token}
          invitation={invitation}
          isLoading={isLoading}
          error={undefined}
        />
      );
    }

    if (itinerary.status === 'pending') {
      return (
        <ItineraryPendingPage
          itineraryId={itineraryId}
          token={token}
          isAdmin={itinerary.user === userId}
          invitedUserId={invitation?.invited_user_id}
        />
      );
    } else if (itinerary.status !== 'pending') {
      return (
        <ItineraryPage
          itineraryId={itineraryId}
          isItineraryLoading={isItineraryLoading}
          itinerary={itinerary as ItineraryWithExperiences}
          isAdmin={
            itinerary.user === undefined ||
            itinerary.user === userId ||
            (token ? false : true)
          }
        />
      );
    }
  }

  return (
    <ItineraryInviteOverviewPage
      token={token}
      invitation={null}
      isLoading={false}
      error={error ?? undefined}
    />
  );
};
