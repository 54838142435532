import { SpriteIcon, SpriteIconProps } from '../icons/SpriteIcon';

type Props = {
  to: string;
  iconName: SpriteIconProps;
};

export const FooterIconLink = ({ to, iconName }: Props) => {
  return (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className="text-p-small-web text-gray hover:text-purple"
    >
      <SpriteIcon
        iconName={iconName}
        className="w-6 h-6 fill-black hover:fill-purple"
      />
    </a>
  );
};
