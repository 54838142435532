import { Button } from '@components/buttons/Button';
import { SpriteIcon } from '@components/icons/SpriteIcon';

type Params = {
  label: string;
  buttonLabel: string;
  onButtonClick: () => void;
  notes?: string;
  content?: React.ReactNode;
};

export const InvitationCard = ({
  label,
  buttonLabel,
  onButtonClick,
  notes,
  content,
}: Params) => {
  return (
    <div className="grid gap-y-8 h-full mt-32 justify-center">
      <div className="flex justify-center align-middle">
        <SpriteIcon
          iconName="logo"
          className="w-full h-15 stroke-black fill-black"
        />
      </div>
      <div className="flex-column background bg-purple text-white rounded-36 pl-5 pr-5 pt-6 pb-6 max-w-[420px]">
        <h1 className="text-h3">{label}</h1>
        {content}
        {notes ? <p className="text-p-medium mt-6 mb-6">{notes}</p> : null}
        <Button
          size="large"
          variant="primary"
          type="button"
          full={true}
          iconName="stars"
          onClick={onButtonClick}
        >
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
};
