import { useMutation } from '@tanstack/react-query';

import { useApi } from '@/api/serviceProvider';
import { ChangePasswordSchemaType } from '@/forms/useChangePasswordForm';
import { removeAuthCookie } from '@/utils/removeAuthCookie';
import { showToast } from '@/utils/showToast';

export const useChangePassword = () => {
  const { auth } = useApi();

  const { mutate, isPending } = useMutation({
    mutationFn: async (data: ChangePasswordSchemaType) => {
      removeAuthCookie();

      if (!data) {
        return;
      }

      return await auth.authPasswordChangeCreate(data);
    },
    onSuccess: () => showToast('Password changed successfully'),
    onError: () => showToast('Failed to change your password'),
  });

  return {
    handleChangePassword: mutate,
    isLoading: isPending,
  };
};
