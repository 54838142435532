import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useGetFoodPreferences = (searchValue: string) => {
  const apiFactory = useApi();

  // Define the mutation
  const mutation = useMutation({
    mutationKey: ['openResource'],
    mutationFn: () => apiFactory.api.apiV1FoodPreferencesList(searchValue),
  });

  // Define the query
  const { data: queryData, isLoading: isQueryLoading } = useQuery({
    queryFn: () => apiFactory.api.apiV1FoodPreferencesList(searchValue),
    queryKey: [queryKeyTypes.foodPreferences],
  });

  // Merge query and mutation results
  const foodPreferences = mutation.data || queryData || [];

  // Determine loading state
  const isLoading = isQueryLoading;

  return {
    foodPreferences,
    isLoading,
    searchForFoodPreferences: mutation.mutate,
  };
};
