import { useState, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useInitialSearchStore } from '@/stores/initialSearchStore';
import { useStartPageModalStore } from '@/stores/startPageModalStore';
import { StartPageModalTypes } from '@/types/AppTypes';
import { getTotalPeopleCount } from '@/utils/getTotalPeopleCount';
import { ToastMessage } from '@components/toasts/ToastMessage';
import { SpriteIcon } from '@components/icons/SpriteIcon';
import { parseDate } from '@/utils/formatDateTime';
import { MenuType } from '@/types/MenuTypes';

export const useLandingPageMenu = () => {
    const [isToastVisible, setIsToastVisible] = useState(false);
    const toastId = useRef<null | string | number>(null);

    const dateRange = useInitialSearchStore((state) => state.dateRange);
    const location = useInitialSearchStore((state) => state.location);
    const people = useInitialSearchStore((state) => state.people);

    const { openedModal, setOpenedModal } = useStartPageModalStore((state) => state);

    const notify = useCallback(() => {
        const newToastId = toast(
            <ToastMessage
                icon={
                    <SpriteIcon
                        iconName="info"
                        className="w-5 h-5 stroke-black stroke-[2px]"
                    />
                }
                message="Please provide the rest of the details"
            />,
        );
        toastId.current = newToastId;
        setIsToastVisible(true);
    }, []);

    const handleMenuItemClick = useCallback(
        (itineraryModel: StartPageModalTypes) => {
            setOpenedModal(itineraryModel);
            if (toastId.current) {
                toast.dismiss(toastId.current);
                toastId.current = null;
                setIsToastVisible(false);
            }
        },
        [setOpenedModal]
    );

    const handlePlanTrip = useCallback(
        (handleGenerateItinerary: () => void) => {
            const isInvalidInput = [
                !location,
                !dateRange,
                !people,
                getTotalPeopleCount(people) < 1,
            ].some(Boolean);
            if (isInvalidInput) {
                return notify();
            }
            handleGenerateItinerary();
        },
        [dateRange, location, notify, people]
    );

    const menuItems: MenuType[] = [
        {
            id: 1,
            text: location?.name ?? 'Where are you going?',
            iconName: 'map',
            withoutData: isToastVisible && location === null,
            onClick: () => handleMenuItemClick('LOCATION'),
        },
        {
            id: 2,
            text:
                parseDate(
                    {
                        startDate: dateRange?.from,
                        endDate: dateRange?.to,
                    }) ?? 'When?',
            iconName: 'calendar',
            withoutData: isToastVisible && dateRange === null,
            onClick: () => handleMenuItemClick('DATES'),
        },
        {
            id: 3,
            text: getTotalPeopleCount(people)
                ? getTotalPeopleCount(people) == 1
                    ? '1 traveler'
                    : `${getTotalPeopleCount(people)} travelers`
                : 'With whom?',
            iconName: 'user',
            withoutData: isToastVisible && getTotalPeopleCount(people) < 1,
            onClick: () => handleMenuItemClick('PEOPLE'),
        },
    ];

    return {
        isToastVisible,
        openedModal,
        setOpenedModal,
        handleMenuItemClick,
        handlePlanTrip,
        menuItems
    };
};