import { IconButton } from '@components/buttons/IconButton';
import { ExperienceFilterList } from '@components/lists/ExperienceFilterList';
import { routes } from '@routes/routes';
import { useAddExperienceStore } from '@stores/addExperienceStore';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { Experience } from '@/generated';
import { useGetCategories } from '@/hooks/useGetCategories';
import { ChosenExperienceCategory } from '@/types/stores/AddExperienceStoreTypes';
import { SimpleInput } from '../inputs/SimpleInput';
import { useInitialSearchStore } from '@/stores/initialSearchStore';
import { useItineraryStore } from '@/stores/itineraryStore';

const categories: ChosenExperienceCategory[] = ['activities', 'dining'];

type Props = {
  experiences: Experience[] | undefined;
  itineraryId: number | undefined;
  onFilterClick: () => void;
  searchValue?: string;
  onSearchChange?: (value: string) => void;
};

export const AddExperienceHeader = ({
  itineraryId,
  experiences,
  onFilterClick,
  searchValue = '',
  onSearchChange,
}: Props) => {
  const chosenExperienceCategory = useAddExperienceStore(
    (state) => state.chosenExperienceCategory,
  );
  const initialSearch = useInitialSearchStore((state) => state);
  const itineraryDestination = useItineraryStore(
    (state) => state.itineraryDestination,
  );
  const city = itineraryDestination
    ? itineraryDestination.split(',')[0]
    : initialSearch.location?.name?.split(',')[0];

  const handleCategoryClick = useAddExperienceStore(
    (state) => state.setChosenExperienceCategory,
  );

  const chosenFilters = useAddExperienceStore((state) => state.chosenFilters);

  const handleFilterClick = useAddExperienceStore(
    (state) => state.setChosenFilters,
  );

  const navigate = useNavigate();

  const { categories: availableFilters } = useGetCategories(experiences);

  const handleNavigateBack = () => navigate(routes.back);
  const handleSearchClick = () =>
    navigate(`/itinerary/${itineraryId}/add-experience/search`);

  return (
    <header className="px-2 pt-2 pb-2 lg:px-5 pt-[14px] shadow-header bg-white flex-shrink-0">
      <IconButton
        iconName="arrowLeftLarge"
        onClick={handleNavigateBack}
        className="lg:w-8 lg:h-8"
      />
      <div
        className={classNames(
          'flex justify-between w-full items-center',
          'lg:flex-col',
        )}
      >
        <div className="flex gap-5 my-5 w-full items-center">
          {categories.map((category) => (
            <h3
              key={category}
              className={classNames('text-h3 capitalize cursor-pointer', {
                'text-gray': chosenExperienceCategory !== category,
              })}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </h3>
          ))}
        </div>
        <div className="lg:hidden">
          <IconButton
            iconName="search"
            background="gray-light"
            onClick={handleSearchClick}
          />
        </div>
        <div className="hidden lg:flex mb-4 w-full">
          <SimpleInput
            name="text"
            type="text"
            value={searchValue}
            updateValue={(val: string) =>
              onSearchChange ? onSearchChange(val) : null
            }
            placeholder={`Search experiences in ${city}`}
            iconName="search"
            fixedHeight={false}
            autoComplete="off"
            styles="rounded-48 gap-2 px-5"
          />
        </div>
      </div>
      <div className="flex gap-2">
        <IconButton
          iconName="filter"
          background="gray-light"
          onClick={onFilterClick}
        />
        <ExperienceFilterList
          filters={availableFilters}
          chosenFilters={chosenFilters.categories}
          onFilterClick={handleFilterClick}
        />
      </div>
    </header>
  );
};
