import { Button } from '@components/buttons/Button';
import { TextButton } from '@components/buttons/TextButton';
import { Header } from '@components/headers/Header';
import { ProgressBar } from '@components/progressBar/ProgressBar';
import { ErrorSection } from '@components/sections/ErrorSection';
import { BudgetStep } from '@components/tailoringSteps/BudgetStep';
import { FoodPreferences } from '@components/tailoringSteps/FoodPreferences';
import { Interests } from '@components/tailoringSteps/Interests';
import { useMultistepForm } from '@hooks/useMultistepForm';
import { motion } from 'framer-motion';
import { useState } from 'react';

import { useTooltipsStore } from '@/stores/itineraryTooltipsStore';
import { FoodPreferenceType } from '@/types/FoodPreference';
import { useAcceptInvitation } from '@/hooks/useAcceptInvitation';
import { Loader } from '../loaders/Loader';
import { useInvitationStore } from '@/stores/invitationStore';
import { ActivityTag } from '@/types/AppTypes';

type Props = {
  token: string;
  allowAcceptInvitation: () => boolean;
  onClose: () => void;
  is_admin: boolean;
  navigateToTheItinerary: () => void;
};

export const InvitationPreferenceModal = ({
  token,
  allowAcceptInvitation,
  onClose,
  navigateToTheItinerary,
}: Props) => {
  const [_, setAvailableInterests] = useState<ActivityTag[]>([]);
  const [availableFoodPreferences, setAvailableFoodPreferences] = useState<
    FoodPreferenceType[]
  >([]);

  const { acceptInvitation, isLoading, error } = useAcceptInvitation({ token });
  const didShowTailoringTooltips = useTooltipsStore(
    (state) => state.didShowTailoringTooltips,
  );
  const handleSetDidShowTailoringTooltips = useTooltipsStore(
    (state) => state.setDidShowTailoringTooltips,
  );

  const variants = {
    hidden: { y: '100svh' },
    visible: {
      y: '0',
      transition: { type: 'tween', stiffness: 60, damping: 10 },
    },
    exit: { y: '-100svh', transition: { ease: 'easeInOut' } },
  };

  const invitationSearch = useInvitationStore(
    (state) => state.invitationSearch,
  );
  const setInvitationSearch = useInvitationStore(
    (state) => state.setInvitationSearch,
  );
  const resetInvitationSearch = useInvitationStore(
    (state) => state.resetInvitationSearch,
  );

  const { currStepIndex, step, steps, back, next, isFirstStep, isLastStep } =
    useMultistepForm([
      <Interests
        activity_tags={undefined}
        setAvailableInterests={setAvailableInterests}
        storage={invitationSearch}
        setStorageSearch={setInvitationSearch}
      />,
      <FoodPreferences
        availableFoodPreferences={availableFoodPreferences}
        setAvailableFoodPreferences={setAvailableFoodPreferences}
        storage={invitationSearch}
        setStorageSearch={setInvitationSearch}
      />,
      <BudgetStep
        storage={invitationSearch}
        setStorageSearch={setInvitationSearch}
      />,
    ]);

  const onSave = () => {
    const isAllowed = allowAcceptInvitation();
    if (!didShowTailoringTooltips) {
      handleSetDidShowTailoringTooltips(true);
    }
    if (isAllowed) {
      acceptInvitation(undefined);
      resetInvitationSearch();
      navigateToTheItinerary();
    }
  };

  if (isLoading) {
    return <Loader size="large" />;
  }

  if (!isLoading && error) {
    return <ErrorSection errorMessage={error} />;
  }

  return (
    <>
      <motion.div
        variants={variants}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="absolute px-4 py-2 top-0 left-0 z-10 h-svh w-full bg-white flex flex-col items-center"
      >
        <div className="space-y-2 w-full">
          <Header
            actionButtonText="Save & close"
            onBackButtonClick={onClose}
            onActionButtonClick={onSave}
          />
          <ProgressBar active={currStepIndex + 1} steps={steps.length} />
        </div>
        {step}
        <div
          className={`flex w-full max-w-[375px] items-center ${
            !isFirstStep ? 'justify-between' : 'justify-end'
          }`}
        >
          {!isFirstStep && (
            <TextButton padding="2" onClick={back}>
              Back
            </TextButton>
          )}
          <Button
            size="large"
            onClick={isLastStep ? onSave : next}
            variant="primary"
          >
            {isLastStep ? 'Save & close' : 'Next'}
          </Button>
        </div>
      </motion.div>
    </>
  );
};
