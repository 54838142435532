import { SpriteIcon, SpriteIconProps } from '@components/icons/SpriteIcon';
import classNames from 'classnames';
import { ReactNode } from 'react';

type Props = {
  icon: SpriteIconProps;
  selected?: boolean;
  children: ReactNode;
  disabled?: boolean;
  onClick: () => void;
};

export const TransportButton = ({
  icon,
  children,
  selected,
  disabled,
  onClick,
}: Props) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        'flex justify-center items-center gap-2 pl-1 pr-3 h-10 w-fit rounded-40',
        {
          'bg-black': selected,
          'bg-gray-light': !selected,
        },
      )}
    >
      <div className="bg-white rounded-full p-1.5">
        <SpriteIcon iconName={icon} className="w-5 h-5 fill-black" />
      </div>
      <p
        className={classNames('text-p self-center whitespace-nowrap my-auto', {
          'text-white': selected,
          'text-black': !selected,
        })}
      >
        {children}
      </p>
    </button>
  );
};
