import { Footer } from '@/components/footer/Footer';
import { TitleHeader } from '@/components/headers/TitleHeader';
import { SpriteIcon } from '@/components/icons/SpriteIcon';
import { ItineraryLoader } from '@/components/loaders/ItineraryLoader';
import { DiscoveryPageMenu } from '@/components/menu/DiscoveryPageMenu';
import { useGenerateItinerary } from '@/hooks/useGenerateItinerary';
import { useSendInvites } from '@/hooks/useSendInvites';
import { useTailorItinerary } from '@/hooks/useTailorItinerary';
import { useUpdateItinerary } from '@/hooks/useUpdateItinerary';
import { routes } from '@/routes/routes';
import { useInitialSearchStore } from '@/stores/initialSearchStore';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useStartPageModalStore } from '@/stores/startPageModalStore';
import { useWindowSize } from '@/hooks/useWindowSize';

export const DiscoverPage = () => {
  const navigate = useNavigate();
  const { setOpenedModal } = useStartPageModalStore();
  const windowSize = useWindowSize();

  const dateRange = useInitialSearchStore((state) => state.dateRange);

  const { itineraryId, invitedPeople, isWaitingForPeople } =
    useInitialSearchStore((state) => state);
  const setInitialSearch = useInitialSearchStore(
    (state) => state.setInitialSearch,
  );
  const { tailorItinerary, isPending: isTailoring } = useTailorItinerary({
    onClose: () => {
      navigateToItinerary();
      setInitialSearch({
        itineraryId: null,
        invitedPeople: null,
        isWaitingForPeople: false,
      });
    },
    itineraryId: itineraryId ?? undefined,
    toastMessage: 'Generated itinerary',
    isGAEventNeeded: false,
  });

  const { isPending, generateItinerary } = useGenerateItinerary({});
  const { updateItinerary } = useUpdateItinerary();
  const { sendInvites } = useSendInvites(itineraryId ? itineraryId : undefined);

  const navigateToItinerary = () => {
    if (itineraryId) {
      navigate(
        routes.itinerary.index
          .replace(':id', itineraryId.toString())
          .replace(':token', ''),
      );
    }
  };

  const handleGenerateItinerary = () => {
    if (itineraryId) {
      updateItinerary(itineraryId);
      sendInvites({ invitedUsers: invitedPeople ?? [] });

      if (isWaitingForPeople) {
        setInitialSearch({
          itineraryId: null,
          invitedPeople: null,
          isWaitingForPeople: false,
        });
        navigateToItinerary();
      } else {
        tailorItinerary([]);
        setInitialSearch({
          itineraryId: null,
          invitedPeople: null,
          isWaitingForPeople: false,
        });
      }
    } else {
      generateItinerary();
      setInitialSearch({
        itineraryId: null,
        invitedPeople: null,
        isWaitingForPeople: false,
      });
    }
  };

  useEffect(() => {
    setOpenedModal('NONE');
  }, []);

  if (isTailoring || isPending) {
    return <ItineraryLoader dateRange={dateRange} />;
  }

  return (
    <div className="flex flex-col w-full min-h-screen">
      <div
        className={classNames(
          'relative bg-no-repeat bg-cover bg-hero-img px-4',
          'md:bg-bottom',
        )}
      >
        <TitleHeader variant="discovery" />

        <div className="flex items-center justify-between py-6"></div>
        <div className="flex flex-col items-center pt-6 pb-8 space-y-8 lg:pb-[64px]">
          <div className="space-y-4">
            <div className="flex gap-1 justify-center">
              <SpriteIcon iconName="stars" className="w-6 h-6 fill-white" />
              <p className="text-white text-p-web">Powered by AI</p>
            </div>
            <h1 className="text-center text-white text-h1">
              Let's plan your next trip
            </h1>
          </div>
          <div className="flex justify-center w-full lg:grid lg:grid-cols-12">
            <div className="hidden lg:flex lg:col-span-1" />
            <DiscoveryPageMenu
              handleGenerateItinerary={handleGenerateItinerary}
              isLoading={isPending}
              styles="lg:col-span-10"
              currentWindow={windowSize}
            />
            <div className="hidden lg:flex lg:col-span-1" />
          </div>
        </div>
      </div>
      <div className="hidden mdx:flex flex-grow h-full" />
      {/* <Container
        fullHeight
        variant="secondary"
        styles="pt-8 pb-8"
        mainStyles="gap-y-5 pb-8"
      >
        <RecentTripsSection />
        <CurrentLocationSection />
        <GemsSection />
        <Game setInitialSearch={setInitialSearch} generateItinerary={generateItinerary} />
        <WorldGatesSection
          setInitialSearch={setInitialSearch}
          generateItinerary={generateItinerary}
        />
      </Container> */}
      <Footer />
    </div>
  );
};
