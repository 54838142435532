import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider.ts';
import { useTailorSearchStore } from '@stores/tailorSearchStore';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { UserPlans } from '@/generated';
import { showToast } from '@/utils/showToast';
import { useGoogleAnalytics } from './useGoogleAnalytics';

type Props = {
  onClose: () => void;
  itineraryId?: number;
  toastMessage?: string;
  isGAEventNeeded?: boolean;
};

export const useTailorItinerary = (
  {
    onClose,
    itineraryId,
    toastMessage,
    isGAEventNeeded
  }: Props,
) => {
  const [error, setError] = useState<string | null>(null);
  const apiFactory = useApi();
  const tailoredSearch = useTailorSearchStore((state) => state.tailoredSearch);
  const resetTailoredSearch = useTailorSearchStore(
    (state) => state.resetTailoredSearch,
  );
  const { sendGAEvent, EVENT_ACTIONS, EVENT_CATEGORIES } = useGoogleAnalytics();
  const queryClient = useQueryClient();

  const { mutateAsync: tailorItinerary, isPending } = useMutation({
    mutationFn: async (userPlans: UserPlans[]) => {
      if (!itineraryId) {
        return Promise.reject('Itinerary ID is missing');
      }
      if (isGAEventNeeded) {
        sendGAEvent(EVENT_CATEGORIES.itinerary, EVENT_ACTIONS.completeTailoring);
      }
      return await apiFactory.api.apiV1ItinerariesTailorItinerary(itineraryId, {
        companions: tailoredSearch?.companions,
        food_preferences: tailoredSearch?.foodPreferences?.map(
          (foodPreference) => foodPreference.code,
        ),
        diets: tailoredSearch?.diets?.map((diet) => diet.code),
        interests: tailoredSearch?.interests?.map((interest) => interest.name!),
        earliest_start: tailoredSearch?.earliestStart,
        budget: tailoredSearch?.dailyBudget,
        accommodation: tailoredSearch?.accommodation,
        user_plans: userPlans,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.itinerary],
      });
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.commutes],
      });

      showToast(`${toastMessage ?? 'Experience tailored'} successfully`);
    },
    onError: () => {
      showToast(toastMessage ? `${toastMessage} failed!` : "Failed to tailor experience");
      setError('An unexpected error occurred');
    },
    onSettled: () => {
      resetTailoredSearch();
      onClose();
    },
  });

  return {
    error,
    isPending,
    tailorItinerary,
  };
};
