import '@styles/day-picker.css';
import 'react-day-picker/dist/style.css';

import { IconButton } from '@components/buttons/IconButton';
import { CalendarTextField } from '@components/texts/CalendarTextField';
import {
  DATE_MONTH_YEAR_FORMAT,
  DATE_WEEK_DAY_SHORT_FORMAT,
} from '@utils/constants';
import { useState } from 'react';
import {
  CaptionProps,
  DateRange,
  DayClickEventHandler,
  DayPicker,
  useNavigation,
} from 'react-day-picker';

import { SelectRangeEventHandlerCustom } from '@/types/DatePickerTypes';
import { getFormattedDate } from '@/utils/formatDateTime';

type Props = {
  defaultMonth: Date | undefined;
  selected: DateRange | undefined;
  onSelect: SelectRangeEventHandlerCustom;
  withDateRange?: boolean;
  removeMargin?: boolean;
};

const CustomCaption = ({ displayMonth }: CaptionProps) => {
  const { goToMonth, nextMonth, previousMonth, currentMonth } = useNavigation();

  const formattedMonth = getFormattedDate({
    date: displayMonth,
    format: DATE_MONTH_YEAR_FORMAT,
  });

  return (
    <div className="flex justify-between items-center">
      <h5 className="text-h5">{formattedMonth}</h5>
      <div className="flex gap-2">
        <IconButton
          iconName="arrowLeft"
          disabled={currentMonth < new Date()}
          onClick={() => previousMonth && goToMonth(previousMonth)}
        />
        <IconButton
          iconName="arrowLeft"
          className="transform rotate-180"
          onClick={() => nextMonth && goToMonth(nextMonth)}
        />
      </div>
    </div>
  );
};

const selectedDay = {
  backgroundColor: '#4C0DD2',
  borderRadius: '12px',
};

const range = {
  backgroundColor: '#E4D9FD',
  color: '#000000',
};

const today = {
  fontWeight: 400,
};

export const DatePicker = ({
  defaultMonth,
  selected,
  onSelect,
  withDateRange = true,
  removeMargin = false,
}: Props) => {
  const [isFirstButtActive, setIsFirstButtActive] = useState(true);

  const handleDayClick: DayClickEventHandler = (day, modifiers) => {
    const newSelected: DateRange = { from: selected?.from, to: selected?.to };
    let flag = false;

    if (isFirstButtActive) {
      if (!newSelected.from || !newSelected.to) {
        newSelected.from = day;
        newSelected.to = day;
        setIsFirstButtActive(false);
        flag = true;
      } else if (newSelected.to == newSelected.from && newSelected.to > day) {
        newSelected.from = day;
        flag = true;
      } else if (
        newSelected.from > day ||
        (newSelected.from < day && newSelected.to > day)
      ) {
        newSelected.from = day;
      } else if (newSelected.from < day && newSelected.from < day) {
        newSelected.from = day;
        newSelected.to = day;
      } else if (modifiers.range_start) {
        newSelected.from = newSelected.to;
      } else {
        newSelected.from = day;
        newSelected.to = day;
      }
    } else {
      if (!newSelected.from || !newSelected.to) {
        newSelected.from = day;
        newSelected.to = day;
        setIsFirstButtActive(false);
        flag = true;
      } else if (newSelected.to == newSelected.from && newSelected.from > day) {
        newSelected.from = day;
        flag = true;
      } else if (
        newSelected.to < day ||
        (newSelected.from < day && day < newSelected.to)
      ) {
        newSelected.to = day;
      } else if (newSelected.to > day && newSelected.from > day) {
        newSelected.from = day;
        newSelected.to = day;
      } else if (modifiers.range_end) {
        newSelected.to = newSelected.from;
      } else {
        newSelected.from = day;
        newSelected.to = day;
      }
    }

    !flag ? setIsFirstButtActive(!isFirstButtActive) : null;
    onSelect({
      from: newSelected?.from,
      to: newSelected?.to,
    });
  };

  return (
    <div className="flex flex-col w-full">
      {withDateRange && (
        <div className="flex gap-4 w-full">
          <CalendarTextField date={selected?.from} placeholder="From" />
          <CalendarTextField date={selected?.to} placeholder="To" />
        </div>
      )}
      <DayPicker
        className="w-fit self-center"
        components={{ Caption: CustomCaption }}
        disabled={{ before: new Date() }}
        mode="range"
        onDayClick={handleDayClick}
        defaultMonth={defaultMonth}
        modifiersStyles={{
          '.rdp-root': removeMargin ? { margin: '0' } : {},
          range_end: {
            ...selectedDay,
          },
          range_start: {
            ...selectedDay,
          },
          range_middle: {
            ...range,
          },
          today: {
            ...today,
          },
        }}
        selected={selected}
        formatters={{
          formatWeekdayName: (date) => {
            return (
              <p className="capitalize text-gray text-p-small">
                {getFormattedDate({
                  date: date,
                  format: DATE_WEEK_DAY_SHORT_FORMAT,
                })}
              </p>
            );
          },
        }}
      />
    </div>
  );
};
