import { SpriteIcon, SpriteIconProps } from '@components/icons/SpriteIcon';
import classNames from 'classnames';
import { CardOnMap } from '../cards/CardOnMap';
import { Experience } from '@/generated';

type Props = {
  iconName: SpriteIconProps;
  isActive: boolean;
  isSelected?: boolean;
  experience: Experience;
};

export const MapPinIcon = ({
  iconName,
  isActive,
  isSelected,
  experience,
}: Props) => {
  return (
    <div
      className={classNames(
        'relative p-2.5 rounded-full w-fit shadow-card-on-map',
        {
          'bg-black': isActive,
          'bg-white': !isActive,
        },
      )}
    >
      <SpriteIcon
        iconName={iconName}
        className={classNames('w-5 h-5', {
          'fill-green': isActive,
          'fill-black': !isActive,
        })}
      />
      {isSelected && (
        <SpriteIcon
          iconName="triangleDown"
          className="absolute w-2 h-2 top-[39px] left-4 fill-black"
        />
      )}
      {isSelected && (
        <div className="hidden lg:block absolute top-[3rem] left-[-165px] w-[365px] left-0">
          <CardOnMap
            name={experience.name}
            details={experience.details}
            width={365}
            styles="z-10"
          />
        </div>
      )}
    </div>
  );
};
