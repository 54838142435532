import { useMemo } from 'react';

import { Interest } from '@/generated';

export const useRemoveDuplicatesFromInterestArray = (
  interests?: Interest[],
) => {
  const duplicates: Array<Interest> = [];
  const filteredExperiences = useMemo(
    () =>
      interests?.filter((interest, index) => {
        const res =
          index ===
          interests?.findIndex((inter) => interest.name === inter.name);
        if (!res) {
          duplicates.push(interest);
        }
        return res;
      }),
    [interests],
  );

  return filteredExperiences;
};
