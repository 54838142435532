import { useUserStore } from "@/stores/userStore";

export const useUserShortName = () => {
    const user = useUserStore((state) => state.user);
    if (!user) return undefined;

    const { first_name: FName, last_name: LName } = user.user;


    const extractLetter = (value: string) => {
        return value.charAt(0).toUpperCase();
    };

    return `${extractLetter(FName)}${extractLetter(LName)}`;
};