import { Loader } from '@components/loaders/Loader';

type Props = {
  text?: string;
};

export const FullScreenLoader = ({ text }: Props) => {
  return (
    <div className="flex flex-col w-full h-svh bg-white z-10 absolute top-0 left-0 justify-center items-center gap-8 px-4">
      <Loader />
      <h5 className="text-h5 text-center width-full max-w-[250px]">{text}</h5>
    </div>
  );
};
