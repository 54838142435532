import { ArticleCard } from '@components/cards/ArticleCard';
import { featureList } from '@data/appData/startPageData';
import classNames from 'classnames';

export const FeatureList = () => {
  const getMobileOrder = (order: number) => {
    return order === 3 || order === 4
      ? `order-${order === 3 ? 4 : 3} mdx:order-none`
      : `order-${order} mdx:order-none`;
  };

  return (
    <div className="flex flex-col my-[60px] mx-auto">
      <div className="px-7 mb-[44px] lg:mb-[77px]">
        <h3 className="text-center uppercase sm:text-h3-web-sm mdm:text-h3-web-mdm lg:text-h3-web-lg">
          LEAVE COMPROMISES IN THE DUST. IT’S ALL SMOOTH SAILING FROM HERE.
        </h3>
      </div>
      <div
        className={classNames(
          'grid grid-cols-1 gap-4',
          'justify-items-center mx-2',
          'mdx:grid-cols-2 lg:grid-cols-3',
          'mdm:mx-5 lg:mx-7',
        )}
      >
        {featureList.map((feature, index) => (
          <ArticleCard
            key={feature.title}
            {...feature}
            parentClassNames={classNames(
              getMobileOrder(index + 1),
              feature.parentClassNames,
            )}
          />
        ))}
      </div>
    </div>
  );
};
