import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Loader } from '@/components/loaders/Loader';
import { useVerifyRegistrationEmail } from '@/hooks/useVerifyRegistrationEmail';
import { routes } from '@/routes/routes';

export const SignUpEmailVerificationPage = () => {
  const navigate = useNavigate();

  const { token } = useParams();
  const { handleVerifyRegistrationEmail } = useVerifyRegistrationEmail();

  useEffect(() => {
    if (!token) {
      navigate(routes.home);
    }
  }, []);

  useEffect(() => {
    handleVerifyRegistrationEmail(token ?? '');
  }, []);

  return (
    <main className="flex justify-center items-center h-svh">
      <Loader />
    </main>
  );
};
