import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import { useApi } from '@/api/serviceProvider';
import { useAuthEmailStore } from '@/stores/authEmailStore';
import { showToast } from '@/utils/showToast';

export const useResendSignUpEmail = () => {
  const apiFactory = useApi();

  const [didUseResend, setDidUseResend] = useState(false);

  const email = useAuthEmailStore((state) => state.email);

  const clearInitialRegisterEmail = useAuthEmailStore(
    (state) => state.clearEmail,
  );

  const { mutate, isPending } = useMutation({
    mutationKey: ['signUp'],
    mutationFn: async () => {
      if (!email) {
        return;
      }

      return await apiFactory.auth.authRegistrationResendEmailCreate({
        email,
      });
    },
    onSuccess: () => {
      clearInitialRegisterEmail();
      setDidUseResend(true);
    },
    onError: () => showToast('Failed to resend email. Please try again later.'),
  });

  return {
    didUseResend,
    handleResendSignUpEmail: mutate,
    isLoading: isPending,
  };
};
