import { ReactNode } from 'react';

import { AuthHeader } from '@components/headers/AuthHeader';
import { Container } from '@components/containers/Container';

type Props = {
  hasBackButton?: boolean;
  handleCloseButtonClick?: () => void;
  children: ReactNode;
};

export const AuthLayout = ({
  hasBackButton,
  handleCloseButtonClick,
  children,
}: Props) => {
  return (
    <Container styles="px-4 flex flex-col h-svh w-full items-center">
      <AuthHeader
        hasBackButton={hasBackButton}
        handleCloseButtonClick={handleCloseButtonClick}
      />
      {children}
    </Container>
  );
};
