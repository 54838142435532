import { SpriteIcon } from '@components/icons/SpriteIcon';
import { useEffect, useState } from 'react';

type Props = {
  value?: number;
  onChange: (value: number) => void;
  maxAmount?: number;
};

export const AmountButton = ({ onChange, maxAmount, value = 0 }: Props) => {
  const [count, setCount] = useState<number>(value);

  const handleDecrement = () => {
    if (count >= 1) {
      setCount(count - 1);
    }
  };

  const handleIncrement = () => {
    if (maxAmount === undefined || count < maxAmount) {
      setCount(count + 1);
    }
  };

  // QUESTION: useRef could be used here?
  useEffect(() => {
    onChange(count);
  }, [count]);

  return (
    <div className="flex items-center justify-between w-[114px]">
      <button
        onClick={handleDecrement}
        className="justify-center items-center shadow-sm bg-gray-light self-stretch flex flex-col p-2 rounded-[40px]"
      >
        <SpriteIcon
          iconName="minus"
          className={`w-6 h-6 ${count === 0 && 'fill-gray'}`}
        />
      </button>
      <p className={`${count === 0 ? 'text-gray' : 'text-black'} text-p px-3`}>
        {count}
      </p>
      <button
        onClick={handleIncrement}
        className="justify-center items-center shadow-sm bg-gray-light self-stretch flex flex-col p-2 rounded-[40px]"
      >
        <SpriteIcon iconName="plus" className="w-6 h-6" />
      </button>
    </div>
  );
};
