import { useRemoveInvitedUser } from '@/hooks/useRemoveInvitedUser';
import { Button } from '../buttons/Button';
import { PopUpContainer } from '../containers/PopUpContainer';

type Props = {
  itineraryId?: number;
  isOpen: boolean;
  onClose: () => void;
  travelerToRemove: any;
};

export const RemoveInvitedUserBottomSheet = ({
  itineraryId,
  isOpen,
  onClose,
  travelerToRemove,
}: Props) => {
  const { removeInvitedUser, isLoading } = useRemoveInvitedUser(itineraryId);

  const onRemoveUserHandler = () => {
    if (travelerToRemove && travelerToRemove.id) {
      removeInvitedUser(travelerToRemove.id);
      onClose();
    }
  };

  return (
    <PopUpContainer snapPoints={[0.375]} onClose={onClose} isOpen={isOpen}>
      <div className="flex flex-col justify-between pb-4 h-full lg:pb-0">
        <div>
          <h5 className="text-h4 pb-2">Are you sure?</h5>
          <p className="text-p-medium">
            The user “{travelerToRemove?.email ?? travelerToRemove?.username}”
            will no longer have access to this page
          </p>
        </div>
        <div className="flex flex-col gap-2 lg:flex-row">
          <Button
            size="large"
            full={true}
            onClick={onRemoveUserHandler}
            disabled={isLoading}
          >
            Remove
          </Button>
          <Button
            size="large"
            variant="secondary"
            full={true}
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
        </div>
      </div>
    </PopUpContainer>
  );
};
