import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AddNewTripBottomSheet } from '@components/bottomSheets/AddNewTripBottomSheet';
import { SpriteIcon } from '@components/icons/SpriteIcon';
import { ItineraryList } from '@components/lists/ItineraryList';
import { ItineraryLoader } from '@/components/loaders/ItineraryLoader';
import {
  MyTripsHeader,
  ItineraryStatusTab,
} from '@/components/headers/MyTripsHeader';

import { useGetUsersTrips } from '@hooks/useGetUsersTrips';
import { useGenerateItinerary } from '@/hooks/useGenerateItinerary';
import { useSendInvites } from '@/hooks/useSendInvites';
import { useInvitationLink } from '@/hooks/useInvitationLink';
import { useUpdateItinerary } from '@/hooks/useUpdateItinerary';
import { useTailorItinerary } from '@/hooks/useTailorItinerary';

import { useInitialSearchStore } from '@/stores/initialSearchStore';
import { Container } from '@/components/containers/Container';

export const MyTripsPage = () => {
  const [isAddNewTripBottomSheetOpen, setAddNewTripBottomSheetOpen] =
    useState(false);
  const [chosenTab, setChosenTab] = useState<ItineraryStatusTab>('all');

  const navigate = useNavigate();
  const dateRange = useInitialSearchStore((state) => state.dateRange);

  const { generateItinerary, isPending } = useGenerateItinerary({});
  const { usersTrips, isUsersTripsLoading } = useGetUsersTrips(chosenTab);

  const handleTripCardClick = (itineraryId: number) =>
    navigate(`/itinerary/${itineraryId}`);

  const { itineraryId, invitedPeople, isWaitingForPeople } =
    useInitialSearchStore((state) => state);
  const setInitialSearch = useInitialSearchStore(
    (state) => state.setInitialSearch,
  );
  const { tailorItinerary, isPending: isTailoring } = useTailorItinerary({
    onClose: () => {
      navigateToItinerary(invitationRedirectLink);
    },
    itineraryId: itineraryId ?? undefined,
    toastMessage: 'Generated itinerary',
    isGAEventNeeded: false,
  });

  const { updateItinerary } = useUpdateItinerary();
  const { sendInvites } = useSendInvites(itineraryId ? itineraryId : undefined);
  const { invitationRedirectLink, navigateToItinerary } = useInvitationLink(
    itineraryId ?? undefined,
    '',
  );

  const handleGenerateItinerary = () => {
    if (itineraryId) {
      updateItinerary(itineraryId);
      sendInvites({ invitedUsers: invitedPeople ?? [] });
      setInitialSearch({
        itineraryId: null,
        invitedPeople: null,
        isWaitingForPeople: false,
      });

      if (isWaitingForPeople) {
        navigateToItinerary(invitationRedirectLink);
      } else {
        tailorItinerary([]);
      }
    } else {
      generateItinerary();
    }
  };

  const handleAddNewTripBottomSheet = () =>
    setAddNewTripBottomSheetOpen(!isAddNewTripBottomSheetOpen);

  if (isPending || isTailoring) {
    return <ItineraryLoader dateRange={dateRange} />;
  }

  const onTabChange = (tab: ItineraryStatusTab) => {
    setChosenTab(tab);
  };

  return (
    <div className="flex flex-col h-svh overflow-y-hidden">
      <MyTripsHeader selectedTab={chosenTab} onTabChange={onTabChange} />
      <Container styles="bg-gray-light overflow-auto" fullHeight>
        <div className="px-2 py-4 gap-4 overflow-y-auto flex-grow md:col-span-6 lg:col-span-4 min-w-[314px]">
          <div
            className="flex mb-4 h-[130px] border-dashed border-gray border-2 rounded-xl justify-center md:h-[124px] shrink-0"
            onClick={handleAddNewTripBottomSheet}
          >
            <div className="flex gap-1 justify-center items-center">
              <SpriteIcon iconName="plus" className="w-6 h-6" />
              <p className="text-p-bold">New trip</p>
            </div>
          </div>
          <ItineraryList
            itineraries={usersTrips}
            isLoading={isUsersTripsLoading}
            onCardClick={handleTripCardClick}
          />
        </div>
      </Container>
      <AddNewTripBottomSheet
        isOpen={isAddNewTripBottomSheetOpen}
        onClose={handleAddNewTripBottomSheet}
        generateItinerary={handleGenerateItinerary}
        isPending={isPending}
      />
    </div>
  );
};
