import { Button } from '@components/buttons/Button';
import { LargeIconButton } from '@components/buttons/LargeIconButton';
import { useInitialSearchStore } from '@stores/initialSearchStore';
import { getTotalPeopleCount } from '@utils/getTotalPeopleCount';
import _ from 'lodash';
import { useState } from 'react';

import { StartPageModalTypes } from '@/types/AppTypes';
import { parseDate } from '@/utils/formatDateTime';

import { TextButton } from '@components/buttons/TextButton';
import { PopUpContainer } from '@components/containers/PopUpContainer';
import { SetPeopleWindow } from './SetPeopleWindow';
import { Itinerary } from '@/generated';
import { useWindowSize } from '@/hooks/useWindowSize';
import { MenuItem } from '../menu/MenuItem';
import { SetDestinationWindow } from './SetDestinationWindow';
import { SetDatesWindow } from './SetDatesWindow';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDeleteTripClick?: () => void;
  onSaveClick?: () => void;
  itineraryData: Itinerary | undefined;
  menuCaller?: HTMLButtonElement | null;
  onEditPreferencesClick?: () => void;
  onEditTripInfoClick?: () => void;
  withMenu?: boolean;
  isAdmin?: boolean;
};

export const EditTripWindow = ({ withMenu = true, ...props }: Props) => {
  const { onSaveClick, onClose, onDeleteTripClick } = props;
  const { windowType } = useWindowSize();
  const [isMenu, setIsMenu] = useState(true);

  // TODO: don't use initialSearch store - replace with Itinerary store
  const initialSearch = useInitialSearchStore((state) => state);
  const { location, dateRange, people } = initialSearch;

  const [defaultItineraryValue] = useState(initialSearch);

  const isItineraryEdited = [
    !_.isEqual(location, defaultItineraryValue.location),
    !_.isEqual(people, defaultItineraryValue.people),
    !_.isEqual(dateRange, defaultItineraryValue.dateRange),
  ].some((value) => value);

  const [isPersonsModalOpen, setIsPersonsModalOpen] = useState(false);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isDatesModalOpen, setIsDatesModalOpen] = useState(false);

  const handleModalToggle = (type: StartPageModalTypes) => {
    if (type === 'PEOPLE') {
      setIsPersonsModalOpen(!isPersonsModalOpen);
    } else if (type === 'LOCATION') {
      setIsLocationModalOpen(!isLocationModalOpen);
    } else if (type === 'DATES') {
      setIsDatesModalOpen(!isDatesModalOpen);
    } else {
      setIsPersonsModalOpen(false);
      setIsLocationModalOpen(false);
      setIsDatesModalOpen(false);
    }
  };

  const handleEditItinerary = () => {
    setIsMenu(false);
  };

  const handleEditPreferences = () => {
    props.onClose();
    props.onEditPreferencesClick ? props.onEditPreferencesClick() : null;
  };

  const handleExit = () => {
    setIsMenu(true);
    handleModalToggle('NONE');
    props.onClose();
  };

  return (
    <>
      {!isLocationModalOpen && !isDatesModalOpen && !isPersonsModalOpen && (
        <PopUpContainer
          {...props}
          onClose={handleExit}
          isPadded={false}
          isMenu={isMenu && withMenu}
          isWhite={false}
        >
          {windowType === 'lg' && isMenu && withMenu ? (
            <>
              <MenuItem
                iconName="edit"
                text="Edit trip info"
                isRounded
                onClick={handleEditItinerary}
              />
              <div className="border-t border-gray-light" />
              <MenuItem
                iconName="stars"
                text="Edit preferences"
                isRounded
                onClick={handleEditPreferences}
              />
            </>
          ) : (
            <div className="flex flex-col px-4 justify-center gap-4 w-full h-full">
              <h4 className="text-h4">Edit trip</h4>
              <div className="flex flex-col gap-3 items-center h-full">
                <LargeIconButton
                  onClick={() => handleModalToggle('LOCATION')}
                  leftIcon="map"
                  rightIcon="pen"
                  styles="bg-white"
                  filled
                >
                  {location?.name ?? 'Where are you going?'}
                </LargeIconButton>
                <LargeIconButton
                  onClick={() => handleModalToggle('DATES')}
                  leftIcon="calendar"
                  rightIcon="pen"
                  styles="bg-white"
                  filled
                >
                  {parseDate({
                    startDate: dateRange?.from,
                    endDate: dateRange?.to,
                  }) ?? 'When?'}
                </LargeIconButton>
                <LargeIconButton
                  onClick={() => handleModalToggle('PEOPLE')}
                  leftIcon="user"
                  rightIcon="pen"
                  styles="bg-white"
                  filled
                >
                  {getTotalPeopleCount(people)
                    ? getTotalPeopleCount(people) == 1
                      ? '1 traveler'
                      : `${getTotalPeopleCount(people)} travelers`
                    : 'With whom?'}
                </LargeIconButton>
                {props.isAdmin && (
                  <>
                    {onDeleteTripClick ? (
                      <div className="flex w-full items-center justify-center mt-5">
                        <TextButton
                          color="red"
                          onClick={() => {
                            onClose();
                            onDeleteTripClick();
                          }}
                        >
                          Delete trip
                        </TextButton>
                      </div>
                    ) : null}
                    <div className="w-full p-4 mt-auto bottom-0 left-0">
                      {onSaveClick && (
                        <Button
                          size="large"
                          onClick={onSaveClick}
                          full
                          disabled={!isItineraryEdited}
                        >
                          Save changes
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </PopUpContainer>
      )}
      <SetDestinationWindow
        isOpen={isLocationModalOpen}
        onClose={() => handleModalToggle('LOCATION')}
      />
      <SetDatesWindow
        isOpen={isDatesModalOpen}
        onClose={() => handleModalToggle('DATES')}
        initialSearchDateRange={initialSearch.dateRange}
        setInitialSearch={initialSearch.setInitialSearch}
      />
      <SetPeopleWindow
        isOpen={isPersonsModalOpen}
        onClose={() => handleModalToggle('PEOPLE')}
        persons={people}
        itineraryData={props.itineraryData}
        setData={(newData) => {
          initialSearch.setInitialSearch({ people: newData });
        }}
        clearData={() => {
          initialSearch.setInitialSearch({
            location: null,
            dateRange: null,
            people: null,
          });
        }}
      />
    </>
  );
};
