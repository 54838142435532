import 'dayjs/locale/en';

import { IconButton } from '@components/buttons/IconButton';
import { SpriteIcon } from '@components/icons/SpriteIcon';
import { TimeSlot } from '@components/timeSlots/TimeSlot';
import { formatDateTime } from '@utils/formatDateTime.ts';

import { Experience } from '@/generated';
import { usePinExperience } from '@/hooks/usePinExperience';
import { forwardRef } from 'react';
import { ImageContainer } from '../containers/ImageContainer';
import { useRetrieveImage } from '@/hooks/useRetrieveImage';

type Props = {
  experience: Experience;
  onEditClick: () => void;
  isLoadingDetail?: boolean;
  isAdmin?: boolean;
  timeSlotRef?: React.MutableRefObject<HTMLDivElement | null>;
};

export const Card = forwardRef<HTMLButtonElement, Props>(
  (
    { experience, onEditClick, isLoadingDetail, isAdmin = true, timeSlotRef },
    ref,
  ) => {
    const { pinUnpinExperience } = usePinExperience(experience);
    const { name, start_time, end_time, pinned, date, details } = experience;

    return (
      <div className="items-start shadow-general flex w-full h-[223px] flex-col rounded-3xl">
        <div className="flex-col overflow-hidden self-stretch relative flex aspect-[2.5] w-full pl-3 pr-2 pt-3 pb-24 rounded-t-3xl">
          <ImageContainer
            image={useRetrieveImage({
              experienceId: experience.id,
              image: details?.place_photo,
            })}
            alt={name ?? 'travel experience'}
            displayImage={!isLoadingDetail}
          />
          <div className="relative self-stretch flex w-full items-start justify-between gap-5">
            <TimeSlot
              time={`${formatDateTime(date, start_time)} - ${formatDateTime(
                date,
                end_time,
              )}`}
              pinned={pinned}
              pinClick={pinUnpinExperience}
              hasIcon
              ref={timeSlotRef}
            />
            {isAdmin && (
              <IconButton
                onClick={onEditClick}
                iconName="edit"
                size="medium"
                ref={ref}
              />
            )}
          </div>
        </div>
        <div className="rounded-b-3xl bg-white self-stretch flex w-full grow flex-col p-4">
          <div className="items-start self-stretch flex flex-col">
            <p className="text-h6 text-black">{details?.place_name}</p>
            <div className="flex w-full gap-2 mt-1 self-start">
              {isLoadingDetail && (
                <div className="animate-pulse flex gap-x-4 w-full">
                  <div className="h-6 w-12 bg-gray-medium rounded" />
                  <div className="h-6 w-full bg-gray-medium rounded" />
                </div>
              )}
              {!isLoadingDetail && details && (
                <div className="items-start self-stretch flex justify-between gap-1">
                  <SpriteIcon iconName="star" className="w-5 h-5" />
                  <div className="items-start self-stretch flex justify-between gap-2">
                    <div className="text-p text-black">{details.rating}</div>
                    <div className="text-gray text-p whitespace-nowrap">
                      {details.user_ratings_total}&nbsp;
                      {details.user_ratings_total === 1 ? 'review' : 'reviews'}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  },
);
