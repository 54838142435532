import { LatLng } from 'use-places-autocomplete';

export const getGoogleMapsCircleBounds = (
  center: LatLng | null | undefined,
  radiusInMeters: number,
) =>
  new google.maps.Circle({
    center: center,
    radius: radiusInMeters,
  });
