import { State, useInitialSearchStore } from '@/stores/initialSearchStore';
import { getGoogleMapsCircleBounds } from '@/utils/getGoogleMapsCircleBounds';
import {
  DEBOUNCE_TIME_MS,
  GOOGLE_MAPS_TAILORING_BOUNDARY_RADIUS,
} from '@utils/constants';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import { TailoredSearchTypes } from '@/stores/tailorSearchStore';

type Params = {
  setTailorValue?: (value: Partial<TailoredSearchTypes>) => void;
  setInitialValue?: (value: Partial<State>) => void;
};

export const useGoogleLocation = ({ setTailorValue, setInitialValue }: Params) => {
  const initialSearchLocation = useInitialSearchStore(
    (state) => state.location,
  );

  const locationBounds = getGoogleMapsCircleBounds(
    initialSearchLocation?.coords,
    GOOGLE_MAPS_TAILORING_BOUNDARY_RADIUS,
  );

  const {
    value: searchValue,
    suggestions,
    setValue: setSearchValue,
  } = usePlacesAutocomplete({
    callbackName: 'initMap',
    defaultValue: initialSearchLocation?.name,
    requestOptions: {
      language: 'en',
      types: ['lodging', 'campground'],
      locationBias: locationBounds.getBounds(),
    },
    debounce: DEBOUNCE_TIME_MS,
  });

  const handleSetSearch = (data: any) => {
    if (setTailorValue) setTailorValue({ ...data });
    if (setInitialValue) setInitialValue({ location: data.accommodation });
  };

  const handleSelectClick = (
    value: google.maps.places.AutocompletePrediction,
  ) => {
    const accommodation = {
      name: value.structured_formatting.main_text,
      address: value.structured_formatting.secondary_text,
      coordinates: { lat: 0, lng: 0 },
    };

    getGeocode({ placeId: value.place_id })
      .then((results) => {
        const { lat, lng } = results[0].geometry.location;
        accommodation.coordinates = { lat: lat(), lng: lng() };
        handleSetSearch({ accommodation });
      })
      .catch((error) => {
        console.log(`Error: ${error}`);
        handleSetSearch({ accommodation });
      });

    setSearchValue(value.description, false);
    suggestions.data = [];
  };

  return {
    searchValue,
    setSearchValue,
    suggestions,
    handleSelectClick,
  };
};
