import { DayButton } from '@components/buttons/DayButton';
import { FilterItemButton } from '@components/buttons/FilterItemButton';
import { CardOnMap } from '@components/cards/CardOnMap';
import { ItineraryItemCard } from '@components/cards/ItineraryItemCard';
import { MapItineraryIcon } from '@components/icons/MapItineraryIcon';
import { MapPinIcon } from '@components/icons/MapPinIcon';
import { PathLayer } from '@deck.gl/layers/typed';
import { useGetCategories } from '@hooks/useGetCategories';
import { convertToGeoPath } from '@utils/convertToGeoPath';
import { mapCategoryToIcon } from '@utils/mapExperienceCatetegoryToIcon';
import { AdvancedMarker, APIProvider, Map } from '@vis.gl/react-google-maps';
import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Commute, Experience } from '@/generated';
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter';
import { getExperiencesBySelectedDate } from '@/utils/experiencesHelperFunctions';
import { getUniqueDatesFromExperiences } from '@/utils/getUniqueArray';

import { IconButton } from '../buttons/IconButton';
import { ExperienceFilterList } from '../lists/ExperienceFilterList';
import { DeckGlOverlay } from './DeckGlOverlay';

export type MapFiltersType = 'date' | 'categories';

type Props = {
  experiences: Experience[];
  commutes: Commute[];
  filtersType?: MapFiltersType;
  chosenFilters?: string[];
  onFilterClick?: (value: string) => void;
  handleToggleFilterSheet?: () => void;
  selectedDate: string | null | undefined;
  setSelectedDate?: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >;
  rounded?: boolean;
};

type PolylineRoute = {
  id: number | undefined;
  originId: number;
  destinationId: number;
  path: number[][];
  color: number[];
};

export const ExperienceMap = ({
  experiences,
  commutes,
  filtersType = 'date',
  chosenFilters,
  onFilterClick,
  handleToggleFilterSheet,
  selectedDate,
  setSelectedDate,
  rounded = true,
}: Props) => {
  const [selectedExperienceId, setSelectedExperienceId] = useState<
    number | null
  >(null);

  const itineraryItemsRef = useRef<HTMLDivElement | null>(null);

  const filteredExperiences = experiences.filter(
    ({ details }) => details !== null,
  );

  const dateExperiences = getExperiencesBySelectedDate(
    filteredExperiences,
    selectedDate,
  );

  const { categories } = useGetCategories(filteredExperiences);

  const polylines: PolylineRoute[] = commutes.map(
    ({ id, origin_id, destination_id, polyline }) => {
      const geoPath = convertToGeoPath(polyline);
      return {
        id: id,
        originId: origin_id,
        destinationId: destination_id,
        path: geoPath,
        color: [13, 25, 34],
      };
    },
  );

  const datePolylines = polylines.filter(
    (route) =>
      dateExperiences?.find(
        (exp) => exp.id === route.originId || exp.id === route.destinationId,
      ) !== undefined,
  );

  const pathlayer = useMemo(
    () =>
      new PathLayer({
        id: 'path-layer',
        data: selectedDate ? datePolylines : [],
        pickable: true,
        widthScale: 10,
        widthMinPixels: 4,
        widthMaxPixels: 20,
        jointRounded: true,
        getPath: (d) => d.path,
        getColor: (d) => d.color,
        getWidth: () => 1,
      }),
    [datePolylines],
  );

  const mapCenter =
    dateExperiences?.find(({ details }) => details?.coordinates)?.details
      ?.coordinates || null;

  const handleMarkerClick = (experience: Experience) => {
    setSelectedExperienceId(experience.id ?? 0);
    const selectedItemRef = document.getElementById(
      experience.id?.toString() ?? '0',
    );
    if (itineraryItemsRef.current && selectedItemRef) {
      itineraryItemsRef.current.scrollTo({
        left: selectedItemRef.offsetLeft,
        behavior: 'smooth',
      });
    }
  };

  const selectedExperience = experiences.find(
    (exp) => exp.id === selectedExperienceId,
  );

  const filteredExperiencesByCategory = useMemo(
    () =>
      chosenFilters?.length !== 0
        ? filteredExperiences.filter((experience) =>
            experience.category?.some(({ name }) =>
              chosenFilters?.includes(capitalizeFirstLetter(name)),
            ),
          )
        : filteredExperiences,
    [chosenFilters, filteredExperiences],
  );

  const finalExperiences =
    filtersType === 'date' ? dateExperiences : filteredExperiencesByCategory;

  const handleFilterIconClick = () => {
    if (handleToggleFilterSheet) {
      handleToggleFilterSheet();
    }
  };

  const handleOnFilterClick = (value: string) => {
    if (onFilterClick) {
      onFilterClick(value);
    }
  };

  const roundedStyles: React.CSSProperties = rounded
    ? {
        borderTopLeftRadius: '24px',
        borderTopRightRadius: '24px',
      }
    : {};

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={classNames(
        'flex flex-col bg-white w-full h-full shadow-xl gap-4 relative',
        {
          'rounded-b-3xl': rounded,
          'rounded-none': !rounded,
        },
      )}
    >
      <div className="sticky top-0 z-20">
        <div className="w-full overflow-x-auto flex pt-5 pl-2 gap-x-2 scrollbar-hide lg:hidden">
          {filtersType === 'categories' && (
            <>
              <IconButton
                iconName="filter"
                background="gray-light"
                onClick={handleFilterIconClick}
              />
              <ExperienceFilterList
                filters={categories}
                chosenFilters={chosenFilters ?? []}
                onFilterClick={handleOnFilterClick}
              />
            </>
          )}
          {filtersType === 'date' && (
            <>
              <FilterItemButton
                onClick={() => (setSelectedDate ? setSelectedDate(null) : null)}
                selected={!selectedDate}
              >
                All experiences
              </FilterItemButton>
              {getUniqueDatesFromExperiences(
                experiences.filter((experience) => experience?.date),
              ).map((date) => (
                <DayButton
                  key={date}
                  onClick={() =>
                    setSelectedDate ? setSelectedDate(date as string) : null
                  }
                  selected={selectedDate === date}
                  date={date ?? ''}
                />
              ))}
            </>
          )}
        </div>
      </div>
      <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY}>
        <Map
          mapId={import.meta.env.VITE_GOOGLE_MAP_KEY}
          zoom={10}
          center={mapCenter}
          mapTypeControl={false}
          zoomControl={false}
          streetViewControl={false}
          fullscreenControl={false}
          style={{
            position: 'absolute',
            ...roundedStyles,
          }}
          onClick={() => setSelectedExperienceId(null)}
        >
          {finalExperiences?.map((experience, index) => (
            <AdvancedMarker
              key={experience.id}
              position={experience.details?.coordinates}
              onClick={() => handleMarkerClick(experience)}
              className={selectedExperience === experience ? 'z-10' : 'z-20'}
            >
              {!selectedDate && (
                <MapPinIcon
                  iconName={mapCategoryToIcon(experience?.category)}
                  isActive={
                    !selectedExperience || selectedExperience === experience
                  }
                  isSelected={selectedExperience === experience}
                  experience={experience}
                />
              )}
              {selectedDate && (
                <MapItineraryIcon
                  iconName={mapCategoryToIcon(experience?.category)}
                  isActive={selectedExperience === experience}
                  sequenceNumber={index + 1}
                />
              )}
            </AdvancedMarker>
          ))}
          <DeckGlOverlay layers={[pathlayer]} />
        </Map>
      </APIProvider>
      <div
        className={classNames('fixed bottom-0 z-10 w-full lg:hidden', {
          'px-2 pb-4': !selectedDate && selectedExperienceId,
        })}
      >
        {selectedDate && (
          <div
            ref={itineraryItemsRef}
            className="overflow-x-auto flex pb-4 scrollbar-hide touch-pan-x cursor-grab snap-x scroll-smooth lg:hidden"
          >
            {finalExperiences?.map((experience) => (
              <ItineraryItemCard
                key={experience.id ?? uuidv4()}
                experience={experience}
              />
            ))}
          </div>
        )}
        {!selectedDate && selectedExperience && (
          <CardOnMap
            name={selectedExperience.name}
            details={selectedExperience.details}
            styles="md:hidden"
          />
        )}
      </div>
    </div>
  );
};
