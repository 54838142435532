import { useWindowSize } from '@/hooks/useWindowSize';
import { BottomSheet } from '../bottomSheets/BottomSheet';
import { ModalWindow } from '../modals/ModalWindow';
import { PopUpMenu } from '../menu/PopUpMenu';
import { useGetElementPosition } from '@/hooks/useGetElementPosition';

type Props = {
  isOpen: boolean;
  snapPoints?: number[];
  isPadded?: boolean;
  onClose: () => void;
  overrideZIndex?: boolean;
  children: React.ReactNode;
  isContentDragDisabled?: boolean;
  isWhite?: boolean;
  isMenu?: boolean;
  menuCaller?: HTMLButtonElement | null;
  menuChildren?: React.ReactNode;
};

export const PopUpContainer = ({
  children,
  isMenu,
  menuCaller,
  menuChildren,
  ...props
}: Props) => {
  const { windowType } = useWindowSize();
  const { pos } = useGetElementPosition(menuCaller ?? null);

  if (windowType === 'lg' && !isMenu) {
    return (
      <ModalWindow {...props} white={props.isWhite}>
        {children}
      </ModalWindow>
    );
  } else if (windowType === 'lg' && isMenu) {
    return (
      <PopUpMenu
        {...props}
        styles={
          pos.top
            ? {
                top: pos.top + pos.height + 10,
                left: pos.left - pos.width * 2,
              }
            : {}
        }
      >
        {menuChildren ?? children}
      </PopUpMenu>
    );
  } else {
    return (
      <BottomSheet bgWhite={props.isWhite} {...props}>
        {children}
      </BottomSheet>
    );
  }
};
