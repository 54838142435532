import { Button } from '@components/buttons/Button';
import { OverlayBase } from '@components/modals/OverlayBase';
import {
  MenuItemProps,
  ModalOption,
} from '@components/modalsSections/modalOption/ModalOption';
import { motion } from 'framer-motion';

type Props = {
  items: MenuItemProps[];
  isLoading?: boolean;
  onOverlayClick: () => void;
};

export const OptionModalBase = ({
  isLoading,
  items,
  onOverlayClick,
}: Props) => {
  const variants = {
    hidden: { y: '100vh' },
    visible: {
      y: '0',
      transition: { type: 'tween', stiffness: 60, damping: 10 },
    },
    exit: { y: '-100vh', transition: { ease: 'easeInOut' } },
  };

  return (
    <OverlayBase onOverlayClick={onOverlayClick}>
      <motion.div
        className="mt-auto p-4"
        variants={variants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="bg-white w-full rounded-xl mb-3">
          {items.map((item, index) => (
            <ModalOption
              key={item.label}
              hasBorder={index !== items.length - 1}
              {...item}
            />
          ))}
        </div>
        <Button
          size="large"
          full
          variant="quaternary"
          onClick={() => {
            onOverlayClick;
          }}
          roundnessVariant="xl"
          bold={false}
          isLoading={isLoading}
        >
          Cancel
        </Button>
      </motion.div>
    </OverlayBase>
  );
};
