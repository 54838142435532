import { getFormattedDateTime } from '@utils/formatDateTime.ts';
import classNames from 'classnames';

import { TIME_HOUR_MINUTE_WITHOUT_SECONDS_FORMAT } from '@/utils/constants';

type Props = {
  title: string;
  isSelected: boolean;
  onClick: () => void;
};

export const TimeSlotButton = ({ title, isSelected, onClick }: Props) => {
  const time = getFormattedDateTime({
    dateTime: title,
    inputFormat: TIME_HOUR_MINUTE_WITHOUT_SECONDS_FORMAT,
  });

  return (
    <button
      className={classNames('h-12 w-full rounded-xl shrink-0', {
        'text-white': isSelected,
        'bg-black': isSelected,
        'text-black': !isSelected,
        'bg-gray-light': !isSelected,
      })}
      onClick={onClick}
    >
      {time}
    </button>
  );
};
