import { SpriteIcon, SpriteIconProps } from '@components/icons/SpriteIcon';
import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';

type Props = {
  children: ReactNode;
  padding?: string;
  icon?: SpriteIconProps;
  color?: string;
  isLoading?: boolean;
  disabled?: boolean;
  smallText?: boolean;
  styles?: string;
  onClick: () => void;
};

export const TextButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      onClick,
      children,
      icon,
      disabled,
      isLoading,
      smallText,
      padding,
      styles,
      color = 'purple',
    },
    ref,
  ) => {
    return (
      <button
        type="button"
        disabled={disabled || isLoading}
        onClick={onClick}
        className={classNames(
          `text-p-bold hover:text-purple-dark flex ${styles}`,
          {
            [`px-${padding}`]: padding,
            ['text-gray']: disabled,
            [`text-${color}`]: color && !disabled,
            ['text-p-small']: smallText,
          },
        )}
        ref={ref}
      >
        {isLoading ? 'Loading...' : children}
        {icon && (
          <SpriteIcon
            className={classNames('pl-1 w-5 h-5', {
              [`text-${color}`]: color,
            })}
            iconName={icon}
          />
        )}
      </button>
    );
  },
);
