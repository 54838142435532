import { Button } from '@components/buttons/Button';
import { DayButton } from '@components/buttons/DayButton';
import { TimeSlotButton } from '@components/buttons/TimeSlotButton';
import { EmptyListMessage } from '@components/texts/EmptyListMessage';

import { FreeSlot, FreeSlotsByDate } from '@/generated';
import { Loader } from '@/components/loaders/Loader';

type Props = {
  timeSlots: FreeSlotsByDate[] | undefined;
  isLoading?: boolean;
  selectDay: string;
  selectedTimeSlot: FreeSlot;
  isDisabled?: boolean;
  timeslotsForSelectedDay: FreeSlotsByDate | undefined;
  onButtonClick: () => void;
  setSelectDay: (value: string) => void;
  setSelectedTimeSlot: (timeSlot: FreeSlot) => void;
};

export const StartTimeSlots = ({
  timeSlots,
  isLoading,
  selectDay,
  selectedTimeSlot,
  timeslotsForSelectedDay,
  isDisabled = false,
  setSelectDay,
  setSelectedTimeSlot,
  onButtonClick,
}: Props) => {
  return (
    <>
      <div className="flex flex-col h-full">
        <div className="px-4">
          <h4 className="text-h4 text-black mb-1">Select a time slot</h4>
          <p className="text-p text-gray mb-6">
            Choose when you want to start your experience based on your
            available time slots.
          </p>
          {isLoading ? (
            <div className="flex justify-center">
              <Loader size="large" />
            </div>
          ) : (
            <div className="flex gap-2 overflow-auto mb-5 pb-3">
              {timeSlots?.map((timeslot: FreeSlotsByDate) => (
                <DayButton
                  key={timeslot.date}
                  date={timeslot.date}
                  selected={selectDay.includes(timeslot.date)}
                  onClick={() => setSelectDay(timeslot.date)}
                />
              ))}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-3 overflow-auto max-h-[300px] px-4">
          {!timeslotsForSelectedDay && !isLoading && (
            <EmptyListMessage message="No free time slot available" />
          )}
          {timeslotsForSelectedDay?.slots?.map((timeSlot: FreeSlot) => (
            <TimeSlotButton
              key={timeSlot.start_time}
              title={timeSlot.start_time}
              isSelected={selectedTimeSlot.start_time === timeSlot.start_time}
              onClick={() =>
                setSelectedTimeSlot({
                  start_time: timeSlot.start_time,
                  end_times: timeSlot.end_times,
                })
              }
            />
          ))}
        </div>
        <div className="flex mt-auto items-center justify-center p-4 shadow-general">
          <Button
            size="large"
            full
            onClick={onButtonClick}
            disabled={isDisabled}
          >
            Select end time
          </Button>
        </div>
      </div>
    </>
  );
};
