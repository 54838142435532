import { Experience } from '@/generated';

export const getExperiencesBySelectedDate = (
  experiences: Experience[] | undefined,
  selectedDate: string | null | undefined,
) => {
  if (experiences && selectedDate) {
    return experiences.filter(({ date }) => date === selectedDate);
  }
  return experiences;
};

export const getMergedExperiences = ({
  experiences,
  experienceDetails,
  isDevEnv,
}: {
  experiences: Experience[] | undefined;
  experienceDetails: Experience[];
  // TODO: temprary prop to show different content on the dev environment
  isDevEnv?: boolean;
}) => {
  if (areAllExperiencesWithDetails(experiences ?? []) || isDevEnv) {
    return experiences;
  }

  const updatedExperiences = experiences?.map((exp) => {
    const expDetail = experienceDetails.find((detail) => detail?.id === exp.id);
    return expDetail ?? exp;
  });
  return updatedExperiences;
};

export const areAllExperiencesWithDetails = (experiences: Experience[]) => {
  if (Array.isArray(experiences) && experiences.length < 1) {
    return false;
  }

  return experiences.every(
    ({ details }) => details && Object.keys(details).length > 0,
  );
};
