import { useApi } from '@/api/serviceProvider';
import { Itinerary } from '@/generated';
import { useInitialSearchStore } from '@/stores/initialSearchStore';
import { MISSING_INPUT_VALUES_MESSAGE } from '@/utils/constants';
import { getFormattedDate } from '@/utils/formatDateTime';
import { showToast } from '@/utils/showToast';
import { useMutation } from '@tanstack/react-query';

export const useUpdateItinerary = () => {
  const { api } = useApi();
  const { location, people, dateRange } = useInitialSearchStore(
    (state) => state,
  );

  const isValidToGenerate =
    !location || !dateRange || !people || !dateRange.from || !dateRange.to;

  const { mutate, data, isPending, isSuccess } = useMutation({
    mutationFn: async (itineraryId?: number) => {
      if (!itineraryId) {
        return Promise.reject('Itinerary ID is missing');
      }

      if (isValidToGenerate) {
        return Promise.reject(MISSING_INPUT_VALUES_MESSAGE);
      }

      return await api.apiV1ItinerariesUpdate(itineraryId, {
        persons: {
          adults: people.adults,
          children: people.children,
          infants: people.infants,
        },
        city: location.name,
        start_date: getFormattedDate({
          date: dateRange.from,
          format: 'YYYY-MM-DD',
        }),
        end_date: getFormattedDate({
          date: dateRange.to,
          format: 'YYYY-MM-DD',
        }),
      } as Itinerary);
    },
    onSuccess: (data) => {
      console.log('Itinerary updated', data);
    },
    onError: (error) => {
      showToast(error.message, 'dark');
    },
  });

  return {
    updateItinerary: mutate,
    isLoading: isPending,
    isSuccess,
    data,
  };
};
