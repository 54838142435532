import { Key } from 'react';

import { Button } from '@/components/buttons/Button';
import { IconButton } from '@/components/buttons/IconButton';
import { TimeSlotButton } from '@/components/buttons/TimeSlotButton';
import { FreeSlot } from '@/generated';

type Props = {
  timeSlots: string[];
  selectedTimeSlot: FreeSlot;
  isDisabled?: boolean;
  onButtonClick: () => void;
  setSelectedTimeSlot: React.Dispatch<React.SetStateAction<FreeSlot>>;
  handleBackClick: () => void;
};

export const EndTimeSlots = ({
  timeSlots,
  isDisabled = false,
  selectedTimeSlot,
  handleBackClick,
  setSelectedTimeSlot,
  onButtonClick,
}: Props) => {
  return (
    <div className="flex flex-col h-full">
      <div className="px-4">
        <h4 className="text-h4 text-black mb-1">Select a time slot</h4>
        <p className="text-p text-gray mb-6">
          Choose when you want to end your experience based on your available
          time slots.
        </p>
        <IconButton
          iconName="arrowLeft"
          size="large"
          onClick={handleBackClick}
        />
      </div>
      <div className="flex flex-col gap-3 overflow-auto max-h-[300px] px-4">
        {timeSlots?.map((endTime, index: Key) => (
          <TimeSlotButton
            key={endTime}
            title={endTime}
            isSelected={
              timeSlots.indexOf(selectedTimeSlot?.end_times[0]) === index
            }
            onClick={() =>
              setSelectedTimeSlot((previousTimeSlot) => ({
                ...previousTimeSlot,
                end_times: [endTime],
              }))
            }
          />
        ))}
      </div>
      <div className="flex mt-auto items-center justify-center p-4 shadow-general">
        <Button size="large" full onClick={onButtonClick} disabled={isDisabled}>
          Add to my trip
        </Button>
      </div>
    </div>
  );
};
