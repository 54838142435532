import { queryKeyTypes } from '@api/queryKeyTypes';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useApi } from '@/api/serviceProvider';
import { Experience } from '@/generated';
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter';

export const useGetCategories = (experiences: Experience[] | undefined) => {
  const { api } = useApi();

  const { data, isLoading } = useQuery({
    queryFn: async () => api.apiV1ExperienceCategoriesList(),
    queryKey: [queryKeyTypes.experienceCategories],
  });

  const checkCategoryExistenceInExperiences = (
    categoryName: string,
    experiences: Experience[] | undefined,
  ) =>
    experiences?.some(({ category }) =>
      category?.some(
        (category) => category.name === categoryName.toLowerCase(),
      ),
    );

  const availableCategories = useMemo(
    () =>
      data?.filter(({ name }) =>
        checkCategoryExistenceInExperiences(name, experiences),
      ),
    [data, experiences],
  );

  const avalibaleCategoryNames = availableCategories?.map(({ name }) =>
    capitalizeFirstLetter(name),
  );

  const filteredCategoryNames = avalibaleCategoryNames?.filter(
    (filter) => !filter.includes('_'),
  );

  return {
    categories: filteredCategoryNames ?? [],
    isCategoriesLoading: isLoading,
  };
};
