import Cookies from 'js-cookie';
import { Outlet, useNavigate } from 'react-router-dom';
import { routes } from './routes';
import { useEffect } from 'react';

export const AuthRedirect = () => {
  const navigateTo = useNavigate();
  const authCookie = Cookies.get('tp_access_token');

  useEffect(() => {
    if (authCookie) {
      navigateTo(routes.myTrips, { replace: true });
    }
  }, [authCookie]);

  return <Outlet />;
};
