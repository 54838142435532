import { useUpdateExperience } from '@hooks/useUpdateExperience';
import { useEffect } from 'react';

import { Experience } from '@/generated';
import { useAddExperienceStore } from '@/stores/addExperienceStore';

import { TimeSlotBottomSheet } from '@components/bottomSheets/timeSlotBottomSheet/TimeSlotBottomSheet';

type Props = {
  experience: Experience | null;
  isOpen: boolean;
  onClose: () => void;
};

export type ExperienceDateTimeSchemaType = {
  date: string;
  toTime: string;
  fromTime: string;
};

export const ExperienceChangeDateTimeBottomSheet = ({
  experience,
  ...rest
}: Props) => {
  const { setChosenExperience } = useAddExperienceStore();
  useEffect(() => {
    if (experience) {
      setChosenExperience(experience);
    }
  }, [experience]);

  const { handleUpdateExperience } = useUpdateExperience(
    experience?.id,
    rest.onClose,
  );

  const handleSubmitUpdateExperience = (data: ExperienceDateTimeSchemaType) => {
    // FIXME: Strange behavior, the time is reversed
    const fromTime = new Date(`${data.date}T${data.fromTime}Z`);
    const toTime = new Date(`${data.date}T${data.toTime}Z`);
    const start = fromTime < toTime ? data.fromTime : data.toTime;
    const end = fromTime > toTime ? data.fromTime : data.toTime;
    const updatedExperience = {
      type: experience?.type.toLowerCase(),
      date: data.date,
      start_time: start,
      end_time: end,
    };

    handleUpdateExperience(updatedExperience as Experience);
  };

  return (
    <TimeSlotBottomSheet
      itineraryId={experience?.itinerary || undefined}
      isUpdate={true}
      handleUpdateExperience={handleSubmitUpdateExperience}
      isOpen={rest.isOpen}
      onClose={rest.onClose}
    />
  );
};
