import { AmountButton } from '@components/buttons/AmountButton';
import { SpriteIcon, SpriteIconProps } from '@components/icons/SpriteIcon';
import {
  InitialSearchStoreType,
  useInitialSearchStore,
} from '@stores/initialSearchStore';

import { Persons } from '@/generated';
import { IconButton } from '@/components/buttons/IconButton';
import classNames from 'classnames';

type Props = {
  iconName: SpriteIconProps;
  label: 'Adult' | 'Children' | 'Infant';
  ageRange: string;
  category: keyof Persons;
  onChange: (peopleUpdate: Partial<InitialSearchStoreType['people']>) => void;
  value?: number;
  displayInviteBtn?: boolean;
  onInviteClick?: () => void;
  withoutShadow?: boolean;
};

export const PeopleAmountCategory = ({
  iconName,
  label,
  ageRange,
  category,
  onChange,
  value,
  displayInviteBtn = false,
  onInviteClick,
  withoutShadow = false,
}: Props) => {
  const invitedPeopleAmount = useInitialSearchStore(
    (state) => state.invitedPeople?.length,
  );

  return (
    <div className="flex flex-col">
      <div
        className={classNames('flex p-4 justify-between bg-white', {
          'shadow-general': !withoutShadow,
          'shadow-none': withoutShadow,
          'rounded-3xl': !displayInviteBtn,
          'rounded-t-3xl': displayInviteBtn,
        })}
      >
        <div className="flex gap-3 items-start">
          <div className="rounded-xl p-2 bg-gray-light">
            <SpriteIcon iconName={iconName} className="fill-black w-6 h-6" />
          </div>
          <div className="flex flex-col">
            <p className="text-p text-black">{label}</p>
            <p className="text-p-medium text-gray">{ageRange}</p>
          </div>
        </div>
        <AmountButton
          onChange={(value) => onChange({ [category]: value })}
          value={value}
        />
      </div>
      {displayInviteBtn && (
        <div
          className={classNames(
            'flex items-center p-4 pt-[10px] rounded-b-3xl bg-white border-t-2 border-t-gray-light border-t-solid',
            {
              'shadow-general': !withoutShadow,
              'shadow-none': withoutShadow,
            },
          )}
          onClick={onInviteClick}
        >
          <IconButton
            size="large"
            onClick={() => {}}
            iconName="plus"
            className={`w-[24px] h-[24px] fill-purple ${
              invitedPeopleAmount ? 'hidden' : ''
            }`}
          />
          <p className="flex p-web text-purple ml-1 items-center">
            {invitedPeopleAmount ? (
              <>
                Invited people
                <span className="dot mx-1">•</span>
                {invitedPeopleAmount}
              </>
            ) : (
              'Invite people'
            )}
          </p>
        </div>
      )}
    </div>
  );
};
