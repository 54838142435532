import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useGetItinerariesFreeSlots = (
  itineraryId: number | undefined,
  experienceId: number | undefined,
) => {
  const apiFactory = useApi();

  const { data: timeSlots, isLoading } = useQuery({
    queryKey: [queryKeyTypes.freeTimeSlots, itineraryId, experienceId],
    queryFn: () => {
      if (!itineraryId) {
        return [];
      }
      return apiFactory.api.apiV1ItinerariesFreeSlots(
        itineraryId,
        experienceId?.toString(),
      );
    },
    enabled: !!itineraryId && !!experienceId,
  });

  const { data, mutate } = useMutation({
    mutationKey: ['openResource'],
    mutationFn: () =>
      apiFactory.api.apiV1ItinerariesFreeSlots(
        itineraryId!,
        experienceId?.toString(),
      ),
  });

  return {
    timeSlots: timeSlots || data,
    mutation: mutate,
    isLoading,
  };
};
