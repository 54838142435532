import * as yup from 'yup';

import { getTwentyFourHourDayjs } from './formatDateTime';
import { getPasswordCharacterValidationError } from './validationErrors';

export const emailValidation = yup
  .string()
  .email('Invalid email address. Maybe you forgot to add "@"')
  // Validates email addresses with alphanumeric local part, domain, and a two or more letter top-level domain.
  .matches(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    'Invalid email address. Maybe you forgot to add "@"',
  )
  .required('Email address is required');

export const passwordValidation = yup
  .string()
  .required('Password is required')
  .min(8, 'Password must have at least 8 characters')
  .matches(/[0-9]/, getPasswordCharacterValidationError('digit'))
  .matches(/[a-z]/, getPasswordCharacterValidationError('lowercase'))
  .matches(/[A-Z]/, getPasswordCharacterValidationError('uppercase'));

export const timeValidation = yup
  .string()
  .min(4, 'Invalid time')
  .matches(/^(0?[1-9]|1[0-2]):[0-5][0-9]$/, 'Invalid time');

export const toTimeValidation = timeValidation.test(
  'is-after',
  'Earlier than start',
  (toTime, { parent }) => {
    const { fromTime, fromMeridian, toMeridian } = parent;

    if (!toTime || !fromTime) {
      return true;
    }

    const from = getTwentyFourHourDayjs({
      time: fromTime,
      meridian: fromMeridian,
    });
    const to = getTwentyFourHourDayjs({ time: toTime, meridian: toMeridian });

    return to.isAfter(from);
  },
);

export const fromTimeValidation = timeValidation.test(
  'is-before',
  'Later than start',
  (fromTime, { parent }) => {
    const { toTime, fromMeridian, toMeridian } = parent;

    if (!fromTime || !toTime) {
      return true;
    }

    const from = getTwentyFourHourDayjs({
      time: fromTime,
      meridian: fromMeridian,
    });
    const to = getTwentyFourHourDayjs({ time: toTime, meridian: toMeridian });

    return from.isBefore(to);
  },
);
