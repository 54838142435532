import { useAuthLoginFinalStepForm } from '@/forms/useAuthLoginFinalStepForm';
import { useLogin } from '@/hooks/useLogin';
import { routes } from '@/routes/routes';
import { Link, useNavigate } from 'react-router-dom';

import { Button } from '@components/buttons/Button';
import { Input } from '@components/inputs/Input';
import { AuthLayout } from '@/components/layouts/AuthLayout';
import { SpriteIcon } from '@/components/icons/SpriteIcon';
import { TextButton } from '@/components/buttons/TextButton';

import { GoogleButton } from '@/components/buttons/GoogleButton';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { useGoogleSignIn } from '@/hooks/useGoogleSignIn';

export const LoginPage = () => {
  const navigate = useNavigate();

  const { control, isSubmitDisabled, handleSubmitLogInForm } =
    useAuthLoginFinalStepForm();
  const { handleLogin, isLoading: isEntering } = useLogin();

  const handleCrossTap = () => navigate(routes.back);
  const handleRegisterTap = () =>
    navigate(routes.auth.index, { replace: true });

  const handleLoginClick = handleSubmitLogInForm((data) => handleLogin(data));
  const { googleSignIn, isLoading: isEnteringUsingGoogle } = useGoogleSignIn();

  useGoogleOneTapLogin({
    onSuccess: async (response) => googleSignIn({ token: response.credential }),
  });

  const isLoading = isEnteringUsingGoogle || isEntering;

  return (
    <AuthLayout handleCloseButtonClick={handleCrossTap}>
      <div className="hidden md:flex mb-[32px] mt-[64px]">
        <SpriteIcon iconName="logoMobile" className="w-[114px] h-4" />
      </div>
      <form className="flex flex-col h-full w-full justify-between">
        <div className="flex flex-col gap-y-2">
          <div>
            <Input
              label="Email"
              isDisabled={isLoading}
              placeholder="Enter your email"
              type="email"
              name="email"
              control={control}
              autoComplete="email"
            />
            <Input
              label="Password"
              isDisabled={isLoading}
              placeholder="Enter your password"
              type="password"
              name="password"
              control={control}
            />
          </div>
          <Link
            to={routes.auth.resetPassword}
            className="text-p-bold text-purple px-2"
          >
            Forgot your password?
          </Link>
        </div>
        <div className="space-y-5 text-center py-4">
          <TextButton
            onClick={handleRegisterTap}
            styles="text-p-bold text-purple"
          >
            <span className="text-gray text-p pr-2">Not a member yet?</span>
            Sign up
          </TextButton>
          <Button
            size="large"
            variant="primary"
            onClick={handleLoginClick}
            disabled={isSubmitDisabled}
            isLoading={isLoading}
            full
          >
            Log in
          </Button>
        </div>
        <p className="p-text w-full text-center pb-4">or</p>
        <GoogleButton isLoading={isLoading} />
      </form>
    </AuthLayout>
  );
};
