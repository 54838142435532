import defaultPlaceholderImage from '@assets/images/landingPage/experience-placeholder.webp';

type Props = {
    experienceId: number | undefined;
    image: string | undefined | null;
}
export const useRetrieveImage = ({
    experienceId, image
}: Props) => {
    if (image) {
        if (image.includes('undefined')) {
            // some bullshit happens here
            return `${import.meta.env.VITE_API_URL}/api/v1/experiences/${experienceId}/image`;
        } else return image;
    }
    if (experienceId) {
        return `${import.meta.env.VITE_API_URL}/api/v1/experiences/${experienceId}/image`;
    }
    return defaultPlaceholderImage;
};