import { useAcceptInvitationAnonymous } from '@/forms/useAcceptInvitationAnonymous';
import { useAcceptInvitation } from '@/hooks/useAcceptInvitation';
import { Button } from '@components/buttons/Button';
import { Input } from '@components/inputs/Input';
import { DisabledInput } from '@components/inputs/DisabledInput';
import { useInvitationLink } from '@/hooks/useInvitationLink';
import { PopUpContainer } from '../containers/PopUpContainer';
import { useGoogleAnalytics } from '@/hooks/useGoogleAnalytics';

type Props = {
  token?: string;
  isOpen: boolean;
  onClose: () => void;
  usernameNeeded: boolean;
};

export const PersonalLinkBottomSheet = ({
  token,
  isOpen,
  onClose,
  usernameNeeded,
}: Props) => {
  const { control, isSubmitDisabled, isSubmitting, username } =
    useAcceptInvitationAnonymous();
  const { acceptInvitation, data } = useAcceptInvitation({
    token: token,
  });
  const { sendGAEvent, EVENT_ACTIONS, EVENT_CATEGORIES } = useGoogleAnalytics();

  const {
    invitationLink,
    invitationRedirectLink,
    copyToClipboard,
    navigateToItinerary,
  } = useInvitationLink(data?.data?.itinerary_id, data?.data?.personal_token);

  const onConfirmButtonHandler = () => {
    acceptInvitation(username);
    sendGAEvent(EVENT_CATEGORIES.groupPlanning, EVENT_ACTIONS.continueAsGuest);
  };

  const onCopyButtonHandler = () => {
    copyToClipboard(invitationLink);
    sendGAEvent(
      EVENT_CATEGORIES.groupPlanning,
      EVENT_ACTIONS.copyPersonalInvitationLink,
    );
  };

  return (
    <PopUpContainer snapPoints={[0.6]} onClose={onClose} isOpen={isOpen}>
      <div className="flex flex-col justify-between pb-4 h-full">
        <div className="">
          <h5 className="text-h4 pb-2">Your personal link</h5>
          <p className="text-p-medium">
            You must save this link to store your preferences and access the
            itinerary without having to re-enter them.
          </p>

          {usernameNeeded && !invitationLink ? (
            <div className="flex flex-col gap-y-2 pt-10">
              <p className="text-p-medium">
                But first let your travelers know who you are!
              </p>
              <div className="flex flex-col">
                <Input
                  name="username"
                  control={control}
                  type="username"
                  placeholder="Let your travelers know who you are!"
                  label="Name"
                  isDisabled={isSubmitDisabled && isSubmitting}
                />
              </div>
            </div>
          ) : (
            <div className="flex items-center w-full gap-x-1">
              <DisabledInput
                label="Token"
                value={invitationLink}
                placeholder="Your token will appear here..."
              />
              <span className="">
                <Button
                  size="large"
                  onClick={onCopyButtonHandler}
                  iconName="copy"
                  disabled={!invitationLink}
                  roundnessVariant="xxxl"
                  type="submit"
                  styles={{
                    width: '52px',
                    height: '52px',
                  }}
                />
              </span>
            </div>
          )}
        </div>
        <div className="flex">
          {usernameNeeded && !invitationLink ? (
            <Button
              size="large"
              full={true}
              onClick={onConfirmButtonHandler}
              disabled={isSubmitDisabled}
            >
              Accept invitation!
            </Button>
          ) : (
            <Button
              size="large"
              full={true}
              onClick={() => {
                navigateToItinerary(invitationRedirectLink);
              }}
              disabled={invitationLink ? false : true}
            >
              Show itinerary!
            </Button>
          )}
        </div>
      </div>
    </PopUpContainer>
  );
};
