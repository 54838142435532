import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useGetProfile } from '@/hooks/useGetProfile';
import { emailValidation } from '@/utils/yupValidations';

const schema = yup.object().shape({
  email: emailValidation,
});

export type EditEmailSchemaType = yup.InferType<typeof schema>;

export const useEditEmailForm = () => {
  const { profile } = useGetProfile();

  const { control, handleSubmit, formState, setValue } =
    useForm<EditEmailSchemaType>({
      mode: 'all',
      reValidateMode: 'onBlur',
      shouldFocusError: true,
      defaultValues: {
        email: profile?.email,
      },
      resolver: yupResolver(schema),
    });

  const isSubmitDisabled = !formState.isValid || formState.isSubmitting;

  useEffect(() => {
    if (profile) {
      setValue('email', profile?.email ?? '');
    }
  }, [profile, setValue]);

  return {
    control,
    handleSubmit,
    isSubmitting: formState.isSubmitting,
    isSubmitDisabled,
  };
};
