import classNames from 'classnames';

export enum _SpriteIconEnum {
  'adult',
  'arrowLeft',
  'arrowLeftLarge',
  'arrowRight',
  'art',
  'bar',
  'basket',
  'beach',
  'bicycle',
  'brush',
  'bulletPoint',
  'bus',
  'cafe',
  'calendar',
  'camera',
  'car',
  'check',
  'checkmark',
  'chevronDown',
  'chevronRight',
  'chevronUp',
  'cross',
  'culture',
  'edit',
  'facebook',
  'family',
  'filter',
  'folder',
  'food',
  'forkAndKnife',
  'glass',
  'google',
  'hearth',
  'history',
  'image',
  'indoor',
  'infant',
  'info',
  'link',
  'location',
  'logoMobile',
  'map',
  'minus',
  'nightlife',
  'park',
  'paw',
  'pen',
  'pin',
  'plus',
  'restaurant',
  'search',
  'share',
  'shareBox',
  'ship',
  'sightseeing',
  'sort',
  'star',
  'stars',
  'taxi',
  'triangleDown',
  'triangleUp',
  'user',
  'pedestrian',
  'x',
  'youth',
  'zoo',
  'logo',
  'done',
  'pending',
  'copy',
  'logoWhite',
  'arrowBottom',
  'mapbutton',
}
export type SpriteIconProps = keyof typeof _SpriteIconEnum;

interface SvgSpriteIconProps {
  iconName: SpriteIconProps;
  className?: string;
  onClick?: () => void;
}

export const SpriteIcon = ({
  iconName,
  className,
  onClick,
}: SvgSpriteIconProps) => {
  return (
    <svg className={classNames(className)} onClick={onClick}>
      <use href={`/assets/svg/sprite-svg.svg#${iconName}`} />
    </svg>
  );
};
