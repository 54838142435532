export const formatCommuteDuration = (duration: number) => {
  const totalMinutes = Math.ceil(duration / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (hours === 0) {
    return `${minutes} min`;
  }
  return `${hours} h ${minutes} min`;
};
