import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useGetProfile } from '@/hooks/useGetProfile';

const schema = yup.object().shape({
  first_name: yup.string().required('Enter your first name'),
  last_name: yup.string().required('Enter your last name'),
});

export type EditNameSchemaType = yup.InferType<typeof schema>;

export const useEditNameForm = () => {
  const { profile } = useGetProfile();

  const { control, handleSubmit, formState, setValue } =
    useForm<EditNameSchemaType>({
      mode: 'all',
      reValidateMode: 'onBlur',
      shouldFocusError: true,
      defaultValues: {
        first_name: profile?.first_name,
        last_name: profile?.last_name,
      },
      resolver: yupResolver(schema),
    });

  const isSubmitDisabled = !formState.isValid || formState.isSubmitting;

  useEffect(() => {
    if (profile) {
      setValue('first_name', profile?.first_name ?? '');
      setValue('last_name', profile?.last_name ?? '');
    }
  }, [profile, setValue]);

  return {
    control,
    handleSubmit,
    isSubmitting: formState.isSubmitting,
    isSubmitDisabled,
  };
};
