import { useApi } from '@/api/serviceProvider';
import { useInvitationStore } from '@/stores/invitationStore';
import { useMutation } from '@tanstack/react-query';
import { AcceptInvitation } from '@/generated/models/AcceptInvitation';
import { useState } from 'react';

type Params = {
  token?: string;
};

export const useAcceptInvitation = ({ token }: Params) => {
  const { api } = useApi();
  const [error, setError] = useState<string | null>(null);
  const invitationStore = useInvitationStore((state) => state.invitationSearch);

  const { mutate, data, isPending } = useMutation({
    mutationFn: async (username: string | undefined = undefined) => {
      if (!token) {
        return;
      }
      const data = {
        token: token,
        username: username,
        interests: invitationStore.interests?.map(
          (interest) => interest.name!,
        ) ?? [],
        food_preferences: invitationStore?.foodPreferences?.map(
          (foodPreference) => foodPreference.code,
        ) ?? [],
        min_price_level: invitationStore.dailyBudget?.min_price ?? 1,
        max_price_level: invitationStore.dailyBudget?.max_price ?? 4,
      };

      const response = await api.apiV1ItinerariesAcceptInvitation(
        data as AcceptInvitation,
      );
      return response;
    },
    onError: (error: any) => {
      setError(error);
    },
  });

  return {
    acceptInvitation: mutate,
    data,
    isLoading: isPending,
    error,
  };
};
