import { Input } from '@components/inputs/Input';
import { routes } from '@routes/routes';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@/components/buttons/Button';
import { AuthLayout } from '@/components/layouts/AuthLayout';
import { useConfirmPasswordForm } from '@/forms/useConfirmPasswordForm';
import { useConfirmPasswordReset } from '@/hooks/useConfirmPasswordReset';

export const ConfirmPasswordPage = () => {
  const navigate = useNavigate();
  const { uid, token } = useParams();
  const { control, handleSubmit, isSubmitDisabled } = useConfirmPasswordForm();
  const { handleConfirmPasswordReset, isConfirmPasswordResetLoading } =
    useConfirmPasswordReset();

  const handleNavigateToLogin = () => navigate(routes.auth.index);

  const handleConfirmClick = handleSubmit(({ password, confirmPassword }) => {
    handleConfirmPasswordReset({
      password,
      confirmPassword,
      uid: uid ?? '',
      token: token ?? '',
    });
  });

  useEffect(() => {
    if (!uid || !token) {
      navigate(routes.home);
    }
  }, [navigate, token, uid]);

  return (
    <AuthLayout handleCloseButtonClick={handleNavigateToLogin}>
      <h3 className="text-h3 mb-7">Set up a new password</h3>
      <form className="flex flex-col mt-2 h-full">
        <Input
          label="Password"
          isDisabled={isConfirmPasswordResetLoading}
          placeholder="Enter your password"
          type="password"
          name="password"
          control={control}
        />
        <Input
          label="Confirm Password"
          isDisabled={isConfirmPasswordResetLoading}
          placeholder="Confirm your password"
          type="password"
          name="confirmPassword"
          control={control}
        />
        <section className="mt-auto mb-4">
          <Button
            size="large"
            full
            onClick={handleConfirmClick}
            disabled={isSubmitDisabled}
            isLoading={isConfirmPasswordResetLoading}
          >
            Confirm new password
          </Button>
        </section>
      </form>
    </AuthLayout>
  );
};
