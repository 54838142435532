import placeholderImg from '@assets/images/landingPage/experience-placeholder.webp';
import { useState } from 'react';

type Props = {
  src?: string;
  alt: string;
  styles?: string;
  minHeight?: string;
};

export const ImageWithPlaceholder = ({
  src,
  alt,
  styles,
  minHeight,
}: Props) => {
  const [isLoaded, setIsLoaded] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleImageLoad = () => setIsLoaded(true);
  const handleImageError = () => setIsError(true);

  const imageStyles = styles
    ? styles
    : 'absolute h-full w-full object-cover object-center inset-0';
  const placeholderStyles = styles
    ? styles + 'animate-pulse bg-gray-medium' + minHeight
    : 'animate-pulse bg-gray-medium absolute w-full h-full inset-0';

  return (
    <>
      {!isLoaded && !isError && <div className={placeholderStyles} />}
      <img
        loading="lazy"
        src={!isError ? src : placeholderImg}
        className={imageStyles}
        alt={alt}
        onLoad={handleImageLoad}
        onError={handleImageError}
      />
    </>
  );
};
