import { create, StateCreator } from 'zustand';
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';

import { UserLoginResponseType } from '@/types/stores/UserStoreTypes';

// TODO: would be nice to have a type that mirrors the backend one
type State = {
  user: UserLoginResponseType | null;
};

type Actions = {
  setUser: (userLoginResponse: UserLoginResponseType) => void;
  clearUser: () => void;
};

const initialState: State = {
  user: null,
};

type UserStoreType = State & Actions;

type UserPersistStoreType = (
  config: StateCreator<UserStoreType>,
  options: PersistOptions<UserStoreType>,
) => StateCreator<UserStoreType>;

export const useUserStore = create<UserStoreType>(
  (persist as UserPersistStoreType)(
    (set) => ({
      ...initialState,
      setUser: (userLoginResponse: UserLoginResponseType) =>
        set({ user: userLoginResponse }),
      clearUser: () => set(initialState),
    }),
    {
      name: 'user-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
