import { useState } from 'react';

import { Container } from '@/components/containers/Container';
import { Loader } from '@/components/loaders/Loader';
import { InvitationCard } from '@/components/cards/InvitationCard';
import TripDetailItem from '@/components/sections/TripDetailItem';
import { routes } from '@/routes/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { InvitationPreferenceModal } from '@/components/modals/InvitationPreferenceModal';
import { RegisterStep } from '@/components/tailoringSteps/RegisterStep';
import { useInvitationLink } from '@/hooks/useInvitationLink';

type Params = {
  token?: string;
  invitation?: any;
  isLoading?: boolean;
  error?: string;
};

export const ItineraryInviteOverviewPage = ({
  token,
  invitation,
  isLoading,
  error,
}: Params) => {
  const { token: tokenFromParams } = useParams();
  const navigate = useNavigate();
  const { invitationRedirectLink, navigateToItinerary } = useInvitationLink();
  const [isRegistrationStep, setIsRegistrationStep] = useState(false);
  const [isPreferencesModalOpen, setIsPreferencesModalOpen] = useState(false);

  const allowAcceptInvitation = () => {
    if (invitation) {
      if (invitation.is_group) {
        setIsRegistrationStep(true);
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const onAcceptHandler = () => {
    setIsPreferencesModalOpen(!isPreferencesModalOpen);
  };

  if (isLoading) {
    return (
      <div className="flex w-full h-full justify-center align-middle">
        <Loader />
      </div>
    );
  }

  return isPreferencesModalOpen && !error && token ? (
    <Container>
      {!isRegistrationStep ? (
        <InvitationPreferenceModal
          token={token || tokenFromParams || ''}
          allowAcceptInvitation={allowAcceptInvitation}
          onClose={() => setIsPreferencesModalOpen(false)}
          is_admin={false}
          navigateToTheItinerary={() =>
            navigateToItinerary(invitationRedirectLink)
          }
        />
      ) : (
        <RegisterStep
          token={token || tokenFromParams}
          itineraryId={invitation?.itinerary_id}
          usernameNeeded={
            invitation && (invitation.email || invitation.phone_number)
              ? false
              : true
          }
          onHeaderBackHandler={() => {
            setIsRegistrationStep(!isRegistrationStep);
          }}
          onHeaderActionHandler={() => {
            navigate(routes.home);
          }}
        />
      )}
    </Container>
  ) : (
    <Container>
      {token && !error && invitation ? (
        <InvitationCard
          label={'You’ve been invited to group trip'}
          buttonLabel="Fill out my preferences"
          onButtonClick={onAcceptHandler}
          notes={
            'Please fill out your preferences and interests to accept the invitation'
          }
          content={
            <div className="grid mt-6 gap-y-3">
              <TripDetailItem
                iconName="map"
                text={invitation?.city ?? 'Destination'}
              />
              <TripDetailItem
                iconName="calendar"
                text={invitation?.dates ?? 'Dates'}
              />
              <TripDetailItem
                iconName="user"
                text={invitation?.persons ?? 'Persons'}
              />
            </div>
          }
        />
      ) : (
        <InvitationCard
          label={
            error ?? !invitation
              ? 'Token is not valid!'
              : 'Required token is missing!'
          }
          buttonLabel="Go to home"
          notes="Please, contact your trip organizer to get a valid invitation link!"
          onButtonClick={() => {
            navigate(routes.home);
          }}
        />
      )}
    </Container>
  );
};
