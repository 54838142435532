import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { removeAuthCookie } from '@/utils/removeAuthCookie';

import { routes } from './routes';

export const ProtectedRoute = () => {
  const navigate = useNavigate();
  const authCookie = Cookies.get('tp_access_token');

  useEffect(() => {
    if (!authCookie) {
      removeAuthCookie();
      navigate(routes.home, { replace: true });
    }
  }, [authCookie]);

  return <Outlet />;
};
