import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { routes } from '@/routes/routes';

type Props = {
  title: string;
};

export const TitleHeaderWithLogo: React.FC<Props> = ({ title }) => {
  const [bodyWidth, setBodyWidth] = useState<number | null>(null);
  const navigate = useNavigate();

  const onLogoClick: () => void = () => {
    navigate(routes.home);
  };

  useEffect(() => {
    const handleResize = () => {
      const width = document.body.getBoundingClientRect().width;
      setBodyWidth(width);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={bodyWidth ? { width: bodyWidth } : { width: 768 }}
      className="fixed inset-x-0 top-0 z-50 mx-auto"
    >
      <header className="flex justify-between pb-4 pt-4 shadow-header bg-white">
        <h3 className="px-2 text-h3">{title}</h3>
        <img
          src="/assets/svg-sprite-source/logoMin.svg"
          alt="logo"
          className="w-40 mr-10 cursor-pointer"
          onClick={onLogoClick}
        />
      </header>
    </div>
  );
};
