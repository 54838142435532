import { create } from 'zustand';

// TODO: would be nice to have a type that mirrors the backend one
type State = {
  itineraryToSaveId: number | undefined;
};

type Actions = {
  setItineraryToSaveId: (itineraryToSaveId: number) => void;
  clearStore: () => void;
};

const initialState: State = {
  itineraryToSaveId: undefined,
};

export const useItineraryToSaveStore = create<State & Actions>((set) => ({
  ...initialState,
  setItineraryToSaveId: (itineraryToSaveId: number) =>
    set({ itineraryToSaveId }),
  clearStore: () => set(initialState),
}));
