import { Button } from '@components/buttons/Button';
import { capitalizeFirstLetter } from '@utils/capitalizeFirstLetter';
import classNames from 'classnames';

import { ButtonSizeType } from '@/types/ButtonTypes';

type Props = {
  filters: string[];
  chosenFilters: string[];
  onFilterClick: (filter: string) => void;
  isWrapped?: boolean;
  buttonSize?: ButtonSizeType;
};

export const ExperienceFilterList = ({
  filters,
  chosenFilters,
  onFilterClick,
  isWrapped = false,
  buttonSize = 'medium',
}: Props) => {
  return (
    <div
      className={classNames('flex', {
        'overflow-auto gap-2 pb-3': !isWrapped,
        'flex-wrap gap-3': isWrapped,
      })}
    >
      {filters.map((filter) => {
        const isSelected = chosenFilters.includes(filter);

        return (
          <Button
            key={filter}
            variant={isSelected ? 'tertiary' : 'secondary'}
            size={buttonSize}
            onClick={() => onFilterClick(filter)}
          >
            {capitalizeFirstLetter(filter)}
          </Button>
        );
      })}
    </div>
  );
};
