import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeyTypes } from '@/api/queryKeyTypes';
import { useApi } from '@/api/serviceProvider';
import { showToast } from '@/utils/showToast';

import { useGetProfile } from './useGetProfile';

export const useEditUserName = () => {
  const { api } = useApi();
  const { profile } = useGetProfile();

  const queryClient = useQueryClient();

  const userId = profile?.id;

  const { mutate, isPending } = useMutation({
    mutationFn: async ({
      first_name,
      last_name,
    }: {
      first_name: string;
      last_name: string;
    }) => {
      if (!first_name || !last_name || !userId) {
        return;
      }

      return await api.apiV1UsersPartialUpdate(userId, {
        username: profile.email ?? '',
        first_name,
        last_name,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.profile],
      });
      showToast('Name changed successfully');
    },
    onError: () => showToast('Failed to change your name'),
  });

  return {
    handleEditName: mutate,
    isLoading: isPending,
  };
};
