import classNames from 'classnames';

type Props = {
  size?: 'small' | 'large';
  dark?: boolean;
};

export const Loader = ({ size = 'large', dark }: Props) => {
  return (
    <div
      className={classNames('border-4  rounded-full animate-spin', {
        'border-[1px]': size === 'small',
        'w-4 h-4': size === 'small',
        'w-[52px] h-[52px]': size === 'large',
        'border-purple-light border-t-purple-dark': !dark,
        'border-t-black border-white': dark,
      })}
    />
  );
};
