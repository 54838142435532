/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CookieTokenRefresh } from '../models/CookieTokenRefresh';
import type { Login } from '../models/Login';
import type { PasswordChange } from '../models/PasswordChange';
import type { PasswordReset } from '../models/PasswordReset';
import type { PasswordResetConfirm } from '../models/PasswordResetConfirm';
import type { Register } from '../models/Register';
import type { ResendEmailVerification } from '../models/ResendEmailVerification';
import type { SocialLogin } from '../models/SocialLogin';
import type { TokenVerify } from '../models/TokenVerify';
import type { UserDetails } from '../models/UserDetails';
import type { VerifyEmail } from '../models/VerifyEmail';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AuthService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Handles Google OAuth2 login flow.
     * - Verifies the token from the frontend.
     * - Creates a new user and social account if they don't exist.
     * - Completes social login for new users or logs in existing users.
     * @param data
     * @returns SocialLogin
     * @throws ApiError
     */
    public authGoogleLoginCreate(
        data: SocialLogin,
    ): CancelablePromise<SocialLogin> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/google/login/',
            body: data,
        });
    }
    /**
     * Check the credentials and return the REST Token
     * if the credentials are valid and authenticated.
     * Calls Django Auth login method to register User ID
     * in Django session framework
     *
     * Accept the following POST parameters: username, password
     * Return the REST Framework Token Object's key.
     * @param data
     * @returns Login
     * @throws ApiError
     */
    public authLoginCreate(
        data: Login,
    ): CancelablePromise<Login> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/login/',
            body: data,
        });
    }
    /**
     * Calls Django logout method and delete the Token object
     * assigned to the current User object.
     * Accepts/Returns nothing.
     * @returns any
     * @throws ApiError
     */
    public authLogoutList(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/logout/',
        });
    }
    /**
     * Calls Django logout method and delete the Token object
     * assigned to the current User object.
     * Accepts/Returns nothing.
     * @returns any
     * @throws ApiError
     */
    public authLogoutCreate(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/logout/',
        });
    }
    /**
     * Calls Django Auth SetPasswordForm save method.
     * Accepts the following POST parameters: new_password1, new_password2
     * Returns the success/fail message.
     * @param data
     * @returns PasswordChange
     * @throws ApiError
     */
    public authPasswordChangeCreate(
        data: PasswordChange,
    ): CancelablePromise<PasswordChange> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/password/change/',
            body: data,
        });
    }
    /**
     * Calls Django Auth PasswordResetForm save method.
     * Accepts the following POST parameters: email
     * Returns the success/fail message.
     * @param data
     * @returns PasswordReset
     * @throws ApiError
     */
    public authPasswordResetCreate(
        data: PasswordReset,
    ): CancelablePromise<PasswordReset> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/password/reset/',
            body: data,
        });
    }
    /**
     * Password reset e-mail link is confirmed, therefore
     * this resets the user's password.
     * Accepts the following POST parameters: token, uid,
     * new_password1, new_password2
     * Returns the success/fail message.
     * @param data
     * @returns PasswordResetConfirm
     * @throws ApiError
     */
    public authPasswordResetConfirmCreate(
        data: PasswordResetConfirm,
    ): CancelablePromise<PasswordResetConfirm> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/password/reset/confirm/',
            body: data,
        });
    }
    /**
     * @param data
     * @returns Register
     * @throws ApiError
     */
    public authRegistrationCreate(
        data: Register,
    ): CancelablePromise<Register> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/registration/',
            body: data,
        });
    }
    /**
     * @param key
     * @param data
     * @returns any Successfully confirmed email
     * @throws ApiError
     */
    public authRegistrationAccountConfirmEmailCreate(
        key: string,
        data: VerifyEmail,
    ): CancelablePromise<{
        user?: {
            id?: number;
            username?: string;
            email?: string;
            first_name?: string;
            last_name?: string;
        };
        access?: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/registration/account-confirm-email/{key}/',
            path: {
                'key': key,
            },
            body: data,
            errors: {
                400: `Invalid input`,
            },
        });
    }
    /**
     * @param data
     * @returns ResendEmailVerification
     * @throws ApiError
     */
    public authRegistrationResendEmailCreate(
        data: ResendEmailVerification,
    ): CancelablePromise<ResendEmailVerification> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/registration/resend-email/',
            body: data,
        });
    }
    /**
     * @param data
     * @returns VerifyEmail
     * @throws ApiError
     */
    public authRegistrationVerifyEmailCreate(
        data: VerifyEmail,
    ): CancelablePromise<VerifyEmail> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/registration/verify-email/',
            body: data,
        });
    }
    /**
     * @param data
     * @returns CookieTokenRefresh
     * @throws ApiError
     */
    public authTokenRefreshCreate(
        data: CookieTokenRefresh,
    ): CancelablePromise<CookieTokenRefresh> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/token/refresh/',
            body: data,
        });
    }
    /**
     * Takes a token and indicates if it is valid.  This view provides no
     * information about a token's fitness for a particular use.
     * @param data
     * @returns TokenVerify
     * @throws ApiError
     */
    public authTokenVerifyCreate(
        data: TokenVerify,
    ): CancelablePromise<TokenVerify> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/token/verify/',
            body: data,
        });
    }
    /**
     * Reads and updates UserModel fields
     * Accepts GET, PUT, PATCH methods.
     * Default accepted fields: username, first_name, last_name
     * Default display fields: pk, username, email, first_name, last_name
     * Read-only fields: pk, email
     *
     * Returns UserModel fields.
     * @returns UserDetails
     * @throws ApiError
     */
    public authUserRead(): CancelablePromise<UserDetails> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user/',
        });
    }
    /**
     * Reads and updates UserModel fields
     * Accepts GET, PUT, PATCH methods.
     * Default accepted fields: username, first_name, last_name
     * Default display fields: pk, username, email, first_name, last_name
     * Read-only fields: pk, email
     *
     * Returns UserModel fields.
     * @param data
     * @returns UserDetails
     * @throws ApiError
     */
    public authUserUpdate(
        data: UserDetails,
    ): CancelablePromise<UserDetails> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/user/',
            body: data,
        });
    }
    /**
     * Reads and updates UserModel fields
     * Accepts GET, PUT, PATCH methods.
     * Default accepted fields: username, first_name, last_name
     * Default display fields: pk, username, email, first_name, last_name
     * Read-only fields: pk, email
     *
     * Returns UserModel fields.
     * @param data
     * @returns UserDetails
     * @throws ApiError
     */
    public authUserPartialUpdate(
        data: UserDetails,
    ): CancelablePromise<UserDetails> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/user/',
            body: data,
        });
    }
}
