import { useMutation } from '@tanstack/react-query';
import { useApi } from '@/api/serviceProvider';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInvitationLink } from './useInvitationLink';

type Params = {
  itineraryId: number | undefined,
  token: string | undefined
}

export const useDecodeInvitationToken = () => {
  const { api } = useApi();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  const { mutate, data, isPending } = useMutation({
    mutationFn: async ({ itineraryId, token }: Params) => {
      setError(null);

      if (!token) {
        throw new Error('Token is required!');
      }
      if (!itineraryId) {
        throw new Error('Itinerary ID is required!');
      }
      const response = await api.apiV1ItinerariesParseInvitationToken(itineraryId, token);
      const { invitationRedirectLink } = useInvitationLink(
        response.data?.itinerary_id,
        token,
      );

      if (response.status === 200) {
        return response.data;
      } else if (response.status === 302) {
        navigate(invitationRedirectLink, { replace: true });
        return response.data;
      } else {
        setError(
          response.message ||
          'Please, contact your trip organizer to get a valid invitation link!',
        );
        throw new Error('Invalid invitation token!');
      }
    },
    onError: (error: Error) => {
      setError(error.message);
    },
  });

  return {
    invitation: data,
    decodeInvitationToken: mutate,
    isLoading: isPending,
    error,
  };
};
