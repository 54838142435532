import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { queryKeyTypes } from '@/api/queryKeyTypes';
import { useApi } from '@/api/serviceProvider';
import { routes } from '@/routes/routes';
import { useAuthEmailStore } from '@/stores/authEmailStore';
import { showToast } from '@/utils/showToast';
import { useUserStore } from '@/stores/userStore';
import Cookies from 'js-cookie';
import { useParseNextStep } from './useParseNextStep';

export const useVerifyRegistrationEmail = () => {
  const navigate = useNavigate();
  const apiFactory = useApi();
  const clearInitialEmail = useAuthEmailStore((state) => state.clearEmail);
  const setUserStore = useUserStore((state) => state.setUser);
  const { parseNextStep } = useParseNextStep();

  const { mutate } = useMutation({
    mutationKey: [queryKeyTypes.confirmRegistrationEmail],
    mutationFn: async (token: string) => {
      if (!token) {
        return;
      }

      return await apiFactory.auth.authRegistrationAccountConfirmEmailCreate(token, { key: token });
    },
    onSuccess: (response: any) => {
      clearInitialEmail();
      showToast('Registration has been verified successfully');
      setUserStore({
        access: response.access,
        refresh: '',
        user: {
          pk: response.user.id,
          email: response.user.email,
          username: response.user.email,
          first_name: response.user.first_name,
          last_name: response.user.last_name,
        }
      });
      Cookies.set('tp_access_token', response.access);
      parseNextStep(response.user.email);
    },
    onError: () => {
      showToast('Failed to verify registration email');
      navigate(routes.home);
    },
  });

  return {
    handleVerifyRegistrationEmail: mutate,
  };
};
