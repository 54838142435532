import { create, StateCreator } from 'zustand';
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';

type State = {
  didShowTailoringTooltips: boolean;
  didShowItineraryTooltips: boolean;
};

type Actions = {
  setDidShowItineraryTooltips: (wasShown: boolean) => void;
  setDidShowTailoringTooltips: (wasShown: boolean) => void;
};

const initialState: State = {
  didShowTailoringTooltips: false,
  didShowItineraryTooltips: false,
};

type TooltipsStoreType = State & Actions;

type ItineraryTooltipsPersistStoreType = (
  config: StateCreator<TooltipsStoreType>,
  options: PersistOptions<TooltipsStoreType>,
) => StateCreator<TooltipsStoreType>;

export const useTooltipsStore = create<TooltipsStoreType>(
  (persist as ItineraryTooltipsPersistStoreType)(
    (set) => ({
      ...initialState,
      setDidShowItineraryTooltips: (didShowItineraryTooltips) =>
        set({ didShowItineraryTooltips }),
      setDidShowTailoringTooltips: (didShowTailoringTooltips) =>
        set({ didShowTailoringTooltips }),
    }),
    {
      name: 'tooltips-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
