import classNames from 'classnames';
import { ReactNode } from 'react';

type Props = {
  maxWidth?: number;
  className?: string;
  children: ReactNode;
  isFull?: boolean;
};

export const TruncText = ({
  maxWidth = 276,
  className,
  children,
  isFull = true,
}: Props) => {
  return (
    <p
      style={{ maxWidth: `${maxWidth}px` }}
      className={classNames(
        'text-p overflow-hidden whitespace-nowrap text-ellipsis',
        { 'w-full': isFull },
        className,
      )}
    >
      {children}
    </p>
  );
};
