export const itineraryLoaderTexts = [
  'Looking for the best places to go to and hidden gems to discover...',
  'Checking reviews to suggest only the best experiences...',
  'Piecing experiences together for a balanced itinerary...',
  'Crafting your personalized adventure...',
];

export const itineraryLoaderMiamiTexts = [
  'Explore the vibrant neighborhoods like Little Havana and Wynwood for diverse cultural experiences.',
  "Don't forget sunscreen and water when hitting the beaches; South Beach is iconic but consider exploring quieter stretches too.",
  'Sample delicious Cuban cuisine—try a Cuban sandwich and café Cubano in Little Havana.',
  'Take a stroll through the Art Deco Historic District in South Beach for stunning architecture.',
  'Experience the thrill of water activities like snorkeling or jet skiing.',
  'Venture into the Everglades for an airboat tour and wildlife spotting.',
  "Dive into Miami's legendary nightlife scene in South Beach and Downtown.",
  'Immerse yourself in art and culture at places like Pérez Art Museum Miami (PAMM) and Wynwood Walls.',
  'Enjoy a shopping spree at upscale destinations like Lincoln Road Mall and Bal Harbour Shops.',
  "Stay hydrated and cool in Miami's tropical climate by drinking plenty of water and seeking shade when needed.",
  'Take a scenic drive along Ocean Drive for stunning views of the ocean and Art Deco buildings.',
  'Visit the Vizcaya Museum and Gardens to explore a beautiful estate with European-inspired gardens.',
  "Experience the cultural melting pot of flavors at Miami's diverse food trucks and markets.",
  'Catch a basketball game or concert at the American Airlines Arena in Downtown Miami.',
  "Escape the city hustle and relax in one of Miami's lush parks like Bayfront Park or Matheson Hammock Park.",
  'Learn about marine life at the Miami Seaquarium, home to dolphins, sea lions, and tropical fish.',
  "Discover the city's history at the HistoryMiami Museum, featuring exhibits on Miami's past and present.",
  'Embark on a fishing charter to reel in some big catches from the abundant waters surrounding Miami.',
  'Savor fresh seafood at waterfront restaurants along the Miami River or Biscayne Bay.',
  'Attend a cultural festival or event, celebrating everything from food and music to art and film.',
];
