import { create, StateCreator } from 'zustand';
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';

export type SaveTrip = {
  token: string;
  itineraryId: number;
  invitedUserId?: number;
  interests?: string[];
  foodPreferences?: string[];
  min_price_level?: number;
  max_price_level?: number;
};

export type SaveTripAdmin = {
  itineraryId?: number;
  peopleToInvite?: string[];
};

export type NextSteps = {
  saveTrip?: SaveTrip;
  saveTripAdmin?: SaveTripAdmin;
};

type State = {
  nextStep: NextSteps | null;
};

type Actions = {
  setNextStep: (value: NextSteps) => void;
  resetNextStep: () => void;
};

const initialState: NextSteps | null = null;

type NextStepStoreType = State & Actions;

type NextStepPersistStoreType = (
  config: StateCreator<NextStepStoreType>,
  options: PersistOptions<NextStepStoreType>,
) => StateCreator<NextStepStoreType>;

export const useNextStepStore = create<NextStepStoreType>(
  (persist as NextStepPersistStoreType)(
    (set) => ({
      nextStep: initialState,
      setNextStep: (value) =>
        set((state) => ({
          ...state,
          nextStep: value,
        })),
      resetNextStep: () => set({ nextStep: initialState }),
    }),
    {
      name: 'next-step-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
