import { SpriteIcon } from '@components/icons/SpriteIcon';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { routes } from '@/routes/routes';
import { useUserStore } from '@/stores/userStore';

import { Header } from './Header';
import { TripInfo } from './TripInfo';
import { useInitialSearchStore } from '@/stores/initialSearchStore';
import { forwardRef } from 'react';

type Props = {
  location: string;
  dateRangeText: string;
  people: number;
  horizontalSpacing?: number;
  isLoadingData?: boolean;
  onEditClick: () => void;
  onMapClick: () => void;
  is_admin?: boolean; // check is the user has access to the itinerary management or not
  onSaveClick?: () => void;
  editItineraryDivRef?: React.RefObject<HTMLDivElement>;
};

export const UpperPart = forwardRef<HTMLButtonElement, Props>(
  (
    {
      location,
      dateRangeText,
      people,
      horizontalSpacing,
      isLoadingData,
      onEditClick,
      onMapClick,
      is_admin = true,
      onSaveClick,
      editItineraryDivRef,
    },
    ref,
  ) => {
    const navigate = useNavigate();
    const token = useUserStore((state) => state.user);
    const setInitialSearch = useInitialSearchStore(
      (state) => state.setInitialSearch,
    );

    const isLoggedIn = !!token;
    // TODO: Implement
    return (
      <div
        className={classNames('w-full', {
          [`px-${horizontalSpacing}`]: horizontalSpacing,
        })}
      >
        <div className="w-full flex gap-x-1 items-center justify-center">
          {isLoggedIn && (
            <Header
              onBackButtonClick={() => {
                navigate(routes.back);
                setInitialSearch({
                  invitedPeople: null,
                  itineraryId: null,
                  isWaitingForPeople: false,
                });
              }}
              full={false}
            />
          )}
          <TripInfo
            location={location}
            dateRangeText={dateRangeText}
            people={people}
            isLoadingData={isLoadingData}
            onEditClick={onEditClick}
            is_admin={is_admin}
            ref={ref}
            editItineraryDivRef={editItineraryDivRef}
          />
          {/* Show the map button if the user is logged in */}
          {/* if the itinerary in the pending state, need to display all experiences on the map */}
          {(is_admin || isLoggedIn) && (
            <button
              disabled={isLoadingData}
              onClick={onMapClick}
              className="w-[60px] h-[60px] rounded-full bg-gray-light flex justify-center items-center flex-shrink-0 lg:hidden"
            >
              <SpriteIcon iconName="mapbutton" className="w-6 h-6" />
            </button>
          )}
          {/* Register to save the itinerary */}
          {!isLoggedIn && !is_admin && (
            <button
              onClick={
                onSaveClick ? onSaveClick : () => navigate(routes.auth.index)
              }
              className="w-[60px] h-[60px] rounded-full bg-green flex justify-center items-center flex-shrink-0"
            >
              <SpriteIcon iconName="hearth" className="w-6 h-6" />
            </button>
          )}
        </div>
      </div>
    );
  },
);
