import classNames from 'classnames';
import React from 'react';
import { SpriteIcon } from '../icons/SpriteIcon';
import { motion } from 'framer-motion';

type Props = {
  children: React.ReactNode;
  title: string;
  isOpened: boolean;
  onToggle: () => void;
  isDaySelected: boolean;
};

export const DropdownList = ({
  children,
  title,
  isOpened,
  onToggle,
  isDaySelected = false,
}: Props) => {
  // If day is selected or dropdown is disabled, return children directly
  if (isDaySelected) {
    return <>{children}</>;
  }

  const variants = {
    hidden: { y: '-50%', opacity: 0 }, // Start hidden slightly above the parent element
    visible: {
      y: '0%', // Animate down to its natural position within the parent
      opacity: 1, // Fade in
      transition: { type: 'tween', stiffness: 60, damping: 10 },
    },
    exit: { y: '-50%', opacity: 0, transition: { ease: 'easeInOut' } }, // Animate back up slightly above the parent and fade out
  };

  return (
    <div className="relative overflow-hidden flex-col w-full gap-y-6">
      <div
        className={classNames(
          'flex gap-x-1 w-full py-2 cursor-pointer items-center',
          {
            'pb-6': isOpened,
            'border-b border-gray-medium': !isOpened,
          },
        )}
        onClick={onToggle}
      >
        <SpriteIcon
          iconName="arrowBottom"
          className={classNames('w-8 h-8', {
            'transform rotate-[270deg]': !isOpened,
          })}
        />
        <p className="text-h4">{title}</p>
      </div>
      <motion.div
        variants={variants}
        initial="hidden"
        animate={isOpened ? 'visible' : 'hidden'}
        exit="exit"
        className={classNames(
          'flex flex-col w-full gap-y-6 items-center relative',
          {
            hidden: !isOpened,
            flex: isOpened,
          },
        )}
      >
        {children}
      </motion.div>
    </div>
  );
};
