import React from 'react';

type Props = {
  type: string;
  children: React.ReactNode;
};

export enum SectionsTypes {
  TITLE = 'TITLE',
  SUBTITLE = 'SUBTITLE',
  SUB_SUBTITLE = 'SUB_SUBTITLE',
  CONTENT = 'CONTENT',
  POINT = 'POINT',
}

const Section: React.FC<Props> = ({ type, children }) => {
  return (
    <>
      {type === SectionsTypes.TITLE ? (
        <h5 className="text-h5 mb-3">{children}</h5>
      ) : null}
      {type === SectionsTypes.SUBTITLE ? (
        <h6 className="text-h6 mb-2">{children}</h6>
      ) : null}
      {type === SectionsTypes.SUB_SUBTITLE ? (
        <p className="text-p mb-3">{children}</p>
      ) : null}
      {type === SectionsTypes.CONTENT ? (
        <p className="text-p1-medium mb-5">{children}</p>
      ) : null}
      {type === SectionsTypes.POINT ? (
        <li className="text-p1-small mb-3 pl-5">{children}</li>
      ) : null}
    </>
  );
};

export { Section };
