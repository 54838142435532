import { useTooltipsStore } from '@/stores/itineraryTooltipsStore';

export const useShowTailoringTooltips = () => {
  const didShowTailoringTooltips = useTooltipsStore(
    (state) => state.didShowTailoringTooltips,
  );

  const handleSetShownTailoringTooltips = useTooltipsStore(
    (state) => state.setDidShowTailoringTooltips,
  );

  const handleCloseTailoringTooltip = () =>
    handleSetShownTailoringTooltips(true);

  return {
    didShowTailoringTooltips,
    handleCloseTailoringTooltip,
  };
};
