import { useApi } from '@api/serviceProvider.ts';
import { useInitialSearchStore } from '@stores/initialSearchStore';
import { useTailorSearchStore } from '@stores/tailorSearchStore';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { Itinerary } from '@/generated';
import { MISSING_INPUT_VALUES_MESSAGE } from '@/utils/constants';
import { getFormattedDate } from '@/utils/formatDateTime';
import { showToast } from '@/utils/showToast';
import { useGoogleAnalytics } from './useGoogleAnalytics';

type Props = {
  isPendingItineraryNeeded?: boolean;
  isGAEventNeeded?: boolean;
};

export const useGenerateItinerary = ({ isPendingItineraryNeeded = false, isGAEventNeeded = true }: Props) => {
  const navigate = useNavigate();
  const { sendGAEvent, EVENT_ACTIONS, EVENT_CATEGORIES } = useGoogleAnalytics();

  const { location, dateRange, people, invitedPeople, isWaitingForPeople } = useInitialSearchStore((state) => state);

  const apiFactory = useApi();

  const resetTailoredSearch = useTailorSearchStore(
    (state) => state.resetTailoredSearch,
  );

  const isValidToGenerate =
    !location || !dateRange || !people || !dateRange.from || !dateRange.to;

  const {
    mutateAsync: generateItinerary,
    data,
    isPending,
  } = useMutation({
    mutationFn: async () => {
      if (isValidToGenerate) {
        return Promise.reject(MISSING_INPUT_VALUES_MESSAGE);
      }

      if (isGAEventNeeded) {
        sendGAEvent(EVENT_CATEGORIES.itinerary, EVENT_ACTIONS.generateItinerary);
      }

      return await apiFactory.api.apiV1ItinerariesGenerate(
        {
          persons: {
            adults: people.adults,
            children: people.children,
            infants: people.infants,
          },
          city: location.name,
          start_date: getFormattedDate({
            date: dateRange.from,
            format: 'YYYY-MM-DD',
          }),
          end_date: getFormattedDate({
            date: dateRange.to,
            format: 'YYYY-MM-DD',
          }),
          invited_users: invitedPeople ?? [],
          wait_for_all: isPendingItineraryNeeded ?? isWaitingForPeople,
        }
      );
    },
    onSuccess: (data: Itinerary) => {
      if (!data.id) {
        return;
      }

      if (isPendingItineraryNeeded !== undefined ? isPendingItineraryNeeded === false : !isWaitingForPeople) {
        resetTailoredSearch();
        navigate(`/itinerary/${data.id}`);
      }
      return data;
    },
    onError: (error) => {
      if (error.toString() === MISSING_INPUT_VALUES_MESSAGE) {
        return showToast('Missing or invalid input values');
      }
      showToast('Failed to generate itinerary');
    },
  });

  return {
    data,
    isPending,
    generateItinerary,
  };
};
