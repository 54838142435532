import { ActionButton } from '@components/buttons/ActionButton';
import { toast } from 'react-toastify';

type CloseButtonProps = {
  fillColor: 'white' | 'black';
  undo?: boolean;
  onClick?: () => void;
};

export const CloseToastButton = ({
  fillColor,
  undo,
  onClick,
}: CloseButtonProps) => {
  const handleClose = () => {
    toast.dismiss();
    if (undo) {
      onClick?.();
    }
  };
  return (
    <ActionButton onClick={handleClose} undo={undo} fillColor={fillColor} />
  );
};
