import { CardOnMap } from '@components/cards/CardOnMap';
import { TimeSlot } from '@components/timeSlots/TimeSlot';

import { Experience } from '@/generated';
import { usePinExperience } from '@/hooks/usePinExperience';
import { formatTimeRange } from '@/utils/formatDateTime';

type Props = {
  experience: Experience;
  maxWidth?: string;
};

export const ItineraryItemCard = ({
  experience,
  maxWidth,
}: Partial<Experience> & Props) => {
  const { id, date, start_time, end_time, details, name, pinned } = experience;

  const time = formatTimeRange(date ?? '', start_time, end_time);

  const { pinUnpinExperience } = usePinExperience(experience);

  return (
    <div
      id={id?.toString()}
      className="shrink-0 snap-start space-y-2 w-full px-2 lg:hidden"
    >
      <TimeSlot
        time={time}
        hasIcon
        pinned={pinned}
        pinClick={pinUnpinExperience}
      />
      <CardOnMap
        name={name}
        details={details}
        maxWidth={maxWidth}
        styles="lg:hidden"
      />
    </div>
  );
};
