import { Button } from '@components/buttons/Button';
import { OverlayBase } from '@components/modals/OverlayBase';
import {
  MenuItemProps,
  ModalOption,
} from '@components/modalsSections/modalOption/ModalOption';
import { useAddExperienceStore } from '@stores/addExperienceStore';
import { motion } from 'framer-motion';

import { showToast } from '@/utils/showToast';
import { useGetElementPosition } from '@/hooks/useGetElementPosition';
import { useWindowSize } from '@/hooks/useWindowSize';
import { useState } from 'react';
import { PopUpMenu } from '../menu/PopUpMenu';
import React from 'react';
import { MenuItem } from '../menu/MenuItem';

type Props = {
  onOverlayClick: () => void;
  onAddToTripClick: () => void;
  isOpen?: boolean;
  onClose?: () => void;
  menuCaller?: HTMLButtonElement | null;
};

export const AddExperienceOptionsModal = ({
  onOverlayClick,
  onAddToTripClick,
  isOpen = true,
  onClose,
  menuCaller,
}: Props) => {
  const chosenExperience = useAddExperienceStore(
    (state) => state.chosenExperience,
  );

  const [isMenu, setIsMenu] = useState(true);
  const { pos } = useGetElementPosition(menuCaller ?? null);
  const { windowType } = useWindowSize();

  const handleViewDetailsClick = () => {
    if (chosenExperience?.details?.website) {
      window.location.href = chosenExperience?.details?.website;
      return;
    }

    showToast('The chosen experience does not have any details');
  };

  const items: MenuItemProps[] = [
    {
      label: 'View details',
      icon: 'shareBox',
      onClick: handleViewDetailsClick,
    },
    {
      label: 'Add to my trip',
      icon: 'plus',
      onClick: onAddToTripClick,
    },
  ];

  const variants = {
    hidden: { y: '100vh' },
    visible: {
      y: '0',
      transition: { type: 'tween', stiffness: 60, damping: 10 },
    },
    exit: { y: '-100vh', transition: { ease: 'easeInOut' } },
  };

  const handleOnClose = () => {
    setIsMenu(true);
    onClose ? onClose() : null;
  };

  const handleOnChoose = (funcToExecute: () => void) => {
    funcToExecute();
    setIsMenu(true);
    onClose ? onClose() : null;
  };

  if (windowType === 'lg' && isMenu) {
    return (
      <PopUpMenu
        isOpen={isOpen}
        onClose={handleOnClose}
        styles={
          pos.left
            ? {
                left: pos.left - pos.width * 2 - 20,
                top: pos.top + pos.height + 10,
              }
            : {}
        }
      >
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <MenuItem
              iconName={item.icon}
              text={item.label}
              onClick={() => handleOnChoose(item.onClick)}
              isRounded
              iconColor={item.isHighlighted ? 'red' : undefined}
              textColor={item.isHighlighted ? 'red' : undefined}
            />
            <div className="border-b border-gray-light" />
          </React.Fragment>
        ))}
      </PopUpMenu>
    );
  }

  if (isOpen) {
    return (
      <OverlayBase onOverlayClick={onOverlayClick}>
        <motion.div
          className="mt-auto p-4"
          variants={variants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <div className="bg-white w-full rounded-xl mb-3">
            {items.map((item, index) => (
              <ModalOption
                key={item.label}
                {...item}
                hasBorder={index !== items.length - 1}
              />
            ))}
          </div>
          <Button
            size="large"
            variant="quaternary"
            roundnessVariant="xl"
            full
            onClick={onOverlayClick}
            bold={false}
          >
            Cancel
          </Button>
        </motion.div>
      </OverlayBase>
    );
  }

  return null;
};
