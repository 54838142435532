import classNames from 'classnames';
import { useState } from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';

import { FieldTextButton } from '../buttons/FieldTextButton';

export type Props<T extends Record<string, string>> = {
  control?: Control<T> | undefined;
  name: FieldPath<T>;
  label: string;
  placeholder: string;
  type: React.HTMLInputTypeAttribute;
  isDisabled?: boolean;
  autoComplete?: string;
  required?: boolean;
};

export const Input = <T extends Record<string, string>>({
  label,
  placeholder,
  control,
  type,
  isDisabled,
  autoComplete,
  name,
  required,
}: Props<T>) => {
  const [isPasswordDisplayed, setIsPasswordDisplayed] = useState(false);

  const isPasswordField = type === 'password';

  const toggleHiddenPassword = () =>
    setIsPasswordDisplayed(!isPasswordDisplayed);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <div className="relative h-[104px]">
          <label className="text-p-small text-gray p-1">{label}</label>
          <div
            className={classNames(
              'flex items-center px-3 py-4 bg-gray-light rounded-xl w-full',
              {
                'border-red border-[1px]': !!error?.message,
              },
            )}
          >
            <input
              {...field}
              className={classNames(
                'w-full bg-clip-content text-black text-[16px] font-medium placeholder:text-gray outline-none bg-gray-light caret-purple disabled:text-gray peer',
              )}
              disabled={isDisabled}
              type={isPasswordDisplayed ? 'text' : type}
              required={required}
              placeholder={placeholder}
              onChange={(e) => field.onChange(e.currentTarget.value)}
              autoComplete={autoComplete}
            />
            {isPasswordField && (
              <FieldTextButton onClick={toggleHiddenPassword}>
                {isPasswordDisplayed ? 'Hide' : 'Show'}
              </FieldTextButton>
            )}
          </div>
          {!!error?.message && (
            <p className="text-p-small text-red p-1">{error.message}</p>
          )}
        </div>
      )}
    />
  );
};
