import { Button } from '@components/buttons/Button';
import { SpriteIcon } from '@components/icons/SpriteIcon';
import classNames from 'classnames';

import { TooltipPosition } from '@/types/TooltipTypes';

type Props = {
  title: string;
  text: string;
  buttonLabel: string;
  position: TooltipPosition;
  onClick: () => void;
};

export const Tooltip = ({
  title,
  text,
  buttonLabel,
  position,
  onClick,
}: Props) => {
  return (
    <div className="relative bg-white mdm:w-[359px] flex flex-col px-5 py-5 rounded-3xl">
      <SpriteIcon
        iconName="triangleUp"
        className={classNames('absolute w-6 h-4 fill-white', {
          '-top-2.5 right-[74px]': position === 'TOP-RIGHT',
          '-top-2.5 left-[74px]': position === 'TOP-LEFT',
          'rotate-180 top-[182px] left-[63px]': position === 'BOTTOM-LEFT',
        })}
      />
      <div className="flex max-w-full flex-col self-start">
        <div className="justify-between items-center flex w-full mb-1 self-start">
          <p className="text-h6">{title}</p>
        </div>
        <p className="text-gray text-p-medium mb-5 self-start">{text}</p>
      </div>
      <Button size="medium" onClick={onClick}>
        {buttonLabel}
      </Button>
    </div>
  );
};
