import { Persons } from '@/generated';

export const getTotalPeopleCount = (
  people: Persons | null | undefined,
): number => {
  if (!people) {
    return 0;
  }
  const { adults, children, infants } = people;
  const totalPeople = [adults, children, infants]
    .filter((count) => count !== undefined)
    .reduce((acc, count) => (acc || 0) + (count || 0), 0 as number);

  return totalPeople ?? 0;
};
