import { TabBarButton } from '@components/buttons/TabBarButton';
import { tabbarButtons } from '@data/appData/tabbarData';
import { useLocation, useNavigate } from 'react-router-dom';

export const Tabbar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const currentTab = tabbarButtons.find((tab) => tab.routeName === pathname);
  const absoluteStyle: React.CSSProperties = {
    position: 'absolute',
    left: 0,
  };

  return (
    <div
      className="flex bottom-4 justify-between bg-white w-full rounded-36 p-2 shadow-bottom-bar fixed mdx:hidden"
      style={absoluteStyle}
    >
      {tabbarButtons.map(({ icon, value, routeName }) => (
        <TabBarButton
          key={value}
          icon={icon}
          isActive={currentTab?.value === value}
          onClick={() => navigate(routeName)}
        >
          {value}
        </TabBarButton>
      ))}
    </div>
  );
};
