import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { passwordValidation } from '@/utils/yupValidations';

const schema = yup.object().shape({
  new_password1: passwordValidation,
  new_password2: passwordValidation.oneOf(
    [yup.ref('new_password1'), ''],
    'Passwords must match',
  ),
});

export type ChangePasswordSchemaType = yup.InferType<typeof schema>;

export const useChangePasswordForm = () => {
  const { control, handleSubmit, formState } =
    useForm<ChangePasswordSchemaType>({
      mode: 'all',
      reValidateMode: 'onBlur',
      shouldFocusError: true,
      defaultValues: {
        new_password1: '',
        new_password2: '',
      },
      resolver: yupResolver(schema),
    });

  const isSubmitDisabled = !formState.isValid || formState.isSubmitting;

  return {
    control,
    handleSubmit,
    isSubmitting: formState.isSubmitting,
    isSubmitDisabled,
  };
};
