import { interestSectionData } from '@/data/appData/startPageData';
import { InterestItem } from './InterestItem';

export const InterestSection = () => {
  // Calculate the middle index for splitting the array into two parts
  const middleIndex = Math.ceil(interestSectionData.length / 2);

  return (
    <div className="flex justify-center my-10">
      <div className="flex flex-col gap-3 max-w-screen-lgx overflow-x-auto hide-scrollbar">
        <div className="flex flex-nowrap gap-3">
          {interestSectionData.slice(0, middleIndex).map((interest, index) => (
            <InterestItem key={index} content={interest} />
          ))}
        </div>
        <div className="flex flex-nowrap gap-3 pl-8">
          {interestSectionData.slice(middleIndex).map((interest, index) => (
            <InterestItem key={middleIndex + index} content={interest} />
          ))}
        </div>
      </div>
    </div>
  );
};
