import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { showToast } from '@/utils/showToast';

export const useSuggestDifferentExperience = (
  experienceId?: number | undefined,
) => {
  const queryClient = useQueryClient();
  const apiFactory = useApi();

  const { mutate, isPending } = useMutation({
    mutationKey: ['suggestDifferentExperience'],
    mutationFn: async () => {
      if (!experienceId) {
        return;
      }

      return await apiFactory.api.apiV1ExperiencesSuggestDifferent(
        experienceId,
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.itinerary],
      });
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.commutes],
      });
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.commuteList],
      });
      showToast('Experience suggested successfully');
    },
    onError: () => showToast('Failed to suggest experience'),
  });

  return {
    handleSuggestDifferentExperience: mutate,
    isLoading: isPending,
  };
};
