import { SearchInput } from '@components/inputs/SearchInput';
import { InterestsList } from '@components/lists/InterestList';
import { Step } from '@components/tailoringSteps/Step';
import { useGetInterests } from '@hooks/useGetInterests';
import { TailoredSearchTypes } from '@stores/tailorSearchStore';
import { useCallback, useState } from 'react';

import { InvitationSearchTypes } from '@/stores/invitationStore';
import { ActivityTag } from '@/types/AppTypes';

type Props = {
  activity_tags: ActivityTag[] | undefined;
  setAvailableInterests: React.Dispatch<React.SetStateAction<ActivityTag[]>>;
  storage: TailoredSearchTypes | InvitationSearchTypes;
  setStorageSearch: (value: Partial<TailoredSearchTypes>) => void;
};

export const Interests = ({
  activity_tags,
  setAvailableInterests,
  storage,
  setStorageSearch,
}: Props) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const { interests, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useGetInterests(searchValue, activity_tags);

  const handleSelectClick = useCallback(
    (selectedInterest: ActivityTag) => {
      if (storage.interests) {
        const existingPreferences = storage.interests;

        // Check if the selected preference is already in the list
        const index = existingPreferences.findIndex(
          (pref) => pref.tag === selectedInterest.tag,
        );

        if (index > -1) {
          // Remove the selected preference from the list
          existingPreferences.splice(index, 1);
          setStorageSearch({ interests: existingPreferences });
          setAvailableInterests(storage.interests);
          return;
        }

        setStorageSearch({
          interests: [...existingPreferences, selectedInterest],
        });
        setAvailableInterests(storage.interests);
        return;
      }
      setStorageSearch({ interests: [selectedInterest] });
      setAvailableInterests(storage.interests!);
    },
    [storage.interests, setStorageSearch, setAvailableInterests],
  );

  const onSearchInterests = (value: string) => {
    setSearchValue(value);
  };

  const filteredTags =
    searchValue && activity_tags
      ? activity_tags.filter((tag) =>
          tag.name.toLowerCase().includes(searchValue.toLowerCase()),
        )
      : activity_tags;

  return (
    <Step heading="What are you interested in?">
      <SearchInput value={searchValue} onSearch={onSearchInterests} />
      <InterestsList
        interests={filteredTags || interests}
        selectedInterests={storage?.interests}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onInterestClick={handleSelectClick}
        fetchNextPage={fetchNextPage}
      />
    </Step>
  );
};
