import { IconButton } from '@components/buttons/IconButton';
import { SpriteIcon } from '@components/icons/SpriteIcon';
import classNames from 'classnames';
import { Key, forwardRef } from 'react';

type Props = {
  travelerKey: Key;
  traveler: any;
  isAllowEdit: boolean;
  onEditTraveler: (traveler: any) => void;
};

export const Traveler = forwardRef<HTMLButtonElement, Props>(
  ({ travelerKey, traveler, isAllowEdit, onEditTraveler }, ref) => {
    return (
      <div
        key={travelerKey}
        className={classNames(
          'w-full flex justify-between items-center bg-white p-4',
          `${travelerKey === 0 ? 'rounded-t-[12px]' : 'rounded-0'}`,
          'border-b border-gray-light',
        )}
      >
        <div className="flex items-center gap-x-2">
          <SpriteIcon
            iconName={traveler.is_accepted ? 'done' : 'pending'}
            className="w-6 h-6"
          />
          <p className="text-p-medium">
            {typeof traveler === 'string'
              ? traveler
              : traveler.username || traveler.email}
          </p>
        </div>
        {isAllowEdit && (
          <IconButton
            size="medium"
            background="white"
            iconName="edit"
            onClick={() => onEditTraveler(traveler)}
            ref={ref}
          />
        )}
      </div>
    );
  },
);
