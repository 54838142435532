import { SpriteIconProps } from '@components/icons/SpriteIcon';

const travelModeToIconMapping: Record<string, SpriteIconProps> = {
  pedestrian: 'pedestrian',
  bicycle: 'bicycle',
  bus: 'bus',
  taxi: 'taxi',
  car: 'car',
};

export const mapTravelModeToIcon = (travelMode?: string): SpriteIconProps => {
  if (!travelMode) return 'pedestrian';
  return travelModeToIconMapping[travelMode.toLowerCase()] || 'pedestrian';
};
