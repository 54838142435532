import { Experience } from "@/generated";

export const useSplitExperiences = (experiences: Experience[] | undefined) => {
    const splitExperiences = experiences?.reduce(
        (acc, experience) => {
            if (experience?.date) {
                const date = new Date(experience.date).toDateString();
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date].push(experience);
            }
            return acc;
        },
        {} as Record<string, Experience[]>,
    );

    // Convert the object into an array of objects with the desired format
    const formattedSplitExperiences = Object.keys(splitExperiences || {}).map(date => ({
        [date]: splitExperiences![date],
    }));

    return formattedSplitExperiences;
}
