import classNames from 'classnames';
import imgFirst from '@assets/images/landingPage/img_1.webp';
import imgSecond from '@assets/images/landingPage/img_2.webp';
import imgThird from '@assets/images/landingPage/img_3.webp';
import imgFourth from '@assets/images/landingPage/img_4.webp';
import { ImageContainer } from '../containers/ImageContainer';
import { memo } from 'react';

export type ImageType = {
  src: string;
  styleClass: string;
};
export const HeroBgImages: ImageType[] = [
  {
    src: imgFirst,
    styleClass: 'bg-hero-img-1',
  },
  {
    src: imgSecond,
    styleClass: 'bg-hero-img-2',
  },
  {
    src: imgThird,
    styleClass: 'bg-hero-img-3',
  },
  {
    src: imgFourth,
    styleClass: 'bg-hero-img-4',
  },
];

type Props = {
  src?: string;
  images?: ImageType[];
  currentImageIndex?: number;
  styles?: string;
  children?: React.ReactNode;
};

export const BackgroundImage = memo(
  ({
    images = HeroBgImages,
    currentImageIndex = 1,
    styles,
    children,
  }: Props) => {
    if (images && !currentImageIndex) {
      return null;
    }

    return (
      <div
        className={classNames(
          'flex flex-col relative px-4',
          'bg-no-repeat bg-cover bg-center',
          'clip-path-folder-sm rounded-3xl',
          'mdm:clip-path-folder-md mdm:px-9',
          'lg:clip-path-folder lg:px-[68px] lg:min-h-[744px] lg:justify-between',
          `w-full ${styles}`,
          // images[currentImageIndex - 1].styleClass,
        )}
      >
        <ImageContainer
          image={images[currentImageIndex - 1].src}
          alt="background"
          overrideStylesImage="absolute object-cover left-0 top-0 w-full h-full -z-[1]"
          displayImage={true}
        />
        {/* The following SVGs are used to create a diagonal cut on the right side of the image */}
        {/* mobile and tablet */}
        <div className="absolute top-[61px] mdm:top-[73px] lg:hidden right-0 mdm:-right-[1px]">
          <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0C17 1 22.5 15.5 23 22V0H0Z" fill="white" />
          </svg>
        </div>
        <div className="absolute lg:hidden -right-1 mdm:-right-[1px] rotate-90 bottom-0">
          <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0C17 1 22.5 15.5 23 22V0H0Z" fill="white" />
          </svg>
        </div>
        <div className="absolute lg:hidden -left-[1px] rotate-180 bottom-0">
          <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0C17 1 22.5 15.5 23 22V0H0Z" fill="white" />
          </svg>
        </div>
        {/* desktop */}
        <div className="hidden lg:flex top-[96.57px] -right-[1px] absolute">
          <svg
            width="39"
            height="38"
            viewBox="0 0 39 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0.5C28.5 2.5 38.1304 27.6591 39 38V1L0 0.5Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="hidden lg:flex bottom-0 -right-[2px] absolute rotate-90">
          <svg
            width="39"
            height="38"
            viewBox="0 0 39 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0.5C28.5 2.5 38.1304 27.6591 39 38V1L0 0.5Z"
              fill="white"
            />
          </svg>
        </div>
        {children}
      </div>
    );
  },
);
