export const CommutingCardSkeleton = () => {
  return (
    <div className="flex relative items-center justify-between w-full h-14 max-w-[356px]">
      <div className="rounded-2xl p-3 shadow-general min-w-14 h-14 relative animate-pulse bg-gray-medium" />
      <div className="flex flex-col justify-center w-full h-14 gap-2">
        <div className="ml-4 rounded-2xl h-5 shadow-general w-1/2 animate-pulse bg-gray-medium" />
        <div className="ml-4 rounded-2xl h-3 shadow-general w-3/4 animate-pulse bg-gray-medium" />
      </div>
      <div className="rounded-full p-3 shadow-general min-w-10 h-10 relative animate-pulse bg-gray-medium" />
    </div>
  );
};
