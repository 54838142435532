import { useGetElementPosition } from '@/hooks/useGetElementPosition';
import { usePeopleAmount } from '@/hooks/usePeopleAmount';
import { useWindowSize } from '@/hooks/useWindowSize';
import { Button } from '@components/buttons/Button';
import { Header } from '@components/headers/Header';
import { PeopleAmount } from '@components/modalsSections/peopleAmount/PeopleAmount';
import { useInitialSearchStore } from '@stores/initialSearchStore';
import { useStartPageModalStore } from '@stores/startPageModalStore';
import { getTotalPeopleCount } from '@utils/getTotalPeopleCount';
import { BottomSheet } from '../bottomSheets/BottomSheet';
import classNames from 'classnames';
import { SpriteIcon } from '../icons/SpriteIcon';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  menuCaller?: HTMLDivElement | null;
};

export const PeopleModal = ({ isOpen, onClose, menuCaller }: Props) => {
  const { setOpenedModal } = useStartPageModalStore();
  const initialSearchPeople = useInitialSearchStore((state) => state.people);
  const setInitialSearch = useInitialSearchStore(
    (state) => state.setInitialSearch,
  );

  const peopleAmount = usePeopleAmount();
  const { windowType } = useWindowSize();
  const { pos } = useGetElementPosition(menuCaller ?? null);
  const people = useInitialSearchStore((state) => state.people);

  if (windowType !== 'lg') {
    return (
      <BottomSheet isOpen={isOpen} onClose={onClose} bgWhite={false}>
        <Header
          actionButtonText="Clear"
          onActionButtonClick={() => {
            setInitialSearch({
              people: null,
              invitedPeople: null,
              isWaitingForPeople: false,
            });
          }}
          onBackButtonClick={() => setOpenedModal(null)}
        />
        <div className="flex flex-col gap-5 w-full py-5">
          <h4 className="text-h4 flex self-start">Who's coming?</h4>
          <PeopleAmount
            people={peopleAmount.people}
            invitedPeople={peopleAmount.invitedPeople}
            itineraryData={peopleAmount.data}
            generatedItineraryId={peopleAmount.generatedItineraryId}
            isInviteBottomSheet={peopleAmount.isInviteBottomSheet}
            handleAmountChange={peopleAmount.handleAmountChange}
            onInviteHandler={peopleAmount.onInviteHandler}
            setIsInviteBottomSheet={peopleAmount.setIsInviteBottomSheet}
          />
        </div>
        <div className="w-full p-4">
          <Button
            size="large"
            disabled={!getTotalPeopleCount(initialSearchPeople)}
            onClick={() => setOpenedModal(null)}
            full
          >
            Apply
          </Button>
        </div>
      </BottomSheet>
    );
  } else {
    return (
      <>
        <div
          style={{
            margin: 0,
            width: pos.width ? `${pos.width}px` : undefined,
            height: pos.height ? `${pos.height}px` : undefined,
            top: pos.top ? `${pos.top}px` : undefined,
            left: pos.left ? `${pos.left}px` : undefined,
          }}
          className={classNames('fixed m-0 z-10', '', {
            flex: isOpen,
            hidden: !isOpen,
          })}
        >
          <div
            className={classNames(
              'rounded-48 ml-2 my-2 bg-white flex items-center',
              'px-4 py-2',
            )}
            style={{
              width: pos.width ? `${pos.width - 16}px` : undefined,
              height: pos.height ? `${pos.height - 16}px` : undefined,
            }}
          >
            <div className="flex gap-x-1">
              <SpriteIcon iconName="user" className="w-5 h-5" />
              <p className="text-p">
                {getTotalPeopleCount(people)
                  ? getTotalPeopleCount(people) == 1
                    ? '1 traveler'
                    : `${getTotalPeopleCount(people)} travelers`
                  : 'With whom?'}
              </p>
            </div>
            <div
              className={classNames(
                'absolute bg-white rounded-48 left-2 z-10 p-5 min-w-[380px] shadow-general',
              )}
              style={{
                top: pos.height ? `${pos.height}px` : undefined,
              }}
            >
              <PeopleAmount
                people={peopleAmount.people}
                invitedPeople={peopleAmount.invitedPeople}
                itineraryData={peopleAmount.data}
                generatedItineraryId={peopleAmount.generatedItineraryId}
                isInviteBottomSheet={peopleAmount.isInviteBottomSheet}
                handleAmountChange={peopleAmount.handleAmountChange}
                onInviteHandler={peopleAmount.onInviteHandler}
                setIsInviteBottomSheet={peopleAmount.setIsInviteBottomSheet}
                withoutShadow
              />
            </div>
          </div>
        </div>
        <div
          className={classNames('fixed top-0 left-0 w-full h-svh z-9 !m-0', {
            hidden: !isOpen,
            block: isOpen,
          })}
          onClick={onClose}
        />
      </>
    );
  }
};
