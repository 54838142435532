import { useState } from 'react';
import defaultPlaceholderImage from '@assets/images/landingPage/experience-placeholder.webp';
import classNames from 'classnames';

type Props = {
  image: string | undefined | null;
  placeholderImg?: string;
  alt?: string;
  displayImage?: boolean;
  overrideStylesLoadingDiv?: string;
  overrideStylesImage?: string;
};

export const ImageContainer = ({
  image,
  placeholderImg,
  alt,
  displayImage = false,
  overrideStylesImage,
  overrideStylesLoadingDiv,
}: Props) => {
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(true);
  const [imageToDisplay, setImageToDisplay] = useState<
    string | undefined | null
  >(image);
  const placeholder = placeholderImg ?? defaultPlaceholderImage;

  return (
    <>
      {!displayImage ||
        (isLoadingImage && (
          <div
            className={classNames(
              'animate-pulse bg-gray-medium absolute w-full h-full inset-0',
              {
                [overrideStylesLoadingDiv ?? '']: !!overrideStylesLoadingDiv,
              },
            )}
          />
        ))}
      <img
        loading="lazy"
        src={imageToDisplay ?? ''}
        className={classNames(
          'absolute h-full w-full object-cover object-center inset-0 transition-opacity duration-300',
          {
            [overrideStylesImage ?? '']: !!overrideStylesImage,
            'opacity-0': !displayImage || isLoadingImage,
            'opacity-100': displayImage && !isLoadingImage,
          },
        )}
        onLoad={() => setIsLoadingImage(false)}
        onError={() => setImageToDisplay(placeholder)}
        alt={alt ?? 'travel experience'}
      />
    </>
  );
};
