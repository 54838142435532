import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeyTypes } from '@/api/queryKeyTypes';
import { useApi } from '@/api/serviceProvider';
import { EditEmailSchemaType } from '@/forms/useEditEmailForm';
import { showToast } from '@/utils/showToast';

import { useGetProfile } from './useGetProfile';

export const useEditEmail = () => {
  const { api } = useApi();
  const { profile } = useGetProfile();

  const queryClient = useQueryClient();

  const userId = profile?.id;

  const { mutate, isPending } = useMutation({
    mutationFn: async ({ email }: EditEmailSchemaType) => {
      if (!email || !userId) {
        return;
      }

      return await api.apiV1UsersPartialUpdate(userId, {
        username: email,
        email,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.profile],
      });

      showToast('Email changed successfully');
    },
    onError: () => showToast('Failed to change your email'),
  });

  return {
    handleEditEmail: mutate,
    isLoading: isPending,
  };
};
