import { InvitationSearchTypes } from '@/stores/invitationStore';
import { TailoringButton } from '@components/buttons/TailoringButton';
import { Step } from '@components/tailoringSteps/Step';
import { budgetStepOptions } from '@data/appData/budgetStepData';
import { TailoredSearchTypes } from '@stores/tailorSearchStore';

type Props = {
  storage: TailoredSearchTypes | InvitationSearchTypes;
  setStorageSearch:
    | ((value: Partial<TailoredSearchTypes>) => void)
    | ((value: Partial<InvitationSearchTypes>) => void);
};
export const BudgetStep = ({ storage, setStorageSearch }: Props) => {
  const handleSelectOption = (
    min_price: number | null,
    max_price: number | null,
  ) => {
    setStorageSearch({
      dailyBudget: {
        min_price,
        max_price,
      },
    });
  };

  return (
    <Step heading="What is your travel budget per day?">
      {budgetStepOptions.map(({ min_price, max_price, text }) => (
        <TailoringButton
          key={`${text}: ${min_price} - ${max_price}`}
          onClick={() => handleSelectOption(min_price, max_price)}
          selected={
            min_price === storage.dailyBudget?.min_price &&
            max_price === storage.dailyBudget?.max_price
          }
        >
          {text}
        </TailoringButton>
      ))}
    </Step>
  );
};
