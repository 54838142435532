import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider.ts';
import { SpriteIcon } from '@components/icons/SpriteIcon';
import { CloseToastButton } from '@components/toasts/CloseToastButton';
import { ToastMessage } from '@components/toasts/ToastMessage';
import { useExperiencesUndoDeletion } from '@hooks/useExperiencesUndoDeletion';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { showToast } from '@/utils/showToast';

export const useDeleteExperienceFromItinerary = (
  experienceId: number | undefined,
  onSuccess: () => void,
) => {
  const queryClient = useQueryClient();
  const apiFactory = useApi();

  const { handleUndoExperienceDeletion, isUndoExperienceDeletionLoading } =
    useExperiencesUndoDeletion(experienceId);

  const {
    mutateAsync: handleDeleteExperience,
    isPending: isDeleteExperienceLoading,
  } = useMutation({
    mutationFn: async () => {
      if (!experienceId) {
        return;
      }

      return apiFactory.api.apiV1ExperiencesDelete(experienceId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.itinerary],
      });
      await queryClient.invalidateQueries({
        queryKey: [queryKeyTypes.commutes],
      });

      onSuccess();

      toast(
        <ToastMessage
          message="Experience removed successfully"
          variant="dark"
          icon={
            <SpriteIcon
              iconName="basket"
              className="w-5 h-5 fill-white stroke-[2px]"
            />
          }
        />,
        {
          theme: 'dark',
          closeButton: (
            <CloseToastButton
              fillColor="white"
              undo
              onClick={handleUndoExperienceDeletion}
            />
          ),
        },
      );
    },
    onError: () => showToast('Failed to remove experience'),
  });

  return {
    handleDeleteExperience,
    isDeleteExperienceLoading:
      isUndoExperienceDeletionLoading || isDeleteExperienceLoading,
  };
};
