import { Button } from '@components/buttons/Button';
import { TransportButton } from '@components/buttons/TransportButton';
import { CommuteRoute } from '@components/modalsSections/commuteRoute/CommuteRoute';
import { useGetMyItineraryDetails } from '@hooks/useGetMyItineraryDetails';
import { useUpdateCommute } from '@hooks/useUpdateCommute';
import { createMapURL } from '@utils/createMapURL';
import { formatCommuteDuration } from '@utils/formatCommuteDuration';
import { mapTravelModeToIcon } from '@utils/mapTravelModeToIcon';
import { useState } from 'react';

import { Commute, Experience } from '@/generated';
import { isValidCommuteMode } from '@/utils/isValidCommuteMode';
import { PopUpContainer } from '../containers/PopUpContainer';

type Props = {
  commute: Commute;
  itineraryId: number | undefined;
  isOpen: boolean;
  onClose: () => void;
};

export const ChangeCommuteOptionsModal = ({
  commute,
  itineraryId,
  ...rest
}: Props) => {
  const [selectedTravelType, setSelectedTravelType] = useState(
    commute?.travel_mode,
  );

  const { itinerary } = useGetMyItineraryDetails(itineraryId);

  const experiences: Experience[] = itinerary?.experiences ?? [];

  const updatedCommute = {
    travel_mode: selectedTravelType,
  };

  const { handleUpdateCommute, isUpdateCommuteLoading } = useUpdateCommute(
    commute?.id,
    updatedCommute,
    rest.onClose,
  );

  const originExperience = experiences?.find(
    (experience: Experience) => experience.id === commute.origin_id,
  );
  const destinationExperience = experiences?.find(
    (experience: Experience) => experience.id === commute.destination_id,
  );

  const handleGetDirections = () => {
    const originLocation = encodeURIComponent(
      `${originExperience?.details?.coordinates.lat || ''},${
        originExperience?.details?.coordinates.lng || ''
      }`,
    );
    const destinationLocation = encodeURIComponent(
      `${destinationExperience?.details?.coordinates.lat || ''},${
        destinationExperience?.details?.coordinates.lng || ''
      }`,
    );

    const travelMode = (
      selectedTravelType ?? Commute.travel_mode.PEDESTRIAN
    ).toLowerCase();

    const mapUrl = createMapURL(
      originLocation,
      destinationLocation,
      travelMode,
    );
    window.open(mapUrl, '_blank');
  };

  const allAvailableTravelModes = commute?.all_modes.filter(({ duration }) =>
    isValidCommuteMode(
      Number(duration),
      originExperience,
      destinationExperience,
    ),
  );

  return (
    <PopUpContainer snapPoints={[0.55]} {...rest} isPadded={false}>
      <div className="flex flex-col w-full h-full px-4">
        <h4 className="text-h4 text-black mb-4">Edit travel</h4>
        <CommuteRoute
          origin={originExperience?.details?.place_name ?? 'Origin not found'}
          destination={
            destinationExperience?.details?.place_name ??
            'Destination not found'
          }
        />
        <div className="my-6 flex flex-wrap gap-2">
          {allAvailableTravelModes?.map((transportType) => (
            <TransportButton
              disabled={isUpdateCommuteLoading}
              key={transportType.travel_mode}
              icon={mapTravelModeToIcon(transportType.travel_mode)}
              selected={transportType.travel_mode === selectedTravelType}
              onClick={() => setSelectedTravelType(transportType.travel_mode)}
            >
              {formatCommuteDuration(Number(transportType.duration))}
            </TransportButton>
          ))}
        </div>
        <div className="flex gap-1 justify-center lg:justify-between">
          <Button
            size="large"
            variant="secondary"
            disabled={isUpdateCommuteLoading}
            onClick={handleGetDirections}
          >
            Get directions
          </Button>
          <Button
            size="large"
            variant="primary"
            onClick={handleUpdateCommute}
            isLoading={isUpdateCommuteLoading}
            disabled={
              isUpdateCommuteLoading ||
              commute.travel_mode === selectedTravelType
            }
          >
            Save
          </Button>
        </div>
      </div>
    </PopUpContainer>
  );
};
