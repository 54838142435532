import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

const schema = yup.object().shape({
  username: yup.string().required('Username is required'),
});

export type EnterUsernameSchemaType = yup.InferType<typeof schema>;

export const useAcceptInvitationAnonymous = () => {
  const { control, handleSubmit, formState, setValue, watch } =
    useForm<EnterUsernameSchemaType>({
      mode: 'all',
      reValidateMode: 'onBlur',
      shouldFocusError: true,
      defaultValues: {
        username: '',
      },
      resolver: yupResolver(schema),
    });

  const isSubmitDisabled = !formState.isValid || formState.isSubmitting;

  const setUsername = (username: string) => {
    setValue('username', username);
  };

  const username = watch('username');

  return {
    control,
    setValue: setUsername,
    handleSubmit,
    isSubmitting: formState.isSubmitting,
    isSubmitDisabled,
    username,
  };
};
