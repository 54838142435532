import { SpriteIcon, SpriteIconProps } from '@components/icons/SpriteIcon';

type Props = {
  locationText: string;
  iconName: SpriteIconProps;
};

export const CommuteRouteItem = ({ locationText, iconName }: Props) => {
  return (
    <div className="flex gap-x-2 items-center z-10">
      <div className="bg-gray-light p-1 rounded-full">
        <SpriteIcon iconName={iconName} className="w-4 h-4" />
      </div>
      <span className="text-p-medium w-full max-w-[276px] overflow-hidden whitespace-nowrap text-ellipsis">
        {locationText}
      </span>
    </div>
  );
};
