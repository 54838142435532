import { ExperienceFilterList } from '@components/lists/ExperienceFilterList';

type Props = {
  title: string;
  filters: string[];
  chosenFilters: string[];
  onFiltersClick: (value: string) => void;
};

export const FiltersSection = ({
  title,
  filters,
  chosenFilters,
  onFiltersClick,
}: Props) => {
  return (
    <section>
      <h5 className="text-h5 pb-4">{title}</h5>
      <ExperienceFilterList
        filters={filters}
        isWrapped
        buttonSize="smedium"
        chosenFilters={chosenFilters}
        onFilterClick={onFiltersClick}
      />
    </section>
  );
};
