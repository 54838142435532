import { ImageWithPlaceholder } from '@components/images/ImageWithPlaceholder';
import { formatTimeRange } from '@utils/formatDateTime.ts';

import { Experience } from '@/generated';

type Props = {
  experience?: Experience;
  isNew?: boolean;
  startTime?: string;
  endTime?: string;
};

export const OverlappingCard = ({
  experience,
  isNew = false,
  startTime = '00:00',
  endTime = '00:00',
}: Props) => {
  if (!experience) {
    return null;
  }

  return (
    <div
      className={`flex w-full max-h-[104px] rounded-xl relative ${
        isNew ? 'text-white bg-black' : 'text-black bg-gray-light'
      }`}
    >
      <ImageWithPlaceholder
        src={experience?.details?.place_photo || undefined}
        styles={'w-2/5 h-full object-cover rounded-lg'}
        alt={experience.name || 'place photo'}
        minHeight="h-24"
      />
      {isNew ? (
        <div className="flex flex-col h-full w-3/5 ml-2 mr-2">
          <p className="text-p-small left text-right mt-1">NEW</p>
          <div className="">
            <h4 className="text-p-medium text-black left h-1/2 text-white relative top-0">
              {experience?.name}
            </h4>
            <p className="text-p-medium text-gray right absolute right-1 bottom-1">
              {formatTimeRange(experience.date || '', startTime, endTime)}
            </p>
          </div>
        </div>
      ) : (
        <div className={'flex flex-col h-full w-3/5 ml-2 mr-2 mt-2'}>
          <h4 className="text-p-medium text-black left h-1/2 text-black">
            {experience?.name}
          </h4>
          <p className="text-p-medium text-gray right absolute right-1 bottom-1">
            {formatTimeRange(
              experience.date || '',
              experience.start_time,
              experience.end_time,
            )}
          </p>
        </div>
      )}
    </div>
  );
};
