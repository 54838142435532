import { TailoringButton } from '@components/buttons/TailoringButton';
import { SearchInput } from '@components/inputs/SearchInput';
import { FullScreenLoader } from '@components/loaders/FullScreenLoader';
import { Step } from '@components/tailoringSteps/Step';
import { useGetFoodPreferences } from '@hooks/useGetFoodPreferences';
import { TailoredSearchTypes } from '@stores/tailorSearchStore';
import React, { Key, useCallback, useEffect, useState } from 'react';

import { FoodPreferenceType } from '@/types/FoodPreference';
import { InvitationSearchTypes } from '@/stores/invitationStore';

type Props = {
  availableFoodPreferences: FoodPreferenceType[];
  setAvailableFoodPreferences: React.Dispatch<
    React.SetStateAction<FoodPreferenceType[]>
  >;
  storage: TailoredSearchTypes | InvitationSearchTypes;
  setStorageSearch: (value: Partial<TailoredSearchTypes>) => void;
};

export const FoodPreferences = ({
  availableFoodPreferences,
  setAvailableFoodPreferences,
  storage,
  setStorageSearch,
}: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const { foodPreferences, isLoading, searchForFoodPreferences } =
    useGetFoodPreferences(searchValue);

  const handleSelectClick = useCallback(
    (selectedPreference: FoodPreferenceType) => {
      if (storage.foodPreferences) {
        const preferences = [...storage.foodPreferences];
        const index = preferences.findIndex(
          (pref) => pref.code === selectedPreference.code,
        );

        if (index > -1) {
          preferences.splice(index, 1);
        } else {
          preferences.push(selectedPreference);
        }

        setStorageSearch({ foodPreferences: preferences });
        setAvailableFoodPreferences(preferences);
      } else {
        setStorageSearch({ foodPreferences: [selectedPreference] });
        setAvailableFoodPreferences([selectedPreference]);
      }

      // try to remove from Diets choices if it exists
      if (storage.diets) {
        const preferences = [...storage.diets];
        const index = preferences.findIndex(
          (pref) => pref.code === selectedPreference.code,
        );

        if (index > -1) {
          preferences.splice(index, 1);
          setStorageSearch({ diets: preferences });
        }
      }
    },
    [storage.foodPreferences, setStorageSearch, setAvailableFoodPreferences],
  );

  const onSearchFoodPreferences = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    searchForFoodPreferences();
  }, [searchValue, searchForFoodPreferences]);

  return (
    <Step heading="What is your favorite food/drink experiences?">
      {isLoading && <FullScreenLoader text="Getting food preferences" />}
      <SearchInput value={searchValue} onSearch={onSearchFoodPreferences} />
      {(availableFoodPreferences || foodPreferences.length > 0) && (
        <div className="flex flex-wrap overflow-y-auto max-h-[50svh] justify-center gap-3">
          {foodPreferences.map((preference: FoodPreferenceType, index: Key) => (
            <TailoringButton
              key={index}
              onClick={() => handleSelectClick(preference)}
              selected={storage.foodPreferences?.some(
                (foodPreference) => foodPreference.code === preference.code,
              )}
              multipleChoice
            >
              {preference.text}
            </TailoringButton>
          ))}
        </div>
      )}
    </Step>
  );
};
