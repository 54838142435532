import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider';
import { useRemoveDuplicatesFromInterestArray } from '@hooks/useRemoveDuplicatesFromInterestArray';
import { FIRST_PAGE_NUMBER, PAGE_SIZE } from '@utils/constants';

import { Interest } from '@/generated';
import { ActivityTag } from '@/types/AppTypes';

interface ApiResponse {
  count?: number | undefined;
  next?: string | null;
  previous?: string | null;
  results: Interest[];
}

export const useGetInterests = (searchValue: string | undefined, activity_tags: ActivityTag[] | undefined) => {
  const apiFactory = useApi();
  const [isEnabled, setIsEnabled] = useState(false);

  const fetchInterestList = async ({
    pageParam = FIRST_PAGE_NUMBER,
  }): Promise<ApiResponse> => {
    return apiFactory.api.apiV1InterestsList(searchValue, pageParam, PAGE_SIZE);
  };

  const {
    data,
    error,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryFn: fetchInterestList,
    initialPageParam: FIRST_PAGE_NUMBER,
    queryKey: [queryKeyTypes.interests, searchValue],
    getNextPageParam: (lastPage, allPages) =>
      lastPage.results?.length === PAGE_SIZE ? allPages.length + 1 : undefined,
    enabled: isEnabled,
  });

  useEffect(() => {
    if (isEnabled) {
      refetch();
    }
  }, [searchValue, refetch, isEnabled]);

  useEffect(() => {
    if (!activity_tags) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  }, [activity_tags]);

  const featuredInterests =
    data?.pages.map(({ results }) => results).flat(1) || [];

  const featuredInterestsWithoutDuplicates =
    useRemoveDuplicatesFromInterestArray(featuredInterests);

  const interestsToActivityTags = featuredInterestsWithoutDuplicates?.map((interest) => {
    return {
      nmb: undefined,
      tag: interest.tag,
      name: interest.name,
    } as ActivityTag;
  });

  return {
    interests: interestsToActivityTags || [],
    error,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  };
};
