type Props = {
  label: string;
  value: string;
  placeholder: string;
};

export const DisabledInput = ({ label, value, placeholder }: Props) => {
  return (
    <div className="relative h-[104px] w-full">
      <label className="text-p-small text-gray p-1">{label}</label>
      <div className="flex items-center px-3 py-4 bg-gray-light rounded-xl w-full">
        <input
          name="label"
          className="w-full bg-clip-content text-black text-[16px] font-medium placeholder:text-gray outline-none bg-gray-light caret-purple disabled:text-gray peer"
          type="text"
          value={value}
          placeholder={placeholder}
          readOnly={true}
        />
      </div>
    </div>
  );
};
