import { MutableRefObject, useCallback, useRef } from 'react';

import { NextPageParamType } from '@/types/QueryTypes';

type Props<T> = {
  isFetchingNextPage: boolean;
  hasNextPage?: boolean;
  fetchNextPage?: NextPageParamType<T>;
};

export const useInfiniteScrollRef = <T>({
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
}: Props<T>) => {
  const intObserver: MutableRefObject<IntersectionObserver | undefined> =
    useRef();

  const lastElementRef = useCallback(
    (element: Element) => {
      if (isFetchingNextPage) {
        return;
      }

      if (intObserver.current) {
        intObserver.current.disconnect();
      }

      intObserver.current = new IntersectionObserver((element) => {
        if (element[0].isIntersecting && hasNextPage && fetchNextPage) {
          fetchNextPage();
        }
      });

      if (element) {
        intObserver.current.observe(element);
      }
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage],
  );

  return lastElementRef;
};
