import placeholderImg from '@assets/images/landingPage/experience-placeholder.webp';
import { SpriteIcon } from '@components/icons/SpriteIcon';
import classNames from 'classnames';

import { Experience } from '@/generated';

type Props = Partial<Experience> & {
  note?: string;
  maxWidth?: string;
  width?: number;
  styles?: string;
};

export const CardOnMap = ({
  name,
  details,
  note,
  maxWidth,
  width,
  styles,
}: Props) => {
  return (
    <div
      className={classNames(
        'shadow-card-on-map bg-white flex gap-3 p-3 rounded-3xl w-full items-center cursor-pointer',
        {
          'max-w-[360px]': !maxWidth,
          [`max-w-[${maxWidth}]`]: maxWidth,
          [`w-[${width}px]`]: width,
          styles,
        },
      )}
      onClick={() => (details ? window.open(details.website!, '_blank') : null)}
    >
      <img
        loading="lazy"
        src={details?.place_photo ?? placeholderImg}
        className="w-[80px] h-[80px] rounded-xl object-cover object-center"
        alt={name ?? 'travel experience'}
      />
      <div className="flex flex-col flex-1 gap-1">
        {details?.place_name && (
          <p className="text-p-bold text-black line-clamp-2">
            {details?.place_name}
          </p>
        )}
        <div className="items-center flex w-full justify-start gap-2">
          {details?.rating && details.user_ratings_total && (
            <div className="items-center flex justify-start gap-1">
              <SpriteIcon iconName="star" className="w-4 h-4" />
              <div className="text-p-medium">
                <span className="text-black">{details.rating}</span>
                &nbsp;
                {details?.user_ratings_total && (
                  <span className="text-gray">
                    ({details.user_ratings_total})
                  </span>
                )}
              </div>
            </div>
          )}
          {note && (
            <>
              <div>•</div>
              <p className="text-p-medium text-black">{note}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
