import { useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';

import { useApi } from '@/api/serviceProvider';
import { LoginFinalStepSchemaType } from '@/forms/useAuthLoginFinalStepForm';
import { useAuthEmailStore } from '@/stores/authEmailStore';
import { useItineraryToSaveStore } from '@/stores/itineraryToSaveStore';
import { useUserStore } from '@/stores/userStore';
import { removeAuthCookie } from '@/utils/removeAuthCookie';
import { showToast } from '@/utils/showToast';

import { useAddItineraryTyMyTrips } from './useAddItineraryToMyTrips';
import { useState } from 'react';
import { useParseNextStep } from './useParseNextStep';
import { useGoogleAnalytics } from './useGoogleAnalytics';

export const useLogin = () => {
  const apiFactory = useApi();
  const setUser = useUserStore((state) => state.setUser);
  const clearInitialEmail = useAuthEmailStore((state) => state.clearEmail);
  const itineraryToSaveId = useItineraryToSaveStore(
    (state) => state.itineraryToSaveId,
  );
  const { handleAddItinerary } = useAddItineraryTyMyTrips();
  const { parseNextStep } = useParseNextStep();
  const { sendGAEvent, EVENT_ACTIONS, EVENT_CATEGORIES } = useGoogleAnalytics();

  const [email, setEmail] = useState<string | undefined>(undefined);
  const handleClearItineraryToSaveId = useItineraryToSaveStore(
    (state) => state.clearStore,
  );

  const { mutate, isPending } = useMutation({
    mutationKey: ['login'],
    mutationFn: ({ email, password }: LoginFinalStepSchemaType) => {
      removeAuthCookie();
      setEmail(email);
      return apiFactory.auth.authLoginCreate({
        email: email,
        password: password,
      });
    },
    onSuccess: (response) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setUser(response);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Cookies.set('tp_access_token', response?.access);
      clearInitialEmail();

      /* How many users log in */
      sendGAEvent(EVENT_CATEGORIES.userAuth, EVENT_ACTIONS.login);

      if (itineraryToSaveId) {
        handleAddItinerary(itineraryToSaveId);
        handleClearItineraryToSaveId();
      }
      parseNextStep(email);
    },
    onError: () => showToast('Incorrect email or password. Please try again.'),
  });

  return {
    handleLogin: mutate,
    isLoading: isPending,
  };
};
