import { routes } from "@/routes/routes";
import { useNextStepStore } from "@/stores/nextStepStore";
import { useSendInvites } from "./useSendInvites";
import { useAddItineraryTyMyTrips } from "./useAddItineraryToMyTrips";
import { useAssignTripToInvitedUser } from "./useAssignTripToInvitedUser";
import { useInitialSearchStore } from "@/stores/initialSearchStore";
import { useNavigate } from "react-router-dom";

export const useParseNextStep = () => {
    const { nextStep, resetNextStep } = useNextStepStore((state) => state);
    const setInitialSearch = useInitialSearchStore((state) => state.setInitialSearch);
    const { assignTrip } = useAssignTripToInvitedUser();
    const { handleAddItinerary } = useAddItineraryTyMyTrips();
    const { sendInvites } = useSendInvites();
    const navigate = useNavigate();

    const parse = (email?: string) => {
        if (nextStep) {
            if (nextStep.saveTrip) {
                assignTrip({
                    email: email ?? '',
                    itineraryId: nextStep.saveTrip.itineraryId,
                    token: nextStep.saveTrip.token,
                    invitedUserId: nextStep.saveTrip.invitedUserId,
                    interests: nextStep.saveTrip.interests,
                    foodPreferences: nextStep.saveTrip.foodPreferences,
                    min_price_level: nextStep.saveTrip.min_price_level,
                    max_price_level: nextStep.saveTrip.max_price_level,
                });
                navigate(
                    routes.itinerary.index
                        .replace(':id', nextStep.saveTrip.itineraryId.toString())
                        .replace(':token', ''),
                    { replace: true }
                );
                resetNextStep();
                setInitialSearch({
                    itineraryId: null,
                    invitedPeople: null,
                    isWaitingForPeople: false,
                });
                return;
            }
            if (nextStep.saveTripAdmin && nextStep.saveTripAdmin.itineraryId) {
                handleAddItinerary(nextStep.saveTripAdmin.itineraryId);
                if (nextStep.saveTripAdmin.peopleToInvite) {
                    sendInvites({ invitedUsers: nextStep.saveTripAdmin.peopleToInvite, itineraryIdDuplicate: nextStep.saveTripAdmin.itineraryId });
                }
                navigate(
                    routes.itinerary.index
                        .replace(':id', nextStep.saveTripAdmin.itineraryId.toString())
                        .replace(':token', ''),
                    { replace: true }
                );
                setInitialSearch({
                    itineraryId: null,
                    invitedPeople: null,
                    isWaitingForPeople: false,
                });
                resetNextStep();
                return;
            }
        } else {
            navigate(routes.myTrips, { replace: true });
            return;
        }
    };

    return {
        parseNextStep: parse,
    }
}