import { useLocation, useNavigate } from 'react-router-dom';

import { TextButton } from '@/components/buttons/TextButton';
import { AuthLayout } from '@/components/layouts/AuthLayout';
import { Loader } from '@/components/loaders/Loader';
import { useResendSignUpEmail } from '@/hooks/useResendSignUpEmail';
import { routes } from '@/routes/routes';
import { useAuthEmailStore } from '@/stores/authEmailStore';

export const SignUpSuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const email = useAuthEmailStore((state) => state.email);

  const enabledResend = new URLSearchParams(location.search).get(
    'enabledResend',
  );

  const { handleResendSignUpEmail, isLoading, didUseResend } =
    useResendSignUpEmail();

  const isResendEnabled = enabledResend === 'false' || didUseResend;

  const resendText = isResendEnabled
    ? 'Resending an email might take several minutes. If you still have not received it, please try again later.'
    : `An email with instructions has been sent to ${email}. Check your email for a verification link and click on it to confirm.`;

  const handleCrossClick = () => navigate(routes.home);

  return (
    <AuthLayout handleCloseButtonClick={handleCrossClick}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <h3 className="text-h3 mb-2 text-black">Check your email</h3>
          <p className="text-p text-gray mb-3">{resendText}</p>
          {!isResendEnabled && (
            <div className="flex gap-2 mt-10">
              <p className="text-p text-gray">Didn't get an email?</p>
              <TextButton
                isLoading={isLoading}
                onClick={handleResendSignUpEmail}
              >
                Resend
              </TextButton>
            </div>
          )}
        </>
      )}
    </AuthLayout>
  );
};
