import { routes } from '@/routes/routes';

export const useInvitationLink = (
  itineraryId?: number,
  token?: string | null,
) => {
  const copyToClipboard = (text: string | undefined) => {
    if (!text) {
      return;
    }
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('URL copied to clipboard successfully!');
      })
      .catch((err) => {
        console.error('Failed to copy URL to clipboard: ', err);
      });
  };

  const navigateToItinerary = (itineraryRoute: string) => {
    window.location.replace(itineraryRoute);
  };

  if (!itineraryId || !token) {
    return {
      invitationLink: '',
      invitationRedirectLink: '',
      copyToClipboard,
      navigateToItinerary,
    };
  }
  const BASE_URL = import.meta.env.VITE_BASE_URL;
  const itineraryRoute = routes.itinerary.index
    .replace(':id', itineraryId.toString())
    .replace(':token', token);

  return {
    invitationLink: `${BASE_URL}${itineraryRoute}`,
    invitationRedirectLink: itineraryRoute,
    copyToClipboard,
    navigateToItinerary,
  };
};
