import { SpriteIcon, SpriteIconProps } from '@components/icons/SpriteIcon';
import { tabbarButtons } from '@data/appData/tabbarData';
import classNames from 'classnames';

type TabBarButtonVariants = 'standard' | 'discovery';

type Params = {
  icon: SpriteIconProps;
  value: string;
  routeName: string;
  currentTab: (typeof tabbarButtons)[number] | undefined;
  shortUserName: string | undefined;
  navigate: (path: string) => void;
  variant?: TabBarButtonVariants;
};

export const DesktopTabBarButton = ({
  icon,
  value,
  routeName,
  currentTab,
  shortUserName,
  navigate,
  variant,
}: Params) => {
  const handleTabClick = () => {
    if (!currentTab || value !== currentTab.value) {
      navigate(routeName);
    }
  };

  return (
    <button
      key={value}
      className={classNames('flex items-center justify-center gap-x-2', {
        'w-[60px] h-[60px] rounded-360 bg-purple-light': value === 'Profile',
      })}
      onClick={handleTabClick}
    >
      <SpriteIcon
        iconName={icon}
        className={classNames('w-6 h-6', {
          'fill-purple': currentTab?.value === value && variant === 'standard',
          'fill-white': 'Profile' !== value && variant === 'discovery',
          hidden: value === 'Profile',
        })}
      />
      <span
        className={classNames('text-sm', {
          'text-purple': currentTab?.value === value && variant === 'standard',
          'text-white': variant === 'discovery' && value !== 'Profile',
          'text-h4 text-purple': value === 'Profile',
        })}
      >
        {value !== 'Profile' ? value : shortUserName}
      </span>
    </button>
  );
};
