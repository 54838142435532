import { TextButton } from '@components/buttons/TextButton';
import { SpriteIcon } from '@components/icons/SpriteIcon';

type Props = {
  actionButtonText?: string;
  onBackButtonClick?: () => void;
  onActionButtonClick?: () => void;
  full?: boolean;
  styles?: string;
};

export const Header = ({
  actionButtonText,
  onBackButtonClick,
  onActionButtonClick,
  styles,
  full = true,
}: Props) => {
  return (
    <header
      className={`${full ? 'w-full' : ''} flex ${
        onBackButtonClick ? 'justify-between' : 'justify-end'
      } items-center ${styles}`}
    >
      {onBackButtonClick && (
        <button onClick={onBackButtonClick}>
          <SpriteIcon iconName="arrowLeft" className="w-8 h-8 fill-black" />
        </button>
      )}
      {actionButtonText && onActionButtonClick && (
        <TextButton onClick={onActionButtonClick}>
          {actionButtonText}
        </TextButton>
      )}
    </header>
  );
};
