import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  to: string;
  children?: ReactNode;
};

export const FooterLink = ({ to, children }: Props) => {
  return (
    <Link to={to} className="text-p-small-web text-gray hover:text-purple">
      {children}
    </Link>
  );
};
