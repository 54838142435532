import { forwardRef } from 'react';
import { SpriteIcon } from '../icons/SpriteIcon';
import { TruncText } from '../texts/TruncText';

type Props = {
  location: string;
  dateRangeText: string;
  people: number;
  onEditClick?: () => void;
  isLoadingData?: boolean;
  is_admin?: boolean;
  editItineraryDivRef?: React.RefObject<HTMLDivElement>;
};

export const TripInfo = forwardRef<HTMLButtonElement, Props>(
  (
    {
      location,
      dateRangeText,
      people,
      onEditClick,
      isLoadingData,
      is_admin = false,
      editItineraryDivRef,
    },
    ref,
  ) => {
    return (
      <div
        className="flex justify-between w-full bg-gray-light rounded-40 py-2 h-[60px] pl-7 pr-3"
        ref={editItineraryDivRef}
      >
        <div className="flex flex-col justify-between">
          <TruncText className="text-p" maxWidth={200}>
            {location}
          </TruncText>
          <p className="flex items-center text-p-medium text-gray">
            {dateRangeText}
            <span className="w-1 h-1 rounded-full bg-gray mx-2" />
            {people} travelers
          </p>
        </div>
        {is_admin && (
          <button
            disabled={isLoadingData}
            onClick={onEditClick}
            className="px-2.5 flex justify-center items-center"
            ref={ref}
          >
            <SpriteIcon iconName="pen" className="w-6 h-6" />
          </button>
        )}
      </div>
    );
  },
);
