/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { useApi } from '@/api/serviceProvider';
import { useMutation } from '@tanstack/react-query';

type Params = {
  invitedUsers: (string | number)[];
  itineraryIdDuplicate?: number;
};

export const useSendInvites = (itineraryId?: number) => {
  const { api } = useApi();

  const { mutate, data, isPending } = useMutation({
    mutationFn: async ({ invitedUsers, itineraryIdDuplicate }: Params) => {
      if (!itineraryId && !itineraryIdDuplicate) {
        return;
      }
      const id = itineraryId || itineraryIdDuplicate;
      return await api.apiV1ItinerariesInvite(id!!, {
        emails: invitedUsers.filter((user) => typeof user === 'string') as string[],
      });
    },
  });

  return {
    sendInvites: mutate,
    data: data?.data || undefined,
    isLoading: isPending,
  };
};
