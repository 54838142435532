import { create, StateCreator } from 'zustand';
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';

type State = {
  email: string;
};

type Actions = {
  setEmail: (email: string) => void;
  clearEmail: () => void;
};

const initialState: State = {
  email: '',
};

type AuthEmailStoreType = State & Actions;

type AuthEmailPersistStoreType = (
  config: StateCreator<AuthEmailStoreType>,
  options: PersistOptions<AuthEmailStoreType>,
) => StateCreator<AuthEmailStoreType>;

export const useAuthEmailStore = create<AuthEmailStoreType>(
  (persist as AuthEmailPersistStoreType)(
    (set) => ({
      ...initialState,
      setEmail: (email: string) => set({ email }),
      clearEmail: () => set(initialState),
    }),
    {
      name: 'auth-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
