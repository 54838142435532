import { Avatar } from '@components/avatar/Avatar';
import { DeleteAccountBottomSheet } from '@components/bottomSheets/DeleteAccountBottomSheet';
import { LogoutBottomSheet } from '@components/bottomSheets/LogoutBottomSheet';
import { Button } from '@components/buttons/Button';
import { TextButton } from '@components/buttons/TextButton';
import { TitleHeader } from '@components/headers/TitleHeader';
import { Loader } from '@components/loaders/Loader';
import { ProfileDetailSection } from '@components/profileDetailSection/ProfileDetailSection';
import { useGetProfile } from '@hooks/useGetProfile';
import { useState } from 'react';

import { useChangePasswordForm } from '@/forms/useChangePasswordForm';
import { useEditEmailForm } from '@/forms/useEditEmailForm';
import { useEditNameForm } from '@/forms/useEditNameForm';
import { useChangePassword } from '@/hooks/useChangePassword';
import { useEditEmail } from '@/hooks/useEditEmail';
import { useEditUserName } from '@/hooks/useEditUserName';
import { Container } from '@/components/containers/Container';

export const ProfilePage = () => {
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
    useState(false);
  const [isLogoutSheetOpen, setIsLogoutSheetOpen] = useState(false);

  const { profile, isProfileLoading } = useGetProfile();
  const { handleEditName } = useEditUserName();
  const { handleChangePassword } = useChangePassword();
  const { handleEditEmail } = useEditEmail();

  const changeNameForm = useEditNameForm();
  const changePasswordForm = useChangePasswordForm();
  const editEmailForm = useEditEmailForm();

  const username = `${profile?.first_name} ${profile?.last_name}`;

  const handleToggleDeleteAccountModal = () =>
    setIsDeleteAccountModalOpen(!isDeleteAccountModalOpen);

  const handleToggleLogoutSheet = () =>
    setIsLogoutSheetOpen(!isLogoutSheetOpen);

  return (
    <div className="pb-[100px] lg:pb-[80px] flex flex-col min-h-svh gap-8">
      <TitleHeader title="Profile" />
      {isProfileLoading ? (
        <div className="flex justify-center">
          <Loader />
        </div>
      ) : (
        <Container
          styles="flex flex-col w-full mdm:grid mdm:grid-cols-12 overflow-y-auto"
          fullHeight
        >
          <div className="flex flex-col gap-8 px-2 md:col-span-6 lg:col-span-4">
            <Avatar
              firstName={profile?.first_name}
              lastName={profile?.last_name}
            />
            <section>
              <ProfileDetailSection
                control={changeNameForm.control}
                isLoading={changeNameForm.isSubmitting}
                title="Name"
                isSubmitDisabled={changeNameForm.isSubmitDisabled}
                inputs={[
                  {
                    name: 'first_name',
                    placeholder: 'Enter your first name',
                    label: 'First Name',
                    type: 'text',
                  },
                  {
                    name: 'last_name',
                    placeholder: 'Enter your last name',
                    label: 'Last Name',
                    type: 'text',
                  },
                ]}
                value={username}
                onSavePress={changeNameForm.handleSubmit((data) =>
                  handleEditName(data),
                )}
              />
              <ProfileDetailSection
                control={editEmailForm.control}
                isLoading={editEmailForm.isSubmitting}
                title="Email"
                isSubmitDisabled={editEmailForm.isSubmitDisabled}
                inputs={[
                  {
                    name: 'email',
                    placeholder: 'Enter a new email',
                    label: 'New Email',
                    type: 'email',
                    autoComplete: 'email',
                  },
                ]}
                value={profile?.email}
                onSavePress={editEmailForm.handleSubmit((data) =>
                  handleEditEmail(data),
                )}
              />
              <ProfileDetailSection
                control={changePasswordForm.control}
                isLoading={changePasswordForm.isSubmitting}
                title="Password"
                isSubmitDisabled={changePasswordForm.isSubmitDisabled}
                inputs={[
                  {
                    name: 'new_password1',
                    placeholder: 'Enter a new password',
                    label: 'New Password',
                    type: 'password',
                  },
                  {
                    name: 'new_password2',
                    placeholder: 'Confirm your new password',
                    label: 'Confirm Password',
                    type: 'password',
                  },
                ]}
                value="********"
                onSavePress={changePasswordForm.handleSubmit((data) =>
                  handleChangePassword(data),
                )}
              />
            </section>
            <div className="flex flex-col gap-4 px-2 justify-center items-center">
              <Button
                size="large"
                full
                variant="secondary"
                onClick={handleToggleLogoutSheet}
              >
                Log out
              </Button>
              <TextButton onClick={handleToggleDeleteAccountModal} color="red">
                Delete account
              </TextButton>
            </div>
          </div>
        </Container>
      )}
      <DeleteAccountBottomSheet
        isOpen={isDeleteAccountModalOpen}
        onClose={handleToggleDeleteAccountModal}
      />
      <LogoutBottomSheet
        isOpen={isLogoutSheetOpen}
        onClose={handleToggleLogoutSheet}
      />
    </div>
  );
};
