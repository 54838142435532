export const useGetElementPosition = (element: HTMLButtonElement | null | HTMLDivElement) => {

    const getElementPosition = (element: HTMLButtonElement | HTMLDivElement | null) => {
        if (!element) return {} as DOMRect;
        return element.getBoundingClientRect();
    };

    return {
        pos: getElementPosition(element),
        getElementPosition
    };
}