type Props = {
  firstName: string | undefined;
  lastName: string | undefined;
};
export const Avatar = ({ firstName, lastName }: Props) => {
  const userInitialsArray = [firstName, lastName]?.map((word) => word?.[0]);
  const userInitials = userInitialsArray?.join('').toUpperCase();

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex w-[76px] h-[76px] justify-center items-center bg-purple-medium rounded-xl mb-3">
        <h4 className="text-h4 text-purple">{userInitials}</h4>
      </div>
      <h4 className="text-h4">
        {firstName && lastName ? `${firstName} ${userInitials?.[1]}.` : ''}
      </h4>
    </div>
  );
};
