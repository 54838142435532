import { ExperienceFilterBottomSheet } from '@components/bottomSheets/ExperienceFilterBottomSheet';
import { ExperienceMapBottomSheet } from '@components/bottomSheets/ExperienceMapBottomSheet';
import { TimeSlotBottomSheet } from '@components/bottomSheets/timeSlotBottomSheet/TimeSlotBottomSheet';
import { Button } from '@components/buttons/Button';
import { SearchHeader } from '@components/headers/SearchHeader';
import { ExperienceList } from '@components/lists/ExperienceList';
import { Loader } from '@components/loaders/Loader';
import { AddExperienceOptionsModal } from '@components/modals/AddExperienceOptionsModal';
import { useGetFeaturedExperiences } from '@hooks/useGetFeaturedExperiences';
import { useRedirectOnInvalidItineraryId } from '@hooks/useRedirectOnInvalidItineraryId';
import { useAddExperienceStore } from '@stores/addExperienceStore';
import removeAccents from '@utils/removeAccents';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Experience } from '@/generated';

export const AddExperienceSearchPage = () => {
  const [isFilterSheetOpen, setIsFilterSheetOpen] = useState(false);
  const [isAddExperienceModalOpen, setIsAddExperienceModalOpen] =
    useState(false);
  const [isTimeSlotBottomSheetOpen, setIsTimeSlotBottomSheetOpen] =
    useState(false);
  const [isMapSheetOpen, setIsMapSheetOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [chosenFilters, setChosenFilters] = useState<string[]>([]);

  const { id } = useParams();

  useRedirectOnInvalidItineraryId(id);

  const itineraryId = id ? Number(id) : undefined;

  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useGetFeaturedExperiences(searchValue);

  const handleSetExperience = useAddExperienceStore(
    (state) => state.setChosenExperience,
  );

  const handleToggleIsAddExperienceModalOpen = () =>
    setIsAddExperienceModalOpen(!isAddExperienceModalOpen);

  const handleToggleIsTimeSlotBottomSheetOpen = () =>
    setIsTimeSlotBottomSheetOpen(!isTimeSlotBottomSheetOpen);

  const handleToggleFilterSheet = () =>
    setIsFilterSheetOpen(!isFilterSheetOpen);

  const handleExperienceCardPlusClick = (experience: Experience) => {
    handleSetExperience(experience);
    handleToggleIsAddExperienceModalOpen();
  };
  const handleToggleIsMapSheetOpen = () => setIsMapSheetOpen(!isMapSheetOpen);

  const handleFilterClick = (value: string) => {
    if (chosenFilters?.includes(value)) {
      const filteredFilters = chosenFilters.filter(
        (filter) => filter !== value,
      );

      return setChosenFilters(filteredFilters);
    }
    return setChosenFilters((previousFilters) => [...previousFilters, value]);
  };

  const handleClearFiltersClick = () => setChosenFilters([]);

  const onSearchValueChange = (value: string) => {
    setSearchValue(removeAccents(value));
  };

  return (
    <div className="flex flex-col bg-gray-light h-svh pb-6 overflow-y-hidden">
      <div className="flex w-full fixed z-20 bottom-3 justify-center">
        <Button
          variant="secondary"
          iconName="mapbutton"
          size="large"
          hasShadow
          onClick={handleToggleIsMapSheetOpen}
          disabled={!data?.length}
        >
          Map
        </Button>
      </div>
      <div className="flex justify-center w-full py-2 pl-4 mb-6 flex-shrink-0">
        <SearchHeader value={searchValue} onSearch={onSearchValueChange} />
      </div>
      <div className="flex gap-2 justify-center w-full flex-wrap flex-grow overflow-y-auto">
        {isLoading ? (
          <Loader />
        ) : (
          <ExperienceList
            experiences={data}
            onCardClick={handleExperienceCardPlusClick}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        )}
      </div>
      {isAddExperienceModalOpen && (
        <AddExperienceOptionsModal
          onAddToTripClick={handleToggleIsTimeSlotBottomSheetOpen}
          onOverlayClick={handleToggleIsAddExperienceModalOpen}
        />
      )}

      <TimeSlotBottomSheet
        isOpen={isTimeSlotBottomSheetOpen}
        onClose={handleToggleIsTimeSlotBottomSheetOpen}
        itineraryId={itineraryId}
        isUpdate={false}
        handleUpdateExperience={undefined}
      />
      <ExperienceMapBottomSheet
        isOpen={isMapSheetOpen}
        onClose={handleToggleIsMapSheetOpen}
        dateExperiences={data}
        mapFiltersType="categories"
        commutes={undefined}
        chosenFilters={chosenFilters}
        onFilterClick={handleFilterClick}
        handleToggleFilterSheet={handleToggleFilterSheet}
        selectedDate={undefined}
      />
      <div className="z-10">
        <ExperienceFilterBottomSheet
          experiences={data}
          isOpen={isFilterSheetOpen}
          onClose={handleToggleFilterSheet}
          chosenFilters={chosenFilters}
          onFilterClick={handleFilterClick}
          onClearFiltersClick={handleClearFiltersClick}
        />
      </div>
    </div>
  );
};
