import { toast } from 'react-toastify';

import { CloseToastButton } from '@/components/toasts/CloseToastButton';
import { ToastMessage } from '@/components/toasts/ToastMessage';
import { ToastMessageVariant } from '@/types/AppTypes';

export const showToast = (
  message: string,
  variant: ToastMessageVariant = 'dark',
) => {
  return toast(<ToastMessage message={message} variant={variant} />, {
    theme: variant,
    closeButton: (
      <CloseToastButton fillColor={variant === 'dark' ? 'white' : 'black'} />
    ),
    toastId: message,
  });
};
