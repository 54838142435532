import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

import { Meridian } from '@/types/AppTypes';

import {
  DATE_DAY_FORMAT,
  DATE_MONTH_FORMAT,
  DATE_WEEK_DAY_SHORT_FORMAT,
  TIME_HOUR_MINUTE_MERIDIAN_FORMAT,
  TIME_HOUR_MINUTE_MERIDIAN_WITHOUT_SPACE_FORMAT,
  TIME_HOUR_MINUTE_SECONDS_FORMAT,
  TIME_HOUR_MINUTE_WITHOUT_SECONDS_FORMAT,
} from './constants';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const formatDateTime = (
  date: string | null | undefined,
  time: string | null | undefined,
) => {
  if (time && date) {
    return getUTCDateTime({ dateTime: `${date} ${time}` }).format(
      TIME_HOUR_MINUTE_MERIDIAN_FORMAT,
    );
  }
  return '';
};

export const formatTimeRange = (
  date: string,
  startTime: string | null | undefined,
  endTime: string | null | undefined,
) => {
  const formattedStartTime = formatDateTime(date, startTime);
  const formattedEndTime = formatDateTime(date, endTime);
  return `${formattedStartTime} - ${formattedEndTime}`;
};

export const getTwentyFourHourFormatTime = (
  time: string | undefined,
  meridian: Meridian,
) =>
  getTwentyFourHourDayjs({ time, meridian }).format(
    TIME_HOUR_MINUTE_SECONDS_FORMAT,
  );

export const getTwentyFourHourDayjs = ({
  time,
  meridian,
}: {
  time: string | undefined;
  meridian: Meridian;
}) => {
  const dayjsTime = getUTCTime({
    time: time,
    inputFormat: TIME_HOUR_MINUTE_WITHOUT_SECONDS_FORMAT,
  });
  const isTwelveHour = dayjsTime.hour() === 12;

  if (
    (meridian === 'AM' && isTwelveHour) ||
    (meridian === 'PM' && !isTwelveHour)
  ) {
    return dayjsTime.add(12, 'hours');
  }

  return dayjsTime;
};

export const getFormattedDateTime = ({
  dateTime,
  format = TIME_HOUR_MINUTE_MERIDIAN_WITHOUT_SPACE_FORMAT,
  inputFormat,
}: {
  dateTime?: string;
  format?: string;
  inputFormat?: string;
}) => {
  if (!dateTime) {
    return getUTCDateTime().format(format);
  }

  return getUTCDateTime({
    dateTime: dateTime,
    inputFormat: inputFormat,
  }).format(format);
};

export const getFormattedDate = ({
  date,
  format = DATE_WEEK_DAY_SHORT_FORMAT,
}: {
  date?: Date | string;
  format?: string;
}) => {
  if (!date) {
    return dayjs().format(format);
  }
  return dayjs(date).format(format);
};

export const parseDate = ({
  startDate,
  endDate,
}: {
  startDate?: Date | string;
  endDate?: Date | string;
}) => {
  if (!startDate || !endDate) {
    return null;
  }

  const start = dayjs(startDate);
  const end = dayjs(endDate);

  const startMonth = start.format(DATE_MONTH_FORMAT);
  const endMonth = end.format(DATE_MONTH_FORMAT);
  const startDay = start.format(DATE_DAY_FORMAT);
  const endDay = end.format(DATE_DAY_FORMAT);

  if (startDay == endDay) {
    // if we have only one day, we should show exactly that day
    return `${startMonth} ${startDay}`;
  }

  if (startMonth !== endMonth) {
    return `${startMonth} ${startDay} – ${endMonth} ${endDay}`;
  }
  return `${startMonth} ${startDay} – ${endDay}`;
};

export const getUTCDateTime = ({
  dateTime,
  inputFormat,
}: {
  dateTime?: Dayjs | Date | string;
  inputFormat?: string;
} = {}) => dayjs(dateTime, inputFormat);

export const getUTCTime = ({
  time,
  inputFormat,
}: {
  time?: string;
  inputFormat?: string;
} = {}) => dayjs(time, inputFormat);
