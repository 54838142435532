import { yupResolver } from '@hookform/resolvers/yup';
import { useAuthEmailStore } from '@stores/authEmailStore';
import { emailValidation } from '@utils/yupValidations';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const ResetPasswordSchema = yup.object().shape({
  email: emailValidation,
});

export type ResetPasswordSchemaType = yup.InferType<typeof ResetPasswordSchema>;

export const useResetPasswordForm = () => {
  const authEmail = useAuthEmailStore((state) => state.email);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<ResetPasswordSchemaType>({
    mode: 'all',
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      email: authEmail,
    },
    resolver: yupResolver(ResetPasswordSchema),
  });

  return {
    control,
    handleSubmit,
    isSubmitDisabled: !isValid,
  };
};
