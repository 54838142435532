import { Button } from '@components/buttons/Button';

import { useDeleteItinerary } from '@/hooks/useDeleteItinerary';
import { PopUpContainer } from '../containers/PopUpContainer';

type Props = {
  itineraryId: number | undefined;
  isOpen: boolean;
  onClose: () => void;
};

export const DeleteItineraryBottomSheet = ({
  itineraryId,
  onClose,
  isOpen,
}: Props) => {
  const { handleDeleteItinerary, isLoading } = useDeleteItinerary();

  return (
    <PopUpContainer snapPoints={[0.4]} onClose={onClose} isOpen={isOpen}>
      <div className="flex flex-col h-full lg:w-full pb-4 lg:pb-0">
        <h4 className="text-h4 mb-5">Are you sure?</h4>
        <p className="text-p text-gray mb-5">
          This trip with all of your customization will be permanently deleted
          from your saves.
        </p>
        <div className="flex flex-col gap-2 lg:flex-row">
          <Button
            size="large"
            full
            onClick={() => handleDeleteItinerary(itineraryId)}
            isLoading={isLoading}
          >
            Delete
          </Button>
          <Button
            size="large"
            full
            variant="secondary"
            onClick={onClose}
            isLoading={isLoading}
          >
            Cancel
          </Button>
        </div>
      </div>
    </PopUpContainer>
  );
};
