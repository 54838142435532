import { DatesModal } from '@components/modals/DatesModal';
import { LocationSearchModal } from '@components/modals/LocationSearchModal';
import { PeopleModal } from '@components/modals/PeopleModal';

import { StartPageModalTypes } from '@/types/AppTypes';

type Props = {
  openedModal: StartPageModalTypes;
  setOpenedModal: (modalName: StartPageModalTypes) => void;
  menuCaller?: React.MutableRefObject<{
    [key: string]: HTMLDivElement | null;
  }>;
};

export const StartPageModal = ({
  openedModal,
  setOpenedModal,
  menuCaller,
}: Props) => {
  return (
    <>
      <LocationSearchModal
        isOpen={openedModal === 'LOCATION'}
        onClose={() => {
          setOpenedModal('NONE');
        }}
        menuCaller={menuCaller ? menuCaller.current[1] : null}
      />
      <DatesModal
        isOpen={openedModal === 'DATES'}
        onClose={() => {
          setOpenedModal('NONE');
        }}
        menuCaller={menuCaller ? menuCaller.current[2] : null}
      />
      <PeopleModal
        isOpen={openedModal === 'PEOPLE'}
        onClose={() => {
          setOpenedModal('NONE');
        }}
        menuCaller={menuCaller ? menuCaller.current[3] : null}
      />
    </>
  );
};
