import { SpriteIcon } from '@components/icons/SpriteIcon';
import classNames from 'classnames';

type Props = {
  label: string;
  isSelected: boolean;
  onClick: () => void;
  isRoundedBottom?: boolean;
  isRoundedTop?: boolean;
};

export const SortExperienceItem = ({
  isSelected,
  label,
  onClick,
  isRoundedBottom,
  isRoundedTop,
}: Props) => {
  return (
    <div
      className={classNames(
        'flex justify-between items-center px-3 rounded-xl lg:rounded-none h-12 cursor-pointer',
        'hover:bg-purple-light',
        {
          'lg:rounded-b-xl': isRoundedBottom,
          'lg:rounded-t-xl': isRoundedTop,
        },
      )}
      onClick={onClick}
    >
      <p
        className={classNames('text-p', {
          'text-purple': isSelected,
        })}
      >
        {label}
      </p>
      {isSelected && <SpriteIcon className="w-5 h-5 " iconName="checkmark" />}
    </div>
  );
};
