import { TitleHeaderWithLogo } from '@components/headers/TitleHeaderWithLogo';

import { Footer } from '@/components/footer/Footer';
import { Section, SectionsTypes } from '@/components/legal/Section';

const TermsAndConditionsPage: React.FC = () => {
  return (
    <>
      <TitleHeaderWithLogo title="Terms and Conditions" />
      <div className={'flex flex-col bg-gray-light brd-radius-4 p-4 pt-24'}>
        {/* 1. General */}
        <Section type={SectionsTypes.TITLE}>1. General</Section>
        <Section type={SectionsTypes.SUBTITLE}>1.1 Scope of Services</Section>
        <Section type={SectionsTypes.CONTENT}>
          TravelPal provides a Software as a Service (SaaS) platform designed to
          assist travelers in planning their vacations. The platform generates
          itineraries based on user preferences and allows users to invite
          friends to help plan the trip. Users can visit the websites of
          third-party providers to book experiences suggested in their
          itinerary. TravelPal does not have control over, nor does it assume
          any responsibility for, the actions, products, services, or content of
          these third-party providers and is not liable for any issues or
          damages that may arise from the use of these third-party services. It
          is not involved in any part of the booking process or the fulfillment
          of experiences booked through third-party websites.
        </Section>

        <Section type={SectionsTypes.SUBTITLE}>1.2 Account Creation</Section>
        <Section type={SectionsTypes.CONTENT}>
          Users must create an account using their name, email, and password to
          access the platform. Users are responsible for maintaining the
          confidentiality of their account and password and for restricting
          access to their computer or device. Users agree to accept
          responsibility for all activities that occur under their account.
        </Section>

        <Section type={SectionsTypes.SUBTITLE}>1.3 Liability</Section>
        <Section type={SectionsTypes.CONTENT}>
          Notwithstanding any other provision in these Terms and Conditions,
          TravelPal categorically disclaims all warranties, whether stated
          explicitly or implied, including, but not restricted to, the implied
          warranties of merchantability, suitability for a specific purpose, and
          non-violation of proprietary rights. Furthermore, TravelPal absolves
          itself from any responsibility for direct, indirect, incidental,
          punitive, special, consequential, or exemplary damages that may ensue
          from the utilization or incapacity to utilize the services provided.
          This includes, but is not limited to, damages arising out of the usage
          of or reliance on third-party websites or the booking process,
          regardless of whether these damages arise in contract, tort (including
          negligence), or any other legal or equitable theory of liability, even
          if TravelPal has been advised of the possibility of such damages.
        </Section>

        {/* 2. User Obligations */}
        <Section type={SectionsTypes.TITLE}>2. User Obligations</Section>
        <Section type={SectionsTypes.SUBTITLE}>2.1 User Responsibility</Section>
        <Section type={SectionsTypes.CONTENT}>
          Users hereby affirm their commitment to strictly adhere to all
          applicable laws, regulations, and ordinances while utilizing the
          TravelPal platform. This includes, but is not limited to, refraining
          from any form of fraudulent activity, misrepresentation, or illegal
          conduct. Users must ensure that their use of the service does not
          infringe upon the rights of others or violate any legal statutes.
          Failure to comply with this provision may result in immediate
          termination of the user's account and access to the service, at
          TravelPal's sole discretion.
        </Section>

        <Section type={SectionsTypes.SUBTITLE}>
          2.2 Third-Party Websites
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          Users are responsible for reading and understanding the terms and
          conditions of third-party websites before making any bookings.
          TravelPal does not endorse, guarantee, or assume responsibility for
          the content, products, or services provided by these third-party
          sites.
        </Section>

        {/* 3. Privacy */}
        <Section type={SectionsTypes.TITLE}>3. User Obligations</Section>
        <Section type={SectionsTypes.SUBTITLE}>3.1 Data Collection</Section>
        <Section type={SectionsTypes.CONTENT}>
          TravelPal collects data from users to provide the service, including
          but not limited to, personal information such as name, email, and
          password. In addition to the aforementioned data, TravelPal may also
          collect other pertinent information necessary for the enhancement of
          user experience and service delivery, such as travel preferences,
          booking history, and user feedback.
        </Section>

        <Section type={SectionsTypes.SUBTITLE}>3.2 Data Usage</Section>
        <Section type={SectionsTypes.CONTENT}>
          The data collected by TravelPal will be utilized to tailor the service
          to meet users' individual needs, improve service functionality, and
          communicate updates or offers that may be of interest to the user.
          TravelPal is committed to protecting the privacy of its users.
          Accordingly, collected data will be handled in strict compliance with
          our privacy policy. Except as required by law or as necessary to
          provide the service to the user, personal information will not be
          disclosed or shared with third parties without explicit user consent.
        </Section>

        {/* 4. Intellectual Property*/}

        <Section type={SectionsTypes.TITLE}>4. Intellectual Property</Section>
        <Section type={SectionsTypes.SUBTITLE}>4.1 Ownership</Section>
        <Section type={SectionsTypes.CONTENT}>
          Intellectual Property Disclosure: All intellectual property rights
          associated with the TravelPal platform and its services, including but
          not limited to, the software, design, text, images, graphics, user
          interface, visual interface, and TravelPal's trademarks and logos
          contained therein (collectively, "Intellectual Property"), are owned
          by, or licensed to TravelPal. The Intellectual Property is protected
          by copyright, patent, trademark laws, and various other intellectual
          property and unfair competition laws. Users are granted a limited,
          revocable, non-exclusive, non-transferable, non-sublicensable license
          to access and use the TravelPal platform and services strictly in
          accordance with these Terms and Conditions. This license is for the
          sole purpose of enabling users to use and enjoy the benefit of the
          services provided by TravelPal, in the manner permitted by these Terms
          and Conditions. No part of the platform or its services may be copied,
          reproduced, republished, uploaded, posted, publicly displayed,
          encoded, translated, transmitted, or distributed in any way (including
          "mirroring") to any other computer, server, website, or other medium
          for publication or distribution, without TravelPal's express prior
          written consent.
        </Section>

        <Section type={SectionsTypes.SUBTITLE}>4.2 Licensing</Section>
        <Section type={SectionsTypes.CONTENT}>
          Users are hereby granted a limited, non-exclusive, non-transferable,
          and non-sublicensable license to access and utilize the TravelPal
          platform solely for personal, non-commercial use. This license is
          conditioned upon users' compliance with all terms, conditions,
          policies, and notices stated herein. Under this license, users are
          permitted to view, download (where applicable), and utilize the
          TravelPal platform for the purpose of planning and organizing personal
          travel itineraries. However, it is strictly prohibited to use the
          TravelPal platform for any commercial purpose, to duplicate,
          distribute, modify, create derivative works from, publicly display,
          publicly perform, republish, download (except as expressly provided in
          these terms), store, or transmit any of the material on our platform,
          except as generally and ordinarily permitted through the platform
          according to these terms and conditions. Any use of the TravelPal
          platform not expressly permitted by these terms and conditions is a
          breach of these terms and conditions and may violate copyright,
          trademark, and other laws.
        </Section>

        {/* 5. Liability and Indemnification */}
        <Section type={SectionsTypes.TITLE}>
          5. Liability and Indemnification
        </Section>
        <Section type={SectionsTypes.SUBTITLE}>
          5.1 Limitation of Liability
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, UNDER NO
          CIRCUMSTANCES SHALL TRAVELPAL BE LIABLE TO ANY USER OR ANY THIRD PARTY
          FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
          PUNITIVE DAMAGES, INCLUDING LOST PROFIT DAMAGES ARISING FROM YOUR USE
          OF THE SERVICES, EVEN IF TRAVELPAL HAS BEEN ADVISED OF THE POSSIBILITY
          OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
          HEREIN, TRAVELPAL'S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND
          REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO
          THE AMOUNT PAID, IF ANY, BY YOU TO TRAVELPAL FOR THE SERVICES DURING
          THE TERM OF MEMBERSHIP.
        </Section>

        <Section type={SectionsTypes.SUBTITLE}>5.2 Indemnification</Section>
        <Section type={SectionsTypes.CONTENT}>
          YOU AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS TRAVELPAL, ITS
          SUBSIDIARIES, AFFILIATES, LICENSORS, EMPLOYEES, AGENTS, THIRD PARTY
          INFORMATION PROVIDERS AND INDEPENDENT CONTRACTORS AGAINST ANY CLAIMS,
          DAMAGES, COSTS, LIABILITIES AND EXPENSES (INCLUDING, BUT NOT LIMITED
          TO, REASONABLE ATTORNEYS' FEES) ARISING OUT OF OR RELATED TO ANY USER
          CONTENT THAT YOU POST, STORE OR OTHERWISE TRANSMIT ON OR THROUGH THE
          SERVICES OR YOUR USE OF OR INABILITY TO USE THE SERVICES, INCLUDING
          WITHOUT LIMITATION ANY ACTUAL OR THREATENED SUIT, DEMAND OR CLAIM MADE
          AGAINST TRAVELPAL AND/OR ITS INDEPENDENT CONTRACTORS, SERVICE
          PROVIDERS, EMPLOYEES, DIRECTORS OR CONSULTANTS, ARISING OUT OF OR
          RELATING TO THE USER CONTENT, YOUR CONDUCT, YOUR VIOLATION OF THESE
          TERMS AND CONDITIONS OR YOUR VIOLATION OF THE RIGHTS OF ANY THIRD
          PARTY.
        </Section>

        {/* 6. Termination */}
        <Section type={SectionsTypes.TITLE}>6. Termination</Section>
        <Section type={SectionsTypes.SUBTITLE}>
          6.1 Termination of Account
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          TravelPal reserves the right, at its sole discretion, to terminate or
          suspend a user's account at any time, for any reason, including but
          not limited to a breach or violation of these Terms and Conditions.
          Upon such termination or suspension, the user's right to access and
          use the TravelPal platform and services will immediately cease, and
          TravelPal may, without liability to the user or any third party,
          immediately deactivate or delete the user's account, as well as all
          related information and files in the account, and/or bar any further
          access to such information or files, except as may be required by law.
        </Section>

        <Section type={SectionsTypes.SUBTITLE}>
          6.2 Termination of Service
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          TravelPal may, at its sole discretion, discontinue or terminate the
          provision of its services, in whole or in part, at any time without
          prior notice. In the event of termination, TravelPal will endeavor to
          provide users with reasonable notice, to the extent practicable, and
          will offer information regarding the termination process. Users
          acknowledge that TravelPal is not liable to any user or third party
          for any modification, suspension, or discontinuance of the services.
        </Section>

        {/* 7. Governing Law */}
        <Section type={SectionsTypes.TITLE}>7. Governing Law</Section>
        <Section type={SectionsTypes.SUBTITLE}>7.1 Jurisdiction</Section>
        <Section type={SectionsTypes.CONTENT}>
          These terms and conditions will be governed by the laws of the State
          of New York, County of New York, without regard for conflict of law
          principles.
        </Section>

        <Section type={SectionsTypes.SUBTITLE}>7.2 Dispute Resolution</Section>
        <Section type={SectionsTypes.CONTENT}>
          Any dispute, controversy, or claim arising out of, relating to, or in
          connection with these Terms and Conditions, including any questions
          regarding its existence, validity, interpretation, breach, or
          termination, shall be resolved exclusively by binding arbitration in
          accordance with the commercial arbitration rules of the American
          Arbitration Association then in effect. The arbitration shall be
          conducted in New York, New York, and judgment on the arbitration award
          may be entered into any court having jurisdiction thereof. Each party
          shall bear its own costs and expenses and an equal share of the
          arbitrators' and administrative fees of arbitration.
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          THE PARTIES HEREBY WAIVE THEIR RIGHT TO A TRIAL BY JURY IN ANY ACTION
          OR PROCEEDING INVOLVING ANY DISPUTE BETWEEN THEM ARISING OUT OF OR IN
          ANY WAY RELATING TO THESE TERMS AND CONDITIONS.
        </Section>

        {/* 8. Legal Disclaimers and Warranty Statements */}
        <Section type={SectionsTypes.TITLE}>
          8. Legal Disclaimers and Warranty Statements
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          8.1 Disclaimer of Warranties: The Service provided by TravelPal is on
          an "as is" and "as available" basis. To the fullest extent permissible
          pursuant to applicable law, TravelPal disclaims all warranties,
          express or implied, including, but not limited to, implied warranties
          of merchantability and fitness for a particular purpose. TravelPal
          does not warrant that the Service will be uninterrupted or error-free,
          that defects will be corrected, or that the Service or the server that
          makes it available are free of viruses or other harmful components.
          TravelPal does not warrant or make any representations regarding the
          use or the results of the use of the Service in terms of its
          correctness, accuracy, reliability, or otherwise.
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          8.2 Some jurisdictions do not allow the exclusion of implied
          warranties, so the above exclusion may not apply to you. In such
          jurisdictions, the foregoing disclaimers may not apply to you insofar
          as they relate to implied warranties.
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          8.3 Limitations on Warranties: The warranties provided by TravelPal
          shall be limited to the greatest extent permitted by law. In no event
          shall TravelPal be liable for any direct, indirect, punitive,
          incidental, special, or consequential damages arising out of or in any
          way connected with the use of the Service or with the delay or
          inability to use the Service, or for any information, software,
          products, and services obtained through the Service, or otherwise
          arising out of the use of the Service, whether based on contract,
          tort, strict liability, or otherwise, even if TravelPal has been
          advised of the possibility of damages.
        </Section>
        <Section type={SectionsTypes.CONTENT}>
          8.4 Certain state laws do not allow limitations on implied warranties
          or the exclusion or limitation of certain damages. If these laws apply
          to you, some or all of the above disclaimers, exclusions, or
          limitations may not apply to you, and you might have additional
          rights.
        </Section>

        {/* 9. Miscellaneous */}
        <Section type={SectionsTypes.TITLE}>9. Miscellaneous</Section>
        <Section type={SectionsTypes.SUBTITLE}>9.1 Entire Agreement</Section>
        <Section type={SectionsTypes.CONTENT}>
          These terms and conditions constitute the entire agreement between the
          user and TravelPal.
        </Section>

        <Section type={SectionsTypes.SUBTITLE}>9.2 Amendments</Section>
        <Section type={SectionsTypes.CONTENT}>
          TravelPal may amend these terms and conditions at any time. Users will
          be notified of any changes and will be required to accept the updated
          terms to continue using the platform.By using the TravelPal platform,
          users agree to these terms and conditions. These terms and conditions
          are effective as of March 28, 2024. TravelPal reserves the right to
          modify these terms and conditions at any time, with or without notice.
          Any changes will be effective upon posting of the revisions on the
          TravelPal platform.
        </Section>

        <Footer />
      </div>
    </>
  );
};

export { TermsAndConditionsPage };
