import { Button } from '@components/buttons/Button';
import { LargeIconButton } from '@components/buttons/LargeIconButton';
import { Step } from '@components/tailoringSteps/Step';
import { Dispatch, SetStateAction } from 'react';

import { FromToTimeRange } from '@/types/AppTypes';
import {
  DATE_DAY_MONTH_DAY_FORMAT,
  TIME_HOUR_MINUTE_FORMAT,
  TIME_HOUR_MINUTE_SECONDS_FORMAT,
} from '@/utils/constants';
import { getFormattedDate, getFormattedDateTime } from '@/utils/formatDateTime';

type Props = {
  timeRangePlans: FromToTimeRange[];
  onAddTimeRangeButtonClick: () => void;
  setTimeRangeToUpdate: Dispatch<SetStateAction<FromToTimeRange | null>>;
};

export const Plans = ({
  timeRangePlans,
  onAddTimeRangeButtonClick,
  setTimeRangeToUpdate,
}: Props) => {
  const handleEditTimeSlot = (timeRange: FromToTimeRange) => {
    setTimeRangeToUpdate(timeRange);
    onAddTimeRangeButtonClick();
  };
  return (
    <Step
      heading="Share your plans"
      subheading="We won't plan anything for you during these time slots."
    >
      <div className="w-full gap-y-6 flex flex-col items-center">
        {timeRangePlans?.map((range) => {
          const rangeFrom = getFormattedDateTime({
            dateTime: range.from,
            inputFormat: TIME_HOUR_MINUTE_SECONDS_FORMAT,
            format: TIME_HOUR_MINUTE_FORMAT,
          });
          const rangeTo = getFormattedDateTime({
            dateTime: range.to,
            inputFormat: TIME_HOUR_MINUTE_SECONDS_FORMAT,
            format: TIME_HOUR_MINUTE_FORMAT,
          });
          const formattedDate = getFormattedDate({
            date: range.date,
            format: DATE_DAY_MONTH_DAY_FORMAT,
          });

          const timeslot = `${formattedDate}${rangeFrom}${range.meridianFrom.toLowerCase()} - ${rangeTo}${range.meridianTo.toLowerCase()}`;

          return (
            <LargeIconButton
              key={`${range.from}-${range.to}`}
              rightIcon="edit"
              onClick={() => handleEditTimeSlot(range)}
            >
              {timeslot}
            </LargeIconButton>
          );
        })}
        <Button
          iconName="plus"
          variant="secondary"
          size="medium"
          onClick={onAddTimeRangeButtonClick}
        >
          Add a time slot
        </Button>
      </div>
    </Step>
  );
};
