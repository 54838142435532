import { ItineraryStatusTab } from '@/components/headers/MyTripsHeader';
import { queryKeyTypes } from '@api/queryKeyTypes';
import { useApi } from '@api/serviceProvider';
import { useQuery } from '@tanstack/react-query';

export const useGetUsersTrips = (chosenTab: ItineraryStatusTab) => {
  const apiFactory = useApi();

  const { data, isLoading } = useQuery({
    queryKey: [queryKeyTypes.usersTrips, chosenTab],
    queryFn: async () => apiFactory.api.apiV1UsersMyTrips(chosenTab.toString()),
  });

  return {
    usersTrips: data,
    isUsersTripsLoading: isLoading,
  };
};
