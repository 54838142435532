import { useMutation } from '@tanstack/react-query';

import { useApi } from '@/api/serviceProvider';
import { showToast } from '@/utils/showToast';

export const useResetPassword = (onCompleted?: () => void) => {
  const { auth } = useApi();

  const { mutate: handleResetPassword, isPending: isResetPasswordLoading } =
    useMutation({
      mutationKey: ['resetPassword'],
      mutationFn: async (email: string | undefined) => {
        if (!email) {
          return;
        }

        return await auth.authPasswordResetCreate({ email });
      },
      onSuccess: () => {
        if (onCompleted) {
          onCompleted();
        }

        showToast('A new password has been sent to your email');
      },
      onError: () => showToast('Failed to reset password'),
    });

  return {
    handleResetPassword,
    isResetPasswordLoading,
  };
};
