import { useApi } from '@/api/serviceProvider';
import { useMutation } from '@tanstack/react-query';

export const useRemoveInvitedUser = (itineraryId: number | undefined) => {
  const { api } = useApi();

  const { mutate, isPending } = useMutation({
    mutationFn: async (key: string | number | undefined) => {
      if (!key || !itineraryId) {
        return false;
      }
      const id = Number(key);
      await api.apiV1ItinerariesRemove(itineraryId, { pk: id });
    },
  });

  return {
    removeInvitedUser: mutate,
    isLoading: isPending,
  };
};
