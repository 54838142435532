import { useInitialSearchStore } from '@stores/initialSearchStore.ts';
import { useCallback, useEffect } from 'react';

import { getUTCDateTime } from '@/utils/formatDateTime';

export const useClearDateRange = () => {
  const initialSearchDateRange = useInitialSearchStore(
    (state) => state.dateRange,
  );
  const setInitialSearch = useInitialSearchStore(
    (state) => state.setInitialSearch,
  );

  const handleClearDate = useCallback(() => {
    setInitialSearch({
      dateRange: null,
    });
  }, [setInitialSearch]);

  useEffect(() => {
    if (
      initialSearchDateRange &&
      initialSearchDateRange.from &&
      getUTCDateTime({ dateTime: initialSearchDateRange.from })
        .add(1, 'd')
        .isBefore(getUTCDateTime(), 'd')
    ) {
      handleClearDate();
    }
  }, [handleClearDate, initialSearchDateRange]);

  return { handleClearDate };
};
