import { TruncText } from '@components/texts/TruncText';
import classNames from 'classnames';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  selected?: boolean;
  onClick: () => void;
};

export const FilterItemButton = ({ children, onClick, selected }: Props) => {
  return (
    <button
      onClick={onClick}
      className={classNames('w-fit py-1 px-4 rounded-[40px]', {
        'bg-black': selected,
        'bg-gray-light': !selected,
      })}
    >
      <TruncText
        className={classNames({
          'text-white': selected,
          'text-black': !selected,
        })}
      >
        {children}
      </TruncText>
    </button>
  );
};
