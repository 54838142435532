import { useState, useCallback } from 'react';
import { SpriteIcon } from '../icons/SpriteIcon';
import { LandingPageMenu } from './LandingPageMenu';
import { Button } from '../buttons/Button';
import { SignInButtons } from './SignInButtons';
import { useWindowSize } from '@/hooks/useWindowSize';
import { BackgroundImage } from '../images/BackgroundImage';
import classNames from 'classnames';
const TOTAL_IMAGES = 4;

type Params = {
  onGenerateItinerary: () => void;
  isLoading: boolean;
  onSignIn: (type: 'signIn' | 'signUp' | 'index') => void;
};

export const HeroSection = ({
  onGenerateItinerary,
  isLoading,
  onSignIn,
}: Params) => {
  const [currentImage, setCurrentImage] = useState(1);
  const windowSize = useWindowSize();

  const changeImage = useCallback((direction: 'next' | 'prev') => {
    setCurrentImage((prev) => {
      if (direction === 'next') {
        return prev === TOTAL_IMAGES ? 1 : prev + 1;
      } else {
        return prev === 1 ? TOTAL_IMAGES : prev - 1;
      }
    });
  }, []);

  return (
    <div className="flex justify-center w-full">
      <div
        className={classNames(
          'relative flex flex-col justify-center',
          'mt-9 mx-2 w-full max-w-[359px]',
          '',
          'mdm:mx-7 mdm:mt-[18px] mdm:max-w-[712px]',
          'lg:mt-7 lg:mt-7 lg:w-full lg:max-w-[1440px]',
        )}
      >
        <div
          className={classNames(
            'absolute top-0 left-0',
            'flex w-full items-center justify-end',
            'my-0 mt-[15px] mx-auto',
            'mdm:mt-[10px]',
            'lg:mt-0 lg:justify-start',
            'z-10',
          )}
        >
          <LandingPageMenu
            handleGenerateItinerary={onGenerateItinerary}
            isLoading={isLoading}
            itemsSpacing="none"
            isBigButton
            isBorderNeeded
            itemsVariant="gray"
            styles="hidden lg:flex ml-[29%] lgx:ml-[23%] mr-2"
          />
          <SignInButtons onSignIn={onSignIn} />
        </div>
        <BackgroundImage currentImageIndex={currentImage} styles="">
          <div className="flex flex-col">
            <div className="flex">
              <SpriteIcon
                iconName="logoWhite"
                className={classNames(
                  'w-[120px] h-[46px]',
                  'mb-[47px] mt-5',
                  'md:mt-6 md:mb-[63px]',
                  'lg:mt-10 lg:mb-0',
                )}
              />
            </div>
          </div>
          <div
            className={classNames(
              'flex flex-col justify-between',
              'gap-y-6 mb-8',
              'md:gap-y-6 md:mb-8',
              'lg:gap-y-10',
            )}
          >
            <div className="flex items-center gap-1">
              <SpriteIcon iconName="stars" className="w-6 h-6 fill-white" />
              <p className="text-white text-left text-p-inter font-serif">
                Powered by AI
              </p>
            </div>
            <div>
              <h1 className="text-white text-h1 text-left text-[44px] mdm:text-[58px] lg:text-[88px]">
                GUESS LESS.
                <br />
                GO MORE.
              </h1>
            </div>
            <p className="text-white text-p-web text-left mdm:text-[20px] lg:text-[24px] lg:font-mono">
              A smart little travel planner for real big groups
            </p>
          </div>
          <div className="flex flex-col lg:hidden items-center h-full mb-4 mdm:mb-[35px] lg:mb-10">
            <LandingPageMenu
              handleGenerateItinerary={onGenerateItinerary}
              isLoading={isLoading}
              currentWindow={windowSize}
              itemsVariant="dark"
            />
          </div>
          <div className="hidden lg:flex relative bottom-0 right-0 mb-10 justify-between items-end ">
            <div className="hidden lg:flex grid-x-4">
              <Button
                size="medium"
                variant="tertiary"
                onClick={() => onSignIn('signIn')}
                styles={{ backgroundColor: 'transparent' }}
              >
                Sign in
              </Button>
              <Button
                size="medium"
                variant="tertiary"
                onClick={() => onSignIn('signUp')}
                styles={{
                  border: 'solid white 1px',
                  backgroundColor: 'transparent',
                }}
              >
                Sign up
              </Button>
            </div>
            <div className="hidden lg:flex justify-end gap-2">
              <Button
                iconName="arrowLeft"
                onClick={() => changeImage('prev')}
                size="small"
                variant="quinary"
              />
              <Button
                iconName="arrowRight"
                onClick={() => changeImage('next')}
                size="small"
                variant="quinary"
                styles={{ stroke: 'white', strokeWidth: 2 }}
              />
            </div>
          </div>
        </BackgroundImage>
      </div>
    </div>
  );
};
