import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useAuthEmailStore } from '@/stores/authEmailStore';
import { emailValidation } from '@/utils/yupValidations';

const LoginFinalStepSchema = yup.object().shape({
  email: emailValidation,
  password: yup.string().required('Enter your password'),
});

export type LoginFinalStepSchemaType = yup.InferType<
  typeof LoginFinalStepSchema
>;

export const useAuthLoginFinalStepForm = () => {
  const email = useAuthEmailStore((state) => state.email);

  const { control, handleSubmit, formState } =
    useForm<LoginFinalStepSchemaType>({
      mode: 'all',
      reValidateMode: 'onBlur',
      shouldFocusError: true,
      defaultValues: {
        email: email,
        password: '',
      },
      resolver: yupResolver(LoginFinalStepSchema),
    });

  const isSubmitDisabled = !formState.isValid || formState.isSubmitting;

  return {
    control,
    handleSubmitLogInForm: handleSubmit,
    isSubmitDisabled,
  };
};
